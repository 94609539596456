import {ref} from 'vue'
import {trim, replacePath} from '@/util/Util'
import apiURL from '@/bis/data/registerBranchApi'
const useBranchRegistry = (LoginAuthStore, id) => {
    const groupRef = ref({code: '', name: ''})
    const userIdRef = ref('')
    const passwdRef = ref('')
    const ssnRef = ref(null)
    const phoneNoRef = ref('') //연락처 
    const branchNameRef = ref('') // 지점명
    const bizNameRef = ref('') // 사업자명
    const repNameRef = ref('') //대표자명 
    const bizNoRef = ref(null) //사업자번호 
    const memoRef = ref('')
    const errorRef = ref({})
    const branchListRef = ref([])
    const pageInfoRef = ref([])
    const headersMap = {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': LoginAuthStore.accessToken
    }
    const clearAllFields = () => {
        groupRef.value = {code: '', name: ''}
        userIdRef.value = ''
        passwdRef.value = ''
        ssnRef.value = ''
        phoneNoRef.value = ''
        branchNameRef.value = ''
        repNameRef.value = ''
        bizNoRef.value = ''
        bizNameRef.value = ''
        memoRef.value = ''
    }

    const loadBranch = async () => {
        const url = replacePath(apiURL.loadBranch.api, id)
        fetch(`${apiURL.baseUri}${url}`,
        {
            method: apiURL.loadBranch.method,
            headers: headersMap
        }).then(res => res.json())
        .then(res => {
            if(res.success) {
                console.log(res)
                const payload = res.payload;
                groupRef.value = {code: payload.group, name: payload.groupName}
                userIdRef.value = payload.loginId
                passwdRef.value = ''
                ssnRef.value = payload.ssn
                phoneNoRef.value = payload.phoneNo
                branchNameRef.value = payload.name
                repNameRef.value = payload.representative
                bizNoRef.value = payload.businessNo
                bizNameRef.value = payload.businessName
                memoRef.value = payload.memo
            } else {
                errorRef.value.message = res.error.msg
                LoginAuthStore.redirectInvalidSession(res)
            }
        }).catch(err => {
            errorRef.value.message = err.message
        })
    }
    const listBranch = async(queryObj) => {
        let query = `?rrp=${queryObj.rrp}&pno=${queryObj.pno}`
        const filter = !queryObj.filter ? '': '&filter=' + trim(queryObj.filter)
        query = query + filter
        console.log(query)
        fetch(`${apiURL.baseUri}${apiURL.listBranch.api}${query}`,
        {
            method: apiURL.listBranch.method,
            headers: headersMap
        }).then(res => res.json())
        .then(res => {
            console.log(res)
            if(res.success) {
                branchListRef.value = !res.payload ? []: res.payload
                pageInfoRef.value = !res.pageInfo ? []: res.pageInfo;
            } else {
                errorRef.value.message = res.error.msg
                LoginAuthStore.redirectInvalidSession(res)
            }
        }).catch(err => errorRef.value.message = err.message)
    } 

    const saveBranch = async () => {
        const group = groupRef.value.code
        const userId = trim(userIdRef.value)
        const password = trim(passwdRef.value)
        const ssn = ssnRef.value ? trim(ssnRef.value) : null
        const phoneNo = trim(phoneNoRef.value)
        const branchName = trim(branchNameRef.value)
        const bizName = trim(bizNameRef.value)
        const repName = trim(repNameRef.value)
        const bizNo = bizNoRef.value ? trim(bizNoRef.value) : null
        const memo = trim(memoRef.value)
        const createMode = !id
        errorRef.value = {}
        
        if(!group) {
            errorRef.value.group = '지정그룹을 선택하세요'
        }
        if(!userId) {
            errorRef.value.userId = '지사 아이디를 입력하세요'
        }
        if(createMode && !password) {
            errorRef.value.password = '지사 패스워드를 입력하세요'
        }
        // if(!ssn) {
        //     errorRef.value.ssn = '주민등록번호를 입력하세요'
        // }
        if(phoneNo.length < 9 || phoneNo.length > 13) {
            errorRef.value.phoneNo = '지사 연락처를 올바르게 입력하세요'
        }
        if(!branchName) {
            errorRef.value.branchName = '지사명을 입력하세요'
        }
        // if(!bizName) {
        //     errorRef.value.bizName = '사업자명을 입력하세요'
        // }
        if(!repName) {
            errorRef.value.repName = '대표자명을 입력하세요'
        }
        // if(!bizNo) {
        //     errorRef.value.bizNo = '사업자번호를 입력하세요'
        // }
        const error = errorRef.value;
        if(
            error.group || error.userId || error.password || error.phoneNo 
            || error.repName || error.branchName 
            // || error.ssn
            // || error.bizName 
            // || error.bizNo
        ) {
            console.log('errorRef', error)
            return
        }
        let apiObj = createMode ? 
        apiURL.addBranch : {api: replacePath(apiURL.updateBranch.api, id), method: apiURL.updateBranch.method}
        console.log('apiObject', apiObj)
        const requestBody = {
            type: group,
            userId: userId,
            password,
            ssn,
            representative: repName,
            branchName,
            businessName: bizName,
            businessNo: bizNo,
            phoneNo,
            memo
        }
        fetch(                
            `${apiURL.baseUri}${apiObj.api}`,
            {
                method: apiObj.method,
                headers: headersMap,
                body: JSON.stringify(requestBody)
            }
        ).then(res => res.json())
        .then(res => {
            if(res.success) {
                console.log(res)
                alert(createMode? '지사를 생성하였습니다': '지사정보를 수정하였습니다')
                LoginAuthStore.getRouter.push({name:'branchList'})
            } else {
                errorRef.value.message = res.error.msg
                LoginAuthStore.redirectInvalidSession(res)
            }
        }).catch(err => {
            errorRef.value.message = err.message
        })
    }
    const deleteBranch = async() => {
        const url = replacePath(apiURL.deleteBranch.api, id)
        fetch(`${apiURL.baseUri}${url}`, {
            method: apiURL.deleteBranch.method,
            headers: headersMap
        }).then(res => res.json())
        .then(res => {
            if(res.success) {
                clearAllFields()
                console.log(res)
                alert('관리자 계정을 삭제하였습니다')
            } else {
                errorRef.value.message = res.error.msg
                LoginAuthStore.redirectInvalidSession(res)
            }
        }).catch(err => {
            errorRef.value.message = err.message
        })
    }
    const deleteBranchList = async(branchIdList) => {
        fetch(`${apiURL.baseUri}${apiURL.deleteBranchList.api}`, {
            method: apiURL.deleteBranchList.method,
            headers: headersMap,
            body: JSON.stringify(branchIdList)
        }).then(res => res.json())
        .then(res => {
            if(res.success) {
                clearAllFields()
            } else {
                errorRef.value.message = res.error.msg
                LoginAuthStore.redirectInvalidSession(res)
            }
        }).catch(err => {
            errorRef.value.message = err.message
        })
    }

    return {
        pageInfo: pageInfoRef, group: groupRef,userId: userIdRef, password: passwdRef, ssn: ssnRef, phoneNo:phoneNoRef,
        branchName: branchNameRef, bizName: bizNameRef, repName: repNameRef, bizNo:bizNoRef, memo: memoRef,
        branchList: branchListRef, error: errorRef,
        saveBranch, deleteBranch,deleteBranchList, loadBranch, listBranch
    }
}

export default useBranchRegistry