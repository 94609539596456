<template>
  <div class="modal-custom-style w-100 modal-dialog modal-dialog-centered" role="document">
    <div class="h-100 modal-content">
      <div class="row d-flex justify-content-center h-100 align-items-center">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <div class="d-flex justify-content-between align-items-center">
                <h3 class="modal-title">이전 주문</h3>
                <button id="loadOrderCloseModalForOrder" class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <h5>주문 목록</h5>
              <div class="p-1 m-t-5 d-flex align-items-center btnBox" style="border: solid rgba(175, 173, 173, 0.29) 1px">
                <div class="pe-1">
                  <Datepicker week-start="0" :day-class="isWeekendClass" class="datepicker-here datepicker-multi digits b-r-0 mr-1" :multi="true" v-model="date" range locale="ko">
                  </Datepicker>
                </div>
                <div class="pe-1 d-flex justify-content-center">
                  <select v-model="filter1Type" class="form-select btn-square digits">
                    <option value="All">전체</option>
                    <option value="CustomerName">고객명</option>
                    <option value="Address">주소</option>
                    <option value="PhoneNo">고객연락처</option>
                    <option value="ProductName">상품명</option>
                    <option value="BranchName">지점</option>
                    <option value="EngineerName">기사명</option>
                    <option value="SupplierName">업체명</option>
                    <option value="BasicPayment">장착비</option>
                    <option value="OptionPayment">추가장착비</option>
                    <option value="UninstallPayment">탈거비</option>
                  </select>
                </div>
                <div class="pe-1 d-flex justify-content-center">
                  <input v-model="filter1Val" @input="handleFilter1Val" class="form-control btn-square w-100" type="text" placeholder="">
                </div>
                <div class="pe-1 d-flex justify-content-center">
                  <select v-model="filter2Type" class="form-select btn-square digits">
                    <option value="All">전체</option>
                    <option value="CustomerName">고객명</option>
                    <option value="Address">주소</option>
                    <option value="PhoneNo">고객연락처</option>
                    <option value="ProductName">상품명</option>
                    <option value="BranchName">지점</option>
                    <option value="EngineerName">기사명</option>
                    <option value="SupplierName">업체명</option>
                    <option value="BasicPayment">장착비</option>
                    <option value="OptionPayment">추가장착비</option>
                    <option value="UninstallPayment">탈거비</option>
                  </select>
                </div>
                <div class="pe-1 d-flex justify-content-center">
                  <input v-model="filter2Val" @input="handleFilter2Val" class="form-control btn-square w-100" type="text" placeholder="">
                </div>
                <div class="pe-1 d-flex justify-content-center">
                  <select v-model="statCode" class="form-select btn-square digits">
                    <option>진행상황 검색</option>
                    <option v-for="item in progressListRef" :value="item?.code" :key="item?.code">{{ item?.name }}</option>
                  </select>
                </div>
                <div class="pe-1 d-flex justify-content-center">
                  <select v-model="workType" class="form-select btn-square digits">
                    <option value=''>유형 검색</option>
                    <option value="Install">장착</option>
                    <option value="FreeAs">무상AS</option>
                    <option value="PaidAs">유상AS</option>
                  </select>
                </div>                
                <div class="pe-1 d-flex justify-content-center">
                  <button @click="searchOrder(true)" class="btn btn-square btn-primary" type="button" title="btn btn-outline-light btn-sm">검색</button>
                </div>
                <div class="pe-1 d-flex justify-content-center">
                  <button @click="initSearchOrder" class="btn btn-square btn-outline-light txt-dark" type="button" title="btn btn-outline-light btn-sm"><i class="fa fa-refresh me-1" aria-hidden="true"></i>검색초기화</button>
                </div>                
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive mt-3">
                <EasyDataTable ref="dataTable" v-model:server-options="serverOptions" :headers="Headers" :rowsPerPage="10" :items="orderList" hide-footer header-text-direction="center"
                  border-cell body-text-direction="center" :body-row-class-name="bodyRowClassNameFunction">
                  <template #item-customerAddr="{customerAddr1, customerAddr2, extraAddr}">
                    <span>{{ `${customerAddr1 ? customerAddr1 : ''} ${customerAddr2 ? customerAddr2 : ''}` }}</span>
                  </template>
                  <template #item-progress="{ statName, statCode, reservedDateTime, completeDateTime }">
                    <div>
                      <div class="d-flex justify-content-center">
                        <span>{{ statName }}</span>
                      </div>
                      <div class="d-flex justify-content-center">
                        <span style="font-size:10px;" :style="{color: statCode === '00041' ? '' : statCode === '00042' ? 'red' : ''}">{{ statCode === '00041' && reservedDateTime ? reservedDateTime : statCode === '00042' && completeDateTime ? completeDateTime : '' }}</span>
                      </div>
                    </div>
                  </template>
                  <template #item-happycall="{hcDateTime, happyCallNth}">
                    <div class="d-flex row">
                      <div class="d-flex justify-content-center">
                        <span>{{ happyCallNth ? `${happyCallNth}차` : '-' }}</span>
                      </div>
                      <div class="d-flex justify-content-center">
                        <span style="font-size:10px;">{{ hcDateTime ? hcDateTime : '' }}</span>
                      </div>
                    </div>
                  </template>
                  <template #item-sms="{smsDateTime, smsNth}">
                    <div class="d-flex row">
                      <div class="d-flex justify-content-center">
                        <span>{{ smsNth ? `${smsNth}차` : '-' }}</span>
                      </div>
                      <div class="d-flex justify-content-center">
                        <span style="font-size:10px;">{{ smsDateTime ? smsDateTime : '' }}</span>
                      </div>
                    </div>
                  </template>
                  <template #item-detailed="{ id, workTypeCode }">
                    <router-link @click="closeModal" v-if="workTypeCode === '00030'" :to="{name: 'orderModify', params:{id}}" style="color:#1071BC">조회</router-link>
                    <router-link @click="closeModal" v-else :to="{name: 'ASModify', params:{id}}" style="color:#1071BC">조회</router-link>
                  </template>
                  <template #item-select="{ id, workTypeCode }">
                    <button v-if="workTypeCode === '00030'" class="btn btn-square btn-outline-light txt-dark w-100" @click="selectedItem(id, 'order')" data-bs-dismiss="modal">선택</button>
                    <button v-else class="btn btn-square btn-outline-light txt-dark w-100" @click="selectedItem(id, 'as')" data-bs-dismiss="modal">선택</button>
                  </template>
                </EasyDataTable>
                <div class="customize-footer">
                  <div class="customize-rows-per-page mt-3">
                    <!-- <select
                      class="select-items"
                      @change="updateRowsPerPageSelect"
                    >
                      <option
                        v-for="item in rowsPerPageOpt"
                        :key="item"
                        :selected="item === rowsPerPageActiveOption"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select> -->
                  </div>
                  <div class="customize-index">
                    <nav class="m-b-30" aria-label="">
                      <ul class="pagination justify-content-center pagination-primary">

                        <li class="page-item prev-page" @click="firstPage" :disabled="isFirstPage"><a class="page-link" href="javascript:void(0)">처음</a></li>
                        <li class="page-item" @click="prevPage" :disabled="isFirstPage"><a class="page-link" href="javascript:void(0)">이전</a></li>
                        <li v-for="paginationNumber in pageList"
                          class="page-item customize-button"
                          :class="{ 'active': paginationNumber === pageNo + 1 }"
                          @click="updatePage(paginationNumber)"
                        >
                          <a class="page-link" href="javascript:void(0)">{{ paginationNumber }}</a>
                        </li>
                        <li class="page-item" @click="nextPage" :disabled="isLastPage"><a class="page-link" href="javascript:void(0)">다음</a></li>
                        <li class="page-item next-page" @click="lastPage" :disabled="isLastPage"><a class="page-link" href="javascript:void(0)">끝</a></li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { ref, onMounted, computed, watch, defineEmits } from 'vue';
import { RouterLink, useRouter } from 'vue-router'
import { useLoginAuth } from '@/stores/LoginAuthStore'
import useOrderRegistry from '@/composables/registerOrder'
import commonApi from '@/composables/commonApi'

const router = useRouter()
const LoginAuthStore = useLoginAuth(router)
const { loadCommonCode } = commonApi(LoginAuthStore);
const { orderList, pageInfo, listOrder, getTodayReceiptCount, todayReceiptCount, loadSelectBranchList, loadSelectEngineerList, branchId, engineerId, selectBranchList, selectEngineerList, changeEngineer, deleteOrderList } = useOrderRegistry(LoginAuthStore);
const pageNo = computed(() => pageInfo.value?.pageNo);
const totalCount = computed(() => pageInfo.value?.totalCount);
const totalPages = computed(() => pageInfo.value?.totalPages);
const pageList = ref([])
const filter1Type = ref('All')
const filter2Type = ref('All')
const filter1Val = ref('')
const filter2Val = ref('')
const pno = ref(0)
const size = ref(10);
const pageNum = 10;
const saFilter = ref('None')
const statCode = ref('');
const workType = ref('');
const queryObj = ref({orderType: 'General', startDateTime: '', endDateTime: '', filter1: filter1Type.value, filter2: filter2Type.value, saFilter: saFilter.value, rrp: size.value, pno: pno.value, statCode: statCode.value, workType: workType.value});
const date = ref([]);
const progressListRef = ref([])
const isSearchClick = ref(false);

const currentPageListStart = () => {
  return Math.floor(pno.value / pageNum) * pageNum + 1
}
const paging = async () => {
  //보여지는 페이지 리셋
  pageList.value = [];

  //현재페이지 기준으로 페이징 숫자 넣기
  let pageListStart = await currentPageListStart()
  for (let i = 1; i < pageNum + 1; i++) {  
    if (totalPages.value >= pageListStart){
      pageList.value.push(pageListStart)
      pageListStart ++;
    }
  }
}

const searchOrder = async (click, filter) => {
  if (click) isSearchClick.value = true;
  if (isSearchClick.value) {
    pno.value = 0
    queryObj.value.filter1 = filter1Val.value ? `${filter1Type.value}:${filter1Val.value}` : 'All';
    queryObj.value.filter2 = filter2Val.value ? `${filter2Type.value}:${filter2Val.value}` : 'All';
    if (!filter) saFilter.value = 'None';
    queryObj.value.statCode = statCode.value
    queryObj.value.workType = workType.value
    queryObj.value.startDateTime = date.value ? dateFormatString(date?.value[0]) : null
    queryObj.value.endDateTime = date.value ? dateFormatString(date?.value[1]) : null
  }
  queryObj.value.rrp = size.value
  queryObj.value.pno = pno.value
  queryObj.value.saFilter = saFilter.value

  await listOrder(queryObj.value)
  isSearchClick.value = false;
  setTimeout(async () => {
    await paging()
  }, 1000);
}
const isOpenModal = ref(false);
const emit = defineEmits(['loadOrder']);
onMounted(() => {
  const beforeOrderModalEl = document.getElementById("beforeOrderModal");
    beforeOrderModalEl.addEventListener('shown.bs.modal', async () => {
    if (!isOpenModal.value) {
      isOpenModal.value = true;
      loadCommonCode('00004').then(res => res.json()).then(res => progressListRef.value = res.payload);
      loadSelectBranchList();
      searchOrder();
      getTodayReceiptCount();
    }
  })

  beforeOrderModalEl.addEventListener('hidden.bs.modal', async () => {
    isOpenModal.value = false;
    beforeOrderModalEl.removeEventListener('shown.bs.modal', async () => {
      if (!isOpenModal.value) {
        isOpenModal.value = true;
        loadCommonCode('00004').then(res => res.json()).then(res => progressListRef.value = res.payload);
        loadSelectBranchList();
        searchOrder();
        getTodayReceiptCount();
      }
    })
  })
})

const changeSearchOrder = async (type) => {
  saFilter.value = type;
  searchOrder(true, true);
}
const initSearchOrder = async () => {
  filter1Type.value = 'All';
  filter2Type.value = 'All';
  filter1Val.value = '';
  filter2Val.value = '';
  pno.value = 0;
  saFilter.value = "None";
  statCode.value = '';
  workType.value = '';
  date.value = null;
  queryObj.value = {orderType: 'General', startDateTime: '', endDateTime: '', filter1: filter1Type.value, filter2: filter2Type.value, saFilter: saFilter.value, rrp: size.value, pno: pno.value, statCode: statCode.value, workType: workType.value};
  searchOrder();
}
const selectedItem = async (id, type) => {
  await closeModal();
  await emit('loadOrder', id, type);
}

// $ref dataTable
const dataTable = ref();

// index related
const currentPageFirstIndex = computed(() => dataTable.value?.currentPageFirstIndex);
const currentPageLastIndex = computed(() => dataTable.value?.currentPageLastIndex);
const clientItemsLength = computed(() => dataTable.value?.clientItemsLength);

const isFirstPage = computed(() => dataTable.value?.isFirstPage);
const isLastPage = computed(() => dataTable.value?.isLastPage);

const updatePage = async (paginationNumber) => {
  pno.value = paginationNumber - 1;
  await searchOrder();
};
const lastPage = () => {
  updatePage(totalPages.value);
};
const firstPage = () => {
  updatePage(1);
};
const prevPage = () => {
  if (pno.value >= 1) updatePage(pno.value);
};
const nextPage = () => {
  if (pno.value + 2 <= totalPages.value) updatePage(pno.value + 2);
};

// rows per page related
const rowsPerPageOpt = [10, 25, 50, 100];
// const rowsPerPageOptions = computed(() => dataTable.value?.rowsPerPageOptions);
const rowsPerPageActiveOption = computed(() => dataTable.value?.rowsPerPageActiveOption);
const updateRowsPerPageSelect = async (e) => {
  size.value = Number(e.target.value);
  dataTable.value.updateRowsPerPageActiveOption(Number(e.target.value));
  await updatePage(1);
  await searchOrder()
};

const leftPad = (val) => {
  if (val >= 10) return val;
  return `0${val}`;
}
const dateFormatString = (date) => {
  if (date) {
    const year = date.getFullYear()
    const month = leftPad(date.getMonth() + 1)
    const day = leftPad(date.getDate())
    // const hh = leftPad(date.getHours())
    // const mm = leftPad(date.getMinutes())
  
    return `${[month, day, year].join('/')}`
  }
}

const conversionPhoneNo = (phoneNo) => {
  const phone = phoneNo.replace(/-/g, "");
  if (phone.length === 12) {
    return phone.replace(/^(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3`);
  } else {
    return phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }
}
const handleFilter1Val = (e) => {
  if (filter1Type.value === 'BasicPayment' || filter1Type.value === 'OptionPayment' || filter1Type.value === 'UninstallPayment') {
    filter1Val.value = e.target.value.replace(/[^0-9]/g, '');
  } else filter1Val.value = e.target.value
}
const handleFilter2Val = (e) => {
  if (filter2Type.value === 'BasicPayment' || filter2Type.value === 'OptionPayment' || filter2Type.value === 'UninstallPayment') {
    filter2Val.value = e.target.value.replace(/[^0-9]/g, '');
  } else filter2Val.value = e.target.value
}
watch(filter1Type, (newVal, oldVal) => {
  if (newVal) filter1Val.value = '';
})
watch(filter2Type, (newVal, oldVal) => {
  if (newVal) filter2Val.value = '';
})

watch(branchId, (newVal, oldVal) => {
  if (newVal) {
    engineerId.value = '';
    loadSelectEngineerList();
  }
  else if (oldVal && !newVal) {
    engineerId.value = '';
    selectEngineerList.value = [];
  }
})

const serverOptions = ref({
  page: Number(pno.value),
  rowsPerPage: Number(size.value),
})

const closeModal = () => {
  document.getElementById("loadOrderCloseModalForOrder").click();
}

const Headers = ref([
  {
    text: "접수날짜",
    value: "daterPlaced",
    sortable: false
  },
  {
    text: "업체명",
    value: "providerName",
    sortable: false
  },
  {
    text: "상품명",
    value: "productName",
    sortable: false
  },
  {
    text: "고객명",
    value: "customerName",
    sortable: false
  },
  {
    text: "연락처",
    value: "customerPhoneNo1",
    sortable: false
  },
  {
    text: "지사",
    value: "branchName",
    sortable: false
  },
  {
    text: "기사명",
    value: "engineerName",
    sortable: false
  },
  {
    text: "주소",
    value: "customerAddr",
    sortable: false
  },
  {
    text: "유형",
    value: "workTypeName",
    sortable: false
  },
  {
    text: "장착비",
    value: "basicPaymentMethod",
    sortable: false
  },
  {
    text: "추가비",
    value: "optionPaymentMethod",
    sortable: false
  },
  {
    text: "탈거비",
    value: "removalPaymentMethod",
    sortable: false
  },
  {
    text: "진행상황",
    value: "progress",
    sortable: false
  },
  {
    text: "해피콜",
    value: "happycall",
    sortable: false
  },
  {
    text: "문자",
    value: "sms",
    sortable: false
  },
  {
    text: "상세정보",
    value: "detailed",
    sortable: false
  },
  {
    text: "불러오기",
    value: "select",
    sortable: true
  },
]);
const bodyRowClassNameFunction = (item) => {
  if (item.statCode === '00041' || item.statCode === '00042') return 'checkRow'
}
const isWeekendClass = (date) => {
  if (date.getDay() === 0) return 'sunColor'
  if (date.getDay() === 6) return 'satColor'
  return
}
</script>
<style lang="scss">
.modal-custom-style {
  width: 1984px !important;
  --bs-modal-width: 1984px !important;
}
</style>