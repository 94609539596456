<template>
  <div>
    <Breadcrumbs title="상품 등록" main="상품" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <div class="col-8 d-flex justify-content-between">
                  <h5>상품 등록</h5>
              </div>
            </div>
            <div class="card-body">
              <form class="needs-validation" novalidate="" @submit.prevent="validationSubmit">
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-3 validation-name">업체명</div>
                    <div class="col-9 col-xxl-8">
                      <select class="form-select btn-square digits col-5" v-model="providerId" v-bind:class="formSubmitted ? providerIdError ? 'is-invalid' : 'is-valid' : ''">
                        <option value=''>선택하세요</option>
                        <option v-for="item in selectCompList" :value="item.id" :key="item.id">{{ item.name }}</option>
                      </select>
                      <div class="invalid-feedback" v-if="error.providerId">{{error.providerId}}</div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-3 validation-name">상품분류</div>
                    <div class="col-4">
                      <select class="form-select btn-square digits col-5" v-model="categoryId" v-bind:class="formSubmitted ? categoryIdError ? 'is-invalid' : 'is-valid' : ''">
                        <option value=''>선택하세요</option>
                        <option v-for="item in selectCompProductList" :value="item.id" :key="item.id">{{ item.name }}</option>
                      </select>
                      <div class="invalid-feedback" v-if="error.categoryId">{{error.categoryId}}</div>
                    </div>
                    <div class="col-5 ms-2">
                      <button class="btn btn-outline-light" type="button" data-bs-toggle="modal" data-bs-target="#productManage">상품 분류 관리</button>
                      <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" id="productManage" tabindex="-1" role="dialog" aria-labelledby="productManage"
                        aria-hidden="true">
                        <ProductManageModal @load-list="loadSelectCompProductList" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-3 validation-name">상품명</div>
                    <div class="col-9 col-xxl-8">
                      <input class="form-control btn-square" type="text" v-model="name" v-bind:class="formSubmitted ? nameError ? 'is-invalid' : 'is-valid' : ''" />
                      <div class="invalid-feedback" v-if="error.name">{{error.name}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3 mt-4">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-3">구분</div>
                    <div class="col-12 form-group m-checkbox-inline mb-0 custom-radio-ml">
                      <div class="radio radio-primary">
                        <input id="radioinline1" type="radio" name="radio1" value="Install" v-model="productType">
                        <label class="mb-0" for="radioinline1">장착</label>
                      </div>
                      <div class="radio radio-primary">
                        <input id="radioinline2" type="radio" name="radio1" value="As" v-model="productType">
                        <label class="mb-0" for="radioinline2">A/S</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-3">장착 업체단가</div>
                    <div class="col-9 col-xxl-8">
                      <input class="form-control btn-square" type="text" v-model="installProviderPrice" @input="handleInstallProviderPrice"/>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-3">장착 지점기준단가</div>
                    <div class="col-4">
                      <input class="form-control btn-square" type="text" v-model="installBranchPrice" @input="handleInstallBranchPrice"/>
                    </div>
                    <div class="col-5 ms-2">
                      <button class="btn btn-outline-light" type="button" data-bs-toggle="modal" data-bs-target="#productBranchManage">지점 개별 관리</button>
                      <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" id="productBranchManage" tabindex="-1" role="dialog" aria-labelledby="productBranchManage"
                        aria-hidden="true">
                        <ProductBranchManageModal v-model="productList"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-start col-6 col-xxl-5 me-4">
                    <div class="col-3">장착 표시문구</div>
                    <div class="col-9 col-xxl-8">
                      <textarea class="form-control btn-square" style="min-height: 100px;" type="text" v-model="installDescription" />
                    </div>
                  </div>
                  <div v-if="productList.length > 0" class="col-6 col-xxl-5 ms-3">
                    <div>
                      <span style="font-weight: bold;">예외 지점 단가</span>
                    </div>
                    <div v-for="(prod, idx) in productList" :key="idx">
                      <div class="col-12 d-flex align-items-center mb-1">
                        <span class="col-3 col-xxl-2">{{ prod.name }}</span>
                        <div class="d-flex col-xxl-4 col-6 ms-2 align-items-center">
                          <input class="form-control btn-square me-2" type="text" v-model="prod.price" @input="prod.price = comma(uncomma($event.target.value))" />
                          <i @click="removeSpecialPrice(prod.branchId)" class="icon-close" style="cursor: pointer;"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-3">AS 업체단가</div>
                    <div class="col-9 col-xxl-8">
                      <input class="form-control btn-square" type="text" v-model="asProviderPrice" @input="handleAsProviderPrice" />
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-3">AS 지점기준단가</div>
                    <div class="col-4">
                      <input class="form-control btn-square" type="text" v-model="asBranchPrice" @input="handleAsBranchPrice" />
                    </div>
                    <div class="col-5 ms-2">
                      <button class="btn btn-outline-light" type="button" data-bs-toggle="modal" data-bs-target="#ASBranchManage">지점 개별 관리</button>
                        <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" id="ASBranchManage" tabindex="-1" role="dialog" aria-labelledby="ASBranchManage"
                          aria-hidden="true">
                          <ASBranchManageModal v-model="asProductList"/>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-start col-6 col-xxl-5 me-4">
                    <div class="col-3">AS 표시문구</div>
                    <div class="col-9 col-xxl-8">
                      <textarea class="form-control btn-square" style="min-height: 100px;" type="text" v-model="asDescription" />
                    </div>
                  </div>
                  <div v-if="asProductList.length > 0" class="col-6 col-xxl-5 ms-3">
                    <div>
                      <span style="font-weight: bold;">예외 지점 단가</span>
                    </div>
                    <div v-for="(prod, idx) in asProductList" :key="idx">
                      <div class="col-12 d-flex align-items-center mb-1">
                        <span class="col-xxl-2 col-3">{{ prod.name }}</span>
                        <div class="d-flex col-xxl-4 col-6 ms-2 align-items-center">
                          <input class="form-control btn-square me-2" type="text" v-model="prod.price" @input="prod.price = comma(uncomma($event.target.value))" />
                          <i @click="removeSpecialASPrice(prod.branchId)" class="icon-close" style="cursor: pointer;"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-3">상품 메모</div>
                    <div class="col-9 col-xxl-8">
                      <textarea class="form-control btn-square" style="min-height: 100px;" type="text" v-model="memo"/>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3 mt-4">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-3">매뉴얼 업로드</div>
                    <div class="col-12 align-items-center d-flex">
                      <input type="file" id="manualUpload" @change="getUploadFile" hidden/>
                      <label for="manualUpload" class="btn btn-primary">찾아보기</label>
                      <span style="margin-left:10px;margin-bottom:4px;" v-if="fileChosen">{{ fileChosen }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-xxl-9 d-flex justify-content-center">
                  <button class="btn btn-outline-light" type="submit">등록</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import ProductManageModal from './ProductManageModal.vue'
import ProductBranchManageModal from './ProductBranchManageModal.vue'
import ASBranchManageModal from './ASBranchManageModal.vue'
import { useRouter } from 'vue-router'
import { useLoginAuth } from '@/stores/LoginAuthStore';
import useProductRegistry from '@/composables/registerProduct';
const router = useRouter();
const LoginAuthStore = useLoginAuth(router)
const { selectCompList, selectCompProductList, loadSelectCompList, loadSelectCompProductList,
name, categoryId, providerId, productType, installProviderPrice, installBranchPrice, installDescription,
asProviderPrice, asBranchPrice, asDescription, memo, loadProduct, saveProduct, error, uploadFile } = useProductRegistry(LoginAuthStore)
onMounted(() => {
  loadSelectCompList()
  loadSelectCompProductList()
})
const formSubmitted = ref(false);
const providerIdError = ref(false);
const categoryIdError = ref(false);
const nameError = ref(false);
const productList = ref([]);
const asProductList = ref([]);
const fileChosen = ref('No file chosen');
const removeSpecialPrice = (id) => {
  const idx = productList.value.findIndex(item => item.id === id);
  productList.value.splice(idx, 1);
}
const removeSpecialASPrice = (id) => {
  const idx = asProductList.value.findIndex(item => item.id === id);
  console.log(idx, "idx");
  asProductList.value.splice(idx, 1);
}

const createProduct = async (installPriceList, asPriceList) => {
  const exceptionalBranchPriceList = {
    installPriceList,
    asPriceList
  }
  await saveProduct(exceptionalBranchPriceList);
}

const comma = (val) => {
  val = String(val);
  return val.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
}
const uncomma = (val) => {
  val = String(val);
  return val.replace(/[^\d]+/g, '');
}
const handleInstallProviderPrice = (e) => {
  installProviderPrice.value = comma(uncomma(e.target.value))
}
const handleInstallBranchPrice = (e) => {
  installBranchPrice.value = comma(uncomma(e.target.value))
}
const handleAsProviderPrice = (e) => {
  asProviderPrice.value = comma(uncomma(e.target.value))
}
const handleAsBranchPrice = (e) => {
  asBranchPrice.value = comma(uncomma(e.target.value))
}

const validationSubmit = async () => {
  formSubmitted.value = true;
  if (providerId.value.length < 1) providerIdError.value = true;
  else providerIdError.value = false;
  
  if (categoryId.value.length < 1) categoryIdError.value = true;
  else categoryIdError.value = false;

  if (name.value.length < 1) nameError.value = true;
  else nameError.value = false;

  await createProduct(productList.value, asProductList.value);
}

const getUploadFile = (e) => {
  fileChosen.value = e.target.files[0].name;
  uploadFile.value =  e.target.files[0];
}
</script>