<template>
  <div>
    <Breadcrumbs title="기사 등록" main="기사" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <div class="col-8 d-flex justify-content-between">
                <h5>기사 등록</h5>
              </div>
            </div>
            <div class="card-body">
              <form class="needs-validation" novalidate="" @submit.prevent="validationSubmit">
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1 validation-name">지점</div>
                    <div class="col-12 col-xxl-6">
                      <select class="form-select btn-square digits col-5" v-model="branch" v-bind:class="formSubmitted ? branchError ? 'is-invalid' : 'is-valid' : ''">
                        <option value="">지점 선택</option>
                        <option v-for="item in branchCodeList" :value='item.id' :key="item.id">{{item.name}}</option>
                      </select>
                      <div class="invalid-feedback" v-if="error.branch">{{error.branch}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1 validation-name" >아이디</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="text" v-model="userId" v-bind:class="formSubmitted ? idError ? 'is-invalid' : 'is-valid' : ''"/>
                      <div class="invalid-feedback" v-if="error.userId">{{error.userId}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1 validation-name">비밀번호</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="password" v-model="password" v-bind:class="formSubmitted ? pwError ? 'is-invalid' : 'is-valid' : ''"/>
                      <div class="invalid-feedback" v-if="error.password">{{error.password}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1 validation-name">이름</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="text" v-model="name" v-bind:class="formSubmitted ? nameError ? 'is-invalid' : 'is-valid' : ''"/>
                      <div class="invalid-feedback" v-if="error.name">{{error.name}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1 validation-name">휴대폰번호</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="text" maxlength="14" v-model="phoneNo" @input="handlePhoneNo" v-bind:class="formSubmitted ? phoneError ? 'is-invalid' : 'is-valid' : ''"/>
                      <div class="invalid-feedback" v-if="error.phoneNo">{{error.phoneNo}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1">주민등록번호</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="text" v-model="ssn"/>
                      <!-- <div class="invalid-feedback" v-if="error.ssn">{{error.ssn}}</div> -->
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1">은행명</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="text" v-model="accBank"/>
                      <!-- <div class="invalid-feedback" v-if="error.accBank">{{error.accBank}}</div> -->
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1">입금명/예금주</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="text" v-model="accHolder"/>
                      <!-- <div class="invalid-feedback" v-if="error.accHolder">{{error.accHolder}}</div> -->
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1">계좌번호</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="text" v-model="accNo"/>
                      <!-- <div class="invalid-feedback" v-if="error.accNo">{{error.accNo}}</div> -->
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1">이메일</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="text" v-model="email"/>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1">우편번호</div>
                    <div class="col-9 col-xxl-5 me-3">
                      <input class="form-control btn-square" type="text" v-model="postalCd"/>
                      <!-- <div class="invalid-feedback" v-if="error.postalCd">{{error.postalCd}}</div> -->
                    </div>
                    <div class="col-3 col-xxl-2" v-bind:class="error.postalCd ? 'mb-4' : ''">
                      <PostCode @address-sought="applyAddress"/>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1">주소</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="text" v-model="addr1"/>
                      <!-- <div class="invalid-feedback" v-if="error.addr1">{{error.addr1}}</div> -->
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1">상세주소</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="text" v-model="addr2"/>
                      <!-- <div class="invalid-feedback" v-if="error.addr2">{{error.addr2}}</div> -->
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1">참고항목</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="text" v-model="remark"/>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1">메모</div>
                    <div class="col-12 col-xxl-6">
                      <textarea class="form-control btn-square" type="text" v-model="memo"/>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1">자격증 업로드</div>
                    <div class="col-12 d-flex">
                      <div v-if="pic" class="d-flex idPicture me-2">
                        <div v-if="pic" class="col-12 square-container">
                          <div @click="openPicture(pic)" class="d-flex justify-content-center align-items-center search-icon">
                            <i class="fa fa-search" style="font-size:15px;"></i>
                          </div>
                          <img class="square-image"  :src="pic ? pic : ''" />
                        </div>
                      </div>
                      <div>
                        <button v-if="!pic" class="btn btn-primary" type="button" @click="openFileInput">찾아보기</button>
                        <button v-else class="btn btn-danger" type="button" @click="pic = null">삭제</button>
                        <input type="file" ref="fileInput" @change="idPicture" hidden accept="image/gif, image/jpeg, image/png"/>
                        <div class="invalid-feedback" v-if="error.certPic">{{error.certPic}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3 mt-4">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1">상태</div>
                    <div class="col-12 form-group m-checkbox-inline mb-0 custom-radio-ml">
                      <div class="radio radio-primary">
                        <input id="radioinline1" type="radio" name="radio1" value="Y" v-model="activeYn">
                        <label class="mb-0" for="radioinline1">재직</label>
                      </div>
                      <div class="radio radio-primary">
                        <input id="radioinline2" type="radio" name="radio1" value="N" v-model="activeYn">
                        <label class="mb-0" for="radioinline2">퇴사</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="error.message" class="error">{{ error.message }}</div>
                <div class="col-12 col-xxl-5 d-flex justify-content-center">
                  <button class="btn btn-outline-light" type="submit">등록</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Swal from 'sweetalert2';
import { ref, watch, onMounted, defineAsyncComponent } from 'vue'
import PostCode from '@/components/PostCode.vue'
import { useRouter } from 'vue-router'
import { useLoginAuth } from '@/stores/LoginAuthStore';
import useEngineerRegistry from '@/composables/registerEngineer';
const asyncPicComp = defineAsyncComponent(() => import('../../components/PicComponent.vue'))
const router = useRouter();
const LoginAuthStore = useLoginAuth(router)
const {
  branchCodeList, branch, userId, name, password, ssn, phoneNo, 
  accBank, accNo, accHolder, email,
  postalCd, addr1, addr2,
  remark, memo, activeYn, certPicUri,
  error, loadBranchCodeList, saveEngineer,applyAddress, uploadPicture
} = useEngineerRegistry(LoginAuthStore)
const pic = ref(null)
const isModifyPic = ref(false);
const fileInput = ref(null);
onMounted(() => {
  loadBranchCodeList()
})
const formSubmitted = ref(false);
const branchError = ref(false);
const idError = ref(false);
const pwError = ref(false);
const nameError = ref(false);
const phoneError = ref(false);
const ssnError = ref(false);
const bankError = ref(false);
const holderError = ref(false);
const accNoError = ref(false);
const postalError = ref(false);
const addr1Error = ref(false);
const addr2Error = ref(false);
const certPicError = ref(false);

const handlePhoneNo = (e) => {
  if (e.target.value.replace(/[^0-9]/g, '').length === 12) {
    phoneNo.value = e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3`);
  } else {
    phoneNo.value = e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }
}

watch(pic, (newVal, oldVal) => {
  if (newVal) isModifyPic.value = false;
  else isModifyPic.value = true;
})

const validationSubmit = () => {
  formSubmitted.value = true;
  if (branch.value.length < 1) branchError.value = true;
  else branchError.value = false;
  
  if (userId.value.length < 1) idError.value = true;
  else idError.value = false;
  
  if (password.value.length < 1) pwError.value = true;
  else pwError.value = false;

  if (name.value.length < 1) nameError.value = true;
  else nameError.value = false;

  if (phoneNo.value.length < 9 || phoneNo.value.length > 13) phoneError.value = true;
  else phoneError.value = false;

  // if (ssn.value === null) ssnError.value = true;
  // else ssnError.value = false;

  // if (accBank.value.length < 1) bankError.value = true;
  // else bankError.value = false;

  // if (accHolder.value.length < 1) holderError.value = true;
  // else holderError.value = false;

  // if (accNo.value.length < 1) accNoError.value = true;
  // else accNoError.value = false;

  // if (postalCd.value.length < 1) postalError.value = true;
  // else postalError.value = false;

  // if (addr1.value.length < 1) addr1Error.value = true;
  // else addr1Error.value = false;

  // if (addr2.value.length < 1) addr2Error.value = true;
  // else addr2Error.value = false;

  // if (fileInput.value.files[0]) certPicError.value = false;
  // else certPicError.value = true;

  saveEngineer();
}
const openFileInput = () => {
  fileInput.value.click();
}
const idPicture = () => {
  const file = fileInput.value.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      pic.value = e.target.result;
    }
    reader.readAsDataURL(file);
  }
  uploadPicture.value = fileInput.value.files[0];
}
const openPicture = (url) => {
  new Swal({
    imageUrl: url,
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    },
    showConfirmButton: false, // "확인" 버튼 표시 안 함
    showCancelButton: true,
  });
}
</script>
<style lang="scss">
.idPicture {
  width: 219.2px;
  height: 340.1px;
}
</style>