export const orderHeader = [
  {
    text: "삭제된 날짜",
    value: "deleteDateTime",
    sortable: false
  },
  {
    text: "접수날짜",
    value: "daterPlaced",
    sortable: false
  },
  {
    text: "업체명",
    value: "providerName",
    sortable: false
  },

  {
    text: "상품명",
    value: "productName",
    sortable: false
  },
  {
    text: "고객명",
    value: "customer",
    sortable: false
  },
  {
    text: "연락처",
    value: "customerPhone",
    sortable: false
  },
  {
    text: "지사",
    value: "branchName",
    sortable: false
  },
  {
    text: "기사명",
    value: "engineerName",
    sortable: false
  },
  {
    text: "주소",
    value: "customerAddr",
    sortable: false
  },
  {
    text: "유형",
    value: "workTypeName",
    sortable: false
  },
  {
    text: "장착비",
    value: "basicPayment",
    sortable: false
  },
  {
    text: "추가비",
    value: "optionPayment",
    sortable: false
  },
  {
    text: "탈거비",
    value: "removalPayment",
    sortable: false
  },
  {
    text: "진행상황",
    value: "progress",
    sortable: false
  },
  {
    text: "해피콜",
    value: "happycall",
    sortable: false
  },
  {
    text: "문자",
    value: "sms",
    sortable: false
  },
  {
    text: "설치확인서",
    value: "installConfirm",
    sortable: false
  },
  {
    text: "상세정보",
    value: "detailed",
    sortable: false
  },
]

export const asHeader = [
  {
    text: "삭제된 날짜",
    value: "deleteDateTime",
    sortable: false
  },
  {
    text: "접수날짜",
    value: "daterPlaced",
    sortable: false
  },
  {
    text: "업체명",
    value: "providerName",
    sortable: false
  },

  {
    text: "상품명",
    value: "productName",
    sortable: false
  },
  {
    text: "고객명",
    value: "customer",
    sortable: false
  },
  {
    text: "연락처",
    value: "customerPhone",
    sortable: false
  },
  {
    text: "지사",
    value: "branchName",
    sortable: false
  },
  {
    text: "기사명",
    value: "engineerName",
    sortable: false
  },
  {
    text: "주소",
    value: "customerAddr",
    sortable: false
  },
  {
    text: "유형",
    value: "workTypeName",
    sortable: false
  },
  {
    text: "결제구분",
    value: "basicPayment",
    sortable: false
  },
  {
    text: "진행상황",
    value: "progress",
    sortable: false
  },
  {
    text: "해피콜",
    value: "happycall",
    sortable: false
  },
  {
    text: "문자",
    value: "sms",
    sortable: false
  },
  {
    text: "작동여부",
    value: "installConfirm",
    sortable: false
  },
  {
    text: "상세정보",
    value: "detailed",
    sortable: false
  },
]

export const claimHeader = [
  {
    text: "삭제된 날짜",
    value: "deleteDateTime",
    sortable: false
  },
  {
    text: "접수날짜",
    value: "daterPlaced",
    sortable: false
  },
  {
    text: "업체명",
    value: "providerName",
    sortable: false
  },

  {
    text: "상품명",
    value: "productName",
    sortable: false
  },
  {
    text: "고객명",
    value: "customer",
    sortable: false
  },
  {
    text: "연락처",
    value: "customerPhone",
    sortable: false
  },
  {
    text: "지사",
    value: "branchName",
    sortable: false
  },
  {
    text: "기사명",
    value: "engineerName",
    sortable: false
  },
  {
    text: "주소",
    value: "customerAddr",
    sortable: false
  },
  {
    text: "접수형태",
    value: "claimInbound",
    sortable: false
  },
  {
    text: "클레임분류",
    value: "claimType",
    sortable: false
  },
  {
    text: "상세정보",
    value: "detailed",
    sortable: false
  },
]


export const productHeader = [
  {
    text: "삭제된 날짜",
    value: "deleteDateTime",
    sortable: false
  },
  {
    text: "업체",
    value: "providerName",
    sortable: false
  },

  {
    text: "상품분류",
    value: "categoryName",
    sortable: false
  },
  {
    text: "상품명",
    value: "name",
    sortable: false
  },
  {
    text: "구분",
    value: "typeName",
    sortable: false
  },
  {
    text: "업체단가",
    value: "providerPrice",
    sortable: false
  },
  {
    text: "지점단가",
    value: "sellerPrice",
    sortable: false
  },
  {
    text: "상세정보",
    value: "id",
    sortable: false
  },
]

export const companyHeader = [
  {
    text: "삭제된 날짜",
    value: "deleteDateTime",
    sortable: false
  },
  {
    text: "아이디",
    value: "loginId",
    sortable: false
  },
  {
    text: "업체명",
    value: "name",
    sortable: false
  },

  {
    text: "담당자이름",
    value: "pocName",
    sortable: false
  },
  {
    text: "휴대폰번호",
    value: "phoneNo",
    sortable: false
  },
  {
    text: "상태",
    value: "activeYn",
    sortable: false
  },
  {
    text: "상세정보",
    value: "id",
    sortable: false
  },
]

export const branchHeader = [
  {
    text: "삭제된 날짜",
    value: "deleteDateTime",
    sortable: false
  },
  {
    text: "그룹",
    value: "groupName",
    sortable: false
  },
  {
    text: "아이디",
    value: "loginId",
    sortable: false
  },
  {
    text: "지점명",
    value: "name",
    sortable: false
  },

  {
    text: "대표자이름",
    value: "representative",
    sortable: false
  },
  {
    text: "연락처",
    value: "phoneNo",
    sortable: false
  },
  {
    text: "등록일",
    value: "createdAt",
    sortable: false
  },
  {
    text: "상세정보",
    value: "id",
    sortable: false
  },

]

export const engineerHeader = [
  {
    text: "삭제된 날짜",
    value: "deleteDateTime",
    sortable: false
  },
  {
    text: "그룹",
    value: "branchName",
    sortable: false
  },
  {
    text: "아이디",
    value: "loginId",
    sortable: false
  },
  {
    text: "이름",
    value: "name",
    sortable: false
  },
  {
    text: "휴대폰번호",
    value: "phoneNo",
    sortable: false
  },
  {
    text: "주소",
    value: "address",
    sortable: false
  },
  {
    text: "메모",
    value: "memo",
    sortable: false
  },
  {
    text: "상태",
    value: "activeYn",
    sortable: false
  },
  {
    text: "상세정보",
    value: "id",
    sortable: false
  },
]

export const adminHeader = [
  {
    text: "삭제된 날짜",
    value: "deleteDateTime",
    sortable: false
  },
  {
    text: "아이디",
    value: "userId",
    sortable: false
  },
  {
    text: "이름",
    value: "name",
    sortable: false
  },
  {
    text: "휴대폰번호",
    value: "phoneNo",
    sortable: false
  },
  {
    text: "등록날짜",
    value: "createdAt",
    sortable: false
  },
  {
    text: "상세정보",
    value: "id",
    sortable: false
  },
]