import { ref } from 'vue'
import { trim, replacePath } from '@/util/Util'
import apiURL from '@/bis/data/registerOrderApi'

const useOrderRegistry = (LoginAuthStore, id) => {
  const headersMap = {
    'Content-Type': 'application/json',
    'X-AUTH-TOKEN': LoginAuthStore.accessToken
  }

  const selectCompListRef = ref([]);
  const selectProdTypeRef = ref([]);
  const selectProductListRef = ref([])
  const selectBranchListRef = ref([]);
  const selectEngineerListRef = ref([]);
  const providerIdRef = ref('');
  const prodTypeIdRef = ref('');
  const engineerIdRef = ref(null);
  const loadEngineerIdRef = ref('');
  const errorRef = ref({});
  const orderListRef = ref([]);
  const pageInfoRef = ref({});
  const histPageInfoRef = ref({});
  const histDataRef = ref([]);
  const assigneeNameRef = ref('');
  const typeCodeRef = ref('');
  const customerNameRef = ref('');
  const postalCodeRef = ref('');
  const customerAddr1Ref = ref('');
  const customerAddr2Ref = ref('');
  const extraAddrRef = ref('');
  const addrTypeRef = ref(null);
  const primaryPhoneNoRef = ref('');
  const secondaryPhoneNoRef = ref('');
  const workTypeCodeRef = ref('');
  const statCodeRef = ref('00040');
  const vhcCatCodeRef = ref('');
  const vhcTypeNameRef = ref('');
  const productIdRef = ref('');
  const loadProductIdRef = ref('');
  const loadProdTypeIdRef = ref('');
  const loadProviderIdRef = ref('');
  const installDescriptionRef = ref('');
  const asDescriptionRef = ref('');
  const branchIdRef  = ref('');
  const remarkRef  = ref('');
  const memoRef  = ref('');
  const engineerMemoRef  = ref('');
  const rejectMemoRef  = ref('');
  const loadMemoRef = ref('');
  const loadEngineerMemoRef = ref('');
  const loadRejectMemoRef = ref('');
  const happyCallRequestedRef  = ref(false);
  const basicPaymentRef  = ref({
    typeCode: '01000',
    amount: 0,
    cardCompany: null,
    cardNo: null,
    cardValidPeriod: null,
    txApprovalNo: null,
    approvalDateTime: null,
    remark: null,
    desc: null,
  });
  const optionPaymentRef = ref({
    typeCode: '01000',
    amount: 0,
    cardCompany: null,
    cardNo: null,
    cardValidPeriod: null,
    txApprovalNo: null,
    approvalDateTime: null,
    remark: null,
    desc: null,
  });
  const removalPaymentRef = ref({
    typeCode: '01000',
    amount: 0,
    cardCompany: null,
    cardNo: null,
    cardValidPeriod: null,
    txApprovalNo: null,
    approvalDateTime: null,
    remark: null,
    desc: null,
  });
  const updateItemsRef = ref([]);
  const loadOrderDataRef = ref({});
  const todayReceiptCountRef = ref('');
  const checkedDetailArrayRef = ref([]);
  const happyCallMemoRef = ref('');
  const progressOrderRef = ref({})
  const resultOrderRef = ref({})
  const latRef = ref('')
  const lonRef = ref('')
  const asInfoRef = ref({
    itemCodeList: [],
    operateYn: 'N',
  })
  const claimInfoRef = ref({
    inboundCode: '',
    typeCode: ''
  })
  const uploadFilesRef = ref([])
  const excelFileRef = ref({});
  const happyCallHistRef = ref([]);
  const smsHistRef = ref([]);
  
  const certPicUriRef = ref('');
  const excelUploadedDataRef = ref({});
  const histAllRef = ref({})
  const clearAllFields = async () => {
    selectCompListRef.value = [];
    selectProdTypeRef.value = [];
    selectProductListRef.value = []
    selectBranchListRef.value = [];
    selectEngineerListRef.value = [];
    providerIdRef.value = '';
    prodTypeIdRef.value = '';
    engineerIdRef.value = null;
    errorRef.value = {};
    orderListRef.value = [];
    pageInfoRef.value = {};
    assigneeNameRef.value = '';
    typeCodeRef.value = '';
    customerNameRef.value = '';
    postalCodeRef.value = '';
    customerAddr1Ref.value = '';
    customerAddr2Ref.value = '';
    extraAddrRef.value = '';
    addrTypeRef.value = null;
    primaryPhoneNoRef.value = '';
    secondaryPhoneNoRef.value = '';
    workTypeCodeRef.value = '';
    statCodeRef.value = '';
    vhcCatCodeRef.value = '';
    vhcTypeNameRef.value = '';
    productIdRef.value = '';
    loadProductIdRef.value = '';
    loadProdTypeIdRef.value = '';
    loadProviderIdRef.value = '';
    installDescriptionRef.value = '';
    asDescriptionRef.value = '';
    branchIdRef.value  = '';
    remarkRef.value  = '';
    memoRef.value  = '';
    engineerMemoRef.value  = '';
    rejectMemoRef.value  = '';
    loadMemoRef.value = '';
    loadEngineerMemoRef.value = '';
    loadRejectMemoRef.value = '';
    happyCallRequestedRef.value  = false;
    basicPaymentRef.value  = {
      id: null,
      typeCode: null,
      amount: 0,
      cardCompany: null,
      cardNo: null,
      cardValidPeriod: null,
      txApprovalNo: null,
      approvalDateTime: null,
      desc: null,
      remark: null,
    };
    optionPaymentRef.value = {
      id: null,
      typeCode: null,
      amount: 0,
      cardCompany: null,
      cardNo: null,
      cardValidPeriod: null,
      txApprovalNo: null,
      approvalDateTime: null,
      desc: null,
      remark: null,
    };
    removalPaymentRef.value = {
      id: null,
      typeCode: null,
      amount: 0,
      cardCompany: null,
      cardNo: null,
      cardValidPeriod: null,
      txApprovalNo: null,
      approvalDateTime: null,
      desc: null,
      remark: null,
    };
    updateItemsRef.value = [];
    asInfoRef.value = {
      itemCodeList: [],
      operateYn: 'N',
    }
    checkedDetailArrayRef.value = [];
    happyCallMemoRef.value = '';
    progressOrderRef.value = {};
    uploadFilesRef.value = [];
    latRef.value = '';
    lonRef.value = '';
    excelFileRef.value = {};
    happyCallHistRef.value = [];
    smsHistRef.value = [];
    histAllRef.value = {};
  }
  const comma = (val) => {
    val = String(val);
    return val.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }
  const uncomma = (val) => {
    val = String(val);
    return val.replace(/[^\d]+/g, '');
  }
  const stringNumberToInt = (stringNumber) => {
    return parseInt(String(stringNumber).replace(/,/g , ''));
  }
  const loadSelectCompList = async () => {
    fetch(`${apiURL.baseUri}${apiURL.getSelectCompList.api}`,
    {
      method: apiURL.getSelectCompList.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
      if (res.success) {
        selectCompListRef.value = !res.payload ? []: res.payload;
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    })
  }
  const loadSelectProdTypeList = async () => {
    const supplrId = providerIdRef.value || loadProviderIdRef.value;
    let query = `?supplrId=${supplrId}`
    fetch(`${apiURL.baseUri}${apiURL.getSelectProdTypeList.api}${query}`,
    {
      method: apiURL.getSelectProdTypeList.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
      if (res.success) {
        selectProdTypeRef.value = !res.payload ? []: res.payload;
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    })
  }
  const loadSelectProductList = async () => {
    const supplrId = providerIdRef.value|| loadProviderIdRef.value;
    const prodTypeId = prodTypeIdRef.value || loadProdTypeIdRef.value;
    let query = `?supplrId=${supplrId}&catId=${prodTypeId}`
    fetch(`${apiURL.baseUri}${apiURL.productCodeList.api}${query}`,
    {
      method: apiURL.productCodeList.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
      if (res.success) {
        selectProductListRef.value = !res.payload ? []: res.payload;
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    })
  }

  const loadSelectBranchList = async () => {
    fetch(`${apiURL.baseUri}${apiURL.branchCodeList.api}`,
    {
      method: apiURL.branchCodeList.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
      if (res.success) {
        selectBranchListRef.value = !res.payload ? []: res.payload;
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    })
  }

  const loadSelectEngineerList = async () => {
    const brnchId = branchIdRef.value;
    let query = `?brnchId=${brnchId}`
    fetch(`${apiURL.baseUri}${apiURL.engineerCodeList.api}${query}`,
    {
      method: apiURL.engineerCodeList.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
      if (res.success) {
        selectEngineerListRef.value = !res.payload ? []: res.payload;
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    })
  }

  const saveOrder = async (mId, isCal, isComp) => {
    errorRef.value = {};
    const createMode = !id && !mId;
    // if (mId) id = mId;
    basicPaymentRef.value.amount = basicPaymentRef.value.amount ? stringNumberToInt(basicPaymentRef.value.amount) : 0
    optionPaymentRef.value.amount = optionPaymentRef.value.amount ? stringNumberToInt(optionPaymentRef.value.amount) : 0
    removalPaymentRef.value.amount = removalPaymentRef.value.amount ? stringNumberToInt(removalPaymentRef.value.amount) : 0
    const requestBody = {
      typeCode: typeCodeRef.value,
      customerName: customerNameRef.value,
      postalCode: postalCodeRef.value, 
      customerAddr1: customerAddr1Ref.value, 
      customerAddr2: customerAddr2Ref.value, 
      extraAddr: extraAddrRef.value,
      addrType: addrTypeRef.value,
      primaryPhoneNo: primaryPhoneNoRef.value, 
      secondaryPhoneNo: secondaryPhoneNoRef.value,
      workTypeCode: workTypeCodeRef.value, 
      statCode: statCodeRef.value, 
      vhcCatCode: vhcCatCodeRef.value,
      vhcTypeName: vhcTypeNameRef.value,
      productId: productIdRef.value, 
      branchId: branchIdRef.value, 
      engineerId: engineerIdRef.value,
      remark: remarkRef.value,
      memo: memoRef.value, 
      assigneeName: assigneeNameRef.value,
      engineerMemo: engineerMemoRef.value, 
      rejectMemo: rejectMemoRef.value, 
      happyCallRequested: happyCallRequestedRef.value,
      happyCallMemo: happyCallMemoRef.value,
      basicPayment: basicPaymentRef.value,
      optionPayment: optionPaymentRef.value, 
      removalPayment: removalPaymentRef.value,
    }
    if (!addrTypeRef.value && latRef.value && lonRef.value) {
      requestBody['lat'] = latRef.value;
      requestBody['lon'] = lonRef.value;
    }
      // updateItems: updateItemsRef.value,
    const loadOrderData = loadOrderDataRef.value;
    updateItemsRef.value = [];
    const updateItems = updateItemsRef.value
    console.log(requestBody, "requestBody");
    const error = errorRef.value;
    
    const providerId = providerIdRef.value;
    const prodTypeId = prodTypeIdRef.value;
    const productId = productIdRef.value;
    const customerName = customerNameRef.value;
    const primaryPhoneNo = primaryPhoneNoRef.value;
    const customerAddr1 = customerAddr1Ref.value;
    const postalCode = postalCodeRef.value;
    const addrType = addrTypeRef.value;
    const vhcCatCode = vhcCatCodeRef.value;
    const vhcTypeName = vhcTypeNameRef.value;
    
    const happyCallRequested = happyCallRequestedRef.value;
    const basicPayment = basicPaymentRef.value;
    const optionPayment = optionPaymentRef.value;
    const removalPayment = removalPaymentRef.value;

    // const basicDesc = basicPayment.desc;
    // const basicAmount = basicPayment.amount;
    // const basicCardCompany = basicPayment.cardCompany;
    // const basicCardNo = basicPayment.cardNo;
    // const basicCardValidPeriod = basicPayment.cardValidPeriod;
    // const basicTxApprovalNo = basicPayment.txApprovalNo;
    // const basicApprovalDateTime = basicPayment.approvalDateTime;

    // const optionDesc = optionPayment.desc;
    // const optionAmount = optionPayment.amount;
    // const optionCardCompany = optionPayment.cardCompany;
    // const optionCardNo = optionPayment.cardNo;
    // const optionCardValidPeriod = optionPayment.cardValidPeriod;
    // const optionTxApprovalNo = optionPayment.txApprovalNo;
    // const optionApprovalDateTime = optionPayment.approvalDateTime;

    // const removalDesc = removalPayment.desc;
    // const removalAmount = removalPayment.amount;
    // const removalCardCompany = removalPayment.cardCompany;
    // const removalCardNo = removalPayment.cardNo;
    // const removalCardValidPeriod = removalPayment.cardValidPeriod;
    // const removalTxApprovalNo = removalPayment.txApprovalNo;
    // const removalApprovalDateTime = removalPayment.approvalDateTime;

    // 카드 선택시
    if (basicPayment.typeCode === '01003') {
      // if (!basicDesc) errorRef.value.basicDesc = '내용을 입력하세요'
      // if (!basicAmount) errorRef.value.basicAmount = '금액을 입력하세요'
      // if (!basicCardCompany) errorRef.value.basicCardCompany = '카드사명을 입력하세요'
      // if (!basicCardNo) errorRef.value.basicCardNo = '카드번호를 입력하세요'
      // if (!basicCardValidPeriod) errorRef.value.basicCardValidPeriod = '유효기간을 입력하세요'
      // if (!basicTxApprovalNo) errorRef.value.basicTxApprovalNo = '승인번호를 입력하세요'
      // if (!basicApprovalDateTime) errorRef.value.basicApprovalDateTime = '승인일자를 입력하세요'
    }
    if (optionPayment.typeCode === '01003') {
      // if (!optionDesc) errorRef.value.optionDesc = '내용을 입력하세요'
      // if (!optionAmount) errorRef.value.optionAmount = '금액을 입력하세요'
      // if (!optionCardCompany) errorRef.value.optionCardCompany = '카드사명을 입력하세요'
      // if (!optionCardNo) errorRef.value.optionCardNo = '카드번호를 입력하세요'
      // if (!optionCardValidPeriod) errorRef.value.optionCardValidPeriod = '유효기간을 입력하세요'
      // if (!optionTxApprovalNo) errorRef.value.optionTxApprovalNo = '승인번호를 입력하세요'
      // if (!optionApprovalDateTime) errorRef.value.optionApprovalDateTime = '승인일자를 입력하세요'
    }
    if (removalPayment.typeCode === '01003') {
      // if (!removalDesc) errorRef.value.removalDesc = '내용을 입력하세요'
      // if (!removalAmount) errorRef.value.removalAmount = '금액을 입력하세요'
      // if (!removalCardCompany) errorRef.value.removalCardCompany = '카드사명을 입력하세요'
      // if (!removalCardNo) errorRef.value.removalCardNo = '카드번호를 입력하세요'
      // if (!removalCardValidPeriod) errorRef.value.removalCardValidPeriod = '유효기간을 입력하세요'
      // if (!removalTxApprovalNo) errorRef.value.removalTxApprovalNo = '승인번호를 입력하세요'
      // if (!removalApprovalDateTime) errorRef.value.removalApprovalDateTime = '승인일자를 입력하세요'
    }
    // 세금계산서 선택시
    if (basicPayment.typeCode === '01004') {
      // if (!basicDesc) errorRef.value.basicDesc = '내용을 입력하세요'
      // if (!basicAmount) errorRef.value.basicAmount = '금액을 입력하세요'
      // if (!basicApprovalDateTime) errorRef.value.basicApprovalDateTime = '계산서발행일자를 입력하세요'
    }
    if (optionPayment.typeCode === '01004') {
      // if (!optionDesc) errorRef.value.optionDesc = '내용을 입력하세요'
      // if (!optionAmount) errorRef.value.optionAmount = '금액을 입력하세요'
      // if (!optionApprovalDateTime) errorRef.value.optionApprovalDateTime = '계산서발행일자를 입력하세요'
    }
    if (removalPayment.typeCode === '01004') {
      // if (!removalDesc) errorRef.value.removalDesc = '내용을 입력하세요'
      // if (!removalAmount) errorRef.value.removalAmount = '금액을 입력하세요'
      // if (!removalApprovalDateTime) errorRef.value.removalApprovalDateTime = '계산서발행일자를 입력하세요'
    }

    if (!providerId) errorRef.value.providerId = '업체명을 선택하세요'
    if (!prodTypeId) errorRef.value.prodTypeId = '상품분류를 선택하세요'
    if (!productId) errorRef.value.productId = '상품명을 선택하세요'
    if (!customerName) errorRef.value.customerName = '고객명을 입력하세요'
    if (primaryPhoneNo.length > 15 || primaryPhoneNo.length < 1) errorRef.value.primaryPhoneNo = '전화번호를 올바르게 입력하세요'
    if (!customerAddr1) errorRef.value.customerAddr1 = '주소를 입력하세요'
    if (!vhcCatCode) errorRef.value.vhcCatCode = '차종을 선택하세요'
    if (!vhcTypeName) errorRef.value.vhcTypeName = '차량명을 입력하세요'
    console.log(error, "error");

    // if (
    //     error.providerId || error.prodTypeId || error.productId || error.customerName || error.primaryPhoneNo || error.customerAddr1 
    //     || error.vhcCatCode || error.vhcTypeName || error.basicDesc || error.basicAmount || error.basicCardCompany || error.basicCardNo
    //     || error.basicCardValidPeriod || error.basicTxApprovalNo || error.basicApprovalDateTime || error.optionDesc || error.optionAmount || error.optionCardCompany || error.optionCardNo
    //     || error.optionCardValidPeriod || error.optionTxApprovalNo || error.optionApprovalDateTime || error.removalDesc || error.removalAmount || error.removalCardCompany || error.removalCardNo
    //     || error.removalCardValidPeriod || error.removalTxApprovalNo || error.removalApprovalDateTime
    // ) {
    if (
        error.providerId || error.prodTypeId || error.productId || error.customerName || error.primaryPhoneNo || error.customerAddr1 
        || error.vhcCatCode || error.vhcTypeName || error.basicDesc || error.basicAmount || error.optionDesc || error.optionAmount || error.removalDesc || error.removalAmount
    ) {
        console.log('errorRef', error)
    } else {
      if (!createMode) {
        if (loadOrderData.customerName !== requestBody.customerName) updateItems.push('customerName');
        if (loadOrderData.postalCode !== requestBody.postalCode) updateItems.push('postalCode');
        if (loadOrderData.customerAddr1 !== requestBody.customerAddr1) updateItems.push('customerAddr1');
        if (loadOrderData.customerAddr2 !== requestBody.customerAddr2) updateItems.push('customerAddr2');
        if (loadOrderData.extraAddr !== requestBody.extraAddr) updateItems.push('extraAddr');
        if (loadOrderData.primaryPhoneNo !== requestBody.primaryPhoneNo) updateItems.push('primaryPhoneNo');
        if (loadOrderData.secondaryPhoneNo !== requestBody.secondaryPhoneNo) updateItems.push('secondaryPhoneNo');
        if (loadOrderData.workTypeCode !== requestBody.workTypeCode) updateItems.push('workTypeCode');
        if (loadOrderData.statCode !== requestBody.statCode) updateItems.push('statCode');
        if (loadOrderData.vhcCatCode !== requestBody.vhcCatCode) updateItems.push('vhcCatCode');
        if (loadOrderData.vhcTypeName !== requestBody.vhcTypeName) updateItems.push('vhcTypeName');
        if (loadOrderData.productId !== requestBody.productId) updateItems.push('productId');
        if (loadOrderData.branchId !== requestBody.branchId) updateItems.push('branchId');
        if (loadOrderData.engineerId !== requestBody.engineerId) updateItems.push('engineerId');
        if (loadOrderData.remark !== requestBody.remark) updateItems.push('remark');
        if (loadOrderData.assigneeName !== requestBody.assigneeName) updateItems.push('assigneeName');
        if (requestBody.memo) updateItems.push('memo');
        if (requestBody.engineerMemo) updateItems.push('engineerMemo');
        if (requestBody.rejectMemo) updateItems.push('rejectMemo');
        if (!(loadOrderData.basicPayment?.typeCode === requestBody.basicPayment.typeCode &&
            loadOrderData.basicPayment?.amount === requestBody.basicPayment.amount &&
            loadOrderData.basicPayment?.cardCompany === requestBody.basicPayment.cardCompany &&
            loadOrderData.basicPayment?.cardNo === requestBody.basicPayment.cardNo &&
            loadOrderData.basicPayment?.cardValidPeriod === requestBody.basicPayment.cardValidPeriod &&
            loadOrderData.basicPayment?.txApprovalNo === requestBody.basicPayment.txApprovalNo &&
            loadOrderData.basicPayment?.approvalDateTime === requestBody.basicPayment.approvalDateTime &&
            loadOrderData.basicPayment?.remark === requestBody.basicPayment.remark &&
            loadOrderData.basicPayment?.desc === requestBody.basicPayment.desc
          )) updateItems.push('basicPayment');
        if (!(loadOrderData.optionPayment?.typeCode === requestBody.optionPayment.typeCode &&
            loadOrderData.optionPayment?.amount === requestBody.optionPayment.amount &&
            loadOrderData.optionPayment?.cardCompany === requestBody.optionPayment.cardCompany &&
            loadOrderData.optionPayment?.cardNo === requestBody.optionPayment.cardNo &&
            loadOrderData.optionPayment?.cardValidPeriod === requestBody.optionPayment.cardValidPeriod &&
            loadOrderData.optionPayment?.txApprovalNo === requestBody.optionPayment.txApprovalNo &&
            loadOrderData.optionPayment?.approvalDateTime === requestBody.optionPayment.approvalDateTime &&
            loadOrderData.optionPayment?.remark === requestBody.optionPayment.remark &&
            loadOrderData.optionPayment?.desc === requestBody.optionPayment.desc
          )) updateItems.push('optionPayment');
        if (!(loadOrderData.removalPayment?.typeCode === requestBody.removalPayment.typeCode &&
            loadOrderData.removalPayment?.amount === requestBody.removalPayment.amount &&
            loadOrderData.removalPayment?.cardCompany === requestBody.removalPayment.cardCompany &&
            loadOrderData.removalPayment?.cardNo === requestBody.removalPayment.cardNo &&
            loadOrderData.removalPayment?.cardValidPeriod === requestBody.removalPayment.cardValidPeriod &&
            loadOrderData.removalPayment?.txApprovalNo === requestBody.removalPayment.txApprovalNo &&
            loadOrderData.removalPayment?.approvalDateTime === requestBody.removalPayment.approvalDateTime &&
            loadOrderData.removalPayment?.remark === requestBody.removalPayment.remark &&
            loadOrderData.removalPayment?.desc === requestBody.removalPayment.desc
          )) updateItems.push('removalPayment');
        requestBody['updateItems'] = updateItems;
      }
      let apiObj = createMode ? 
      apiURL.addOrder : {api: replacePath(apiURL.updateOrder.api, mId || id), method: apiURL.updateOrder.method}
      console.log('apiObject', apiObj)
      fetch(
          `${apiURL.baseUri}${apiObj.api}`,
          {
              method: apiObj.method,
              headers: headersMap,
              body: JSON.stringify(requestBody)
          }
      ).then(res => res.json())
      .then(res => {
        if(res.success) {
            console.log(res, "success response")
            alert(createMode ? '주문을 생성하였습니다': '주문을 수정하였습니다')
            if (isCal && isComp) LoginAuthStore.getRouter.push({name:'calculateCompList'})
            else if (isCal && !isComp) LoginAuthStore.getRouter.push({name:'calculateBISList'})
            else LoginAuthStore.getRouter.push({name:'orderList'})
            clearAllFields()
          } else {
            errorRef.value.message = res.error.msg
            LoginAuthStore.redirectInvalidSession(res)
          }
        }).catch(err => {
          errorRef.value.message = err.message
        })
    }
  }

  const listOrder = async (queryObj) => {
    let query = `?rrp=${queryObj.rrp}&pno=${queryObj.pno}&orderType=${queryObj.orderType}`
    const startDateTime = !queryObj.startDateTime ? '' : '&startDateTime=' +queryObj.startDateTime
    const endDateTime = !queryObj.endDateTime ? '' : '&endDateTime=' + queryObj.endDateTime
    const filter1 = !queryObj.filter1 ? '' : '&filter1=' + trim(queryObj.filter1)
    const filter2 = !queryObj.filter2 ? '' : '&filter2=' + trim(queryObj.filter2)
    const saFilter = !queryObj.saFilter ? '' : '&saFilter=' + trim(queryObj.saFilter)
    const statCode = !queryObj.statCode ? '' : '&statCode=' + trim(queryObj.statCode)
    const workType = !queryObj.workType ? '' : '&workType=' + trim(queryObj.workType)
    const isExOrderComplete = queryObj.saFilter === 'HapyCallInComplete' || queryObj.saFilter === 'HappyCallComplete' ? `&exOrderComplete=true` : ''
    query = query + startDateTime + endDateTime + filter1 + filter2 + saFilter + statCode + workType + isExOrderComplete
    console.log(query)
    fetch(`${apiURL.baseUri}${apiURL.getOrderList.api}${query}`,
    {
      method: apiURL.getOrderList.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
        console.log(res)
        if(res.success) {
          orderListRef.value = !res.payload ? []: res.payload;
          pageInfoRef.value = res?.pageInfo;
        } else {
          errorRef.value.message = res.error.msg
          LoginAuthStore.redirectInvalidSession(res)
        }
    }).catch(err => errorRef.value.message = err.message)
  }

  const loadOrder = async (mId, isBefore) => {
    // if (mId) id = mId;
    const url = replacePath(apiURL.loadOrder.api, mId || id)
    fetch(`${apiURL.baseUri}${url}`,
    {
      method: apiURL.loadOrder.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        // await clearAllFields();
        const payload = res.payload;
        loadOrderDataRef.value = res.payload;
        console.log(res, "response");
        assigneeNameRef.value = payload.assigneeName
        latRef.value = payload.lat
        lonRef.value = payload.lon
        // typeCodeRef.value = payload.typeCode
        customerNameRef.value = payload.customerName
        postalCodeRef.value = payload.postalCode
        customerAddr1Ref.value = payload.customerAddr1
        customerAddr2Ref.value = payload.customerAddr2
        extraAddrRef.value = payload.extraAddr
        addrTypeRef.value = payload.addrType
        primaryPhoneNoRef.value = payload.primaryPhoneNo
        secondaryPhoneNoRef.value = payload.secondaryPhoneNo
        workTypeCodeRef.value = payload.workTypeCode
        statCodeRef.value = payload.statCode
        vhcCatCodeRef.value = payload.vhcCatCode
        vhcTypeNameRef.value = payload.vhcTypeName
        productIdRef.value = payload.productId
        loadProductIdRef.value = payload.productId
        loadEngineerIdRef.value = payload.engineerId
        branchIdRef.value = payload.branchId
        remarkRef.value = payload.remark
        loadMemoRef.value = payload.memo
        loadEngineerMemoRef.value = payload.engineerMemo
        loadRejectMemoRef.value = payload.rejectMemo
        
        happyCallRequestedRef.value = payload.happyCallRequested
        basicPaymentRef.value  = {
          id: isBefore ? null : payload.basicPayment.id,
          typeCode: payload.basicPayment.typeCode,
          amount: comma(uncomma(payload.basicPayment.amount)),
          cardCompany: payload.basicPayment.cardCompany,
          cardNo: payload.basicPayment.cardNo,
          cardValidPeriod: payload.basicPayment.cardValidPeriod,
          txApprovalNo: payload.basicPayment.txApprovalNo,
          approvalDateTime: payload.basicPayment.approvalDateTime,
          remark: payload.basicPayment.remark,
          desc: payload.basicPayment.desc,
        };
        optionPaymentRef.value = {
          id: isBefore ? null : payload.optionPayment.id,
          typeCode: payload.optionPayment.typeCode,
          amount: comma(uncomma(payload.optionPayment.amount)),
          cardCompany: payload.optionPayment.cardCompany,
          cardNo: payload.optionPayment.cardNo,
          cardValidPeriod: payload.optionPayment.cardValidPeriod,
          txApprovalNo: payload.optionPayment.txApprovalNo,
          approvalDateTime: payload.optionPayment.approvalDateTime,
          remark: payload.optionPayment.remark,
          desc: payload.optionPayment.desc,
        };
        removalPaymentRef.value = {
          id: isBefore ? null : payload.removalPayment.id,
          typeCode: payload.removalPayment.typeCode,
          amount: comma(uncomma(payload.removalPayment.amount)),
          cardCompany: payload.removalPayment.cardCompany,
          cardNo: payload.removalPayment.cardNo,
          cardValidPeriod: payload.removalPayment.cardValidPeriod,
          txApprovalNo: payload.removalPayment.txApprovalNo,
          approvalDateTime: payload.removalPayment.approvalDateTime,
          remark: payload.removalPayment.remark,
          desc: payload.removalPayment.desc,
        };
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
        errorRef.value.message = err.message
    })
  }

  const loadProduct = (prodId) => {
    const url = replacePath(apiURL.loadProduct.api, prodId)
    fetch(`${apiURL.baseUri}${url}`,
    {
      method: apiURL.loadProduct.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        console.log(res)
        const payload = res.payload;
        productIdRef.value = payload.id
        loadProdTypeIdRef.value = payload.categoryId
        loadProviderIdRef.value = payload.providerId
        installDescriptionRef.value = payload.installDescription
        asDescriptionRef.value = payload.asDescription
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
        errorRef.value.message = err.message
    })
  }

  const getTodayReceiptCount = () => {
    fetch(`${apiURL.baseUri}${apiURL.todayReceiptCount.api}`,
    {
      method: apiURL.todayReceiptCount.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        console.log(res)
        const payload = res.payload;
        todayReceiptCountRef.value = payload;
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
        errorRef.value.message = err.message
    })
  }
  const sendHappycall = (message, mId) => {
    const messageBody = {
      message
    }
    if (id) mId = id; 
    fetch(`${apiURL.baseUri}${replacePath(apiURL.sendHappycall.api, mId)}`,
    {
      method: apiURL.sendHappycall.method,
      headers: headersMap,
      body: JSON.stringify(messageBody)
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        console.log(res)
        alert('해피콜이 요청되었습니다')
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
        errorRef.value.message = err.message
    })
  }

  const deleteOrder = async (id) => {
    const url = replacePath(apiURL.deleteOrder.api, id)
      fetch(`${apiURL.baseUri}${url}`, {
      method: apiURL.deleteOrder.method,
      headers: headersMap,
      body: JSON.stringify(id)
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        console.log(res, "res");
        clearAllFields();
        alert('주문을 삭제하였습니다.')

      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
        errorRef.value.message = err.message
    })  
  }
  const deleteOrderList = async(orderIdList) => {
    fetch(`${apiURL.baseUri}${apiURL.deleteOrderList.api}`, {
      method: apiURL.deleteOrderList.method,
      headers: headersMap,
      body: JSON.stringify(orderIdList)
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        clearAllFields()
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
        errorRef.value.message = err.message
    })
  }

  const modifyHistory = async (orderId, queryObj) => {
    let query = `?pno=${queryObj.pno}`
    const url = replacePath(apiURL.modifyHistory.api, orderId)
      fetch(`${apiURL.baseUri}${url}${query}`, {
      method: apiURL.modifyHistory.method,
      headers: headersMap,
    }).then(res => res.json())
    .then(res => {
      console.log(res, "modifyHistory");
      if(res.success) {
        histPageInfoRef.value = res.pageInfo
        histDataRef.value = res.payload        
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
        errorRef.value.message = err.message
    })  
  }

  const changeEngineer = async (data) => {
    fetch(`${apiURL.baseUri}${apiURL.changeEngineer.api}`, {
      method: apiURL.changeEngineer.method,
      headers: headersMap,
      body: JSON.stringify(data)
    }).then(res => res.json())
    .then(res => {
      console.log(res, "changeEngineer");
      if(res.success) {
        console.log('success');
        // clearAllFields();
        alert('기사 변경이 완료되었습니다.')
        
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
        alert(res.error.msg);
      }
    }).catch(err => {
        errorRef.value.message = err.message
    })  
  }


  const getProgressOrder = async (orderId) => {
    const url = replacePath(apiURL.getProgressOrder.api, orderId)
      fetch(`${apiURL.baseUri}${url}`, {
      method: apiURL.getProgressOrder.method,
      headers: headersMap,
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        progressOrderRef.value = res.payload
        
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
        errorRef.value.message = err.message
    })  
  }
  const getHistAll = async (orderId) => {
    const url = replacePath(apiURL.getAllHist.api, orderId)
      fetch(`${apiURL.baseUri}${url}`, {
      method: apiURL.getAllHist.method,
      headers: headersMap,
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        histAllRef.value = res.payload
        
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
        errorRef.value.message = err.message
    })  
  }

  const getResultOrder = async (orderId) => {
    const url = replacePath(apiURL.getResultOrder.api, orderId)
      fetch(`${apiURL.baseUri}${url}`, {
      method: apiURL.getResultOrder.method,
      headers: headersMap,
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        resultOrderRef.value = res.payload
        console.log(res,"resultOrder");
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
        errorRef.value.message = err.message
    })  
  }

  const saveAs = async (mId, isCal, isComp) => {
    errorRef.value = {};
    const createMode = !id && !mId;
    // if (mId) id = mId;
    basicPaymentRef.value.amount = basicPaymentRef.value.amount ? stringNumberToInt(basicPaymentRef.value.amount) : 0
    const requestBody = {
      typeCode: typeCodeRef.value,
      customerName: customerNameRef.value,
      postalCode: postalCodeRef.value, 
      customerAddr1: customerAddr1Ref.value, 
      customerAddr2: customerAddr2Ref.value, 
      extraAddr: extraAddrRef.value,
      addrType: addrTypeRef.value,
      primaryPhoneNo: primaryPhoneNoRef.value, 
      secondaryPhoneNo: secondaryPhoneNoRef.value,
      workTypeCode: workTypeCodeRef.value, 
      statCode: statCodeRef.value, 
      vhcCatCode: vhcCatCodeRef.value,
      vhcTypeName: vhcTypeNameRef.value,
      productId: productIdRef.value, 
      branchId: branchIdRef.value, 
      engineerId: engineerIdRef.value,
      remark: remarkRef.value,
      memo: memoRef.value, 
      assigneeName: assigneeNameRef.value,
      engineerMemo: engineerMemoRef.value,
      rejectMemo: rejectMemoRef.value, 
      happyCallRequested: happyCallRequestedRef.value,
      happyCallMemo: happyCallMemoRef.value,
      basicPayment: basicPaymentRef.value,
      asInfo: asInfoRef.value,
    }
    if (!addrTypeRef.value && latRef.value && lonRef.value) {
      requestBody['lat'] = latRef.value;
      requestBody['lon'] = lonRef.value;
    }
    const loadOrderData = loadOrderDataRef.value;
    const updateItems = updateItemsRef.value
    if (!createMode) {
      if (loadOrderData.customerName !== requestBody.customerName) updateItems.push('customerName');
      if (loadOrderData.postalCode !== requestBody.postalCode) updateItems.push('postalCode');
      if (loadOrderData.customerAddr1 !== requestBody.customerAddr1) updateItems.push('customerAddr1');
      if (loadOrderData.customerAddr2 !== requestBody.customerAddr2) updateItems.push('customerAddr2');
      if (loadOrderData.extraAddr !== requestBody.extraAddr) updateItems.push('extraAddr');
      if (loadOrderData.primaryPhoneNo !== requestBody.primaryPhoneNo) updateItems.push('primaryPhoneNo');
      if (loadOrderData.secondaryPhoneNo !== requestBody.secondaryPhoneNo) updateItems.push('secondaryPhoneNo');
      if (loadOrderData.workTypeCode !== requestBody.workTypeCode) updateItems.push('workTypeCode');
      if (loadOrderData.statCode !== requestBody.statCode) updateItems.push('statCode');
      if (loadOrderData.vhcCatCode !== requestBody.vhcCatCode) updateItems.push('vhcCatCode');
      if (loadOrderData.vhcTypeName !== requestBody.vhcTypeName) updateItems.push('vhcTypeName');
      if (loadOrderData.productId !== requestBody.productId) updateItems.push('productId');
      if (loadOrderData.branchId !== requestBody.branchId) updateItems.push('branchId');
      if (loadOrderData.engineerId !== requestBody.engineerId) updateItems.push('engineerId');
      if (loadOrderData.remark !== requestBody.remark) updateItems.push('remark');
      if (requestBody.memo) updateItems.push('memo');
      if (requestBody.engineerMemo) updateItems.push('engineerMemo');
      if (requestBody.rejectMemo) updateItems.push('rejectMemo');
      if (requestBody.assigneeName) updateItems.push('assigneeName');
      if (!(loadOrderData.basicPayment.typeCode === requestBody.basicPayment.typeCode &&
        loadOrderData.basicPayment.amount === requestBody.basicPayment.amount &&
        loadOrderData.basicPayment.cardCompany === requestBody.basicPayment.cardCompany &&
        loadOrderData.basicPayment.cardNo === requestBody.basicPayment.cardNo &&
        loadOrderData.basicPayment.cardValidPeriod === requestBody.basicPayment.cardValidPeriod &&
        loadOrderData.basicPayment.txApprovalNo === requestBody.basicPayment.txApprovalNo &&
        loadOrderData.basicPayment.approvalDateTime === requestBody.basicPayment.approvalDateTime &&
        loadOrderData.basicPayment.remark === requestBody.basicPayment.remark &&
        loadOrderData.basicPayment.desc === requestBody.basicPayment.desc
        )) updateItems.push('basicPayment');
      if (loadOrderData?.asInfo?.itemCodeList !== requestBody?.asInfo?.itemCodeList) updateItems.push('asInfo.itemCodeList');
      if (loadOrderData?.asInfo?.operateYn !== requestBody?.asInfo?.operateYn) updateItems.push('asInfo.operateYn');
      requestBody['updateItems'] = updateItems;
    }
    const error = errorRef.value;
    
    const providerId = providerIdRef.value;
    const prodTypeId = prodTypeIdRef.value;
    const productId = productIdRef.value;
    const customerName = customerNameRef.value;
    const primaryPhoneNo = primaryPhoneNoRef.value;
    const customerAddr1 = customerAddr1Ref.value;
    const workTypeCode = workTypeCodeRef.value;
    const remark = remarkRef.value;
    
    const happyCallRequested = happyCallRequestedRef.value;
    const basicPayment = basicPaymentRef.value;

    const basicDesc = basicPayment.desc;
    const basicAmount = basicPayment.amount;
    const basicCardCompany = basicPayment.cardCompany;
    const basicCardNo = basicPayment.cardNo;
    const basicCardValidPeriod = basicPayment.cardValidPeriod;
    const basicTxApprovalNo = basicPayment.txApprovalNo;
    const basicApprovalDateTime = basicPayment.approvalDateTime;

    // 카드 선택시
    if (basicPayment.typeCode === '01003') {
      // if (!basicDesc) errorRef.value.basicDesc = '내용을 입력하세요'
      // if (!basicAmount) errorRef.value.basicAmount = '금액을 입력하세요'
      // if (!basicCardCompany) errorRef.value.basicCardCompany = '카드사명을 입력하세요'
      // if (!basicCardNo) errorRef.value.basicCardNo = '카드번호를 입력하세요'
      // if (!basicCardValidPeriod) errorRef.value.basicCardValidPeriod = '유효기간을 입력하세요'
      // if (!basicTxApprovalNo) errorRef.value.basicTxApprovalNo = '승인번호를 입력하세요'
      // if (!basicApprovalDateTime) errorRef.value.basicApprovalDateTime = '승인일자를 입력하세요'
    }
    // 세금계산서 선택시
    if (basicPayment.typeCode === '01004') {
      // if (!basicDesc) errorRef.value.basicDesc = '내용을 입력하세요'
      // if (!basicAmount) errorRef.value.basicAmount = '금액을 입력하세요'
      // if (!basicApprovalDateTime) errorRef.value.basicApprovalDateTime = '계산서발행일자를 입력하세요'
    }

    if (!providerId) errorRef.value.providerId = '업체명을 선택하세요'
    if (!prodTypeId) errorRef.value.prodTypeId = '상품분류를 선택하세요'
    if (!productId) errorRef.value.productId = '상품명을 선택하세요'
    if (!customerName) errorRef.value.customerName = '고객명을 입력하세요'
    if (primaryPhoneNo.length > 13 || primaryPhoneNo.length < 9) errorRef.value.primaryPhoneNo = '전화번호를 올바르게 입력하세요'
    if (!customerAddr1) errorRef.value.customerAddr1 = '주소를 입력하세요'
    if (!workTypeCode) errorRef.value.workTypeCode = '작업유형을 선택하세요'
    if (!remark) errorRef.value.remark = '불량증상을 입력하세요'
    console.log(error, "error");

    // if (
    //   error.providerId || error.prodTypeId || error.productId || error.customerName || error.primaryPhoneNo || error.customerAddr1 
    //   || error.basicDesc || error.basicAmount || error.basicCardCompany || error.basicCardNo
    //   || error.basicCardValidPeriod || error.basicTxApprovalNo || error.basicApprovalDateTime || error.remark || error.workTypeCode
    // ) {
    if (
      error.providerId || error.prodTypeId || error.productId || error.customerName || error.primaryPhoneNo || error.customerAddr1 
      || error.basicDesc || error.basicAmount || error.remark || error.workTypeCode
    ) {
        console.log('errorRef', error)
    } else {
      let apiObj = createMode ? 
      apiURL.addAs : {api: replacePath(apiURL.updateAs.api, mId || id), method: apiURL.updateAs.method}
      console.log('apiObject', apiObj)
      fetch(                
        `${apiURL.baseUri}${apiObj.api}`,
        {
          method: apiObj.method,
          headers: headersMap,
          body: JSON.stringify(requestBody)
        }
      ).then(res => res.json())
      .then(res => {
        if(res.success) {
          console.log(res, "success response")
          alert(createMode ? 'A/S 주문을 생성하였습니다': 'A/S 주문을 수정하였습니다')
          if (isCal && isComp) LoginAuthStore.getRouter.push({name:'calculateCompList'})
          else if (isCal && !isComp) LoginAuthStore.getRouter.push({name:'calculateBISList'})
          else LoginAuthStore.getRouter.push({name:'orderList'})
        } else {
          errorRef.value.message = res.error.msg
          LoginAuthStore.redirectInvalidSession(res)
        }
      }).catch(err => {
        errorRef.value.message = err.message
      }).finally(() => {
        clearAllFields()
      })
    }
  }

  const loadAs = async (mId) => {
    // if (mId) id = mId;
    const url = replacePath(apiURL.loadAs.api, mId || id)
    fetch(`${apiURL.baseUri}${url}`,
    {
      method: apiURL.loadAs.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        const payload = res.payload;
        loadOrderDataRef.value = res.payload;
        console.log(res, "AS response");
        assigneeNameRef.value = payload.assigneeName
        // typeCodeRef.value = payload.typeCode
        latRef.value = payload.lat
        lonRef.value = payload.lon
        customerNameRef.value = payload.customerName
        postalCodeRef.value = payload.postalCode
        customerAddr1Ref.value = payload.customerAddr1
        customerAddr2Ref.value = payload.customerAddr2
        extraAddrRef.value = payload.extraAddr
        addrTypeRef.value = payload.addrType
        primaryPhoneNoRef.value = payload.primaryPhoneNo
        secondaryPhoneNoRef.value = payload.secondaryPhoneNo
        workTypeCodeRef.value = payload.workTypeCode
        statCodeRef.value = payload.statCode
        vhcCatCodeRef.value = payload.vhcCatCode
        vhcTypeNameRef.value = payload.vhcTypeName
        productIdRef.value = payload.productId
        loadProductIdRef.value = payload.productId
        loadEngineerIdRef.value = payload.engineerId
        branchIdRef.value = payload.branchId
        remarkRef.value = payload.remark
        loadMemoRef.value = payload.memo
        loadEngineerMemoRef.value = payload.engineerMemo
        loadRejectMemoRef.value = payload.rejectMemo

        asInfoRef.value = {
          operateYn: payload.asInfo.operateYn,
          itemCodeList: payload.asInfo.itemCodeList,
        }

        happyCallRequestedRef.value = payload.happyCallRequested
        basicPaymentRef.value  = {
          id: payload.basicPayment.id,
          typeCode: payload.basicPayment.typeCode,
          amount: comma(uncomma(payload.basicPayment.amount)),
          cardCompany: payload.basicPayment.cardCompany,
          cardNo: payload.basicPayment.cardNo,
          cardValidPeriod: payload.basicPayment.cardValidPeriod,
          txApprovalNo: payload.basicPayment.txApprovalNo,
          approvalDateTime: payload.basicPayment.approvalDateTime,
          remark: payload.basicPayment.remark,
          desc: payload.basicPayment.desc,
        };
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
        errorRef.value.message = err.message
    })
  }



  const saveClaim = async (mId) => {
    errorRef.value = {};
    const createMode = !id && !mId;
    // if (mId) id = mId;
    const requestBody = {
      typeCode: typeCodeRef.value,
      customerName: customerNameRef.value,
      postalCode: postalCodeRef.value, 
      customerAddr1: customerAddr1Ref.value, 
      customerAddr2: customerAddr2Ref.value, 
      extraAddr: extraAddrRef.value,
      addrType: addrTypeRef.value,
      primaryPhoneNo: primaryPhoneNoRef.value, 
      secondaryPhoneNo: secondaryPhoneNoRef.value,
      statCode: statCodeRef.value, 
      vhcCatCode: vhcCatCodeRef.value,
      vhcTypeName: vhcTypeNameRef.value,
      productId: productIdRef.value, 
      branchId: branchIdRef.value, 
      engineerId: engineerIdRef.value,
      memo: memoRef.value, 
      engineerMemo: engineerMemoRef.value,
      rejectMemo: rejectMemoRef.value, 
      claimInfo: claimInfoRef.value,
    }
    if (!addrTypeRef.value && latRef.value && lonRef.value) {
      requestBody['lat'] = latRef.value;
      requestBody['lon'] = lonRef.value;
    }    
    const loadOrderData = loadOrderDataRef.value;
    const updateItems = updateItemsRef.value
    if (!createMode) {
      if (loadOrderData.customerName !== requestBody.customerName) updateItems.push('customerName');
      if (loadOrderData.postalCode !== requestBody.postalCode) updateItems.push('postalCode');
      if (loadOrderData.customerAddr1 !== requestBody.customerAddr1) updateItems.push('customerAddr1');
      if (loadOrderData.customerAddr2 !== requestBody.customerAddr2) updateItems.push('customerAddr2');
      if (loadOrderData.extraAddr !== requestBody.extraAddr) updateItems.push('extraAddr');
      if (loadOrderData.primaryPhoneNo !== requestBody.primaryPhoneNo) updateItems.push('primaryPhoneNo');
      if (loadOrderData.secondaryPhoneNo !== requestBody.secondaryPhoneNo) updateItems.push('secondaryPhoneNo');
      if (loadOrderData.statCode !== requestBody.statCode) updateItems.push('statCode');
      if (loadOrderData.vhcCatCode !== requestBody.vhcCatCode) updateItems.push('vhcCatCode');
      if (loadOrderData.vhcTypeName !== requestBody.vhcTypeName) updateItems.push('vhcTypeName');
      if (loadOrderData.productId !== requestBody.productId) updateItems.push('productId');
      if (loadOrderData.branchId !== requestBody.branchId) updateItems.push('branchId');
      if (loadOrderData.engineerId !== requestBody.engineerId) updateItems.push('engineerId');
      if (requestBody.memo) updateItems.push('memo');
      if (loadOrderData.claimInfo.inboundCode !== requestBody.claimInfo.inboundCode) updateItems.push('claimInfo.inboundCode');
      if (loadOrderData.claimInfo.typeCode !== requestBody.claimInfo.typeCode) updateItems.push('claimInfo.typeCode');
      requestBody['updateItems'] = updateItems;
    }
    console.log(requestBody, "requestBody");
    const error = errorRef.value;
    
    const providerId = providerIdRef.value;
    const prodTypeId = prodTypeIdRef.value;
    const productId = productIdRef.value;
    const customerName = customerNameRef.value;
    const customerAddr1 = customerAddr1Ref.value;
    const primaryPhoneNo = primaryPhoneNoRef.value;
    const branchId = branchIdRef.value;
    const engineerId = engineerIdRef.value;
    const inboundCode = claimInfoRef.value.inboundCode;
    const typeCode = claimInfoRef.value.typeCode;

    if (!providerId) errorRef.value.providerId = '업체명을 선택하세요'
    if (!prodTypeId) errorRef.value.prodTypeId = '상품분류를 선택하세요'
    if (!productId) errorRef.value.productId = '상품명을 선택하세요'
    if (!customerName) errorRef.value.customerName = '고객명을 입력하세요'
    if (primaryPhoneNo.length > 13 || primaryPhoneNo.length < 9) errorRef.value.primaryPhoneNo = '전화번호를 올바르게 입력하세요'
    if (!customerAddr1) errorRef.value.customerAddr1 = '주소를 입력하세요'
    if (!branchId) errorRef.value.branchId = '지사를 선택하세요'
    if (!engineerId) errorRef.value.engineerId = '설치기사를 선택하세요'
    if (inboundCode.length === 0) errorRef.value.inboundCode = '접수형태를 선택하세요'
    if (typeCode.length === 0) errorRef.value.typeCode = '분류를 선택하세요'
    console.log(error, "error");

    if (
      error.providerId || error.prodTypeId || error.productId || error.customerName || error.primaryPhoneNo
      || error.branchId || error.engineerId || error.inboundCode || error.typeCode || error.customerAddr1
    ) {
        console.log('errorRef', error)
    } else {
      const uploadFiles = uploadFilesRef.value
      let apiObj = createMode ? 
      apiURL.addClaim : {api: replacePath(apiURL.updateClaim.api, mId || id), method: apiURL.updateClaim.method}
      console.log('apiObject', apiObj)
      fetch(                
        `${apiURL.baseUri}${apiObj.api}`,
        {
          method: apiObj.method,
          headers: headersMap,
          body: JSON.stringify(requestBody)
        }
      ).then(res => res.json())
      .then(async res => {
        if(res.success) {
          if (uploadFiles.length) await uploadClaimPic(res.payload);
          console.log(res, "success response")
          alert(createMode ? '클레임을 생성하였습니다': '클레임을 수정하였습니다')
          LoginAuthStore.getRouter.push({name:'claimList'})
        } else {
          errorRef.value.message = res.error.msg
          LoginAuthStore.redirectInvalidSession(res)
        }
      }).catch(err => {
        errorRef.value.message = err.message
      }).finally(() => {
        clearAllFields()
      })
    }
  }

  const loadClaim = async (mId) => {
    // if (mId) id = mId;
    const url = replacePath(apiURL.loadClaim.api, mId || id)
    fetch(`${apiURL.baseUri}${url}`,
    {
      method: apiURL.loadClaim.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        const payload = res.payload;
        loadOrderDataRef.value = res.payload;
        console.log(res, "Claim response");
        assigneeNameRef.value = payload.assigneeName
        // typeCodeRef.value = payload.typeCode
        latRef.value = payload.lat
        lonRef.value = payload.lon
        customerNameRef.value = payload.customerName
        postalCodeRef.value = payload.postalCode
        customerAddr1Ref.value = payload.customerAddr1
        customerAddr2Ref.value = payload.customerAddr2
        extraAddrRef.value = payload.extraAddr
        addrTypeRef.value = payload.addrType
        primaryPhoneNoRef.value = payload.primaryPhoneNo
        secondaryPhoneNoRef.value = payload.secondaryPhoneNo
        statCodeRef.value = payload.statCode
        vhcCatCodeRef.value = payload.vhcCatCode
        vhcTypeNameRef.value = payload.vhcTypeName
        productIdRef.value = payload.productId
        loadProductIdRef.value = payload.productId
        loadEngineerIdRef.value = payload.engineerId
        branchIdRef.value = payload.branchId
        loadMemoRef.value = payload.memo
        claimInfoRef.value = {
          inboundCode: payload.claimInfo.inboundCode,
          typeCode: payload.claimInfo.typeCode,
          result: payload.claimInfo.result,
          pic1: payload.claimInfo.pic1,
          pic2: payload.claimInfo.pic2,
          pic3: payload.claimInfo.pic3,
          pic4: payload.claimInfo.pic4,
          pic5: payload.claimInfo.pic5,
          pic6: payload.claimInfo.pic6,
        }
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
        errorRef.value.message = err.message
    })
  }

  const listClaim = async (queryObj) => {
    let query = `?rrp=${queryObj.rrp}&pno=${queryObj.pno}`
    const startDateTime = !queryObj.startDateTime ? '' : '&startDateTime=' +queryObj.startDateTime
    const endDateTime = !queryObj.endDateTime ? '' : '&endDateTime=' + queryObj.endDateTime
    const filter1 = !queryObj.filter1 ? '': '&filter1=' + trim(queryObj.filter1)
    const filter2 = !queryObj.filter2 ? '': '&filter2=' + trim(queryObj.filter2)
    const saFilter = !queryObj.saFilter ? '': '&saFilter=' + trim(queryObj.saFilter)
    query = query + startDateTime + endDateTime + filter1 + filter2 + saFilter
    console.log(query)
    fetch(`${apiURL.baseUri}${apiURL.listClaim.api}${query}`,
    {
      method: apiURL.listClaim.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
        console.log(res)
        if(res.success) {
          orderListRef.value = !res.payload ? []: res.payload;
          pageInfoRef.value = res?.pageInfo;
        } else {
          errorRef.value.message = res.error.msg
          LoginAuthStore.redirectInvalidSession(res)
        }
    }).catch(err => errorRef.value.message = err.message)
  }

  const uploadClaimPic = async (id) => {
    const uploadFiles = uploadFilesRef.value;
    const formData = new FormData();
    for (let i = 0; i < uploadFiles.length; i++) {
      formData.append("files", uploadFiles[i]);
    }
    const headers = {
      'X-AUTH-TOKEN': LoginAuthStore.accessToken,
    }
    await fetch(`${apiURL.baseUri}${replacePath(apiURL.uploadClaimPics.api, id)}`,
    {
      method: apiURL.uploadClaimPics.method,
      headers,
      body: formData
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        console.log(res, "payload upload");
        const payload = res.payload;
        if(!payload) payload = [];
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
      console.error(err);
      errorRef.value.message = err.message
    })
  }

  const formExcelDownLoad = async (type) => {
    let query = `?uri=${type === 'order' ? 'order/xls-template/order.xlsx' : type === 'as' ? 'order/xls-template/as.xlsx' : 'order/xls-template/claim.xlsx'}`
    await fetch(`${apiURL.baseUri}${apiURL.formExcelDownLoad.api}${query}`,
    {
      method: apiURL.formExcelDownLoad.method,
      headers: headersMap,
    }).then(async res => {
      if (res.status === 200) {
        const blob = await res.blob();
  
        const newBlob = new Blob([blob]);
        const newUrl = window.URL.createObjectURL(newBlob);
  
        const link = document.createElement('a');
        link.href = newUrl;
        link.setAttribute('download', `${type}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
  
        window.URL.revokeObjectURL(newBlob);
      } else {
        console.log(res, "excel false RES");
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    })
    .catch(err => {
      console.error(err);
      errorRef.value.message = err.message
    })
  }

  const excelUpload = async (type) => {
    const formData = new FormData();
    formData.append("file", excelFileRef.value);
    const headers = {
      'X-AUTH-TOKEN': LoginAuthStore.accessToken,
    }
    const url = type === 'order' ? apiURL.orderExcelUpload.api : type === 'as' ? apiURL.asExcelUpload.api : ''
    const method = type === 'order' ? apiURL.orderExcelUpload.method : type === 'as' ? apiURL.asExcelUpload.method : ''
    await fetch(`${apiURL.baseUri}${url}`,
    {
      method,
      headers,
      body: formData
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        console.log(res, "payload upload");
        const payload = res.payload;
        excelUploadedDataRef.value = res.payload;
        if(!payload) payload = [];
        clearAllFields();
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
      console.error(err);
      errorRef.value.message = err.message
    })
  }
  
  const happyCallHist = async (queryObj, mId) => {
    let query = `?rrp=${queryObj.rrp}&pno=${queryObj.pno}`
    fetch(`${apiURL.baseUri}${replacePath(apiURL.happyCallHist.api, mId)}${query}`,
    {
      method: apiURL.happyCallHist.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
        console.log(res)
        if(res.success) {
          happyCallHistRef.value = !res.payload ? []: res.payload;
          pageInfoRef.value = res?.pageInfo;
        } else {
          errorRef.value.message = res.error.msg
          LoginAuthStore.redirectInvalidSession(res)
        }
    }).catch(err => errorRef.value.message = err.message)
  }

  const smsHist = async (queryObj, mId) => {
    let query = `?rrp=${queryObj.rrp}&pno=${queryObj.pno}`
    fetch(`${apiURL.baseUri}${replacePath(apiURL.smsHist.api, mId)}${query}`,
    {
      method: apiURL.smsHist.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
        console.log(res)
        if(res.success) {
          smsHistRef.value = !res.payload ? []: res.payload;
          pageInfoRef.value = res?.pageInfo;
        } else {
          errorRef.value.message = res.error.msg
          LoginAuthStore.redirectInvalidSession(res)
        }
    }).catch(err => errorRef.value.message = err.message)
  }
  
  const loadEngineer = async (mId) => {
    const url = replacePath(apiURL.loadEngineer.api, mId)
    fetch(`${apiURL.baseUri}${url}`,
    {
      method: apiURL.loadEngineer.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        console.log(res)
        const payload = res.payload;
        if (payload.certPicUri) certPicUriRef.value = payload.certPicUri
        else alert('자격증 사진이 없습니다.')
      } else {
        alert(res.error.msg);
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
      errorRef.value.message = err.message
    })
  }

  const orderListExcelDownloadSelected = async (idList, isExcelDown) => {
    let query = '';
    idList.forEach((n, i) => {
      if (i === 0) query = `?orderIdList=${n}`
      else query += `&orderIdList=${n}`
    })
    fetch(`${apiURL.baseUri}${apiURL.orderListExcelDownloadSelected.api}${query}`,
    {
      method: apiURL.orderListExcelDownloadSelected.method,
      headers: headersMap
    }).then(async res => {
      if (res.status === 200) {
        const blob = await res.blob();
  
        const newBlob = new Blob([blob]);
        const newUrl = window.URL.createObjectURL(newBlob);
  
        const link = document.createElement('a');
        link.href = newUrl;
        link.setAttribute('download', 'orderList.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
  
        window.URL.revokeObjectURL(newBlob);
        isExcelDown.value = false;
      } else {
        console.log(res, "excel false RES");
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    })
    .catch(err => {
      console.error(err);
      errorRef.value.message = err.message
    })
  }

  const orderListExcelDownload = async (queryObj, isExcelDown) => {
    let query = `?rrp=${queryObj.rrp}&pno=${queryObj.pno}&orderType=${queryObj.orderType}`
    const startDateTime = !queryObj.startDateTime ? '' : '&startDateTime=' +queryObj.startDateTime
    const endDateTime = !queryObj.endDateTime ? '' : '&endDateTime=' + queryObj.endDateTime
    const filter1 = !queryObj.filter1 ? '' : '&filter1=' + trim(queryObj.filter1)
    const filter2 = !queryObj.filter2 ? '' : '&filter2=' + trim(queryObj.filter2)
    const saFilter = !queryObj.saFilter ? '' : '&saFilter=' + trim(queryObj.saFilter)
    const statCode = !queryObj.statCode ? '' : '&statCode=' + trim(queryObj.statCode)
    const workType = !queryObj.workType ? '' : '&workType=' + trim(queryObj.workType)
    query = query + startDateTime + endDateTime + filter1 + filter2 + saFilter + statCode + workType
    console.log(query)   
    await fetch(`${apiURL.baseUri}${apiURL.orderListExcelDownload.api}${query}`,
    {
      method: apiURL.orderListExcelDownload.method,
      headers: headersMap,
    }).then(async res => {
      if (res.status === 200) {
        const blob = await res.blob();
  
        const newBlob = new Blob([blob]);
        const newUrl = window.URL.createObjectURL(newBlob);
  
        const link = document.createElement('a');
        link.href = newUrl;
        link.setAttribute('download', 'orderList.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
  
        window.URL.revokeObjectURL(newBlob);
        isExcelDown.value = false;
      } else {
        console.log(res, "excel false RES");
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    })
    .catch(err => {
      console.error(err);
      errorRef.value.message = err.message
    })
  }
  const claimListExcelDownload = async (queryObj, isExcelDown) => {
    let query = `?rrp=${queryObj.rrp}&pno=${queryObj.pno}`
    const startDateTime = !queryObj.startDateTime ? '' : '&startDateTime=' +queryObj.startDateTime
    const endDateTime = !queryObj.endDateTime ? '' : '&endDateTime=' + queryObj.endDateTime
    const filter1 = !queryObj.filter1 ? '': '&filter1=' + trim(queryObj.filter1)
    const filter2 = !queryObj.filter2 ? '': '&filter2=' + trim(queryObj.filter2)
    const saFilter = !queryObj.saFilter ? '': '&saFilter=' + trim(queryObj.saFilter)
    query = query + startDateTime + endDateTime + filter1 + filter2 + saFilter
    await fetch(`${apiURL.baseUri}${apiURL.claimListExcelDownload.api}${query}`,
    {
      method: apiURL.claimListExcelDownload.method,
      headers: headersMap,
    }).then(async res => {
      if (res.status === 200) {
        const blob = await res.blob();
  
        const newBlob = new Blob([blob]);
        const newUrl = window.URL.createObjectURL(newBlob);
  
        const link = document.createElement('a');
        link.href = newUrl;
        link.setAttribute('download', 'claimList.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
  
        window.URL.revokeObjectURL(newBlob);
        isExcelDown.value = false;
      } else {
        console.log(res, "excel false RES");
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    })
    .catch(err => {
      console.error(err);
      errorRef.value.message = err.message
    })
  }

  const claimListExcelDownloadSelected = async (idList, isExcelDown) => {
    let query = '';
    idList.forEach((n, i) => {
      if (i === 0) query = `?orderIdList=${n}`
      else query += `&orderIdList=${n}`
    })
    fetch(`${apiURL.baseUri}${apiURL.claimListExcelDownloadSelected.api}${query}`,
    {
      method: apiURL.claimListExcelDownloadSelected.method,
      headers: headersMap
    }).then(async res => {
      if (res.status === 200) {
        const blob = await res.blob();
  
        const newBlob = new Blob([blob]);
        const newUrl = window.URL.createObjectURL(newBlob);
  
        const link = document.createElement('a');
        link.href = newUrl;
        link.setAttribute('download', 'claimList.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
  
        window.URL.revokeObjectURL(newBlob);
        isExcelDown.value = false;
      } else {
        console.log(res, "excel false RES");
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    })
    .catch(err => {
      console.error(err);
      errorRef.value.message = err.message
    })
  }

  return {
    selectCompList: selectCompListRef, selectProdType: selectProdTypeRef, selectProductList: selectProductListRef, selectBranchList: selectBranchListRef, selectEngineerList: selectEngineerListRef,
    providerId: providerIdRef, prodTypeId: prodTypeIdRef, loadProviderId :loadProviderIdRef, loadProdTypeId: loadProdTypeIdRef, loadProductId: loadProductIdRef, productId: productIdRef, branchId: branchIdRef, engineerId: engineerIdRef, loadEngineerId: loadEngineerIdRef, workTypeCode: workTypeCodeRef, statCode: statCodeRef, vhcCatCode: vhcCatCodeRef, vhcTypeName: vhcTypeNameRef, assigneeName: assigneeNameRef,
    typeCode: typeCodeRef, customerName: customerNameRef, postalCode: postalCodeRef, customerAddr1: customerAddr1Ref, customerAddr2: customerAddr2Ref, extraAddr: extraAddrRef, addrType: addrTypeRef, primaryPhoneNo: primaryPhoneNoRef, secondaryPhoneNo: secondaryPhoneNoRef, remark: remarkRef,
    loadMemo: loadMemoRef, loadEngineerMemo: loadEngineerMemoRef, loadRejectMemo: loadRejectMemoRef, memo: memoRef, engineerMemo: engineerMemoRef, rejectMemo: rejectMemoRef, happyCallRequested: happyCallRequestedRef, basicPayment: basicPaymentRef, optionPayment: optionPaymentRef, removalPayment: removalPaymentRef,
    loadSelectCompList, loadSelectProdTypeList, loadSelectProductList, loadSelectBranchList, loadSelectEngineerList, saveOrder,
    error: errorRef, listOrder, orderList: orderListRef, pageInfo: pageInfoRef, loadOrder, loadProduct, loadOrderData: loadOrderDataRef, updateItems: updateItemsRef, getTodayReceiptCount, todayReceiptCount: todayReceiptCountRef, sendHappycall, deleteOrder, modifyHistory, checkedDetailArray: checkedDetailArrayRef,
    histData: histDataRef, histPageInfo: histPageInfoRef, changeEngineer, deleteOrderList, asInfo: asInfoRef, saveAs, loadAs, happyCallMemo: happyCallMemoRef, claimInfo: claimInfoRef, saveClaim, loadClaim, listClaim, getProgressOrder, progressOrder: progressOrderRef, resultOrder: resultOrderRef, getResultOrder, uploadFiles: uploadFilesRef, uploadClaimPic, formExcelDownLoad, excelUpload, excelFile: excelFileRef,
    happyCallHistData: happyCallHistRef, happyCallHist, smsHist, smsHistData: smsHistRef, loadEngineer, certPicUri: certPicUriRef, excelUploadedData: excelUploadedDataRef, orderListExcelDownload, claimListExcelDownload, orderListExcelDownloadSelected, claimListExcelDownloadSelected, getHistAll, histAll: histAllRef, installDescription: installDescriptionRef, asDescription: asDescriptionRef,
  }
}

export default useOrderRegistry