import { ref } from 'vue'
import { trim, replacePath } from '@/util/Util'
import apiURL from '@/bis/data/deleteManagementApi'

const deleteManagement = (LoginAuthStore) => {
  const headersMap = {
    'Content-Type': 'application/json',
    'X-AUTH-TOKEN': LoginAuthStore.accessToken
  }
  const deletedOrderListRef = ref([]);
  const deletedProductListRef = ref([]);
  const deletedCompanyListRef = ref([]);
  const deletedBranchListRef = ref([]);
  const deletedEngineerListRef = ref([]);
  const deletedAdminListRef = ref([]);
  const pageInfoRef = ref({});
  const errorRef = ref([]);

  const deletedListOrder = async (queryObj) => {
    let query = `?rrp=${queryObj.rrp}&pno=${queryObj.pno}&orderType=${queryObj.orderType}`
    const startDateTime = !queryObj.startDateTime ? '' : '&startDateTime=' +queryObj.startDateTime
    const endDateTime = !queryObj.endDateTime ? '' : '&endDateTime=' + queryObj.endDateTime
    const filter1 = !queryObj.filter1 ? '': '&filter1=' + trim(queryObj.filter1)
    const filter2 = !queryObj.filter2 ? '': '&filter2=' + trim(queryObj.filter2)
    const saFilter = !queryObj.saFilter ? '': '&saFilter=' + trim(queryObj.saFilter)
    const statCode = !queryObj.statCode ? '': '&statCode=' + trim(queryObj.statCode)
    query = query + startDateTime + endDateTime + filter1 + filter2 + saFilter + statCode
    console.log(query)
    fetch(`${apiURL.baseUri}${apiURL.deletedListOrder.api}${query}`,
    {
      method: apiURL.deletedListOrder.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
        console.log(res)
        if(res.success) {
          deletedOrderListRef.value = !res.payload ? []: res.payload;
          pageInfoRef.value = res?.pageInfo;
        } else {
          errorRef.value.message = res.error.msg
          LoginAuthStore.redirectInvalidSession(res)
        }
    }).catch(err => errorRef.value.message = err.message)
  }
  const deletedListClaim = async (queryObj) => {
    let query = `?rrp=${queryObj.rrp}&pno=${queryObj.pno}&orderType=${queryObj.orderType}`
    const startDateTime = !queryObj.startDateTime ? '' : '&startDateTime=' +queryObj.startDateTime
    const endDateTime = !queryObj.endDateTime ? '' : '&endDateTime=' + queryObj.endDateTime
    const filter1 = !queryObj.filter1 ? '': '&filter1=' + trim(queryObj.filter1)
    const filter2 = !queryObj.filter2 ? '': '&filter2=' + trim(queryObj.filter2)
    const saFilter = !queryObj.saFilter ? '': '&saFilter=' + trim(queryObj.saFilter)
    query = query + startDateTime + endDateTime + filter1 + filter2 + saFilter
    console.log(query)
    fetch(`${apiURL.baseUri}${apiURL.deletedListClaim.api}${query}`,
    {
      method: apiURL.deletedListClaim.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
        console.log(res)
        if(res.success) {
          deletedOrderListRef.value = !res.payload ? []: res.payload;
          pageInfoRef.value = res?.pageInfo;
        } else {
          errorRef.value.message = res.error.msg
          LoginAuthStore.redirectInvalidSession(res)
        }
    }).catch(err => errorRef.value.message = err.message)
  }

  const deletedListProduct = async (queryObj) => {
    let query = `?rrp=${queryObj.rrp}&pno=${queryObj.pno}`
    const filter = !queryObj.filter ? '': '&filter=' + trim(queryObj.filter)
    query = query + filter
    console.log(query)
    fetch(`${apiURL.baseUri}${apiURL.deletedListProduct.api}${query}`,
    {
      method: apiURL.deletedListProduct.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
        console.log(res)
        if(res.success) {
          deletedProductListRef.value = !res.payload ? []: res.payload;
          pageInfoRef.value = res?.pageInfo;
        } else {
          errorRef.value.message = res.error.msg
          LoginAuthStore.redirectInvalidSession(res)
        }
    }).catch(err => errorRef.value.message = err.message)
  }

  const deletedListCompany = async(queryObj) => {
    let query = `?rrp=${queryObj.rrp}&pno=${queryObj.pno}`
    const filter = !queryObj.filter ? '': '&filter=' + trim(queryObj.filter)
    query = query + filter
    console.log(query)
    fetch(`${apiURL.baseUri}${apiURL.deletedListCompany.api}${query}`,
    {
      method: apiURL.deletedListCompany.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
      console.log(res)
      if (res.success) {
        deletedCompanyListRef.value = !res.payload ? []: res.payload
        pageInfoRef.value = !res.pageInfo ? []: res.pageInfo;
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => errorRef.value.message = err.message)
  }
  const deletedListBranch = async(queryObj) => {
    let query = `?rrp=${queryObj.rrp}&pno=${queryObj.pno}`
    const filter = !queryObj.filter ? '': '&filter=' + trim(queryObj.filter)
    query = query + filter
    console.log(query)
    fetch(`${apiURL.baseUri}${apiURL.deletedListBranch.api}${query}`,
    {
      method: apiURL.deletedListBranch.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
      console.log(res)
      if(res.success) {
        deletedBranchListRef.value = !res.payload ? []: res.payload
        pageInfoRef.value = !res.pageInfo ? []: res.pageInfo;
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => errorRef.value.message = err.message)
    } 
  
    const deletedListEngineer = async(queryObj) => {
      let query = `?rrp=${queryObj.rrp}&pno=${queryObj.pno}`
      const filter = !queryObj.filter ? '': '&filter=' + trim(queryObj.filter)
      query = query + filter
      console.log(query)
      fetch(`${apiURL.baseUri}${apiURL.deletedListEngineer.api}${query}`,
      {
        method: apiURL.deletedListEngineer.method,
        headers: headersMap
      }).then(res => res.json())
      .then(res => {
        console.log(res)
        if(res.success) {
          deletedEngineerListRef.value = !res.payload ? []: res.payload
          pageInfoRef.value = !res.pageInfo ? []: res.pageInfo;
        } else {
          errorRef.value.message = res.error.msg
          LoginAuthStore.redirectInvalidSession(res)
        }
      }).catch(err => errorRef.value.message = err.message)
    } 
    const deletedListAdmin = async(queryObj) => {
      let query = `?rrp=${queryObj.rrp}&pno=${queryObj.pno}`
      const filter = !queryObj.filter ? '': '&filter=' + trim(queryObj.filter)
      query = query + filter
      console.log(query)
      fetch(`${apiURL.baseUri}${apiURL.deletedListAdmin.api}${query}`,
      {
        method: apiURL.deletedListAdmin.method,
        headers: headersMap
      }).then(res => res.json())
      .then(res => {
        console.log(res)
        if(res.success) {
          deletedAdminListRef.value = !res.payload ? []: res.payload;
          pageInfoRef.value = !res.pageInfo ? []: res.pageInfo;
        } else {
          errorRef.value.message = res.error.msg
          LoginAuthStore.redirectInvalidSession(res)
        }
      }).catch(err => errorRef.value.message = err.message)
    }

  const permanentDelete = async (ids, searchType) => {
    const url = (searchType === 'order' || searchType === 'as' || searchType === 'claim') 
                  ? apiURL.orderPermanentDelete.api
                  : (searchType === 'product')
                    ? apiURL.productPermanentDelete.api
                    : (searchType === 'branch')
                      ? apiURL.branchPermanentDelete.api
                      : apiURL.userPermanentDelete.api
    const method = (searchType === 'order' || searchType === 'as' || searchType === 'claim') 
                  ? apiURL.orderPermanentDelete.method
                  : (searchType === 'product')
                    ? apiURL.productPermanentDelete.method
                    : (searchType === 'branch')
                      ? apiURL.branchPermanentDelete.method
                      : apiURL.userPermanentDelete.method
    fetch(`${apiURL.baseUri}${url}`, {
      method,
      headers: headersMap,
      body: JSON.stringify(ids)
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        console.log(res, "res");
        // alert('상품을 삭제하였습니다.')

      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
        errorRef.value.message = err.message
    })  
  }

  const deletedRestore = async (ids, searchType) => {
    const url = (searchType === 'order' || searchType === 'as' || searchType === 'claim') 
                  ? apiURL.orderRestore.api
                  : (searchType === 'product')
                    ? apiURL.productRestore.api
                    : apiURL.userRestore.api
    const method = (searchType === 'order' || searchType === 'as' || searchType === 'claim') 
                  ? apiURL.orderRestore.method
                  : (searchType === 'product')
                    ? apiURL.productRestore.method
                    : apiURL.userRestore.method
    fetch(`${apiURL.baseUri}${url}`, {
      method,
      headers: headersMap,
      body: JSON.stringify(ids)
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        console.log(res, "res");
        // alert('상품을 복구하였습니다.')

      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
        errorRef.value.message = err.message
    })  
  }

  return {
    deletedListOrder, deletedListClaim, deletedListProduct, deletedListCompany, deletedListBranch, deletedListEngineer, deletedListAdmin, permanentDelete, deletedRestore,
    deletedOrderList: deletedOrderListRef, deletedProductList: deletedProductListRef, deletedCompanyList: deletedCompanyListRef,
    deletedBranchList: deletedBranchListRef, deletedEngineerList: deletedEngineerListRef, deletedAdminList: deletedAdminListRef,
    pageInfo: pageInfoRef,
  }
}
export default deleteManagement