<template>
  <div :id="`print${orderId}`" class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header d-flex justify-content-between align-items-center">
        <h5 class="modal-title">{{ searchType === 'order' ? '주문 설치 확인서' : 'A/S 완료서' }}</h5>
        <div class="col-2 d-flex justify-content-between align-items-center">
          <i class="fa fa-print" @click="handlePrint" style="font-size:40px;cursor: pointer;"></i>
          <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
      </div>
      <div class="modal-body">
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>업체명</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square w-100" type="text" v-model="resultOrder.providerName" placeholder="" readOnly>
          </div>
        </div>
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>상품분류</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square w-100" type="text" v-model="resultOrder.productCategoryName" placeholder="" readOnly>
          </div>
        </div>
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>상품명</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square w-100" type="text" v-model="resultOrder.productName" placeholder="" readOnly>
          </div>
        </div>
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>고객명</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square w-100" type="text" v-model="resultOrder.customerName" placeholder="" readOnly>
          </div>
        </div>
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>전화번호1</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square w-100" type="text" v-model="resultOrder.phoneNo1" placeholder="" readOnly>
          </div>
        </div>
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>주소</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square w-100" type="text" v-model="resultOrder.address" placeholder="" readOnly>
          </div>
        </div>
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>차종</span>
          </div>
          <div class="col-9">
            <div class="col-12 d-flex">
              <div class="col-4 pe-2">
                <input class="form-control btn-square w-100" type="text" :value="resultOrder.vhcCategory === '00050' ? '국산' : resultOrder.vhcCategory === '00051' ? '수입' : ''" placeholder="" readOnly>
              </div>
              <div class="col-8">
                <input class="form-control btn-square col-7" type="text" v-model="resultOrder.vhcName" placeholder="" readOnly>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 d-flex align-items-center justify-content-center mt-3">
          <div class="col-2">
            <span>완료일자</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square col-7" type="text" v-model="resultOrder.completeDateTime" placeholder="" readOnly>
          </div>
        </div>           
        <div v-if="searchType === 'order'" class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>기존제품탈거</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square col-7" type="text" :value="resultOrder?.orderResult?.removePreinstallYn === 'Y' ? '탈거' : resultOrder?.orderResult?.removePreinstallYn === 'N' ? '미탈거' : ''" placeholder="" readOnly>
          </div>
        </div>           
        <div v-if="searchType === 'order'" class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>전원연결구분(B+, ACC)</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square col-7" type="text" :value="resultOrder?.orderResult ? handleConnectTypeList() : ''" placeholder="" readOnly>
          </div>
        </div>           
        <div v-if="searchType === 'order'" class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>설치구분</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square col-7" type="text" :value="resultOrder?.orderResult?.bboxInstallType === 'DrivingRecord' ? '주행녹화' : resultOrder?.orderResult?.bboxInstallType === 'AlwaysRecord' ? '상시녹화' : ''" placeholder="" readOnly>
          </div>
        </div>           
        <div v-if="searchType === 'order'" class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>주차모드전환 확인완료</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square col-7" type="text" :value="resultOrder?.orderResult?.parkModeYn === 'Y' ? '전환 완료' : resultOrder?.orderResult?.parkModeYn === 'N' ? '미완료' : ''" placeholder="" readOnly>
          </div>
        </div>           
        <div v-if="searchType === 'order'" class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>정상작동확인</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square col-7" type="text" 
              :value="resultOrder?.orderResult?.operateNormalYn === 'Y'
                ? '정상작동'
                : resultOrder?.orderResult?.operateNormalYn === 'N'
                  ? resultOrder?.orderResult?.abnormalReason
                    ? `작동불량: ${resultOrder.orderResult.abnormalReason}`
                    : '작동불량'
                  : ''" placeholder="" readOnly>
          </div>
        </div>
        <div v-if="searchType !== 'order'" class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>A/S 교체 내용</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square col-7" type="text" value="no data" placeholder="" readOnly>
          </div>
        </div>
        <div v-if="searchType !== 'order'" class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>작동여부</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square col-7" type="text" value="no data" placeholder="" readOnly>
          </div>
        </div>
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>사진</span>
          </div>
          <div class="col-9">
            <div class="col-12 d-flex picture-container">
              <div v-if="resultOrder?.orderResult?.pic1Uri" class="col-4 p-3">
                <asyncPicComp :picUri="resultOrder?.orderResult?.pic1Uri" :isZoom="true" :id="orderId" num="1"/>
              </div>
              <div v-if="resultOrder?.orderResult?.pic2Uri" class="col-4 p-3">
                <asyncPicComp :picUri="resultOrder?.orderResult?.pic2Uri" :isZoom="true" :id="orderId" num="2"/>
              </div>
              <div v-if="resultOrder?.orderResult?.pic3Uri" class="col-4 p-3">
                <asyncPicComp :picUri="resultOrder?.orderResult?.pic3Uri" :isZoom="true" :id="orderId" num="3"/>
              </div>
              <div v-if="resultOrder?.orderResult?.pic4Uri" class="col-4 p-3">
                <asyncPicComp :picUri="resultOrder?.orderResult?.pic4Uri" :isZoom="true" :id="orderId" num="4"/>
              </div>
              <div v-if="resultOrder?.orderResult?.pic5Uri" class="col-4 p-3">
                <asyncPicComp :picUri="resultOrder?.orderResult?.pic5Uri" :isZoom="true" :id="orderId" num="5"/>
              </div>
              <div v-if="resultOrder?.orderResult?.pic6Uri" class="col-4 p-3">
                <asyncPicComp :picUri="resultOrder?.orderResult?.pic6Uri" :isZoom="true" :id="orderId" num="6"/>
              </div>
            </div>
          </div>
        </div>           
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>정보동의</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square col-7" type="text" :value="resultOrder?.orderResult?.infoProvConsentYn === 'Y' ? '동의' : resultOrder?.orderResult?.infoProvConsentYn === 'N' ? '미동의' : ''" placeholder="" readOnly>
          </div>
        </div>           
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>서명</span>
          </div>
          <div class="col-9">
            <div v-if="resultOrder?.orderResult?.signatureUri" class="col-4">
              <asyncPicComp :picUri="resultOrder?.orderResult?.signatureUri" :isSign="true" style="border:1px solid #d9dee2;"/>
            </div>
            <input v-else class="form-control btn-square col-7" type="text" placeholder="" readOnly>
          </div>
        </div>           
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>본사메모</span>
          </div>
          <div class="col-9">
            <div class="form-control btn-square" style="height: 80px;overflow-y:auto;">
              <div class="d-flex" v-html="resultOrder?.hqMemo" style="text-align:left;"></div>
            </div>          
          </div>
        </div>           
        <div class="col-12 d-flex align-items-center justify-content-center mt-2 mb-5">
          <div class="col-2">
            <span>기사메모</span>
          </div>
          <div class="col-9">
            <div class="form-control btn-square" style="height: 80px;overflow-y:auto;">
              <div class="d-flex" v-html="resultOrder?.engMemo" style="text-align:left;"></div>
            </div>          
          </div>
        </div>           
      </div>
      <!-- <div class="modal-footer d-flex justify-content-center">
        <button class="btn btn-secondary" type="button" data-bs-dismiss="modal" @click="changeOrder">수정</button>
        <button class="btn btn-primary" type="button" @click="happyCall">해피콜 요청</button>
      </div> -->
    </div>
  </div>
</template>
<script setup>
import Swal from 'sweetalert2';
import { ref, onMounted, computed, defineProps, toRefs, onUnmounted, watch, defineAsyncComponent } from 'vue';
import useOrderRegistry from '@/composables/registerOrder';
import commonApi from '@/composables/commonApi'
import { useRouter } from 'vue-router'
import { useLoginAuth } from '@/stores/LoginAuthStore';

const asyncPicComp = defineAsyncComponent(() => import('../PicComponent.vue'))

const router = useRouter();
const LoginAuthStore = useLoginAuth(router)
const props = defineProps({
  orderId: {
    type: Number,
    required: true,
  },
  isRead: {
    type: Boolean,
    required: false,
  },
  searchType: {
    type: String,
    required: false,
  }
});
const { orderId, isRead, searchType } = toRefs(props);
const { resultOrder, getResultOrder, error } = useOrderRegistry(LoginAuthStore)
const { loadCommonCode } = commonApi(LoginAuthStore);
const workTypeListRef = ref([])
const progressListRef = ref([])
const vhcCatCodeListRef = ref([])
const paymentCodeRef = ref([]);
const inChargePersonRef = ref({});
const path = ref(router.currentRoute.value.path);
const isClaim = path.value.includes('claim');
const isCal = path.value.includes('calculate');
const isOpenModal = ref(false);
const whatIam = isCal ? 'cal' : (searchType.value === 'order') ? 'order' : (isClaim || searchType.value === 'claim') ? 'claim' : 'as'
onMounted(async () => {
  const installModalEl = document.getElementById(`${whatIam}Install${orderId.value}`);
  installModalEl.addEventListener('shown.bs.modal', async () => {
    if (!isOpenModal.value) {
      isOpenModal.value = true;
      await getResultOrder(orderId.value)
    }
  })

  installModalEl.addEventListener('hidden.bs.modal', async () => {
    isOpenModal.value = false;
    installModalEl.removeEventListener('shown.bs.modal', async () => {
      if (!isOpenModal.value) {
        isOpenModal.value = true;
        await getResultOrder(orderId.value)
      }
    })
  })
})

const handlePrint = () => {
  const html = document.querySelector('html');
  const printContents = document.getElementById(`print${orderId.value}`).cloneNode(true);
  const printDiv = document.createElement('div');
  printDiv.className = "print-div"
  printDiv.appendChild(printContents);
  html.appendChild(printDiv);
  document.body.style.display = 'none';
  window.print();
  document.body.style.display = 'block';
  printDiv.style.display = 'none';
}

const handleConnectTypeList = () => {
  const battTypeList = [];
  const accTypeList = [];
  resultOrder?.value.orderResult?.battConnectTypeList?.forEach(el => battTypeList.push(el));
  resultOrder?.value.orderResult?.accConnectTypeList?.forEach(el => accTypeList.push(el));
  if (battTypeList.indexOf('ElecSeat') !== -1) battTypeList[battTypeList.indexOf('ElecSeat')] = '전동시트';
  if (battTypeList.indexOf('StopLight') !== -1) battTypeList[battTypeList.indexOf('StopLight')] = '정지등';
  if (battTypeList.indexOf('DoorLock') !== -1) battTypeList[battTypeList.indexOf('DoorLock')] = '도어잠금';
  if (battTypeList.indexOf('Trunk') !== -1) battTypeList[battTypeList.indexOf('Trunk')] = '트렁크';
  if (battTypeList.indexOf('Amp') !== -1) battTypeList[battTypeList.indexOf('Amp')] = '엠프';
  if (battTypeList.indexOf('SunRoof') !== -1) battTypeList[battTypeList.indexOf('SunRoof')] = '썬루프';
  if (battTypeList.indexOf('EmergencyLight') !== -1) battTypeList[battTypeList.indexOf('EmergencyLight')] = '비상등';
  if (battTypeList.indexOf('Etc') !== -1) {
    if (resultOrder.value.orderResult.battEtcRemark) battTypeList[battTypeList.indexOf('Etc')] = `기타(${resultOrder.value.orderResult.battEtcRemark})`;
    else battTypeList[battTypeList.indexOf('Etc')] = '기타';
  }
  if (accTypeList.indexOf('CigarLight') !== -1) accTypeList[accTypeList.indexOf('CigarLight')] = '시가라이트';
  if (accTypeList.indexOf('Wiper') !== -1) accTypeList[accTypeList.indexOf('Wiper')] = '와이퍼';
  if (accTypeList.indexOf('PowerOutlet') !== -1) accTypeList[accTypeList.indexOf('PowerOutlet')] = '파워아울렛';
  if (accTypeList.indexOf('AuxPower') !== -1) accTypeList[accTypeList.indexOf('AuxPower')] = '예비전원';
  if (accTypeList.indexOf('Etc') !== -1) {
    if (resultOrder.value.orderResult.accEtcRemark) accTypeList[accTypeList.indexOf('Etc')] = `기타(${resultOrder.value.orderResult.accEtcRemark})`;
    else accTypeList[accTypeList.indexOf('Etc')] = '기타';
  }
  if (battTypeList.length !== 0 && accTypeList.length !== 0) return `B+: ${battTypeList.join(', ')} ACC: ${accTypeList.join(', ')}`
  if (battTypeList.length !== 0 && accTypeList.length === 0) return `B+: ${battTypeList.join(', ')}`
  if (battTypeList.length === 0 && accTypeList.length !== 0) return `ACC: ${accTypeList.join(', ')}`
  if (battTypeList.length === 0 && accTypeList.length === 0) return ''
}
const changeOrder = () => {
  new Swal({
    title: '수정이 완료되었습니다.'
  });
}
watch(error, (newVal, oldVal) => {
  if (newVal.message) {
    new Swal({
      title: newVal.message,
      showClass: {
        backdrop: 'swal2-noanimation', 
        popup: '',                     
        icon: ''                       
      },
      hideClass: {
        popup: '',                      
      },
      showConfirmButton: true,
      showCancelButton: false,
    });
  }  
}, { deep: true })
</script>
<style lang="scss">
.picture-container {
  border: solid 1px #D9DEE2;
  height: 374px;
  flex-wrap: wrap;
  overflow: auto;
}
.square-container {
  aspect-ratio: 1 / 1;
  overflow: hidden;
  position: relative;
}
.square-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.search-icon {
  position:absolute;
  background-color: white;
  border-radius: 15px; 
  width: 30px;
  height: 30px;
  left: 3px;
  bottom: 3px;
  cursor: pointer;
}
</style>