<template>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="modal-title">개별 관리</h5>
          <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="d-flex align-items-center">
          <div class="px-3 d-flex justify-content-center">
            <span>
              Search: 
            </span>
          </div>
          <div class="pe-1 d-flex justify-content-center">
            <input class="form-control btn-square w-100" v-model="filter" type="text" placeholder="" @keypress.enter.prevent="searchBranch(true)">
          </div>
          <div class="pe-1 d-flex justify-content-center">
            <button @click="searchBranch(true)" class="btn btn-square btn-outline-light txt-dark" type="button"
              title="btn btn-outline-light">검색</button>
          </div>
        </div>
        <table class="productManageTable">
          <thead>
            <tr>
              <th class="productColumnWidth">
                <div class="d-flex align-items-center ms-3">
                  지사 명
                </div>
              </th>
              <th class="productColumnWidthPer">
                예외 지점
              </th>
            </tr>
          </thead>
          <tbody v-if="branchCodeList.length === 0">
            <td colspan="2">
              <span>검색된 지사가 없습니다.</span>
            </td>
          </tbody>
          <tbody v-else>
            <tr v-for="prod in branchCodeList">
              <td>
                <div class="d-flex align-items-center ms-3">
                  {{ prod.name }}
                </div>
              </td>
              <td v-if="exceptArray.length === 0 || exceptArray.filter(item => item.id === prod.id && item.name === prod.name).length === 0">
                <i @click="editProduct(prod.id, prod.name)" class="fa fa-plus-square" style="font-size:20px;cursor: pointer;"></i>
              </td>
              <td v-else>
                <i @click="editProduct(prod.id, prod.name)" class="fa fa-minus-square" style="font-size:20px;cursor: pointer;"></i>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="customize-index">
          <nav class="m-b-30" aria-label="">
            <ul class="pagination justify-content-center pagination-primary">

              <li class="page-item prev-page" @click="firstPage" :disabled="pageNo + 1 === 1"><a class="page-link"
                  href="javascript:void(0)">처음</a></li>
              <li class="page-item" @click="prevPage" :disabled="pageNo + 1 === 1"><a class="page-link"
                  href="javascript:void(0)">이전</a></li>
              <li v-for="paginationNumber in totalPages" class="page-item customize-button"
                :class="{ 'active': paginationNumber === pageNo + 1 }"
                @click="updatePage(paginationNumber)">
                <a class="page-link" href="javascript:void(0)">{{ paginationNumber }}</a>
              </li>
              <li class="page-item" @click="nextPage" :disabled="pageNo + 1 === totalPages"><a class="page-link"
                  href="javascript:void(0)">다음</a></li>
              <li class="page-item next-page" @click="lastPage" :disabled="pageNo + 1 === totalPages"><a class="page-link"
                  href="javascript:void(0)">끝</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, defineEmits, onMounted, defineProps, watch } from 'vue';
import useEngineerRegistry from '@/composables/registerEngineer';
import useProductRegistry from '@/composables/registerProduct'
import { useRouter } from 'vue-router'
import { useLoginAuth } from '@/stores/LoginAuthStore';
const router = useRouter();
const LoginAuthStore = useLoginAuth(router)
const { branchCodeList, loadBranchCodeList, pageInfo } = useEngineerRegistry(LoginAuthStore)
const pno = ref(0)
const filter = ref('');
const isSearchClick = ref(false);
const queryObj = ref({pno: pno.value, filter: filter.value});
const searchBranch = async (click) => {
  if (click) isSearchClick.value = true;
  if (isSearchClick.value) {
    pno.value = 0
    queryObj.value.filter = filter.value
  }
  // queryObj.value.rrp = size.value
  queryObj.value.pno = pno.value
  await updatePage(1);
  isSearchClick.value = false;
}
const pageNo = computed(() => pageInfo.value?.pageNo);
const totalCount = computed(() => pageInfo.value?.totalCount);
const totalPages = computed(() => pageInfo.value?.totalPages);
const updatePage = async (paginationNumber) => {
  pno.value = paginationNumber - 1;
  queryObj.value.pno = pno.value
  queryObj.value.filter = filter.value
  await loadBranchCodeList(true, queryObj);
};
const firstPage = () => {
  if (pno.value >= 1) updatePage(1);
};
const prevPage = () => {
  if (pno.value >= 1) updatePage(pno.value);
};
const nextPage = () => {
  if (pno.value + 2 <= totalPages.value) updatePage(pno.value + 2);
};
const lastPage = () => {
  if (pno.value + 2 <= totalPages.value) updatePage(totalPages.value);
};
const props = defineProps(['modelValue']);
const emit = defineEmits(['modelValue']);
const exceptArray = computed({
  get: () => props.modelValue,
  set: (id, name) => editProduct(id, name)
})
const itemsPerPage = ref(10)
const currentPage = ref(1)
const editProduct = (id, name) => {
  const idx = exceptArray.value.findIndex(item => item.id === id);
  if (idx === -1) {
    exceptArray.value.push({
      id,
      name
    })
  } else {
    exceptArray.value.splice(idx, 1);
  }
  emit('modelValue', exceptArray.value);
}
onMounted(() => {
  loadBranchCodeList(true, queryObj);
})
</script>
<style lang="scss" scoped>
.productManageTable {
  border-collapse: collapse;
  margin: 20px auto;
}
.productColumnWidth {
  width: 300px;
}
.productColumnWidthPer {
  width: 100px;
}
th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

// th {
//     background-color: #f2f2f2;
// }

// tr:nth-child(even) {
//     background-color: #f2f2f2;
// }

.edit-button {
  background-color: #4CAF50;
  color: white;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}
.confirm-button {
  position: absolute;
  right: 8px;
  top: 4px;
  font-size: 30px;
  cursor: pointer;
}
.edit-wrap {
  position: relative;
}
</style>