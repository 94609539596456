<template>
  <div>
    <Breadcrumbs title="업체 등록" main="업체" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <div class="col-8 d-flex justify-content-between">
                <h5>업체 등록</h5>
              </div>
            </div>
            <div class="card-body">
              <form class="needs-validation" novalidate="" @submit.prevent="validationSubmit">
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1 validation-name">아이디</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="text" v-model="userId" v-bind:class="formSubmitted ? idError ? 'is-invalid' : 'is-valid' : ''"/>
                      <div class="invalid-feedback" v-if="error.userId">{{error.userId}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1 validation-name">비밀번호</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="password" v-model="password" v-bind:class="formSubmitted ? pwError ? 'is-invalid' : 'is-valid' : ''"/>
                      <div class="invalid-feedback" v-if="error.password">{{error.password}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1 validation-name">업체명</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="text" v-model="name" v-bind:class="formSubmitted ? nameError ? 'is-invalid' : 'is-valid' : ''"/>
                      <div class="invalid-feedback" v-if="error.name">{{error.name}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1 validation-name">담당자 이름</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="text" v-model="pocName" v-bind:class="formSubmitted ? pocError ? 'is-invalid' : 'is-valid' : ''"/>
                      <div class="invalid-feedback" v-if="error.pocName">{{error.pocName}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1 validation-name">휴대폰 번호</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="text" maxlength="14" v-model="phoneNo" @input="handlePhoneNo" v-bind:class="formSubmitted ? phoneError ? 'is-invalid' : 'is-valid' : ''"/>
                      <div class="invalid-feedback" v-if="error.phoneNo">{{error.phoneNo}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3 mt-4">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1">권한</div>
                    <div class="col-12 col-xxl-6 form-group m-checkbox-inline mb-0 custom-radio-ml">
                      <div class="radio radio-primary">
                        <input id="radioinline1" type="radio" name="radio1" value="Y" v-model="activeYn">
                        <label class="mb-0" for="radioinline1">사용중</label>
                      </div>
                      <div class="radio radio-primary">
                        <input id="radioinline2" type="radio" name="radio1" value="N" v-model="activeYn">
                        <label class="mb-0" for="radioinline2">중지</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="error.message" class="error">{{ error.message }}</div>
                <div class="col-12 col-xxl-5 d-flex justify-content-center">
                  <button class="btn btn-outline-light" type="submit">등록</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useLoginAuth } from '@/stores/LoginAuthStore'
import useSupplierRegistry from '@/composables/registerSupplier'
const router = useRouter();
const LoginAuthStore = useLoginAuth(router)
const {
  userId, password, phoneNo, name, pocName, activeYn,
  error,
  saveSupplier
} = useSupplierRegistry(LoginAuthStore)
const formSubmitted = ref(false);
const idError = ref(false);
const pwError = ref(false);
const nameError = ref(false);
const pocError = ref(false);
const phoneError = ref(false);

const handlePhoneNo = (e) => {
  if (e.target.value.replace(/[^0-9]/g, '').length === 12) {
    phoneNo.value = e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3`);
  } else {
    phoneNo.value = e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }
}

const validationSubmit = () => {
  formSubmitted.value = true;  
  if (userId.value.length < 1) idError.value = true;
  else idError.value = false;
  
  if (password.value.length < 1) pwError.value = true;
  else pwError.value = false;

  if (name.value.length < 1) nameError.value = true;
  else nameError.value = false;

  if (pocName.value.length < 1) pocError.value = true;
  else pocError.value = false;

  if (phoneNo.value.length < 9 || phoneNo.value.length > 13) phoneError.value = true;
  else phoneError.value = false;

  saveSupplier();
}
</script>