<template>
  <div>
    <Breadcrumbs title="상품 목록" main="상품" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h5>상품 목록 ( 총 : {{ totalCount }} 개 )</h5>
            </div>
            <div class="card-body">
              <div>
                <div class="mb-2">
                  <span>업체명, 상품 분류, 상품명을 입력하세요. 해당 데이터를 검색할 수 있습니다.</span>
                </div>
                <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="px-3 d-flex justify-content-center">
                      <span>
                        Search: 
                      </span>
                    </div>
                    <div class="pe-1 d-flex justify-content-center">
                      <input class="form-control btn-square w-100" type="text" placeholder="" v-model="filter" @keypress.enter="searchProduct(true)">
                    </div>
                    <div class="pe-1 d-flex justify-content-center">
                      <button class="btn btn-square btn-outline-light txt-dark w-100" type="button"
                        title="btn btn-outline-light btn-sm" @click="searchProduct(true)">검색</button>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="d-flex align-items-center">
                      <button :disabled="!itemsSelected.length" class="btn btn-square btn-outline-light txt-dark" type="button"
                        title="btn btn-outline-light btn-sm" data-bs-toggle="modal"
                        data-bs-target="#orderDelete">선택 삭제</button>
                      <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" id="orderDelete" tabindex="-1" role="dialog" aria-labelledby="orderDelete"
                        aria-hidden="true">
                        <DeleteModal @delete-list="deleteAllSelected" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-responsive mt-3">
                <EasyDataTable ref="dataTable" v-model:server-options="serverOptions" v-model:items-selected="itemsSelected" :headers="Headers" :rowsPerPage="10"
                  border-cell :items="productList" :sort-by="sortBy" :sort-type="sortType" hide-footer>
                  <template #item-providerPrice="{ providerPrice }">
                    <span>{{ conversionToLocaleString(providerPrice) }}</span>
                  </template>
                  <template #item-sellerPrice="{ sellerPrice }">
                    <span>{{ conversionToLocaleString(sellerPrice) }}</span>
                  </template>
                  <template #item-id="{ id }">
                    <router-link :to="{name: 'BISProductModify', params:{id}, query:{filter, size, pno, name: 'BISProductList'}}" style="color:#1071BC">조회</router-link>
                  </template>
                </EasyDataTable>
                <div class="customize-footer">
                  <div class="customize-rows-per-page mt-1">
                    <select class="select-items" @change="updateRowsPerPageSelect">
                      <option v-for="item in rowsPerPageOpt" :key="item" :selected="item === rowsPerPageActiveOption"
                        :value="item">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div class="customize-index">
                    <nav class="m-b-30" aria-label="">
                      <ul class="pagination justify-content-center pagination-primary">

                        <li class="page-item prev-page" @click="firstPage" :disabled="isFirstPage"><a class="page-link"
                            href="javascript:void(0)">처음</a></li>
                        <li class="page-item" @click="prevPage" :disabled="isFirstPage"><a class="page-link"
                            href="javascript:void(0)">이전</a></li>
                        <li v-for="paginationNumber in pageList" class="page-item customize-button"
                          :class="{ 'active': paginationNumber === pageNo + 1 }"
                          @click="updatePage(paginationNumber)">
                          <a class="page-link" href="javascript:void(0)">{{ paginationNumber }}</a>
                        </li>
                        <li class="page-item" @click="nextPage" :disabled="isLastPage"><a class="page-link"
                            href="javascript:void(0)">다음</a></li>
                        <li class="page-item next-page" @click="lastPage" :disabled="isLastPage"><a class="page-link"
                            href="javascript:void(0)">끝</a></li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { ref, onMounted, computed } from 'vue';
import DeleteModal from '@/components/DeleteModal.vue'
import { RouterLink, useRouter, useRoute } from 'vue-router'
import {useLoginAuth} from '@/stores/LoginAuthStore'
import useProductRegistry from '@/composables/registerProduct'
import { productHeader } from '@/constants/ListHeader'
const router = useRouter()
const route = useRoute()
const LoginAuthStore = useLoginAuth(router)

const pageNo = computed(() => pageInfo.value?.pageNo);
const totalCount = computed(() => pageInfo.value?.totalCount);
const totalPages = computed(() => pageInfo.value?.totalPages);
const pageList = ref([])
const filter = ref('')
const { productList, pageInfo, error, listProduct, deleteProductList } = useProductRegistry(LoginAuthStore);
const pno = ref(0)
const size = ref(25);
const pageNum = 10;
const queryObj = ref({filter: filter.value, rrp: size.value, pno: pno.value});
const isSearchClick = ref(false);

const currentPageListStart = () => {
  return Math.floor(pno.value / pageNum) * pageNum + 1
}
const paging = async () => {
  //보여지는 페이지 리셋
  pageList.value = [];

  //현재페이지 기준으로 페이징 숫자 넣기
  let pageListStart = await currentPageListStart()
  for (let i = 1; i < pageNum + 1; i++) {  
    if (totalPages.value >= pageListStart){
      pageList.value.push(pageListStart)
      pageListStart ++;
    }
  }
}

const searchProduct = async (click) => {
  if (route.query.pno) {
    queryObj.value.pno = route.query.pno
    queryObj.value.rrp = route.query.size
    queryObj.value.filter = route.query.filter
  } else {
    if (click) isSearchClick.value = true;
    if (isSearchClick.value) {
      pno.value = 0
      queryObj.value.filter = filter.value
    }
    queryObj.value.rrp = size.value
    queryObj.value.pno = pno.value
  }
  await listProduct(queryObj.value)
  isSearchClick.value = false;
  if (route.query?.pno) router.replace({query: {}})
  setTimeout(async () => {
    await paging()
  }, 1000);
}
const date = ref();
onMounted(() => {
  const startDate = new Date();
  const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
  date.value = [startDate, endDate];
  searchProduct();
})
const itemsSelected = ref([]);
const deleteAllSelected = async () => {
  const selectedIdList = itemsSelected.value.map(item => item.id);
  if(selectedIdList.length) {
    await deleteProductList(selectedIdList);
    setTimeout(() => {
      searchProduct();
    }, 500);
  }
  itemsSelected.value = [];
}

const conversionToLocaleString = (money) => {
  return `₩${money.toLocaleString()}`;
}

// $ref dataTable
const dataTable = ref();

// index related
const currentPageFirstIndex = computed(() => dataTable.value?.currentPageFirstIndex);
const currentPageLastIndex = computed(() => dataTable.value?.currentPageLastIndex);
const clientItemsLength = computed(() => dataTable.value?.clientItemsLength);

// pagination related
const maxPaginationNumber = computed(() => dataTable.value?.maxPaginationNumber);
const currentPaginationNumber = computed(() => dataTable.value?.currentPaginationNumber);

const isFirstPage = computed(() => dataTable.value?.isFirstPage);
const isLastPage = computed(() => dataTable.value?.isLastPage);

const updatePage = async (paginationNumber) => {
  pno.value = paginationNumber - 1;
  await searchProduct();
};
const lastPage = () => {
  updatePage(totalPages.value);
};
const firstPage = () => {
  updatePage(1);
};
const prevPage = () => {
  if (pno.value >= 1) updatePage(pno.value);
};
const nextPage = () => {
  if (pno.value + 2 <= totalPages.value) updatePage(pno.value + 2);
};

// rows per page related
const rowsPerPageOpt = [10, 25, 50, 100];
// const rowsPerPageOptions = computed(() => dataTable.value?.rowsPerPageOptions);
const rowsPerPageActiveOption = computed(() => dataTable.value?.rowsPerPageActiveOption);
const updateRowsPerPageSelect = async (e) => {
  size.value = Number(e.target.value);
  dataTable.value.updateRowsPerPageActiveOption(Number(e.target.value));
  await updatePage(1);
  await searchProduct()
};

const sortBy = ["num", "compName", "prodType", "prodName", "type", "price", "branchPrice"];
const sortType = ["desc", "asc"];

const serverOptions = ref({
  page: Number(pno.value),
  rowsPerPage: Number(size.value),
})


const Headers = ref(productHeader);
</script>