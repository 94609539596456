<template>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="modal-title">비밀번호 입력</h5>
          <button ref="closeBtn" class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div>
          <span>{{`사용자: ${LoginAuthStore?.currentUser?.name}(${LoginAuthStore?.currentUser?.userId})`}}</span>
        </div>
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>비밀번호</span>
          </div>
          <div class="col-6">
            <input @keypress.enter="deleteOrder" class="form-control btn-square w-100" type="password" v-model="pwd" placeholder="">
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center my-3">
        <button class="btn btn-secondary" type="button" @click="deleteOrder">확인</button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router'
import { useLoginAuth } from '@/stores/LoginAuthStore'
import { ref, defineEmits, watch, defineProps } from 'vue';
import commonApi from '@/composables/commonApi'
import Swal from 'sweetalert2';
const router = useRouter()
const LoginAuthStore = useLoginAuth(router);
const { checkPwd, pwd, error, checkResponse } = commonApi(LoginAuthStore);
const closeBtn = ref('');
const emit = defineEmits(['deleteList']);
const deleteOrder = async () => {
  await checkPwd();
}
const props = defineProps({
  searchType: {
    type: String,
  }
})
watch(checkResponse, async (newVal, oldVal) => {
  if (props.searchType === 'branch') {
    await closeBtn.value.click();
    const confirmBranchDelete = await new Swal({
      title: '지사 영구삭제 시 소속된 기사들이 영구 삭제 됩니다.',
      showCancelButton: true,
      showClass: {
        backdrop: 'swal2-noanimation', // disable backdrop animation
        popup: '',                     // disable popup animation
        icon: ''                       // disable icon animation
      },
      hideClass: {
        popup: '',                     // disable popup fade-out animation
      },
    });
    if (!confirmBranchDelete.isConfirmed) {
      return;
    }
  }
  if (newVal.success) {
    if (newVal.payload) {
      await closeBtn.value.click();
      await emit('deleteList');
      new Swal({
        title: '삭제가 완료되었습니다',
        showClass: {
          backdrop: 'swal2-noanimation', // disable backdrop animation
          popup: '',                     // disable popup animation
          icon: ''                       // disable icon animation
        },
        hideClass: {
          popup: '',                     // disable popup fade-out animation
        },
      });
    } else {
      new Swal({
        title: `${newVal.error.msg}`,
        showClass: {
          backdrop: 'swal2-noanimation', // disable backdrop animation
          popup: '',                     // disable popup animation
          icon: ''                       // disable icon animation
        },
        hideClass: {
          popup: '',                     // disable popup fade-out animation
        },
      });
    }
  } else {
    new Swal({
      title: '삭제 실행 중 오류가 발생하였습니다',
      showClass: {
        backdrop: 'swal2-noanimation', // disable backdrop animation
        popup: '',                     // disable popup animation
        icon: ''                       // disable icon animation
      },
      hideClass: {
        popup: '',                     // disable popup fade-out animation
      },
    });
  }
})
</script>
<style lang="scss">
</style>