import {defineStore} from 'pinia'
import {menuItems} from '@/data/menu'

export const useMenuStore = defineStore('MenuStore', {
    state: () => ({
        menuData: menuItems.data,
        userAuth: null
    }),
    getters: {
        menuItems () {
            return this.menuData.filter(m => {
                if (this.userAuth === 'ROLE_ROOT' || this.userAuth === 'ROLE_ADMIN') 
                    return m.accessGroup === 'ADMIN'
                else if (this.userAuth === 'ROLE_BRANCH' || this.userAuth === 'ROLE_ENGINEER')
                    return  m.accessGroup === 'BRANCH'
                else if (this.userAuth === 'ROLE_SUPPLIER') return  m.accessGroup === 'SELLER'
                // else return true
            })
        }
    },
    actions: {
        applyUserAuth(userAuth) {
            this.userAuth = userAuth
        }
    }
})