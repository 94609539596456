<template>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <form class="needs-validation" novalidate="" @submit.prevent="validationSubmit">
        <div class="modal-header">
          <h5 class="modal-title">세금계산서 정보</h5>
          <button :id="`taxCloseModal${orderId}`" class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="col-11 d-flex align-items-center justify-content-center mt-2">
            <div class="col-12 d-flex align-items-center justify-content-start">
              <div v-if="taxInvoiceInfoClone.basicPaymentAmount" class="col-2">
                <span class="">기본비</span>
              </div>
              <div v-if="taxInvoiceInfoClone.basicPaymentAmount" class="col-2">
                <input v-model="taxInvoiceInfoClone.basicPaymentAmount" @input="handleBasicPaymentAmount" class="form-control btn-square" type="text" :readOnly="isRead">
                <!-- <div class="invalid-feedback text-start" v-if="error.basicDesc">{{error.basicDesc}}</div> -->
              </div>
              <div v-if="taxInvoiceInfoClone.optionPaymentAmount" class="col-2">
                <span class="">추가비</span>
              </div>
              <div v-if="taxInvoiceInfoClone.optionPaymentAmount" class="col-2">
                <input v-model="taxInvoiceInfoClone.optionPaymentAmount" @input="handleOptionPaymentAmount" class="form-control btn-square" type="text" :readOnly="isRead">
              </div>
              <div v-if="taxInvoiceInfoClone.uninstallPaymentAmount" class="col-2">
                <span class="">탈거비</span>
              </div>
              <div v-if="taxInvoiceInfoClone.uninstallPaymentAmount" class="col-2">
                <input v-model="taxInvoiceInfoClone.uninstallPaymentAmount" @input="handleUninstallPaymentAmount" class="form-control btn-square" type="text" :readOnly="isRead">
              </div>
            </div>
          </div>          
          <div class="col-11 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span class="">내용</span>
            </div>
            <div class="col-10">
              <input v-model="taxInvoiceInfoClone.desc" class="form-control btn-square col-7" type="text" :readOnly="isRead">
              <!-- <div class="invalid-feedback text-start" v-if="error.basicDesc">{{error.basicDesc}}</div> -->
            </div>
          </div>
          <!-- <div class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span class="">금액</span>
            </div>
            <div class="col-9">
              <input v-model="taxInvoiceInfoClone.amount" @input="handleAmount" class="form-control btn-square col-7" type="text" :readOnly="isRead">
            </div>
          </div> -->
          <div class="col-11 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span class="">계산서발행일자</span>
            </div>
            <div class="col-10">
              <input maxlength="5" placeholder="숫자만 입력하세요. 월/년" v-model="taxInvoiceInfoClone.approvalDateTime" @input="handleApprovalDateTime" class="form-control btn-square col-7" type="text" :readOnly="isRead">
              <!-- <div class="invalid-feedback text-start" v-if="error.basicApprovalDateTime">{{error.basicApprovalDateTime}}</div> -->
            </div>
          </div>
          <div class="col-11 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span>비고</span>
            </div>
            <div class="col-10">
              <input v-model="taxInvoiceInfoClone.remark" @input="checkRemarkByte" class="form-control btn-square col-7" type="text" placeholder="" :readOnly="isRead">
            </div>
          </div>
        </div>
        <div v-if="!isRead" class="modal-footer d-flex justify-content-center">
          <button class="btn btn-secondary" type="submit">수정</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed, defineProps, toRefs, onUnmounted, watch, defineEmits } from 'vue';
import useSettleManagement from '@/composables/settleManagement'
import commonApi from '@/composables/commonApi'
import PostCode from '@/components/PostCode.vue'
import { useRouter } from 'vue-router'
import { useLoginAuth } from '@/stores/LoginAuthStore';
import Swal from 'sweetalert2';
const router = useRouter();
const LoginAuthStore = useLoginAuth(router)
const props = defineProps({
  orderId: {
    type: Number,
    required: true,
  },
  taxInvoiceInfo: {
    required: true,
  },
  isRead: {
    type: Boolean,
    required: false,
  },
  searchType: {
    type: String,
    required: false,
  }
});
const { orderId, taxInvoiceInfo, isRead, searchType } = toRefs(props);
const { updatePayment } = useSettleManagement(LoginAuthStore);
const { loadCommonCode } = commonApi(LoginAuthStore);
const taxInvoiceInfoClone = ref({});
const workTypeListRef = ref([])
const progressListRef = ref([])
const vhcCatCodeListRef = ref([])
const paymentCodeRef = ref([]);
const inChargePersonRef = ref({});
const path = ref(router.currentRoute.value.path);
const isTax = path.value.includes('tax');
const isOpenModal = ref(false);
const emit = defineEmits(['listTax']);

onMounted(() => {
  const taxModalEl = document.getElementById(`tax${orderId.value}`);
  taxModalEl.addEventListener('shown.bs.modal', async () => {
    if (!isOpenModal.value) {
      isOpenModal.value = true;
      taxInvoiceInfoClone.value = {...taxInvoiceInfo.value};
      taxInvoiceInfoClone.value.basicPaymentAmount = comma(uncomma(taxInvoiceInfoClone.value.basicPaymentAmount))
      taxInvoiceInfoClone.value.optionPaymentAmount = comma(uncomma(taxInvoiceInfoClone.value.optionPaymentAmount))
      taxInvoiceInfoClone.value.uninstallPaymentAmount = comma(uncomma(taxInvoiceInfoClone.value.uninstallPaymentAmount))
    }
  })
  taxModalEl.addEventListener('hidden.bs.modal', async () => {
  isOpenModal.value = false;
  taxInvoiceInfoClone.value = {};
  taxModalEl.removeEventListener('shown.bs.modal', async () => {
      if (!isOpenModal.value) {
        isOpenModal.value = true;
        taxInvoiceInfoClone.value = {...taxInvoiceInfo.value};
        taxInvoiceInfoClone.value.basicPaymentAmount = comma(uncomma(taxInvoiceInfoClone.value.basicPaymentAmount))
        taxInvoiceInfoClone.value.optionPaymentAmount = comma(uncomma(taxInvoiceInfoClone.value.optionPaymentAmount))
        taxInvoiceInfoClone.value.uninstallPaymentAmount = comma(uncomma(taxInvoiceInfoClone.value.uninstallPaymentAmount))
      }
    })
  })
})

const comma = (val) => {
  val = String(val);
  return val.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
}
const uncomma = (val) => {
  val = String(val);
  return val.replace(/[^\d]+/g, '');
}
const handleBasicPaymentAmount = (e) => {
  taxInvoiceInfoClone.value.basicPaymentAmount = comma(uncomma(e.target.value))
}
const handleOptionPaymentAmount = (e) => {
  taxInvoiceInfoClone.value.optionPaymentAmount = comma(uncomma(e.target.value))
}
const handleUninstallPaymentAmount = (e) => {
  taxInvoiceInfoClone.value.uninstallPaymentAmount = comma(uncomma(e.target.value))
}
const conversionDate = (rowDate) => {
  const date = rowDate.replace(/[^0-9]/g, "");
  return date.replace(/^(\d{2})(\d{2})$/, `$1/$2`);
}
const handleApprovalDateTime = (e) => {
  taxInvoiceInfoClone.value.approvalDateTime = conversionDate(e.target.value)
}

const checkRemarkByte = (e) => {
  let limitByte = 240;
  let totalByte = 0;
  let validText = '';
  const remark = e.target.value;
  for (let i = 0; i < remark.length; i++) {
    if (totalByte + 2 <= limitByte) {
      if (remark.charCodeAt(i) > 128) totalByte += 2;
      else totalByte++;
      validText += remark[i];
    } else {
      break;
    }
  }
  taxInvoiceInfoClone.value.remark = validText;
}

const validationSubmit = async () => {
  taxInvoiceInfoClone.value.basicPaymentAmount = taxInvoiceInfoClone.value.basicPaymentAmount ? uncomma(taxInvoiceInfoClone.value.basicPaymentAmount) : 0
  taxInvoiceInfoClone.value.optionPaymentAmount = taxInvoiceInfoClone.value.optionPaymentAmount ? uncomma(taxInvoiceInfoClone.value.optionPaymentAmount) : 0
  taxInvoiceInfoClone.value.uninstallPaymentAmount = taxInvoiceInfoClone.value.uninstallPaymentAmount ? uncomma(taxInvoiceInfoClone.value.uninstallPaymentAmount) : 0  
  await updatePayment(orderId.value, taxInvoiceInfoClone.value);
  const modalClose = document.getElementById(`taxCloseModal${orderId.value}`);
  await modalClose.click();
  await emit('listTax');
}
</script>
<style lang="sass">
</style>