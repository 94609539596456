<template>
  <router-link to="/order/list" class="site_title d-flex align-items-center">
    <div class="img-fluid for-light">
      <i class="fa fa-star" aria-hidden="true"></i>
      <span class="ms-1">BIS 관리자</span>
    </div>
    <div class="img-fluid for-dark">
      <i class="fa fa-star" aria-hidden="true"></i>
      <span class="ms-1">BIS 관리자</span>
    </div>
    <!-- <img
      class="img-fluid for-light"
      src="../../assets/images/logo/logo.png"
      alt=""
    /><img
      class="img-fluid for-dark"
      src="../../assets/images/logo/logo_dark.png"
      alt=""
    /> -->
  </router-link>
</template>
<script>

  export default {
    name: 'Logo',
  };
</script>
<style lang="scss">
.site_title {
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 400;
  font-size: 22px;
  width: 100%;
  color: #717185 !important;
  margin-left: 0 !important;
  line-height: 59px;
  display: block;
  height: 50px;
  margin: 0;
  
  i {
    border: 1px solid #717185;
    padding: 5px 6px;
    border-radius: 50%;
  }
}
</style>