<template>
  <div>
    <Breadcrumbs title="삭제 목록" main="삭제"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h5>{{`삭제 목록 ( ${
                  searchType === 'order' ? '주문' 
                  : searchType === 'as' ? 'A/S' 
                  : searchType === 'claim' ? '클레임' 
                  : searchType === 'product' ? '상품' 
                  : searchType === 'company' ? '업체'
                  : searchType === 'branch' ? '지사'
                  : searchType === 'engineer' ? '기사'
                  : searchType === 'admin' ? '관리자'
                  : ''} ) : ${totalCount ? totalCount : '-'}`}}
              </h5>
              <div class="mt-3 d-flex align-items-center">
                <div class="pe-2 d-flex justify-content-center">
                  <button @click="handleSearchType('order')" class="btn btn-square btn-outline-light txt-dark btn" v-bind:class="searchType === 'order' ? 'selectedBtn' : ''" type="button" title="btn btn-outline-light btn-sm">
                    주문
                  </button>
                </div>
                <div class="pe-2 d-flex justify-content-center">
                  <button @click="handleSearchType('as')" class="btn btn-square btn-outline-light txt-dark btn" v-bind:class="searchType === 'as' ? 'selectedBtn' : ''" type="button" title="btn btn-outline-light btn-sm">
                    A/S
                  </button>
                </div>
                <div class="pe-2 d-flex justify-content-center">
                  <button @click="handleSearchType('claim')" class="btn btn-square btn-outline-light txt-dark btn" v-bind:class="searchType === 'claim' ? 'selectedBtn' : ''" type="button" title="btn btn-outline-light btn-sm">
                    클레임
                  </button>
                </div>
                <div class="pe-2 d-flex justify-content-center">
                  <button @click="handleSearchType('product')" class="btn btn-square btn-outline-light txt-dark btn" v-bind:class="searchType === 'product' ? 'selectedBtn' : ''" type="button" title="btn btn-outline-light btn-sm">
                    상품
                  </button>
                </div>
                <div class="pe-2 d-flex justify-content-center">
                  <button @click="handleSearchType('company')" class="btn btn-square btn-outline-light txt-dark btn" v-bind:class="searchType === 'company' ? 'selectedBtn' : ''" type="button" title="btn btn-outline-light btn-sm">
                    업체
                  </button>
                </div>
                <div class="pe-2 d-flex justify-content-center">
                  <button @click="handleSearchType('branch')" class="btn btn-square btn-outline-light txt-dark btn" v-bind:class="searchType === 'branch' ? 'selectedBtn' : ''" type="button" title="btn btn-outline-light btn-sm">
                    지사
                  </button>
                </div>
                <div class="pe-2 d-flex justify-content-center">
                  <button @click="handleSearchType('engineer')" class="btn btn-square btn-outline-light txt-dark btn" v-bind:class="searchType === 'engineer' ? 'selectedBtn' : ''" type="button" title="btn btn-outline-light btn-sm">
                    기사
                  </button>
                </div>
                <div class="pe-2 d-flex justify-content-center">
                  <button @click="handleSearchType('admin')" class="btn btn-square btn-outline-light txt-dark btn" v-bind:class="searchType === 'admin' ? 'selectedBtn' : ''" type="button" title="btn btn-outline-light btn-sm">
                    관리자
                  </button>
                </div>
              </div>
              <div class="p-1 m-t-5 d-flex align-items-center btnBox" style="border: solid rgba(175, 173, 173, 0.29) 1px">
                <div v-if="searchType === 'order' || searchType === 'as' || searchType === 'claim'" class="pe-1">
                  <Datepicker week-start="0" :day-class="isWeekendClass" class="datepicker-here datepicker-multi digits b-r-0 mr-1" :multi="true" v-model="date" range locale="ko">
                  </Datepicker>
                </div>
                <div v-if="searchType === 'order' || searchType === 'as' || searchType === 'claim'" class="pe-1 d-flex justify-content-center">
                  <select v-model="filter1Type" class="form-select btn-square digits">
                    <option value="All">전체</option>
                    <option value="CustomerName">고객명</option>
                    <option value="Address">주소</option>
                    <option value="PhoneNo">고객연락처</option>
                    <option value="ProductName">상품명</option>
                    <option value="BranchName">지점</option>
                    <option value="EngineerName">기사명</option>
                  </select>
                </div>
                <div v-if="!(searchType === 'order' || searchType === 'as' || searchType === 'claim')" class="px-3 d-flex justify-content-center">
                  <span>
                    Search: 
                  </span>
                </div>
                <div v-if="searchType === 'order' || searchType === 'as' || searchType === 'claim'" class="pe-1 d-flex justify-content-center">
                  <input v-model="filter1Val" @keypress.enter="searchOrder(true)" class="form-control btn-square w-100" type="text" placeholder="">
                </div>
                <div v-else class="pe-1 d-flex justify-content-center">
                  <input class="form-control btn-square w-100" type="text" placeholder="" v-model="filter" @keypress.enter="searchRegistry(true)">
                </div>
                <div v-if="searchType === 'order' || searchType === 'as' || searchType === 'claim'" class="pe-1 d-flex justify-content-center">
                  <select v-model="filter2Type" class="form-select btn-square digits">
                    <option value="All">전체</option>
                    <option value="CustomerName">고객명</option>
                    <option value="Address">주소</option>
                    <option value="PhoneNo">고객연락처</option>
                    <option value="ProductName">상품명</option>
                    <option value="BranchName">지점</option>
                    <option value="EngineerName">기사명</option>
                  </select>      
                </div>
                <div v-if="searchType === 'order' || searchType === 'as' || searchType === 'claim'" class="pe-1 d-flex justify-content-center">
                  <input v-model="filter2Val" @keypress.enter="searchOrder(true)" class="form-control btn-square w-100" type="text" placeholder="">
                </div>
                <div v-if="searchType === 'order' || searchType === 'as' || searchType === 'claim'" class="pe-1 d-flex justify-content-center">
                  <select v-model="statCode" class="form-select btn-square digits">
                    <option value=''>진행상황 검색</option>
                    <option v-for="item in progressListRef" :value="item?.code" :key="item?.code">{{ item?.name }}</option>
                  </select>
                </div>
                <div v-if="searchType === 'order' || searchType === 'as' || searchType === 'claim'" class="pe-1 d-flex justify-content-center">
                  <button @click="searchOrder(true)" class="btn btn-square btn-primary" type="button" title="btn btn-outline-light btn-sm">검색</button>
                </div>
                <div v-else class="pe-1 d-flex justify-content-center">
                  <button @click="searchRegistry(true)" class="btn btn-square btn-outline-light" type="button" title="btn btn-outline-light btn-sm">검색</button>
                </div>
                <div v-if="searchType === 'order'" class="pe-1 d-flex justify-content-center">
                  <button @click="changeSearchOrder('EngineerUnassigned')" class="btn btn-square btn-warning txt-dark" type="button" title="btn btn-outline-light btn-sm">미배정</button>
                </div>
                <div v-if="searchType === 'order'" class="pe-1 d-flex justify-content-center">
                  <button @click="changeSearchOrder('OrderRejected')" class="btn btn-square btn-outline-light txt-dark" type="button" title="btn btn-outline-light btn-sm">반려</button>
                </div>
                <div v-if="searchType === 'order' || searchType === 'as'"  class="pe-1 d-flex justify-content-center">
                  <button @click="changeSearchOrder('HapyCallInComplete')" class="btn btn-square btn-outline-light txt-dark" type="button" title="btn btn-outline-light btn-sm">해피콜 미완료</button>
                </div>
                <div v-if="searchType === 'order' || searchType === 'as'"  class="pe-1 d-flex justify-content-center">
                  <button @click="changeSearchOrder('HappyCallComplete')" class="btn btn-square btn-outline-light txt-dark" type="button" title="btn btn-outline-light btn-sm">해피콜 완료</button>
                </div>
                <!-- <div v-if="searchType === 'order' || searchType === 'as' || searchType === 'claim'" class="pe-1 d-flex justify-content-center">
                  <button class="btn btn-square btn-success" type="button" title="btn btn-outline-light btn-sm">다운로드</button>
                </div> -->
                <div v-if="searchType === 'order' || searchType === 'as' || searchType === 'claim'" class="pe-1 d-flex justify-content-center">
                  <button @click="initSearchOrder" class="btn btn-square btn-outline-light txt-dark" type="button" title="btn btn-outline-light btn-sm"><i class="fa fa-refresh me-1" aria-hidden="true"></i>검색초기화</button>
                </div>                
              </div>
            </div>
            <div class="card-body">
              <div>
                <div class="d-flex justify-content-between">
                  <div class="d-flex">
                  </div>
                  <div class="d-flex">
                    <div class="d-flex align-items-center">
                      <button @click="restoreAllSelected" :disabled="!itemsSelected.length" class="btn btn-square btn-outline-light txt-dark me-2" type="button" title="btn btn-outline-light btn-sm">복구</button>
                      <button :disabled="!itemsSelected.length" class="btn btn-square btn-outline-light txt-dark" type="button" title="btn btn-outline-light btn-sm" data-bs-toggle="modal" data-bs-target="#orderDelete">영구삭제</button>
                      <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" id="orderDelete" tabindex="-1" role="dialog" aria-labelledby="orderDelete"
                        aria-hidden="true">
                        <DeleteModal @delete-list="deleteAllSelected" :searchType="searchType"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-responsive mt-3">
                <EasyDataTable ref="dataTable" v-model:server-options="serverOptions" v-model:items-selected="itemsSelected" :headers="Headers" :rowsPerPage="10" 
                  border-cell :items="(searchType === 'order' || searchType === 'as' || searchType === 'claim') ? deletedOrderList 
                  : searchType === 'product' ? deletedProductList 
                  : searchType === 'company' ? deletedCompanyList 
                  : searchType === 'branch' ? deletedBranchList 
                  : searchType === 'engineer' ? deletedEngineerList 
                  : searchType === 'admin' ? deletedAdminList 
                  : deletedOrderList" 
                  hide-footer header-text-direction="center" body-text-direction="center" :body-row-class-name="bodyRowClassNameFunction">
                  <template #item-customer="{ customerName, id }">
                    <a href="/" style="color:#1071BC" data-bs-toggle="modal" :data-bs-target="`#${searchType === 'order' ? 'order' : searchType === 'as' ? 'as' : 'claim'}InfoDetail${id}`">{{ customerName }}</a>
                    <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" data-bs-focus="false" :id="`${searchType === 'order' ? 'order' : searchType === 'as' ? 'as' : 'claim'}InfoDetail${id}`" tabindex="-1" role="dialog" :aria-labelledby="`${searchType === 'order' ? 'order' : searchType === 'as' ? 'as' : 'claim'}InfoDetail${id}`"
                    aria-hidden="true">
                      <InfoDetailModal :orderId="id" :isRead="true" :searchType="searchType"/>
                    </div>
                  </template>
                  <template #item-customerPhone="{ customerPhoneNo1 }">
                    <span>{{ conversionPhoneNo(customerPhoneNo1) }}</span>
                  </template>
                  <template #item-customerAddr="{customerAddr1, customerAddr2, extraAddr}">
                    <div style="width:100%;text-align:left !important;">
                      <span>{{ `${customerAddr1 ? customerAddr1 : ''} ${customerAddr2 ? customerAddr2 : ''}` }}</span>
                    </div>
                  </template>
                  <template #item-engineerName="{ engineerId, engineerName }">
                    <span style="color:#1071BC;cursor:pointer;" @click="openEngineerCertPic(engineerId)">{{ engineerName }}</span>
                  </template>                  
                  <template #item-basicPayment="{id, basicPaymentId ,basicPaymentMethod}">
                    <a href="/" style="color:#1071BC" data-bs-toggle="modal" :data-bs-target="`#${searchType === 'order' ? 'order' : searchType === 'as' ? 'as' : 'claim'}Payment${id}basic`">{{ basicPaymentMethod }}</a>
                    <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" :id="`${searchType === 'order' ? 'order' : searchType === 'as' ? 'as' : 'claim'}Payment${id}basic`" tabindex="-1" role="dialog" :aria-labelledby="`${searchType === 'order' ? 'order' : searchType === 'as' ? 'as' : 'claim'}Payment${id}basic`"
                      aria-hidden="true">
                      <PaymentTypeModal :orderId="id" type="basic" :isRead="true" :searchType="searchType"/>
                    </div>
                  </template>
                  <template #item-optionPayment="{id, optionPaymentId, optionPaymentMethod}">
                    <a href="/" style="color:#1071BC" data-bs-toggle="modal" :data-bs-target="`#${searchType === 'order' ? 'order' : searchType === 'as' ? 'as' : 'claim'}Payment${id}option`">{{ optionPaymentMethod }}</a>
                    <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" :id="`${searchType === 'order' ? 'order' : searchType === 'as' ? 'as' : 'claim'}Payment${id}option`" tabindex="-1" role="dialog" :aria-labelledby="`${searchType === 'order' ? 'order' : searchType === 'as' ? 'as' : 'claim'}Payment${id}option`"
                      aria-hidden="true">
                      <PaymentTypeModal :orderId="id" type="option" :isRead="true" :searchType="searchType"/>
                    </div>
                  </template>
                  <template #item-removalPayment="{id, removalPaymentId, removalPaymentMethod}">
                    <a href="/" style="color:#1071BC" data-bs-toggle="modal" :data-bs-target="`#${searchType === 'order' ? 'order' : searchType === 'as' ? 'as' : 'claim'}Payment${id}removal`">{{ removalPaymentMethod }}</a>
                    <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" :id="`${searchType === 'order' ? 'order' : searchType === 'as' ? 'as' : 'claim'}Payment${id}removal`" tabindex="-1" role="dialog" :aria-labelledby="`${searchType === 'order' ? 'order' : searchType === 'as' ? 'as' : 'claim'}Payment${id}removal`"
                      aria-hidden="true">
                      <PaymentTypeModal :orderId="id" type="removal" :isRead="true" :searchType="searchType"/>
                    </div>
                  </template>
                  <template #item-progress="{ statName, statCode, id, reservedDateTime, completeDateTime }">
                    <a href="/" style="color:#1071BC" data-bs-toggle="modal" :data-bs-target="`#${searchType === 'order' ? 'order' : searchType === 'as' ? 'as' : 'claim'}Progress${id}`">
                      <div class="d-flex justify-content-center">
                        <span>{{ statName }}</span>
                      </div>
                      <div class="d-flex justify-content-center">
                        <span style="font-size:10px;" :style="{color: statCode === '00041' ? '' : statCode === '00042' ? 'red' : ''}">{{ statCode === '00041' && reservedDateTime ? reservedDateTime : statCode === '00042' && completeDateTime ? completeDateTime : '' }}</span>
                      </div>
                    </a>
                    <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" :id="`${searchType === 'order' ? 'order' : searchType === 'as' ? 'as' : 'claim'}Progress${id}`" tabindex="-1" role="dialog" :aria-labelledby="`${searchType === 'order' ? 'order' : searchType === 'as' ? 'as' : 'claim'}Progress${id}`"
                      aria-hidden="true">
                      <ProgressModal :orderId="id" :isRead="true" :searchType="searchType"/>
                    </div>
                  </template>
                  <template #item-address="{ address }">
                    <div style="width:100%;text-align:left !important;">
                      <span>{{ address }}</span>
                    </div>
                  </template>
                  <template #item-happycall="{hcDateTime, happyCallNth}">
                    <div class="d-flex row">
                      <div class="d-flex justify-content-center">
                        <span>{{ happyCallNth ? `${happyCallNth}차` : '-' }}</span>
                      </div>
                      <div class="d-flex justify-content-center">
                        <span style="font-size:10px;">{{ hcDateTime ? hcDateTime : '' }}</span>
                      </div>
                    </div>
                  </template>
                  <template #item-sms="{smsDateTime, smsNth}">
                    <div class="d-flex row">
                      <div class="d-flex justify-content-center">
                        <span>{{ smsNth ? smsNth : '-' }}</span>
                      </div>
                      <div class="d-flex justify-content-center">
                        <span style="font-size:10px;">{{ smsDateTime ? smsDateTime : '' }}</span>
                      </div>
                    </div>
                  </template>
                  <template #item-installConfirm="{ id, statCode }">
                    <!--완료-->
                    <div v-if="statCode === '00042'">
                      <a href="/" style="color:#1071BC" data-bs-toggle="modal" :data-bs-target="`#${searchType === 'order' ? 'order' : searchType === 'as' ? 'as' : 'claim'}Install${id}`">조회</a>
                      <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" :id="`${searchType === 'order' ? 'order' : searchType === 'as' ? 'as' : 'claim'}Install${id}`" tabindex="-1" role="dialog" :aria-labelledby="`${searchType === 'order' ? 'order' : searchType === 'as' ? 'as' : 'claim'}Install${id}`"
                        aria-hidden="true">
                        <InstallConfirmModal :orderId="id" :isRead="true" :searchType="searchType"/>
                      </div>
                    </div>
                    <span v-else>미설치</span>
                  </template>
                  <template #item-detailed="{ id }">
                    <router-link 
                      :to="searchType === 'order' ? {name: 'orderModify', params:{id}, query:{state:'read', size, pno, saFilter, statCode, startDateTime: queryObjForOrder.startDateTime, endDateTime: queryObjForOrder.endDateTime, filter1: queryObjForOrder.filter1, filter2: queryObjForOrder.filter2, name: 'deleteList', searchType}}
                      : searchType === 'as' ? {name: 'ASModify', params:{id}, query:{state:'read', size, pno, saFilter, statCode, startDateTime: queryObjForOrder.startDateTime, endDateTime: queryObjForOrder.endDateTime, filter1: queryObjForOrder.filter1, filter2: queryObjForOrder.filter2, name: 'deleteList', searchType}}
                      : searchType === 'claim' ? {name: 'claimModify', params:{id}, query:{state:'read', size, pno, saFilter, statCode, startDateTime: queryObjForOrder.startDateTime, endDateTime: queryObjForOrder.endDateTime, filter1: queryObjForOrder.filter1, filter2: queryObjForOrder.filter2, name: 'deleteList', searchType}}
                      : ''" style="color:#1071BC"
                    >조회
                    </router-link>
                  </template>
                  <!-- product -->
                  <template #item-providerPrice="{ providerPrice }">
                    <span>{{ conversionToLocaleString(providerPrice) }}</span>
                  </template>
                  <template #item-sellerPrice="{ sellerPrice }">
                    <span>{{ conversionToLocaleString(sellerPrice) }}</span>
                  </template>
                  <!-- company -->
                  <template #item-phoneNo="{ phoneNo }">
                    <span>{{ conversionPhoneNo(phoneNo) }}</span>
                  </template>
                  <template #item-activeYn="{activeYn}">{{ activeYn === 'Y' ? '사용중': '중지' }}</template>
                  <template #item-id="{ id }">
                    <router-link 
                      :to="searchType === 'product' ? {name: 'BISProductModify', params:{id}, query:{state:'read'}}
                      : searchType === 'company' ? {name: 'companyModify', params:{id}, query:{state:'read'}}
                      : searchType === 'branch' ? {name: 'branchModify', params:{id}, query:{state:'read'}}
                      : searchType === 'engineer' ? {name: 'engineerModify', params:{id}, query:{state:'read'}}
                      : searchType === 'admin' ? {name: 'adminModify', params:{id}, query:{state:'read'}}
                      : ''" style="color:#1071BC"
                    >조회
                    </router-link>
                  </template>
                </EasyDataTable>
                <div class="customize-footer">
                  <div class="customize-rows-per-page mt-1">
                    <select
                      class="select-items"
                      @change="updateRowsPerPageSelect"
                    >
                      <option
                        v-for="item in rowsPerPageOpt"
                        :key="item"
                        :selected="item === rowsPerPageActiveOption"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div class="customize-index">
                    <nav class="m-b-30" aria-label="">
                      <ul class="pagination justify-content-center pagination-primary">

                        <li class="page-item prev-page" @click="firstPage" :disabled="isFirstPage"><a class="page-link" href="javascript:void(0)">처음</a></li>
                        <li class="page-item" @click="prevPage" :disabled="isFirstPage"><a class="page-link" href="javascript:void(0)">이전</a></li>
                        <li v-for="paginationNumber in pageList"
                          class="page-item customize-button"
                          :class="{ 'active': paginationNumber === pageNo + 1 }"
                          @click="updatePage(paginationNumber)"
                        >
                          <a class="page-link" href="javascript:void(0)">{{ paginationNumber }}</a>
                        </li>
                        <li class="page-item" @click="nextPage" :disabled="isLastPage"><a class="page-link" href="javascript:void(0)">다음</a></li>
                        <li class="page-item next-page" @click="lastPage" :disabled="isLastPage"><a class="page-link" href="javascript:void(0)">끝</a></li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Swal from 'sweetalert2';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { ref, onMounted, computed, watch } from 'vue';
import InfoDetailModal from '@/components/list/InfoDetailModal.vue'
import PaymentTypeModal from '@/components/list/PaymentTypeModal.vue'
import ProgressModal from '@/components/list/ProgressModal.vue'
import InstallConfirmModal from '@/components/list/InstallConfirmModal.vue'
import DeleteModal from '@/components/DeleteModal.vue'
import { RouterLink, useRouter, useRoute } from 'vue-router'
import { useLoginAuth } from '@/stores/LoginAuthStore'
import useDeleteManagement from '@/composables/deleteManagement'
import useOrderRegistry from '@/composables/registerOrder'
import commonApi from '@/composables/commonApi'
import { orderHeader, asHeader, claimHeader, productHeader, companyHeader, branchHeader, engineerHeader, adminHeader } from '@/constants/DeletedListHeader'
const router = useRouter()
const route = useRoute()
const LoginAuthStore = useLoginAuth(router)
const { deletedListOrder, deletedListClaim, deletedListProduct, deletedListCompany, deletedListBranch, deletedListEngineer, deletedListAdmin, permanentDelete, deletedRestore,
  deletedOrderList, deletedProductList, deletedCompanyList, deletedBranchList, deletedEngineerList, deletedAdminList,
  pageInfo } = useDeleteManagement(LoginAuthStore);
const { loadEngineer, certPicUri } = useOrderRegistry(LoginAuthStore);
const { loadCommonCode, loadResource, picture } = commonApi(LoginAuthStore);

const searchType = ref('order');

const pageNo = computed(() => pageInfo.value?.pageNo);
const totalCount = computed(() => pageInfo.value?.totalCount);
const totalPages = computed(() => pageInfo.value?.totalPages);
const pageList = ref([])
const filter = ref('')
const filter1Type = ref('All')
const filter2Type = ref('All')
const filter1Val = ref('')
const filter2Val = ref('')
const pno = ref(0)
const size = ref(25);
const pageNum = 10;
const saFilter = ref('None')
const statCode = ref('');
const queryObjForOrder = ref({orderType: 'General', startDateTime: '', endDateTime: '', filter1: filter1Type.value, filter2: filter2Type.value, saFilter: saFilter.value, rrp: size.value, pno: pno.value, statCode: statCode.value});
const queryObj = ref({filter: filter.value, rrp: size.value, pno: pno.value});
const originQueryObjForOrder = ref({orderType: 'General', startDateTime: '', endDateTime: '', filter1: 'All', filter2: 'All', saFilter: 'None', rrp: size.value, pno: pno.value, statCode: ''});
const originQueryObj = ref({filter: filter.value, rrp: size.value, pno: pno.value});
const date = ref([]);
const progressListRef = ref([])
const isSearchClick = ref(false);

const currentPageListStart = () => {
  return Math.floor(pno.value / pageNum) * pageNum + 1
}
const paging = async () => {
  //보여지는 페이지 리셋
  pageList.value = [];

  //현재페이지 기준으로 페이징 숫자 넣기
  let pageListStart = await currentPageListStart()
  for (let i = 1; i < pageNum + 1; i++) {  
    if (totalPages.value >= pageListStart){
      pageList.value.push(pageListStart)
      pageListStart ++;
    }
  }
}

const searchOrder = async (click, filter) => {
  if (route.query.pno) {
    queryObjForOrder.value.pno = route.query.pno
    queryObjForOrder.value.rrp = route.query.size
    queryObjForOrder.value.saFilter = route.query.saFilter
    queryObjForOrder.value.filter1 = route.query.filter1
    queryObjForOrder.value.filter2 = route.query.filter2
    queryObjForOrder.value.statCode = route.query.statCode
    queryObjForOrder.value.startDateTime = route.query.startDateTime
    queryObjForOrder.value.endDateTime = route.query.endDateTime
    searchType.value = route.query.searchType
  } else {
    if (click) isSearchClick.value = true;
    if (isSearchClick.value) {
      pno.value = 0
      queryObjForOrder.value.filter1 = filter1Type.value !== 'All' ? filter1Val.value ? `${filter1Type.value}:${filter1Val.value}` : 'All' : 'All';
      queryObjForOrder.value.filter2 = filter2Type.value !== 'All' ? filter2Val.value ? `${filter2Type.value}:${filter2Val.value}` : 'All' : 'All';
      if (!filter) saFilter.value = 'None';
      queryObjForOrder.value.statCode = statCode.value
      queryObjForOrder.value.startDateTime = date.value ? dateFormatString(date?.value[0]) : null
      queryObjForOrder.value.endDateTime = date.value ? dateFormatString(date?.value[1]) : null
    }
    queryObjForOrder.value.rrp = size.value
    queryObjForOrder.value.pno = pno.value
    queryObjForOrder.value.saFilter = saFilter.value
  }

  if (searchType.value === 'order' || searchType.value === 'as') await deletedListOrder(queryObjForOrder.value)
  else if (searchType.value === 'claim') await deletedListClaim(queryObjForOrder.value)
  isSearchClick.value = false;
  if (route.query?.pno) router.replace({query: {}})
  setTimeout(async () => {
    await paging()
  }, 1000);
}
const searchRegistry = async (click) => {
  if (click) isSearchClick.value = true;
  if (isSearchClick.value) {
    pno.value = 0
    queryObj.value.filter = filter.value
  }
  queryObj.value.rrp = size.value
  queryObj.value.pno = pno.value
  if (searchType.value === 'product') await deletedListProduct(queryObj.value)
  if (searchType.value === 'company') await deletedListCompany(queryObj.value)
  if (searchType.value === 'branch') await deletedListBranch(queryObj.value)
  if (searchType.value === 'engineer') await deletedListEngineer(queryObj.value)
  if (searchType.value === 'admin') await deletedListAdmin(queryObj.value)
  isSearchClick.value = false;
  setTimeout(async () => {
    await paging()
  }, 1000);
}
onMounted(() => {
  loadCommonCode('00004').then(res => res.json()).then(res => progressListRef.value = res.payload);
  // loadSelectBranchList();
  searchOrder();
  // getTodayReceiptCount();
})

const changeSearchOrder = async (type) => {
  saFilter.value = type;
  searchOrder(true, true);
}
const initSearchOrder = async () => {
  filter.value = '';
  filter1Type.value = 'All';
  filter2Type.value = 'All';
  filter1Val.value = '';
  filter2Val.value = '';
  pno.value = 0;
  saFilter.value = "None";
  statCode.value = '';
  date.value = null;
  queryObjForOrder.value = {orderType: 'General', startDateTime: '', endDateTime: '', filter1: filter1Type.value, filter2: filter2Type.value, saFilter: saFilter.value, rrp: size.value, pno: pno.value, statCode: statCode.value};
  await searchOrder();
}
const itemsSelected = ref([]);
const deleteAllSelected = async () => {
  const selectedIdList = (searchType.value === 'order' || searchType.value === 'as' || searchType.value === 'claim' || searchType.value === 'product' || searchType.value === 'branch' ||searchType.value === 'admin') 
                        ? itemsSelected.value.map(item => item.id)
                        : itemsSelected.value.map(item => item.uid)

  if(selectedIdList.length) {
    await permanentDelete(selectedIdList, searchType.value);
    setTimeout(() => {
      if (searchType.value === 'order' || searchType.value === 'as' || searchType.value === 'claim') searchOrder();
      else searchRegistry();
    }, 500);
  }
  itemsSelected.value = [];
}
const restoreAllSelected = async () => {
  const confirmRestore = await new Swal({
    title: '복구를 진행하시겠습니까?',
    showCancelButton: true,
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    },
  });
  if (confirmRestore.isConfirmed) {
  const selectedIdList = (searchType.value === 'order' || searchType.value === 'as' || searchType.value === 'claim' || searchType.value === 'product' || searchType.value === 'admin') 
                        ? itemsSelected.value.map(item => item.id)
                        : itemsSelected.value.map(item => item.uid)
    if(selectedIdList.length) {
      await deletedRestore(selectedIdList, searchType.value);
      setTimeout(() => {
        if (searchType.value === 'order' || searchType.value === 'as' || searchType.value === 'claim') searchOrder();
        else searchRegistry();
      }, 500);
    }
    itemsSelected.value = [];
  }
}
// $ref dataTable
const dataTable = ref();

// index related
const currentPageFirstIndex = computed(() => dataTable.value?.currentPageFirstIndex);
const currentPageLastIndex = computed(() => dataTable.value?.currentPageLastIndex);
const clientItemsLength = computed(() => dataTable.value?.clientItemsLength);

const isFirstPage = computed(() => dataTable.value?.isFirstPage);
const isLastPage = computed(() => dataTable.value?.isLastPage);

const updatePage = async (paginationNumber) => {
  pno.value = paginationNumber - 1;
  if (searchType.value === 'order' || searchType.value === 'as' || searchType.value === 'claim') searchOrder(true);
  else searchRegistry();
};
const lastPage = () => {
  updatePage(totalPages.value);
};
const firstPage = () => {
  updatePage(1);
};
const prevPage = () => {
  if (pno.value >= 1) updatePage(pno.value);
};
const nextPage = () => {
  if (pno.value + 2 <= totalPages.value) updatePage(pno.value + 2);
};

// rows per page related
const rowsPerPageOpt = [10, 25, 50, 100];
// const rowsPerPageOptions = computed(() => dataTable.value?.rowsPerPageOptions);
const rowsPerPageActiveOption = computed(() => dataTable.value?.rowsPerPageActiveOption);
const updateRowsPerPageSelect = async (e) => {
  size.value = Number(e.target.value);
  dataTable.value.updateRowsPerPageActiveOption(Number(e.target.value));
  await updatePage(1);
  if (searchType.value === 'order' || searchType.value === 'as' || searchType.value === 'claim') searchOrder();
  else searchRegistry();
};

const pic = ref('')
const openEngineerCertPic = async (engId) => {
  await loadEngineer(engId)
}
const openPicture = (url) => {
  new Swal({
    imageUrl: url,
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    },
    showConfirmButton: false, // "확인" 버튼 표시 안 함
    showCancelButton: true,
  }).then(() => {
    certPicUri.value = '';
  });
}

const leftPad = (val) => {
  if (val >= 10) return val;
  return `0${val}`;
}
const dateFormatString = (date) => {
  if (date) {
    const year = date.getFullYear()
    const month = leftPad(date.getMonth() + 1)
    const day = leftPad(date.getDate())
  
    return `${[month, day, year].join('/')}`
  }
}

const conversionPhoneNo = (phoneNo) => {
  if (phoneNo) {
    const phone = phoneNo.replace(/-/g, "");
    if (phone.length === 12) {
      return phone.replace(/^(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3`);
    } else {
      return phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
    }
  }
  return "";
}
const conversionToLocaleString = (money) => {
  return `₩${money.toLocaleString()}`;
}

watch(certPicUri, async (newVal, oldVal) => {
  if (certPicUri.value) await loadResource(certPicUri.value)
})
watch(picture, (newVal, oldVal) => {
  picture.value.blob().then(blob => {
    pic.value = URL.createObjectURL(blob)
    return openPicture(pic.value)
  })
})

const serverOptions = ref({
  page: Number(pno.value),
  rowsPerPage: Number(size.value),
})

const Headers = ref(orderHeader);

watch(searchType, (newVal, oldVal) => {
  console.log(newVal, "new search type")
})

const handleSearchType = async (type) => {
  searchType.value = type;
  queryObjForOrder.value = originQueryObjForOrder.value;
  queryObj.value = originQueryObj.value;
  if (type === 'order') {
    queryObjForOrder.value.orderType = 'General'
    Headers.value = orderHeader;
  }
  if (type === 'as') {
    queryObjForOrder.value.orderType = 'As'
    Headers.value = asHeader;
  }
  if (type === 'claim') {
    queryObjForOrder.value.orderType = 'Claim'
    Headers.value = claimHeader;
  }
  if (type === 'product') Headers.value = productHeader;
  if (type === 'company') Headers.value = companyHeader;
  if (type === 'branch') Headers.value = branchHeader;
  if (type === 'engineer') Headers.value = engineerHeader;
  if (type === 'admin') Headers.value = adminHeader;
  await updatePage(1);
}
const bodyRowClassNameFunction = (item) => {
  if (item.statCode === '00041' || item.statCode === '00042') return 'checkRow'
}
const isWeekendClass = (date) => {
  if (date.getDay() === 0) return 'sunColor'
  if (date.getDay() === 6) return 'satColor'
  return
}
</script>
<style lang="scss">
.selectedBtn {
  background-color: #dbdbdb !important;
}
</style>