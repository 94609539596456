<template>
  <div>
    <Breadcrumbs title="주문 등록" main="주문" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <div class="col-12 col-xxl-10 d-flex justify-content-between">
                <h5>주문 등록</h5>
                <div>
                  <button class="btn btn-outline-light me-3" type="button" data-bs-toggle="modal" data-bs-target="#beforeOrderModal">이전 주문</button>
                  <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" id="beforeOrderModal" tabindex="-1" role="dialog" aria-labelledby="beforeOrderModal" aria-hidden="true">
                    <LoadBeforeOrderModal @load-order="getLoadOrderData"/>
                  </div>
                  <!-- <button class="btn btn-outline-light mx-3" type="button" data-bs-toggle="modal" data-bs-target="#beforeASModal">이전 AS</button>
                  <div class="col-10 modal fade" id="beforeASModal" tabindex="-1" role="dialog" aria-labelledby="beforeASModal" aria-hidden="true">
                    <LoadBeforeASModal @load-after="getLoadASData" />
                  </div> -->
                  <button class="btn btn-outline-light" type="button" data-bs-toggle="modal" data-bs-target="#beforeClaimModal">이전 클레임</button>
                  <div class="col-10 modal fade" id="beforeClaimModal" tabindex="-1" role="dialog" aria-labelledby="beforeClaimModal" aria-hidden="true">
                    <LoadBeforeClaimModal @load-claim="getLoadClaimData" />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <form class="needs-validation" novalidate="" @submit.prevent="validationSubmit">
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2 validation-name">업체명</div>
                    <div class="col-9">
                      <select class="form-select btn-square digits col-xxl-5" v-model="providerId" v-bind:class="formSubmitted ? providerIdError ? 'is-invalid' : 'is-valid' : ''">
                        <option value=''>선택하세요</option>
                        <option v-for="item in selectCompList" :value="item?.id" :key="item?.id">{{ item?.name }}</option>
                      </select>
                      <div class="invalid-feedback" v-if="error.providerId">{{error.providerId}}</div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-2 validation-name">상품분류</div>
                    <div class="col-9">
                      <select class="form-select btn-square digits col-5" v-model="prodTypeId" v-bind:class="formSubmitted ? prodTypeIdError ? 'is-invalid' : 'is-valid' : ''">
                        <option value=''>선택하세요</option>
                        <option v-for="item in selectProdType" :value="item?.id" :key="item?.id">{{ item?.name }}</option>
                      </select>
                      <div class="invalid-feedback" v-if="error.prodTypeId">{{error.prodTypeId}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2 validation-name">상품명</div>
                    <div class="col-9">
                      <select class="form-select btn-square digits col-5" v-model="productId" v-bind:class="formSubmitted ? productIdError ? 'is-invalid' : 'is-valid' : ''">
                        <option value=''>선택하세요</option>
                        <option v-for="item in selectProductList" :value="item?.id" :key="item?.id">{{ item?.name }}</option>
                      </select>
                      <div class="invalid-feedback" v-if="error.productId">{{error.productId}}</div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-2">담당자명</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" v-model="assigneeName" />
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2 validation-name">고객명</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" v-model="customerName" v-bind:class="formSubmitted ? customerNameError ? 'is-invalid' : 'is-valid' : ''" />
                      <div class="invalid-feedback" v-if="error.customerName">{{error.customerName}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2 validation-name">전화번호1</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" maxlength="14" v-model="primaryPhoneNo" @input="handlePrimaryPhoneNo" v-bind:class="formSubmitted ? primaryPhoneNoError ? 'is-invalid' : 'is-valid' : ''"/>
                      <div class="invalid-feedback" v-if="error.primaryPhoneNo">{{error.primaryPhoneNo}}</div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-2">전화번호2</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" maxlength="14" v-model="secondaryPhoneNo" @input="handleSecondaryPhoneNo" />
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2">지사명</div>
                    <div class="col-9">
                      <select class="form-select btn-square digits col-5" v-model="branchId">
                        <option value=''>선택하세요</option>
                        <option v-for="item in selectBranchList" :value="item?.id" :key="item?.id">{{ item?.name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-2">설치기사</div>
                    <div class="col-9">
                      <select class="form-select btn-square digits col-5" v-model="engineerId">
                        <option value=''>선택하세요</option>
                        <option v-for="item in selectEngineerList" :value="item?.id" :key="item?.id">{{ item?.name }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2">우편번호</div>
                    <div class="col-9 d-flex">
                      <input class="form-control btn-square me-2" style="width:40%;" type="text" v-model="postalCode" readOnly/>
                      <PostCode @address-sought="addressSought" />
                      <!-- <div class="col-9 d-flex">
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2 validation-name">주소</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" placeholder="주소 검색 버튼을 눌러주세요" v-model="customerAddr1" v-bind:class="formSubmitted ? customerAddr1Error ? 'is-invalid' : 'is-valid' : ''" readOnly/>
                      <div class="invalid-feedback" v-if="error.customerAddr1">{{error.customerAddr1}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2">상세주소</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" v-model="customerAddr2"/>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-2">참고항목</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" v-model="extraAddr" readOnly/>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2">작업유형</div>
                    <div class="col-9">
                      <select class="form-select btn-square digits col-5" v-model="workTypeCode">
                        <option v-for="item in workTypeListRef" :value="item?.code" :key="item?.code">{{ item?.name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-2">진행상황</div>
                    <div class="col-9">
                      <select class="form-select btn-square digits col-5">
                        <option value=''>접수</option>
                        <!-- <option v-for="item in progressListRef" :value="item?.code" :key="item?.code">{{ item?.name }}</option> -->
                      </select>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2 validation-name">차종</div>
                    <div class="col-9">
                      <div class="col-12 d-flex">
                        <div class="col-5 pe-2">
                          <select class="form-select btn-square digits col-5" v-model="vhcCatCode" v-bind:class="formSubmitted ? vhcCatCodeError ? 'is-invalid' : 'is-valid' : ''">
                            <option value=''>선택하세요</option>
                            <option v-for="item in vhcCatCodeListRef" :value="item?.code" :key="item?.code">{{ item?.name }}</option>
                          </select>
                           <div class="invalid-feedback" v-if="error.vhcCatCode">{{error.vhcCatCode}}</div>
                        </div>
                        <div class="col-7">
                          <input class="form-control btn-square" type="text" placeholder="차량명" v-model="vhcTypeName" v-bind:class="formSubmitted ? vhcTypeNameError ? 'is-invalid' : 'is-valid' : ''">
                          <div class="invalid-feedback" v-if="error.vhcTypeName">{{error.vhcTypeName}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3 mt-4">
                  <div class="d-flex align-items-center col-12 col-xxl-9 me-4">
                    <div class="col-2 col-xxl-1 col-xl-1 col-lg-1 col-md-1">기본 장착비</div>
                    <div class="col-12 form-group m-checkbox-inline mb-0 custom-radio-ml btnBox">
                      <div class="radio radio-primary" v-for="(item, idx) in paymentCodeRef">
                        <input :id="'basic' + idx" type="radio" :name="'basic' + idx" :value="item.code" v-model="basicPayment.typeCode">
                        <label class="mb-0" :for="'basic' + idx">{{ item.name }}</label>
                      </div>
                      <div class="radio radio-primary">
                        <input class="form-control btn-square" type="text" placeholder="금액" v-model="basicPayment.amount" @input="handleBasicAmount" />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div v-if="basicPayment.typeCode === '01003'">
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2">내용</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.desc"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2">금액</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.amount" @input="handleBasicAmount"/>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2">카드사명</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.cardCompany"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2">카드번호</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.cardNo"/>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2">유효기간</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.cardValidPeriod"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2">승인번호</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.txApprovalNo"/>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2">승인일자</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.approvalDateTime"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2">비고</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.remark"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="basicPayment.typeCode === '01004'">
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2">내용</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.desc"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2">금액</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.amount" @input="handleBasicAmount"/>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2">계산서발행</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.approvalDateTime"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2">비고</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.remark"/>
                      </div>
                    </div>
                  </div>
                </div> -->

                <div class="d-flex col-12 mb-3 mt-4">
                  <div class="d-flex align-items-center col-12 col-xxl-9 me-4">
                    <div class="col-2 col-xxl-1 col-xl-1 col-lg-1 col-md-1">추가 장착비</div>
                    <div class="col-12 form-group m-checkbox-inline mb-0 custom-radio-ml btnBox">
                      <div class="radio radio-primary" v-for="(item, idx) in paymentCodeRef">
                        <input :id="'option' + idx" type="radio" :name="'option' + idx" :value="item.code" v-model="optionPayment.typeCode">
                        <label class="mb-0" :for="'option' + idx">{{ item.name }}</label>
                      </div>
                      <div class="radio radio-primary">
                        <input class="form-control btn-square" type="text" placeholder="금액" v-model="optionPayment.amount" @input="handleOptionAmount" />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div v-if="optionPayment.typeCode === '01003'">
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2">내용</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="optionPayment.desc"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2">금액</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="optionPayment.amount" @input="handleOptionAmount"/>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2">카드사명</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="optionPayment.cardCompany"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2">카드번호</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="optionPayment.cardNo"/>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2">유효기간</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="optionPayment.cardValidPeriod"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2">승인번호</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="optionPayment.txApprovalNo"/>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2">승인일자</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="optionPayment.approvalDateTime"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2">비고</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="optionPayment.remark"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="optionPayment.typeCode === '01004'">
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2">내용</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="optionPayment.desc"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2">금액</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="optionPayment.amount" @input="handleOptionAmount"/>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2">계산서발행</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="optionPayment.approvalDateTime"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2">비고</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="optionPayment.remark"/>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div class="d-flex col-12 mb-3 mt-4">
                  <div class="d-flex align-items-center col-12 col-xxl-9 me-4">
                    <div class="col-2 col-xxl-1 col-xl-1 col-lg-1 col-md-1">탈거비</div>
                    <div class="col-12 form-group m-checkbox-inline mb-0 custom-radio-ml btnBox">
                      <div class="radio radio-primary" v-for="(item, idx) in paymentCodeRef">
                        <input :id="'removal' + idx" type="radio" :name="'removal' + idx" :value="item.code" v-model="removalPayment.typeCode">
                        <label class="mb-0" :for="'removal' + idx">{{ item.name }}</label>
                      </div>
                      <div class="radio radio-primary">
                        <input class="form-control btn-square" type="text" placeholder="금액" v-model="removalPayment.amount" @input="handleRemovalAmount"/>
                      </div>              
                    </div>
                  </div>
                </div>
                <!-- <div v-if="removalPayment.typeCode === '01003'">
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2">내용</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="removalPayment.desc"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2">금액</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="removalPayment.amount" @input="handleRemovalAmount"/>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2">카드사명</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="removalPayment.cardCompany"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2">카드번호</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="removalPayment.cardNo"/>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2">유효기간</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="removalPayment.cardValidPeriod"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2">승인번호</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="removalPayment.txApprovalNo"/>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2">승인일자</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="removalPayment.approvalDateTime"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2">비고</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="removalPayment.remark"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="removalPayment.typeCode === '01004'">
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2">내용</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="removalPayment.desc"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2">금액</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="removalPayment.amount" @input="handleRemovalAmount"/>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2">계산서발행</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="removalPayment.approvalDateTime"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2">비고</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="removalPayment.remark"/>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-7 col-xxl-5 me-4">
                    <div class="col-2">제품/설치<br/>특이사항</div>
                    <div class="col-9">
                      <textarea class="form-control btn-square" style="min-height: 100px;" type="text" v-model="remark">
                        {{ installDescription ? installDescription : '' }}
                      </textarea>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-7 col-xxl-5 me-4">
                    <div class="col-2">본사 메모<br/></div>
                    <div class="col-9">
                      <textarea class="form-control btn-square" style="min-height: 100px;" type="text" v-model="memo" />
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 col-xxl-5 me-4">
                    <div class="col-2">해피콜 요청</div>
                    <div class="col-12 mt-4 d-flex">
                      <div class="media-body icon-state col-2">
                        <label class="switch">
                          <input type="checkbox" v-model="happyCallRequested" ><span class="switch-state"></span>
                        </label>
                      </div>
                      <div v-if="happyCallRequested" class="col-7">
                        <input class="form-control btn-square" type="text" v-model="happyCallMemo" placeholder="해피콜 메모"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="error.message" class="error">{{ error.message }}</div>
                <div class="col-12 col-xxl-10 d-flex justify-content-center">
                  <button class="btn btn-outline-light" type="submit">등록</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import LoadBeforeOrderModal from '@/components/load/LoadBeforeOrderModal.vue'
import LoadBeforeASModal from '@/components/load/LoadBeforeASModal.vue'
import LoadBeforeClaimModal from '@/components/load/LoadBeforeClaimModal.vue'
import useOrderRegistry from '@/composables/registerOrder';
import commonApi from '@/composables/commonApi'
import PostCode from '@/components/PostCode.vue'
import { ref, onMounted, watch, toRefs } from 'vue';
import { useRouter } from 'vue-router'
import { useLoginAuth } from '@/stores/LoginAuthStore';
const router = useRouter();
const LoginAuthStore = useLoginAuth(router)
const { selectCompList, selectProdType, selectProductList, selectBranchList, selectEngineerList,
loadSelectCompList, loadSelectProdTypeList, loadSelectProductList, loadSelectBranchList, loadSelectEngineerList, saveOrder,
providerId, prodTypeId, productId, branchId, engineerId, workTypeCode, statCode, vhcCatCode, vhcTypeName, error,
typeCode, customerName, postalCode, customerAddr1, customerAddr2, extraAddr, addrType, primaryPhoneNo, secondaryPhoneNo, remark, memo, happyCallRequested, happyCallMemo,
basicPayment, optionPayment, removalPayment, assigneeName, loadOrder, loadProductId, loadProviderId, loadProdTypeId, loadProduct, loadEngineerId, loadAs, loadClaim, installDescription } = useOrderRegistry(LoginAuthStore)
const { loadCommonCode } = commonApi(LoginAuthStore);
const workTypeListRef = ref([])
const progressListRef = ref([])
const vhcCatCodeListRef = ref([])
const paymentCodeRef = ref([]);
const inChargePersonRef = ref({});
onMounted(async () => {
  loadSelectCompList();
  loadSelectBranchList();
  assigneeName.value = LoginAuthStore.currentUser.name;
  // 주문유형 ([0] 00010: 주문, [1] 00011: 클레임, [2] 00012: AS)
  loadCommonCode('00001').then(res => res.json()).then(res => typeCode.value = res.payload[0].code);
  // 작업유형
  loadCommonCode('00003').then(res => res.json()).then(res => {
    workTypeListRef.value = res.payload
    workTypeCode.value = res.payload[0].code
  });
  // 진행상황
  loadCommonCode('00004').then(res => res.json()).then(res => progressListRef.value = res.payload);
  // 차종
  loadCommonCode('00005').then(res => res.json()).then(res => vhcCatCodeListRef.value = res.payload);
  // 결재유형코드
  loadCommonCode('00100').then(res => res.json()).then(res => paymentCodeRef.value = res.payload);
})
const comma = (val) => {
  val = String(val);
  return val.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
}
const uncomma = (val) => {
  val = String(val);
  return val.replace(/[^\d]+/g, '');
}
const handlePrimaryPhoneNo = (e) => {
  if (e.target.value.replace(/[^0-9]/g, '').length === 12) {
    primaryPhoneNo.value = e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3`);
  } else {
    primaryPhoneNo.value = e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }
}
const handleSecondaryPhoneNo = (e) => {
  if (e.target.value.replace(/[^0-9]/g, '').length === 12) {
    secondaryPhoneNo.value = e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3`);
  } else {
    secondaryPhoneNo.value = e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }
}
const handleBasicAmount = (e) => {
  basicPayment.value.amount = comma(uncomma(e.target.value))
}
const handleOptionAmount = (e) => {
  optionPayment.value.amount = comma(uncomma(e.target.value))
}
const handleRemovalAmount = (e) => {
  removalPayment.value.amount = comma(uncomma(e.target.value))
}

const formSubmitted = ref(false);
const providerIdError = ref(false);
const prodTypeIdError = ref(false);
const productIdError = ref(false);
const customerNameError = ref(false);
const primaryPhoneNoError = ref(false);
const customerAddr1Error = ref(false);
const vhcCatCodeError = ref(false);
const vhcTypeNameError = ref(false);

// const basicDescError = ref(false);
// const basicAmountError = ref(false);
// const basicCardCompanyError = ref(false);
// const basicCardNoError = ref(false);
// const basicCardValidPeriodError = ref(false);
// const basicTxApprovalNoError = ref(false);
// const basicApprovalDateTimeError = ref(false);

// const optionDescError = ref(false);
// const optionAmountError = ref(false);
// const optionCardCompanyError = ref(false);
// const optionCardNoError = ref(false);
// const optionCardValidPeriodError = ref(false);
// const optionTxApprovalNoError = ref(false);
// const optionApprovalDateTimeError = ref(false);

// const removalDescError = ref(false);
// const removalAmountError = ref(false);
// const removalCardCompanyError = ref(false);
// const removalCardNoError = ref(false);
// const removalCardValidPeriodError = ref(false);
// const removalTxApprovalNoError = ref(false);
// const removalApprovalDateTimeError = ref(false);

const addressSought = async (address) => {
  postalCode.value = address.postalCd;
  customerAddr1.value = address.addr;
  extraAddr.value = address.extraAddr;
  addrType.value = address.type === 'R' ? 'ROAD' : 'PARCEL'
}

const getLoadOrderData = async (id) => {
  await loadOrder(id, true);
  setTimeout(async () => {
    branchId.value = await '';
    basicPayment.value.id = await '';
    optionPayment.value.id = await '';
    removalPayment.value.id = await '';
    workTypeCode.value = await '00030';
  }, 200);
}
// const getLoadASData = async (id) => {
//   await loadAs(id);
// }
const getLoadClaimData = async (id) => {
  await loadClaim(id)
  setTimeout(async () => {
    branchId.value = await '';
    basicPayment.value.id = await '';
    optionPayment.value.id = await '';
    removalPayment.value.id = await '';
    workTypeCode.value = await '00030';
  }, 200);
}


watch(providerId, async (newVal, oldVal) => {
  if (newVal) {
    prodTypeId.value = '';
    productId.value = '';
    await loadSelectProdTypeList();
    if (loadProdTypeId.value) prodTypeId.value = await loadProdTypeId.value;
  }
  else if (oldVal && !newVal) {
    prodTypeId.value = '';
    productId.value = '';
    selectProdType.value = [];
    selectProductList.value = [];
  }
})

watch(prodTypeId, async (newVal, oldVal) => {
  if (newVal) {
    productId.value = '';
    await loadSelectProductList();
    if (loadProductId.value) productId.value = await loadProductId.value;
  }
  else if (oldVal && !newVal) {
    productId.value = '';
    selectProductList.value = [];
  }
})
watch(productId, async (newVal, oldVal) => {
  if (newVal) {
    await loadProduct(productId.value);
  }
  else if (oldVal && !newVal) {
    productId.value = '';
    selectProductList.value = [];
  }
})
watch(installDescription, async (newVal, oldVal) => {
  if (newVal) {
    remark.value = installDescription.value;
  }
})
watch(loadProductId, async (newVal, oldVal) => {
  if (newVal) {
    await loadProduct(productId.value);
  }
  else if (oldVal && !newVal) {
    productId.value = '';
    selectProductList.value = [];
  }
})
watch(loadProviderId, async (newVal, oldVal) => {
  if (newVal) {
    providerId.value = await loadProviderId.value
  }
})
watch(loadProdTypeId, async (newVal, oldVal) => {
  if (newVal) {
    prodTypeId.value = await loadProdTypeId.value;
  }
})

watch(branchId, async (newVal, oldVal) => {
  if (newVal) {
    engineerId.value = '';
    loadSelectEngineerList();
    if (loadEngineerId.value) engineerId.value = await loadEngineerId.value;
  }
  else if (oldVal && !newVal) {
    engineerId.value = '';
    selectEngineerList.value = [];
  }
})
const { typeCode: typeCode1, desc: desc1, cardCompany: cardCompany1, cardNo: cardNo1, cardValidPeriod: cardValidPeriod1, approvalDateTime: approvalDateTime1, txApprovalNo: txApprovalNo1 } = toRefs(basicPayment.value)
const { typeCode: typeCode2, desc: desc2, cardCompany: cardCompany2, cardNo: cardNo2, cardValidPeriod: cardValidPeriod2, approvalDateTime: approvalDateTime2, txApprovalNo: txApprovalNo2 } = toRefs(optionPayment.value)
const { typeCode: typeCode3, desc: desc3, cardCompany: cardCompany3, cardNo: cardNo3, cardValidPeriod: cardValidPeriod3, approvalDateTime: approvalDateTime3, txApprovalNo: txApprovalNo3 } = toRefs(removalPayment.value)
watch(typeCode1, (newVal, oldVal) => {
  if (newVal === '01003') {
    if (typeCode2.value === '01003') {
      desc1.value = desc2.value
      cardCompany1.value = cardCompany2.value
      cardNo1.value = cardNo2.value
      cardValidPeriod1.value = cardValidPeriod2.value
      approvalDateTime1.value = approvalDateTime2.value
      txApprovalNo1.value = txApprovalNo2.value
    }
    if (typeCode3.value === '01003') {
      desc1.value = desc3.value
      cardCompany1.value = cardCompany3.value
      cardNo1.value = cardNo3.value
      cardValidPeriod1.value = cardValidPeriod3.value
      approvalDateTime1.value = approvalDateTime3.value
      txApprovalNo1.value = txApprovalNo3.value
    } 
    if (!(typeCode2.value === '01003' && typeCode3.value === '01003')) {
      desc1.value = null
      cardCompany1.value = null
      cardNo1.value = null
      cardValidPeriod1.value = null
      approvalDateTime1.value = null
      txApprovalNo1.value = null
    }
  } else if (newVal === '01004') {
    if (typeCode2.value === '01004') {
      desc1.value = desc2.value
      approvalDateTime1.value = approvalDateTime2.value
    }
    if (typeCode3.value === '01004') {
      desc1.value = desc3.value
      approvalDateTime1.value = approvalDateTime3.value
    } 
    if (!(typeCode2.value === '01004' && typeCode3.value === '01004')) {
      desc1.value = null
      approvalDateTime1.value = null
    }
  }
})
watch(typeCode2, (newVal, oldVal) => {
  if (newVal === '01003') {
    if (typeCode1.value === '01003') {
      desc2.value = desc1.value
      cardCompany2.value = cardCompany1.value
      cardNo2.value = cardNo1.value
      cardValidPeriod2.value = cardValidPeriod1.value
      approvalDateTime2.value = approvalDateTime1.value
      txApprovalNo2.value = txApprovalNo1.value
    } 
    if (typeCode3.value === '01003') {
      desc2.value = desc3.value
      cardCompany2.value = cardCompany3.value
      cardNo2.value = cardNo3.value
      cardValidPeriod2.value = cardValidPeriod3.value
      approvalDateTime2.value = approvalDateTime3.value
      txApprovalNo2.value = txApprovalNo3.value
    } 
    if (!(typeCode1.value === '01003' && typeCode3.value === '01003')) {
      desc2.value = null
      cardCompany2.value = null
      cardNo2.value = null
      cardValidPeriod2.value = null
      approvalDateTime2.value = null
      txApprovalNo2.value = null
    }
  } else if (newVal === '01004') {
    if (typeCode1.value === '01004') {
      desc2.value = desc1.value
      approvalDateTime2.value = approvalDateTime1.value
    } 
    if (typeCode3.value === '01004') {
      desc2.value = desc3.value
      approvalDateTime2.value = approvalDateTime3.value
    } 
    if (!(typeCode1.value === '01004' && typeCode3.value === '01004')) {
      desc2.value = null
      approvalDateTime2.value = null
    }
  }
})
watch(typeCode3, (newVal, oldVal) => {
  if (newVal === '01003') {
    if (typeCode1.value === '01003') {
      desc3.value = desc1.value
      cardCompany3.value = cardCompany1.value
      cardNo3.value = cardNo1.value
      cardValidPeriod3.value = cardValidPeriod1.value
      approvalDateTime3.value = approvalDateTime1.value
      txApprovalNo3.value = txApprovalNo1.value
    } 
    if (typeCode2.value === '01003') {
      desc3.value = desc2.value
      cardCompany3.value = cardCompany2.value
      cardNo3.value = cardNo2.value
      cardValidPeriod3.value = cardValidPeriod2.value
      approvalDateTime3.value = approvalDateTime2.value
      txApprovalNo3.value = txApprovalNo2.value
    } 
    if (!(typeCode1.value === '01003' && typeCode2.value === '01003')) {
      desc3.value = null
      cardCompany3.value = null
      cardNo3.value = null
      cardValidPeriod3.value = null
      approvalDateTime3.value = null
      txApprovalNo3.value = null
    }
  } else if (newVal === '01004') {
    if (typeCode1.value === '01004') {
      desc3.value = desc1.value
      approvalDateTime3.value = approvalDateTime3.value
    } 
    if (typeCode2.value === '01004') {
      desc3.value = desc2.value
      approvalDateTime3.value = approvalDateTime2.value
    } 
    if (!(typeCode1.value === '01004' && typeCode2.value === '01004')) {
      desc3.value = null
      approvalDateTime3.value = null
    }
  }
})
watch(desc1, (newVal, oldVal) => {
  if (typeCode1.value === '01003') {
    if (typeCode2.value === '01003') desc2.value = newVal
    if (typeCode3.value === '01003') desc3.value = newVal
  } else if (typeCode1.value === '01004') {
    if (typeCode2.value === '01004') desc2.value = newVal
    if (typeCode3.value === '01004') desc3.value = newVal
  }
})
watch(desc2, (newVal, oldVal) => {
  if (typeCode2.value === '01003') {
    if (typeCode1.value === '01003') desc1.value = newVal
    if (typeCode3.value === '01003') desc3.value = newVal
  } else if (typeCode2.value === '01004') {
    if (typeCode1.value === '01004') desc1.value = newVal
    if (typeCode3.value === '01004') desc3.value = newVal
  }
})
watch(desc3, (newVal, oldVal) => {
  if (typeCode3.value === '01003') {
    if (typeCode1.value === '01003') desc1.value = newVal
    if (typeCode2.value === '01003') desc2.value = newVal
  } else if (typeCode3.value === '01004') {
    if (typeCode1.value === '01004') desc1.value = newVal
    if (typeCode2.value === '01004') desc2.value = newVal
  }
})
watch(cardCompany1, (newVal, oldVal) => {
  if (typeCode1.value === '01003') {
    if (typeCode2.value === '01003') cardCompany2.value = newVal
    if (typeCode3.value === '01003') cardCompany3.value = newVal
  }
})
watch(cardCompany2, (newVal, oldVal) => {
  if (typeCode2.value === '01003') {
    if (typeCode1.value === '01003') cardCompany1.value = newVal
    if (typeCode3.value === '01003') cardCompany3.value = newVal
  }
})
watch(cardCompany3, (newVal, oldVal) => {
  if (typeCode3.value === '01003') {
    if (typeCode1.value === '01003') cardCompany1.value = newVal
    if (typeCode2.value === '01003') cardCompany2.value = newVal
  }
})
watch(cardNo1, (newVal, oldVal) => {
  if (typeCode1.value === '01003') {
    if (typeCode2.value === '01003') cardNo2.value = newVal
    if (typeCode3.value === '01003') cardNo3.value = newVal
  }
})
watch(cardNo2, (newVal, oldVal) => {
  if (typeCode2.value === '01003') {
    if (typeCode1.value === '01003') cardNo1.value = newVal
    if (typeCode3.value === '01003') cardNo3.value = newVal
  }
})
watch(cardNo3, (newVal, oldVal) => {
  if (typeCode3.value === '01003') {
    if (typeCode1.value === '01003') cardNo1.value = newVal
    if (typeCode2.value === '01003') cardNo2.value = newVal
  }
})
watch(cardValidPeriod1, (newVal, oldVal) => {
  if (typeCode1.value === '01003') {
    if (typeCode2.value === '01003') cardValidPeriod2.value = newVal
    if (typeCode3.value === '01003') cardValidPeriod3.value = newVal
  }
})
watch(cardValidPeriod2, (newVal, oldVal) => {
  if (typeCode2.value === '01003') {
    if (typeCode1.value === '01003') cardValidPeriod1.value = newVal
    if (typeCode3.value === '01003') cardValidPeriod3.value = newVal
  }
})
watch(cardValidPeriod3, (newVal, oldVal) => {
  if (typeCode3.value === '01003') {
    if (typeCode1.value === '01003') cardValidPeriod1.value = newVal
    if (typeCode2.value === '01003') cardValidPeriod2.value = newVal
  }
})
watch(approvalDateTime1, (newVal, oldVal) => {
  if (typeCode1.value === '01003') {
    if (typeCode2.value === '01003') approvalDateTime2.value = newVal
    if (typeCode3.value === '01003') approvalDateTime3.value = newVal
  } else if (typeCode1.value === '01004') {
    if (typeCode2.value === '01004') approvalDateTime2.value = newVal
    if (typeCode3.value === '01004') approvalDateTime3.value = newVal
  }
})
watch(approvalDateTime2, (newVal, oldVal) => {
  if (typeCode2.value === '01003') {
    if (typeCode1.value === '01003') approvalDateTime1.value = newVal
    if (typeCode3.value === '01003') approvalDateTime3.value = newVal
  } else if (typeCode2.value === '01004') {
    if (typeCode1.value === '01004') approvalDateTime1.value = newVal
    if (typeCode3.value === '01004') approvalDateTime3.value = newVal
  }
})
watch(approvalDateTime3, (newVal, oldVal) => {
  if (typeCode3.value === '01003') {
    if (typeCode1.value === '01003') approvalDateTime1.value = newVal
    if (typeCode2.value === '01003') approvalDateTime2.value = newVal
  } else if (typeCode3.value === '01004') {
    if (typeCode1.value === '01004') approvalDateTime1.value = newVal
    if (typeCode2.value === '01004') approvalDateTime2.value = newVal
  }
})
watch(txApprovalNo1, (newVal, oldVal) => {
  if (typeCode1.value === '01003') {
    if (typeCode2.value === '01003') txApprovalNo2.value = newVal
    if (typeCode3.value === '01003') txApprovalNo3.value = newVal
  }
})
watch(txApprovalNo2, (newVal, oldVal) => {
  if (typeCode2.value === '01003') {
    if (typeCode1.value === '01003') txApprovalNo1.value = newVal
    if (typeCode3.value === '01003') txApprovalNo3.value = newVal
  }
})
watch(txApprovalNo3, (newVal, oldVal) => {
  if (typeCode3.value === '01003') {
    if (typeCode1.value === '01003') txApprovalNo1.value = newVal
    if (typeCode2.value === '01003') txApprovalNo2.value = newVal
  }
})

const validationSubmit = async () => {
  formSubmitted.value = true;

  if (providerId.value.length < 1) providerIdError.value = true;
  else providerIdError.value = false;

  if (prodTypeId.value.length < 1) prodTypeIdError.value = true;
  else prodTypeIdError.value = false;

  if (productId.value.length < 1) productIdError.value = true;
  else productIdError.value = false;

  if (customerName.value.length < 1) customerNameError.value = true;
  else customerNameError.value = false;

  if (primaryPhoneNo.value.length < 1) primaryPhoneNoError.value = true;
  else primaryPhoneNoError.value = false;

  if (customerAddr1.value.length < 1) customerAddr1Error.value = true;
  else customerAddr1Error.value = false;

  if (vhcCatCode.value.length < 1) vhcCatCodeError.value = true;
  else vhcCatCodeError.value = false;
  if (vhcTypeName.value.length < 1) vhcTypeNameError.value = true;
  else vhcTypeNameError.value = false;

  if (basicPayment.value.typeCode === '01003') {
    // if (basicPayment.value.desc.length < 1) basicDescError.value = true;
    // else basicDescError.value = false;
    // if (basicPayment.value.amount === null) basicAmountError.value = true;
    // else basicAmountError.value = false;
    // if (basicPayment.value.cardCompany.length < 1) basicCardCompanyError.value = true;
    // else basicCardCompanyError.value = false;
    // if (basicPayment.value.cardNo.length < 1) basicCardNoError.value = true;
    // else basicCardNoError.value = false;
    // if (basicPayment.value.cardValidPeriod.length < 1) basicCardValidPeriodError.value = true;
    // else basicCardValidPeriodError.value = false;
    // if (basicPayment.value.txApprovalNo.length < 1) basicTxApprovalNoError.value = true;
    // else basicTxApprovalNoError.value = false;
    // if (basicPayment.value.approvalDateTime.length < 1) basicApprovalDateTimeError.value = true;
    // else basicApprovalDateTimeError.value = false;
  }
  if (basicPayment.value.typeCode === '01004') {
    // if (basicPayment.value.desc.length < 1) basicDescError.value = true;
    // else basicDescError.value = false;
    // if (basicPayment.value.amount === null) basicAmountError.value = true;
    // else basicAmountError.value = false;
    // if (basicPayment.value.approvalDateTime.length < 1) basicApprovalDateTimeError.value = true;
    // else basicApprovalDateTimeError.value = false;
  }
  if (optionPayment.value.typeCode === '01003') {
    // if (optionPayment.value.desc.length < 1) optionDescError.value = true;
    // else optionDescError.value = false;
    // if (optionPayment.value.amount === null) optionAmountError.value = true;
    // else optionAmountError.value = false;
    // if (optionPayment.value.cardCompany.length < 1) optionCardCompanyError.value = true;
    // else optionCardCompanyError.value = false;
    // if (optionPayment.value.cardNo.length < 1) optionCardNoError.value = true;
    // else optionCardNoError.value = false;
    // if (optionPayment.value.cardValidPeriod.length < 1) optionCardValidPeriodError.value = true;
    // else optionCardValidPeriodError.value = false;
    // if (optionPayment.value.txApprovalNo.length < 1) optionTxApprovalNoError.value = true;
    // else optionTxApprovalNoError.value = false;
    // if (optionPayment.value.approvalDateTime.length < 1) optionApprovalDateTimeError.value = true;
    // else optionApprovalDateTimeError.value = false;
  }
  if (optionPayment.value.typeCode === '01004') {
    // if (optionPayment.value.desc.length < 1) optionDescError.value = true;
    // else optionDescError.value = false;
    // if (optionPayment.value.amount === null) optionAmountError.value = true;
    // else optionAmountError.value = false;
    // if (optionPayment.value.approvalDateTime.length < 1) optionApprovalDateTimeError.value = true;
    // else optionApprovalDateTimeError.value = false;
  }
  if (removalPayment.value.typeCode === '01003') {
    // if (removalPayment.value.desc.length < 1) removalDescError.value = true;
    // else removalDescError.value = false;
    // if (removalPayment.value.amount === null) removalAmountError.value = true;
    // else removalAmountError.value = false;
    // if (removalPayment.value.cardCompany.length < 1) removalCardCompanyError.value = true;
    // else removalCardCompanyError.value = false;
    // if (removalPayment.value.cardNo.length < 1) removalCardNoError.value = true;
    // else removalCardNoError.value = false;
    // if (removalPayment.value.cardValidPeriod.length < 1) removalCardValidPeriodError.value = true;
    // else removalCardValidPeriodError.value = false;
    // if (removalPayment.value.txApprovalNo.length < 1) removalTxApprovalNoError.value = true;
    // else removalTxApprovalNoError.value = false;
    // if (removalPayment.value.approvalDateTime.length < 1) removalApprovalDateTimeError.value = true;
    // else removalApprovalDateTimeError.value = false;
  }
  if (removalPayment.value.typeCode === '01004') {
    // if (removalPayment.value.desc.length < 1) removalDescError.value = true;
    // else removalDescError.value = false;
    // if (removalPayment.value.amount === null) removalAmountError.value = true;
    // else removalAmountError.value = false;
    // if (removalPayment.value.approvalDateTime.length < 1) removalApprovalDateTimeError.value = true;
    // else removalApprovalDateTimeError.value = false;
  }

  await saveOrder();
}

</script>