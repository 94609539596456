<template>
  <div class="w-100 modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="h-100 modal-content">
      <div class="row d-flex justify-content-center h-100 align-items-center">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <div class="d-flex justify-content-between align-items-center">
                <h3 class="modal-title">해피콜 히스토리</h3>
                <button id="happyCallHistModal" class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive mt-3">
                <EasyDataTable ref="dataTable" v-model:server-options="serverOptions" :headers="Headers" :rowsPerPage="10" :items="happyCallHistData" hide-footer header-text-direction="center"
                  border-cell body-text-direction="center" :body-row-class-name="bodyRowClassNameFunction">
                </EasyDataTable>
                <div class="customize-footer">
                  <div class="customize-rows-per-page mt-3">
                  </div>
                  <div class="customize-index">
                    <nav class="m-b-30" aria-label="">
                      <ul class="pagination justify-content-center pagination-primary">

                        <li class="page-item prev-page" @click="firstPage" :disabled="isFirstPage"><a class="page-link" href="javascript:void(0)">처음</a></li>
                        <li class="page-item" @click="prevPage" :disabled="isFirstPage"><a class="page-link" href="javascript:void(0)">이전</a></li>
                        <li v-for="paginationNumber in totalPages"
                          class="page-item customize-button"
                          :class="{ 'active': paginationNumber === pageNo + 1 }"
                          @click="updatePage(paginationNumber)"
                        >
                          <a class="page-link" href="javascript:void(0)">{{ paginationNumber }}</a>
                        </li>
                        <li class="page-item" @click="nextPage" :disabled="isLastPage"><a class="page-link" href="javascript:void(0)">다음</a></li>
                        <li class="page-item next-page" @click="lastPage" :disabled="isLastPage"><a class="page-link" href="javascript:void(0)">끝</a></li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { ref, onMounted, computed, watch, defineProps, toRefs } from 'vue';
import { RouterLink, useRouter } from 'vue-router'
import { useLoginAuth } from '@/stores/LoginAuthStore'
import useOrderRegistry from '@/composables/registerOrder'
import commonApi from '@/composables/commonApi'

const router = useRouter()
const LoginAuthStore = useLoginAuth(router)
const { loadCommonCode } = commonApi(LoginAuthStore);
const { happyCallHist, pageInfo, happyCallHistData } = useOrderRegistry(LoginAuthStore);
const pageNo = computed(() => pageInfo.value?.pageNo);
const totalCount = computed(() => pageInfo.value?.totalCount);
const totalPages = computed(() => pageInfo.value?.totalPages);
const filter1Type = ref('All')
const filter2Type = ref('All')
const filter1Val = ref('')
const filter2Val = ref('')
const pno = ref(0)
const size = ref(10);
const saFilter = ref('None')
const queryObj = ref({rrp: size.value, pno: pno.value});
const props = defineProps({
  orderId: {
    type: Number,
    required: true,
  },
});
const { orderId } = toRefs(props);
const searchHist = () => {
  queryObj.value.rrp = size.value;
  queryObj.value.pno = pno.value;
  happyCallHist(queryObj.value, orderId.value)
}
const isOpenModal = ref(false);
onMounted(() => {
  const happyCallHistModalEl = document.getElementById(`orderHappyCallHist${orderId.value}`);
  happyCallHistModalEl.addEventListener('shown.bs.modal', async () => {
    if (!isOpenModal.value) {
      isOpenModal.value = true;
      searchHist();
    }
  })

  happyCallHistModalEl.addEventListener('hidden.bs.modal', async () => {
    isOpenModal.value = false;
    happyCallHistModalEl.removeEventListener('shown.bs.modal', async () => {
      if (!isOpenModal.value) {
        isOpenModal.value = true;
        searchHist();
      }
    })
  })
})

// $ref dataTable
const dataTable = ref();

// index related
const currentPageFirstIndex = computed(() => dataTable.value?.currentPageFirstIndex);
const currentPageLastIndex = computed(() => dataTable.value?.currentPageLastIndex);
const clientItemsLength = computed(() => dataTable.value?.clientItemsLength);

const isFirstPage = computed(() => dataTable.value?.isFirstPage);
const isLastPage = computed(() => dataTable.value?.isLastPage);

const updatePage = async (paginationNumber) => {
  pno.value = paginationNumber - 1;
  await searchHist();
};
const lastPage = () => {
  updatePage(totalPages.value);
};
const firstPage = () => {
  updatePage(1);
};
const prevPage = () => {
  if (pno.value >= 1) updatePage(pno.value);
};
const nextPage = () => {
  if (pno.value + 2 <= totalPages.value) updatePage(pno.value + 2);
};

// rows per page related
const rowsPerPageOpt = [10, 25, 50, 100];
// const rowsPerPageOptions = computed(() => dataTable.value?.rowsPerPageOptions);
const rowsPerPageActiveOption = computed(() => dataTable.value?.rowsPerPageActiveOption);
const updateRowsPerPageSelect = async (e) => {
  size.value = Number(e.target.value);
  dataTable.value.updateRowsPerPageActiveOption(Number(e.target.value));
  await updatePage(1);
  await searchHist()
};

const serverOptions = ref({
  page: Number(pno.value),
  rowsPerPage: Number(size.value),
})

const closeModal = () => {
  document.getElementById("happyCallHistModal").click();
}

const Headers = ref([
  {
    text: "기사명",
    value: "engineerName",
    sortable: false
  },
  {
    text: "유형",
    value: "typeName",
    sortable: false
  },
  {
    text: "메모 사항",
    value: "remark",
    sortable: false
  },
  {
    text: "차수",
    value: "nth",
    sortable: false
  },
  {
    text: "요청일시",
    value: "createdDateTime",
    sortable: false
  },
  {
    text: "통화/전송일시",
    value: "callDateTime",
    sortable: false
  },
  {
    text: "상태",
    value: "statName",
    sortable: false
  },
]);
const bodyRowClassNameFunction = (item) => {
  return 'whiteRow'
}
</script>
<style lang="scss">
// .modal-custom-style {
//   width: 1984px !important;
//   --bs-modal-width: 1984px !important;
// }
</style>