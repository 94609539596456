const trim = (str) => str.replace(/^\s+|\s+$/g,'')
const pathVarRegex = /\{(\w|-)+\}/d
const replacePath = (str, val) => {
    if(Array.isArray(val)) {
        val.forEach(val => str = str.replace(pathVarRegex, val))
    } else {
        str = str.replace(pathVarRegex, val)
    }
    return str
}
export {trim, replacePath}
