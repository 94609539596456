<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
      <img
        class="b-r-10"
        src="@/assets/images/dashboard/profile.png"
        alt=""
      />
      <div class="media-body">
        <span>{{LoginAuthStore.currentUser.name}}</span>
        <p class="mb-0 font-roboto">{{ LoginAuthStore.currentUser.roleDesc }} <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li>
        <router-link :to="`/${LoginAuthStore.currentUser.roleName === 'ROLE_ADMIN' ? 'admin' : LoginAuthStore.currentUser.roleName === 'ROLE_BRANCH' ? 'branch' : LoginAuthStore.currentUser.roleName === 'ROLE_ENGINEER' ? 'engineer' : LoginAuthStore.currentUser.roleName === 'ROLE_SUPPLIER' ? 'company' : 'admin'}/modify/${LoginAuthStore.currentUser.id}`">
          <vue-feather type="user"></vue-feather><span>내정보</span>
        </router-link>
      </li>
      <!-- <li>
        <router-link :to="'/pages/search'"><vue-feather type="settings"></vue-feather><span>설정</span></router-link>
      </li> -->
      <li>
        <a @click="LoginAuthStore.logout"
          ><vue-feather type="log-in"></vue-feather><span>로그아웃</span></a
        >
      </li>
    </ul>
  </li>
</template>
<!-- <script setup>
import { ref } from 'vue'
import loginAuth from '@/composables/loginAuth'

const {logout, currentUser} = loginAuth()

const user = ref(currentUser())
</script> -->
<script setup>
import {useLoginAuth} from '@/stores/LoginAuthStore'
import {useRouter} from 'vue-router'
const router = useRouter()
const LoginAuthStore = useLoginAuth(router)
console.log(LoginAuthStore.currentUser, "currentUser")
</script>
