<template>
  <div>
    <Breadcrumbs title="반려 목록" main="주문"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h5>반려 목록 ( 총 : {{ totalCount }} 개 )</h5>
              <!-- <span>금일 접수 (총 : {{ todayReceiptCount }} 개)</span> -->
              <div class="p-1 m-t-5 d-flex align-items-center btnBox" style="border: solid rgba(175, 173, 173, 0.29) 1px">
                <div class="pe-1">
                  <Datepicker week-start="0" :day-class="isWeekendClass" class="datepicker-multi datepicker-here digits b-r-0 mr-1" format="MM-dd-yyyy" type="date" :multi="true" v-model="date" range locale="ko">
                  </Datepicker>
                </div>
                <div class="pe-1 d-flex justify-content-center">
                  <select v-model="filter1Type" class="form-select btn-square digits">
                    <option value="All">전체</option>
                    <option value="CustomerName">고객명</option>
                    <option value="Address">주소</option>
                    <option value="PhoneNo">고객연락처</option>
                    <option value="ProductName">상품명</option>
                    <option value="BranchName">지점</option>
                    <option value="EngineerName">기사명</option>
                    <option value="SupplierName">업체명</option>
                    <option value="BasicPayment">장착비</option>
                    <option value="OptionPayment">추가장착비</option>
                    <option value="UninstallPayment">탈거비</option>
                  </select>                
                </div>
                <div class="pe-1 d-flex justify-content-center">
                  <input v-model="filter1Val" @input="handleFilter1Val" @keypress.enter="searchOrder(true)" class="form-control btn-square w-100" type="text" placeholder="">
                </div>
                <div class="pe-1 d-flex justify-content-center">
                  <select v-model="filter2Type" class="form-select btn-square digits">
                    <option value="All">전체</option>
                    <option value="CustomerName">고객명</option>
                    <option value="Address">주소</option>
                    <option value="PhoneNo">고객연락처</option>
                    <option value="ProductName">상품명</option>
                    <option value="BranchName">지점</option>
                    <option value="EngineerName">기사명</option>
                    <option value="SupplierName">업체명</option>
                    <option value="BasicPayment">장착비</option>
                    <option value="OptionPayment">추가장착비</option>
                    <option value="UninstallPayment">탈거비</option>
                  </select>     
                </div>
                <div class="pe-1 d-flex justify-content-center">
                  <input v-model="filter2Val" @input="handleFilter2Val" @keypress.enter="searchOrder(true)" class="form-control btn-square w-100" type="text" placeholder="">
                </div>
                <div class="pe-1 d-flex justify-content-center">
                  <select v-model="statCode" class="form-select btn-square digits">
                    <option>진행상황 검색</option>
                    <option v-for="item in progressListRef" :value="item?.code" :key="item?.code">{{ item?.name }}</option>
                  </select>
                </div>
                <div class="pe-1 d-flex justify-content-center">
                  <select v-model="workType" class="form-select btn-square digits">
                    <option value=''>유형 검색</option>
                    <option value="Install">장착</option>
                    <option value="FreeAs">무상AS</option>
                    <option value="PaidAs">유상AS</option>
                  </select>
                </div>                
                <div class="pe-1 d-flex justify-content-center">
                  <button @click="searchOrder(true)" class="btn btn-square btn-primary" type="button" title="btn btn-outline-light btn-sm">검색</button>
                </div>
                <div class="pe-1 d-flex justify-content-center">
                  <button @click="excelDownload" class="btn btn-square btn-success" type="button" title="btn btn-outline-light btn-sm">다운로드</button>
                </div>
                <div class="pe-1 d-flex justify-content-center">
                  <button @click="initSearchOrder" class="btn btn-square btn-outline-light txt-dark" type="button" title="btn btn-outline-light btn-sm"><i class="fa fa-refresh me-1" aria-hidden="true"></i>검색초기화</button>
                </div>                
              </div>
            </div>
            <div class="card-body">
              <div>
                <div class="d-flex justify-content-between">
                  <div class="d-flex">
                  </div>
                  <div class="d-flex">
                    <div class="d-flex align-items-center">
                      <button :disabled="!itemsSelected.length" class="btn btn-square btn-outline-light txt-dark" type="button" title="btn btn-outline-light btn-sm" data-bs-toggle="modal" data-bs-target="#orderDelete">선택 삭제</button>
                      <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" id="orderDelete" tabindex="-1" role="dialog" aria-labelledby="orderDelete"
                        aria-hidden="true">
                        <DeleteModal @delete-list="deleteAllSelected" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-responsive mt-3">
                <EasyDataTable ref="dataTable" v-model:server-options="serverOptions" v-model:items-selected="itemsSelected" :headers="Headers" :rowsPerPage="10" :items="orderList" hide-footer header-text-direction="center"
                  border-cell body-text-direction="center" :body-row-class-name="bodyRowClassNameFunction">
                  <template #item-productName="{ productName }">
                     <div style="width:100%;text-align:left !important;"><span>{{ productName }}</span></div>
                  </template>                  
                  <template #item-customer="{ customerName, id, workTypeCode }">
                    <a href="/" style="color:#1071BC" data-bs-toggle="modal" :data-bs-target="`#${workTypeCode === '00030' ? 'order' : 'as'}InfoDetail${id}`">{{ customerName }}</a>
                    <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" data-bs-focus="false" :id="`${workTypeCode === '00030' ? 'order' : 'as'}InfoDetail${id}`" tabindex="-1" role="dialog" :aria-labelledby="`${workTypeCode === '00030' ? 'order' : 'as'}InfoDetail${id}`"
                    aria-hidden="true">
                      <InfoDetailModal @update-order="setTimeOutSearchOrder" :orderId="id" :searchType="workTypeCode === '00030' ? 'order' : 'as'"/>
                    </div>
                  </template>
                  <template #item-customerPhone="{ customerPhoneNo1 }">
                    <span>{{ conversionPhoneNo(customerPhoneNo1) }}</span>
                  </template>
                  <template #item-customerAddr="{customerAddr1, customerAddr2, extraAddr}">
                    <div style="width:100%;text-align:left !important;">
                      <span>{{ `${customerAddr1 ? customerAddr1 : ''} ${customerAddr2 ? customerAddr2 : ''}` }}</span>
                    </div>
                  </template>
                  <template #item-engineerName="{ engineerId, engineerName }">
                    <span style="color:#1071BC;cursor:pointer;" @click="openEngineerCertPic(engineerId)">{{ engineerName }}</span>
                  </template>                  
                  <template #item-basicPayment="{id, basicPaymentId ,basicPaymentMethod, workTypeCode}">
                    <a href="/" style="color:#1071BC" data-bs-toggle="modal" :data-bs-target="`#${workTypeCode === '00030' ? 'order' : 'as'}Payment${id}basic`">{{ basicPaymentMethod }}</a>
                    <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" :id="`${workTypeCode === '00030' ? 'order' : 'as'}Payment${id}basic`" tabindex="-1" role="dialog" :aria-labelledby="`${workTypeCode === '00030' ? 'order' : 'as'}Payment${id}basic`"
                      aria-hidden="true">
                      <PaymentTypeModal :orderId="id" type="basic" :searchType="workTypeCode === '00030' ? 'order' : 'as'"/>
                    </div>
                  </template>
                  <template #item-optionPayment="{id, optionPaymentId, optionPaymentMethod, workTypeCode}">
                    <a href="/" style="color:#1071BC" data-bs-toggle="modal" :data-bs-target="`#${workTypeCode === '00030' ? 'order' : 'as'}Payment${id}option`">{{ optionPaymentMethod }}</a>
                    <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" :id="`${workTypeCode === '00030' ? 'order' : 'as'}Payment${id}option`" tabindex="-1" role="dialog" :aria-labelledby="`${workTypeCode === '00030' ? 'order' : 'as'}Payment${id}option`"
                      aria-hidden="true">
                      <PaymentTypeModal :orderId="id" type="option" :searchType="workTypeCode === '00030' ? 'order' : 'as'"/>
                    </div>
                  </template>
                  <template #item-removalPayment="{id, removalPaymentId, removalPaymentMethod, workTypeCode}">
                    <a href="/" style="color:#1071BC" data-bs-toggle="modal" :data-bs-target="`#${workTypeCode === '00030' ? 'order' : 'as'}Payment${id}removal`">{{ removalPaymentMethod }}</a>
                    <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" :id="`${workTypeCode === '00030' ? 'order' : 'as'}Payment${id}removal`" tabindex="-1" role="dialog" :aria-labelledby="`${workTypeCode === '00030' ? 'order' : 'as'}Payment${id}removal`"
                      aria-hidden="true">
                      <PaymentTypeModal :orderId="id" type="removal" :searchType="workTypeCode === '00030' ? 'order' : 'as'"/>
                    </div>
                  </template>
                  <template #item-progress="{ statName, statCode, id, workTypeCode, reservedDateTime, completeDateTime }">
                    <a href="/" style="color:#1071BC" data-bs-toggle="modal" :data-bs-target="`#${workTypeCode === '00030' ? 'order' : 'as'}Progress${id}`">
                      <div class="d-flex justify-content-center">
                        <span>{{ statName }}</span>
                      </div>
                      <div class="d-flex justify-content-center">
                        <span style="font-size:10px;" :style="{color: statCode === '00041' ? '' : statCode === '00042' ? 'red' : ''}">{{ statCode === '00041' && reservedDateTime ? reservedDateTime : statCode === '00042' && completeDateTime ? completeDateTime : '' }}</span>
                      </div>
                    </a>
                    <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" :id="`${workTypeCode === '00030' ? 'order' : 'as'}Progress${id}`" tabindex="-1" role="dialog" :aria-labelledby="`${workTypeCode === '00030' ? 'order' : 'as'}Progress${id}`"
                      aria-hidden="true">
                      <ProgressModal :orderId="id" :searchType="workTypeCode === '00030' ? 'order' : 'as'"/>
                    </div>
                  </template>
                  <template #item-happycall="{hcDateTime, happyCallNth, id}">
                    <div v-if="happyCallNth">
                      <a href="/" style="color:#1071BC" class="d-flex row" data-bs-toggle="modal" :data-bs-target="`#orderHappyCallHist${id}`">
                        <div class="d-flex justify-content-center">
                          <span>{{ happyCallNth ? `${happyCallNth}차` : '-' }}</span>
                        </div>
                        <div class="d-flex justify-content-center">
                          <span style="font-size:10px;">{{ hcDateTime ? hcDateTime : '' }}</span>
                        </div>
                      </a>
                      <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" :id="`orderHappyCallHist${id}`" tabindex="-1" role="dialog" :aria-labelledby="`orderHappyCallHist${id}`"
                        aria-hidden="true">
                        <HappyCallHistModal :orderId="id"/>
                      </div>
                    </div>
                    <div v-else>-</div>
                  </template>
                  <template #item-sms="{smsDateTime, smsNth, id}">
                    <div v-if="smsNth">
                      <a href="/" style="color:#1071BC" class="d-flex row" data-bs-toggle="modal" :data-bs-target="`#orderSmsHist${id}`">
                        <div class="d-flex justify-content-center">
                          <span>{{ smsNth ? `${smsNth}차` : '-' }}</span>
                        </div>
                        <div class="d-flex justify-content-center">
                          <span style="font-size:10px;">{{ smsDateTime ? smsDateTime : '' }}</span>
                        </div>
                      </a>
                      <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" :id="`orderSmsHist${id}`" tabindex="-1" role="dialog" :aria-labelledby="`orderSmsHist${id}`"
                        aria-hidden="true">
                        <SmsHistModal :orderId="id"/>
                      </div>
                    </div>
                    <div v-else>-</div>
                  </template>
                  <template #item-installConfirm="{ id, statCode, workTypeCode }">
                    <!--완료-->
                    <div v-if="statCode === '00042'">
                      <a href="/" style="color:#1071BC" data-bs-toggle="modal" :data-bs-target="`#${workTypeCode === '00030' ? 'order' : 'as'}Install${id}`">조회</a>
                      <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" :id="`${workTypeCode === '00030' ? 'order' : 'as'}Install${id}`" tabindex="-1" role="dialog" :aria-labelledby="`${workTypeCode === '00030' ? 'order' : 'as'}Install${id}`"
                        aria-hidden="true">
                        <InstallConfirmModal :orderId="id" :searchType="workTypeCode === '00030' ? 'order' : 'as'"/>
                      </div>
                    </div>
                    <span v-else>미설치</span>
                  </template>
                  <template #item-detailed="{ id, workTypeCode }">
                    <router-link v-if="workTypeCode === '00030'" :to="{name: 'orderModify', params:{id}, query:{size, pno, saFilter, statCode, workType, startDateTime: queryObj.startDateTime, endDateTime: queryObj.endDateTime, filter1: queryObj.filter1, filter2: queryObj.filter2, name: 'returnList'}}" style="color:#1071BC">조회</router-link>
                    <router-link v-else :to="{name: 'ASModify', params:{id}, query:{size, pno, saFilter, statCode, workType, startDateTime: queryObj.startDateTime, endDateTime: queryObj.endDateTime, filter1: queryObj.filter1, filter2: queryObj.filter2, name: 'returnList'}}" style="color:#1071BC">조회</router-link>
                  </template>
                </EasyDataTable>
                <div class="customize-footer">
                  <div class="customize-rows-per-page mt-1">
                    <select
                      class="select-items"
                      @change="updateRowsPerPageSelect"
                    >
                      <option
                        v-for="item in rowsPerPageOpt"
                        :key="item"
                        :selected="item === rowsPerPageActiveOption"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div class="customize-index">
                    <nav class="m-b-30" aria-label="">
                      <ul class="pagination justify-content-center pagination-primary">

                        <li class="page-item prev-page" @click="firstPage" :disabled="isFirstPage"><a class="page-link" href="javascript:void(0)">처음</a></li>
                        <li class="page-item" @click="prevPage" :disabled="isFirstPage"><a class="page-link" href="javascript:void(0)">이전</a></li>
                        <li v-for="paginationNumber in pageList"
                          class="page-item customize-button"
                          :class="{ 'active': paginationNumber === pageNo + 1 }"
                          @click="updatePage(paginationNumber)"
                        >
                          <a class="page-link" href="javascript:void(0)">{{ paginationNumber }}</a>
                        </li>
                        <li class="page-item" @click="nextPage" :disabled="isLastPage"><a class="page-link" href="javascript:void(0)">다음</a></li>
                        <li class="page-item next-page" @click="lastPage" :disabled="isLastPage"><a class="page-link" href="javascript:void(0)">끝</a></li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Swal from 'sweetalert2';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { ref, onMounted, computed, watch } from 'vue';
import InfoDetailModal from '@/components/list/InfoDetailModal.vue'
import PaymentTypeModal from '@/components/list/PaymentTypeModal.vue'
import ProgressModal from '@/components/list/ProgressModal.vue'
import InstallConfirmModal from '@/components/list/InstallConfirmModal.vue'
import HappyCallHistModal from '@/components/list/HappyCallHistModal.vue'
import SmsHistModal from '@/components/list/SmsHistModal.vue'
import DeleteModal from '@/components/DeleteModal.vue'
import { RouterLink, useRouter, useRoute } from 'vue-router'
import { useLoginAuth } from '@/stores/LoginAuthStore'
import useOrderRegistry from '@/composables/registerOrder'
import commonApi from '@/composables/commonApi'
import { orderHeader } from '@/constants/ListHeader'
const router = useRouter()
const route = useRoute()
const LoginAuthStore = useLoginAuth(router)
const { loadCommonCode, loadResource, picture } = commonApi(LoginAuthStore);
const { orderList, pageInfo, listOrder, getTodayReceiptCount, todayReceiptCount, loadSelectBranchList, loadSelectEngineerList, branchId, engineerId, selectBranchList, selectEngineerList, changeEngineer, deleteOrderList, loadEngineer, certPicUri, orderListExcelDownload } = useOrderRegistry(LoginAuthStore);
const pageNo = computed(() => pageInfo.value?.pageNo);
const totalCount = computed(() => pageInfo.value?.totalCount);
const totalPages = computed(() => pageInfo.value?.totalPages);
const pageList = ref([])
const filter1Type = ref('All')
const filter2Type = ref('All')
const filter1Val = ref('')
const filter2Val = ref('')
const pno = ref(0)
const size = ref(25);
const pageNum = 10;
const saFilter = ref('OrderRejected')
const statCode = ref('');
const workType = ref('');
const queryObj = ref({orderType: 'General', startDateTime: '', endDateTime: '', filter1: filter1Type.value, filter2: filter2Type.value, saFilter: saFilter.value, rrp: size.value, pno: pno.value, statCode: statCode.value, workType: workType.value});
const date = ref([]);
const progressListRef = ref([])
const isSearchClick = ref(false);
const isExcelDown = ref(false);
const currentPageListStart = () => {
  return Math.floor(pno.value / pageNum) * pageNum + 1
}
const paging = async () => {
  //보여지는 페이지 리셋
  pageList.value = [];

  //현재페이지 기준으로 페이징 숫자 넣기
  let pageListStart = await currentPageListStart()
  for (let i = 1; i < pageNum + 1; i++) {  
    if (totalPages.value >= pageListStart){
      pageList.value.push(pageListStart)
      pageListStart ++;
    }
  }
}

const searchOrder = async (click) => {
  if (route.query.pno) {
    queryObj.value.pno = route.query.pno
    queryObj.value.rrp = route.query.size
    queryObj.value.saFilter = route.query.saFilter
    queryObj.value.filter1 = route.query.filter1
    queryObj.value.filter2 = route.query.filter2
    queryObj.value.statCode = route.query.statCode
    queryObj.value.workType = route.query.workType
    queryObj.value.startDateTime = route.query.startDateTime
    queryObj.value.endDateTime = route.query.endDateTime
  } else {
    if (click) isSearchClick.value = true;
    if (isSearchClick.value) {
      pno.value = 0
      queryObj.value.filter1 = filter1Val.value ? `${filter1Type.value}:${filter1Val.value}` : 'All';
      queryObj.value.filter2 = filter2Val.value ? `${filter2Type.value}:${filter2Val.value}` : 'All';
      // saFilter.value = 'None';
      queryObj.value.statCode = statCode.value
      queryObj.value.workType = workType.value
      queryObj.value.startDateTime = date.value ? dateFormatString(date?.value[0]) : null
      queryObj.value.endDateTime = date.value ? dateFormatString(date?.value[1]) : null
    }
    queryObj.value.rrp = size.value
    queryObj.value.pno = pno.value
    queryObj.value.saFilter = saFilter.value
  }

  await listOrder(queryObj.value)
  isSearchClick.value = false;
  if (route.query?.pno) router.replace({query: {}})
  setTimeout(async () => {
    await paging()
  }, 1000);
}

const excelDownload = async () => {
  new Swal({
    title: "엑셀 다운로드 하시겠습니까?",
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    },
    showConfirmButton: true, // "확인" 버튼 표시 안 함
    showCancelButton: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      isExcelDown.value = true;
      // queryObj.value.filter1 = filter1Val.value ? `${filter1Type.value}:${filter1Val.value}` : 'All';
      // queryObj.value.filter2 = filter2Val.value ? `${filter2Type.value}:${filter2Val.value}` : 'All';
      // queryObj.value.statCode = statCode.value
      // queryObj.value.workType = workType.value
      // queryObj.value.startDateTime = date.value ? dateFormatString(date?.value[0]) : null
      // queryObj.value.endDateTime = date.value ? dateFormatString(date?.value[1]) : null
      await orderListExcelDownload(queryObj.value, isExcelDown)
    }
  });
}

onMounted(() => {
  // const endDate = new Date();
  // const startDate = new Date(new Date().setDate(endDate.getDate() - 7));
  // date.value = [startDate, endDate];
  // 진행상황
  loadCommonCode('00004').then(res => res.json()).then(res => progressListRef.value = res.payload);
  loadSelectBranchList();
  searchOrder();
  getTodayReceiptCount();
})
const itemsSelected = ref([]);
const deleteAllSelected = async () => {
  const selectedIdList = itemsSelected.value.map(item => item.id);
  if(selectedIdList.length) {
    await deleteOrderList(selectedIdList);
    setTimeOutSearchOrder()
  }
  itemsSelected.value = [];
}

const changeSearchOrder = async (type) => {
  saFilter.value = type;
  searchOrder();
}
const initSearchOrder = async () => {
  filter1Type.value = 'All';
  filter2Type.value = 'All';
  filter1Val.value = '';
  filter2Val.value = '';
  pno.value = 0;
  statCode.value = '';
  workType.value = '';
  date.value = null;
  queryObj.value = {orderType: 'General', startDateTime: '', endDateTime: '', filter1: filter1Type.value, filter2: filter2Type.value, saFilter: saFilter.value, rrp: size.value, pno: pno.value, statCode: statCode.value, workType: workType.value};
  searchOrder();
}
const handleChangeEngineer = async () => {
  if (itemsSelected.value.length === 0) return alert('주문 목록을 선택하세요.')
  else {
    if (branchId.value.length === 0 && engineerId.value.length === 0) return alert('변경할 지사를 선택하세요.')
    if (branchId.value.length !== 0 && engineerId.value.length === 0) return alert('변경할 기사를 선택하세요.')
  }
  const orderIdList = itemsSelected.value.map(el => el.id);
  const data = {
    branchId: branchId.value,
    engineerId: engineerId.value,
    orderIdList
  }
  await changeEngineer(data);
  setTimeOutSearchOrder()
}
const setTimeOutSearchOrder = () => {
  setTimeout(() => {
    searchOrder()
  }, 500);
}
// $ref dataTable
const dataTable = ref();

// index related
const currentPageFirstIndex = computed(() => dataTable.value?.currentPageFirstIndex);
const currentPageLastIndex = computed(() => dataTable.value?.currentPageLastIndex);
const clientItemsLength = computed(() => dataTable.value?.clientItemsLength);

const isFirstPage = computed(() => dataTable.value?.isFirstPage);
const isLastPage = computed(() => dataTable.value?.isLastPage);

const updatePage = async (paginationNumber) => {
  pno.value = paginationNumber - 1;
  await searchOrder();
};
const lastPage = () => {
  updatePage(totalPages.value);
};
const firstPage = () => {
  updatePage(1);
};
const prevPage = () => {
  if (pno.value >= 1) updatePage(pno.value);
};
const nextPage = () => {
  if (pno.value + 2 <= totalPages.value) updatePage(pno.value + 2);
};

// rows per page related
const rowsPerPageOpt = [10, 25, 50, 100];
// const rowsPerPageOptions = computed(() => dataTable.value?.rowsPerPageOptions);
const rowsPerPageActiveOption = computed(() => dataTable.value?.rowsPerPageActiveOption);
const updateRowsPerPageSelect = async (e) => {
  size.value = Number(e.target.value);
  dataTable.value.updateRowsPerPageActiveOption(Number(e.target.value));
  await updatePage(1);
  await searchOrder()
};

const pic = ref('')
const openEngineerCertPic = async (engId) => {
  await loadEngineer(engId)
}
const openPicture = (url) => {
  new Swal({
    imageUrl: url,
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    },
    showConfirmButton: false, // "확인" 버튼 표시 안 함
    showCancelButton: true,
  }).then(() => {
    certPicUri.value = '';
  });
}

const leftPad = (val) => {
  if (val >= 10) return val;
  return `0${val}`;
}
const dateFormatString = (date) => {
  if (date) {
    const year = date.getFullYear()
    const month = leftPad(date.getMonth() + 1)
    const day = leftPad(date.getDate())
  
    return `${[month, day, year].join('/')}`
  }
}

const conversionPhoneNo = (phoneNo) => {
  const phone = phoneNo.replace(/-/g, "");
  if (phone.length === 12) {
    return phone.replace(/^(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3`);
  } else {
    return phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }
}

watch(branchId, (newVal, oldVal) => {
  if (newVal) {
    engineerId.value = '';
    loadSelectEngineerList();
  }
  else if (oldVal && !newVal) {
    engineerId.value = '';
    selectEngineerList.value = [];
  }
})

watch(certPicUri, async (newVal, oldVal) => {
  if (certPicUri.value) await loadResource(certPicUri.value)
})
watch(picture, (newVal, oldVal) => {
  picture.value.blob().then(blob => {
    pic.value = URL.createObjectURL(blob)
    return openPicture(pic.value)
  })
})

const serverOptions = ref({
  page: Number(pno.value),
  rowsPerPage: Number(size.value),
})

const Headers = ref(orderHeader);
const bodyRowClassNameFunction = (item) => {
  if (item.statCode === '00041' || item.statCode === '00042') return 'checkRow'
}
const isWeekendClass = (date) => {
  if (date.getDay() === 0) return 'sunColor'
  if (date.getDay() === 6) return 'satColor'
  return
}
</script>