<template>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="modal-title">상품 분류 관리</h5>
          <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <table class="productManageTable">
          <thead>
            <tr>
              <th class="productColumnWidth">
                <div class="d-flex align-items-center ms-3">
                  상품 분류
                </div>
              </th>
              <th class="dispOrderColumnWidth">정렬 순서</th>
              <th class="updateColumnWidth">수정</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="prod in selectCompProductList">
              <td v-if="prod.id && !prod.edit">
                <div class="d-flex align-items-center ms-3">
                  {{ prod.name }}
                </div>
              </td>
              <td v-else>
                <div class="edit-wrap">
                  <input class="form-control btn-square" type="text" :value="prod.name" @input="prod.name = $event.target.value"/>
                  <i v-if="prod.name" @click="confirmName(prod.id, prod.name, prod.dispOrder)" class="fa fa-check-circle confirm-button"></i>
                  <!-- <button class="btn btn-outline-light confirm-button btn-sm">확인</button> -->
                </div>
              </td>
              <td v-if="prod.id && !prod.edit">
                {{ prod.dispOrder }}
              </td>
              <td v-else>
                <div class="edit-wrap">
                  <input class="form-control btn-square" type="text" :value="prod.dispOrder" @input="prod.dispOrder = $event.target.value"/>
                  <!-- <button class="btn btn-outline-light confirm-button btn-sm">확인</button> -->
                </div>
              </td>
              <td>
                <i class="fa fa-pencil edit-button" @click="editProduct(prod)"></i>
                <i class="fa fa-trash-o edit-button" @click="deleteProduct(prod.id)"></i>
              </td>
            </tr>
            <tr @click="addProduct" style="cursor: pointer;">
              <td colspan="3">
                추가하기
              </td>
            </tr>
          </tbody>
        </table>
        <div class="customize-index">
          <nav class="m-b-30" aria-label="">
            <ul class="pagination justify-content-center pagination-primary">

              <li class="page-item prev-page" @click="firstPage" :disabled="pageNo + 1 === 1"><a class="page-link"
                  href="javascript:void(0)">처음</a></li>
              <li class="page-item" @click="prevPage" :disabled="pageNo + 1 === 1"><a class="page-link"
                  href="javascript:void(0)">이전</a></li>
              <li v-for="paginationNumber in totalPages" class="page-item customize-button"
                :class="{ 'active': paginationNumber === pageNo + 1 }"
                @click="updatePage(paginationNumber)">
                <a class="page-link" href="javascript:void(0)">{{ paginationNumber }}</a>
              </li>
              <li class="page-item" @click="nextPage" :disabled="pageNo + 1 === totalPages"><a class="page-link"
                  href="javascript:void(0)">다음</a></li>
              <li class="page-item next-page" @click="lastPage" :disabled="pageNo + 1 === totalPages"><a class="page-link"
                  href="javascript:void(0)">끝</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, defineEmits } from 'vue';
import { useRouter } from 'vue-router'
import { useLoginAuth } from '@/stores/LoginAuthStore';
import useProductRegistry from '@/composables/registerProduct';
const router = useRouter();
const LoginAuthStore = useLoginAuth(router)
const { selectCompProductList, product, loadSelectCompProductList, pageInfo, saveSelectCompProduct, deleteSelectCompProduct } = useProductRegistry(LoginAuthStore)
const pno = ref(0)
const queryObj = ref({pno: pno.value});
onMounted(() => {
  loadSelectCompProductList(true, queryObj)
})
const emit = defineEmits(['loadList']);
const pageNo = computed(() => pageInfo.value?.pageNo);
const totalCount = computed(() => pageInfo.value?.totalCount);
const totalPages = computed(() => pageInfo.value?.totalPages);
const updatePage = async (paginationNumber) => {
  pno.value = paginationNumber - 1;
  queryObj.value.pno = pno.value
  isAddProduct.value = false;
  await loadSelectCompProductList(true, queryObj);
};
const firstPage = () => {
  if (pno.value >= 1) updatePage(1);
};
const prevPage = () => {
  if (pno.value >= 1) updatePage(pno.value);
};
const nextPage = () => {
  if (pno.value + 2 <= totalPages.value) updatePage(pno.value + 2);
};
const lastPage = () => {
  if (pno.value + 2 <= totalPages.value) updatePage(totalPages.value);
};
const beforeEditId = ref('');
const beforeEditData = ref({});
const beforeAdd = async () => {
  const lastIdx = selectCompProductList.value.length - 1;
  if (!selectCompProductList.value[lastIdx].id) selectCompProductList.value.splice(lastIdx, 1);
  isAddProduct.value = false;
}
const beforeEdit = async () => {
  await beforeAdd();
  if (beforeEditId.value) {
    const idx = selectCompProductList.value.findIndex(item => item.id === beforeEditId.value);
    if (idx != -1) {
      selectCompProductList.value[idx].edit = false;
      selectCompProductList.value[idx].name = beforeEditData.value.name;
      selectCompProductList.value[idx].dispOrder = beforeEditData.value.dispOrder;
    }
  }
  beforeEditId.value = '';
  beforeEditData.value = {};
}
const editProduct = async (prod) => {
  await beforeEdit();
  if (prod.id) {
    beforeEditId.value = prod.id;
    beforeEditData.value = {
      name: prod.name,
      dispOrder: prod.dispOrder
    }
    const idx = selectCompProductList.value.findIndex(item => item.id === prod.id);
    if (idx != -1) selectCompProductList.value[idx].edit = true;
  }
}
const isAddProduct = ref(false);
const addProduct = async () => {
  await beforeEdit();
  // currentPage.value = totalPages.value;
  if (!isAddProduct.value) {
    isAddProduct.value = true;
    selectCompProductList.value.push({
      name: '',
      dispOrder: '',
    });
  } else {
    alert('하나의 상품만 추가 가능합니다.')
  }
}
const confirmName = async (id, name, dispOrder) => {
  await beforeEdit();
  let saveProduct = {};
  if (id) {
    saveProduct = {
      id,
      name,
      dispOrder
    }
  } else {
    saveProduct = {
      name,
      dispOrder
    }
  }
  await saveSelectCompProduct(saveProduct);
  isAddProduct.value = false;
  setTimeout(() => {
    updatePage(1);
    emit('loadList');
  }, 500)
}
const deleteProduct = async (id) => {
  if (confirm('삭제하시겠습니까?')) {
    await deleteSelectCompProduct(id);
    isAddProduct.value = false;
    setTimeout(() => {
      updatePage(1);
      emit('loadList');
    }, 500)
  }
}

</script>
<style lang="scss" scoped>
.productManageTable {
  border-collapse: collapse;
  margin: 20px auto;
}
.productColumnWidth {
  width: 50%;
}
.dispOrderColumnWidth {
  width: 10%;
}
.updateColumnWidth {
  width: 15%;
}
th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

// th {
//     background-color: #f2f2f2;
// }

// tr:nth-child(even) {
//     background-color: #f2f2f2;
// }

.edit-button {
  padding: 5px 10px;
  cursor: pointer;
}
.confirm-button {
  position: absolute;
  right: 8px;
  top: 2px;
  font-size: 30px;
  cursor: pointer;
}
.edit-wrap {
  position: relative;
}
</style>