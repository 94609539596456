<template>
  <div>
    <Breadcrumbs title="클레임 수정" main="클레임" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <div class="col-12 col-xxl-10 d-flex justify-content-between">
                <div class="d-flex align-items-end">
                  <h5>클레임 수정</h5>
                  <span class="ms-2 modifyDetailed"  data-bs-toggle="modal" data-bs-target="#asModifyHist">자세히 보기</span>
                  <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" id="asModifyHist" tabindex="-1" role="dialog" aria-labelledby="asModifyHist"
                    aria-hidden="true">
                    <ModifyHistModal :orderId="id" ref="modifyHistRef" />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <form class="needs-validation" novalidate="" @submit.prevent="validationSubmit">
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2 validation-name">업체명</div>
                    <div class="col-9">
                        <select class="form-select btn-square digits col-xxl-5" v-model="providerId" v-bind:class="formSubmitted ? providerIdError ? 'is-invalid' : 'is-valid' : ''">
                          <option value=''>선택하세요</option>
                          <option v-for="item in selectCompList" :value="item?.id" :key="item?.id">{{ item?.name }}</option>
                        </select>
                        <div class="invalid-feedback" v-if="error.providerId">{{error.providerId}}</div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-2 validation-name">상품분류</div>
                    <div class="col-9">
                      <select class="form-select btn-square digits col-5" v-model="prodTypeId" v-bind:class="formSubmitted ? prodTypeIdError ? 'is-invalid' : 'is-valid' : ''">
                        <option value=''>선택하세요</option>
                        <option v-for="item in selectProdType" :value="item?.id" :key="item?.id">{{ item?.name }}</option>
                      </select>
                      <div class="invalid-feedback" v-if="error.prodTypeId">{{error.prodTypeId}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2 validation-name">상품명</div>
                    <div class="col-9">
                      <select class="form-select btn-square digits col-5" v-model="productId" v-bind:class="formSubmitted ? productIdError ? 'is-invalid' : 'is-valid' : ''">
                        <option value=''>선택하세요</option>
                        <option v-for="item in selectProductList" :value="item?.id" :key="item?.id">{{ item?.name }}</option>
                      </select>
                      <div class="invalid-feedback" v-if="error.productId">{{error.productId}}</div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-2">담당자명</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" v-model="assigneeName" :readOnly="isRead"/>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2 validation-name">고객명</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" v-model="customerName" v-bind:class="formSubmitted ? customerNameError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead"/>
                      <div class="invalid-feedback" v-if="error.customerName">{{error.customerName}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2 validation-name">전화번호1</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" maxlength="14" v-model="primaryPhoneNo" @input="handlePrimaryPhoneNo" v-bind:class="formSubmitted ? primaryPhoneNoError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead"/>
                      <div class="invalid-feedback" v-if="error.primaryPhoneNo">{{error.primaryPhoneNo}}</div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-2">전화번호2</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" maxlength="14" v-model="secondaryPhoneNo" @input="handleSecondaryPhoneNo" :readOnly="isRead"/>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2 validation-name">지사명</div>
                    <div class="col-9">
                      <select class="form-select btn-square digits col-5" v-model="branchId" v-bind:class="formSubmitted ? branchIdError ? 'is-invalid' : 'is-valid' : ''">
                        <option value=''>선택하세요</option>
                        <option v-for="item in selectBranchList" :value="item?.id" :key="item?.id">{{ item?.name }}</option>
                      </select>
                      <div class="invalid-feedback" v-if="error.branchId">{{error.branchId}}</div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-2 validation-name">설치기사</div>
                    <div class="col-9">
                      <select class="form-select btn-square digits col-5" v-model="engineerId" v-bind:class="formSubmitted ? engineerIdError ? 'is-invalid' : 'is-valid' : ''">
                        <option value=''>선택하세요</option>
                        <option v-for="item in selectEngineerList" :value="item?.id" :key="item?.id">{{ item?.name }}</option>
                      </select>
                      <div class="invalid-feedback" v-if="error.engineerId">{{error.engineerId}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2">우편번호</div>
                    <div class="col-9 d-flex">
                      <input class="form-control btn-square me-2" style="width:40%;" type="text" v-model="postalCode" readOnly/>
                      <PostCode v-if="!isRead" @address-sought="addressSought" />
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2 validation-name">주소</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" placeholder="주소 검색 버튼을 눌러주세요" v-model="customerAddr1" v-bind:class="formSubmitted ? customerAddr1Error ? 'is-invalid' : 'is-valid' : ''" readOnly/>
                      <div class="invalid-feedback" v-if="error.customerAddr1">{{error.customerAddr1}}</div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-2">참고항목</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" v-model="extraAddr" readOnly/>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2">상세주소</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" v-model="customerAddr2" :readOnly="isRead"/>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-2">진행상황</div>
                    <div class="col-9">
                      <select class="form-select btn-square digits col-5" v-model="statCode">
                        <option :value='null'>선택하세요</option>
                        <option v-for="item in progressListRef" :value="item?.code" :key="item?.code">{{ item?.name }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2">차종</div>
                    <div class="col-9">
                      <div class="col-12 d-flex">
                        <div class="col-5 pe-2">
                          <select class="form-select btn-square digits col-5" v-model="vhcCatCode">
                            <option value=''>선택하세요</option>
                            <option v-for="item in vhcCatCodeListRef" :value="item?.code" :key="item?.code">{{ item?.name }}</option>
                          </select>
                        </div>
                        <div class="col-7">
                          <input class="form-control btn-square" type="text" placeholder="차량명" v-model="vhcTypeName" :readOnly="isRead">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-2">처리결과</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" v-model="claimInfo.result"/>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3 mt-4">
                  <div class="d-flex align-items-center col-12 col-xxl-9 me-4">
                    <div class="col-1 validation-name">접수형태</div>
                    <div class="col-12 d-flex form-group m-checkbox-inline mb-0 flex-wrap">
                      <div v-for="(item, idx) in claimTypeRef" v-bind:class="formSubmitted ? inboundCodeError ? 'is-invalid' : 'is-valid' : ''">
                        <label class="d-block" :for="'inbound'+idx">
                          <input class="checkbox_animated" :id="'inbound'+idx" :value="item.code" v-model="claimInfo.inboundCode" type="radio" :readOnly="isRead">{{ item.name }}
                        </label>
                      </div>
                      <div class="invalid-feedback" style="color:#d72e3c;" v-if="error.inboundCode">{{error.inboundCode}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3 mt-4">
                  <div class="d-flex align-items-center col-12 col-xxl-9 me-4">
                    <div class="col-1 validation-name">분류</div>
                    <div class="col-12 d-flex form-group m-checkbox-inline mb-0 flex-wrap">
                      <div v-bind:class="formSubmitted ? typeCodeError ? 'is-invalid' : 'is-valid' : ''" v-for="(item, idx) in claimTypeCodeRef">
                        <label class="d-block" :for="'type'+idx">
                          <input class="checkbox_animated" :id="'type'+idx" :value="item.code" v-model="claimInfo.typeCode" type="radio" :readOnly="isRead">{{ item.name }}
                        </label>
                      </div>
                      <div class="invalid-feedback" style="color:#d72e3c;" v-if="error.typeCode">{{error.typeCode}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2">본사 메모<br/></div>
                    <div class="col-9">
                      <div class="form-control btn-square" @click="focusMemo" style="height: 100px;overflow-y:auto;" :contenteditable="!isRead">
                        <div contenteditable="false"><div v-html="loadMemo"></div>
                          <div id="addClaimMemo" :contenteditable="!isRead" @input="handleMemo"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3 mt-4">
                  <div class="d-flex align-items-center col-7 col-xxl-5 me-4">
                    <div class="col-2">불량 사진 업로드</div>
                    <div v-if="claimInfo?.pic1 && !isModifyPic" class="col-12 d-flex">
                      <div class="col-9 d-flex picture-container me-2">
                        <div class="col-4 p-3 square-container">
                          <asyncPicComp :picUri="claimInfo?.pic1"/>
                        </div>
                        <div v-if="claimInfo?.pic2" class="col-4 p-3 square-container">
                          <asyncPicComp :picUri="claimInfo?.pic2"/>
                        </div>
                        <div v-if="claimInfo?.pic3" class="col-4 p-3 square-container">
                          <asyncPicComp :picUri="claimInfo?.pic3"/>
                        </div>
                        <div v-if="claimInfo?.pic4" class="col-4 p-3 square-container">
                          <asyncPicComp :picUri="claimInfo?.pic4"/>
                        </div>
                        <div v-if="claimInfo?.pic5" class="col-4 p-3 square-container">
                          <asyncPicComp :picUri="claimInfo?.pic5"/>
                        </div>
                        <div v-if="claimInfo?.pic6" class="col-4 p-3 square-container">
                          <asyncPicComp :picUri="claimInfo?.pic6"/>
                        </div>
                      </div>
                      <div v-if="!isRead">
                        <input type="file" ref="fileInput" @change="limitFiles" multiple hidden accept="image/gif, image/jpeg, image/png"/>
                        <button v-if="!pic.length" class="btn btn-primary" type="button" @click="openFileInput">찾아보기</button>
                        <button v-else class="btn btn-danger" type="button" @click="pic.splice(0)">모두 삭제</button>
                      </div>
                    </div>
                    <div v-else class="col-12 d-flex">
                      <div v-if="pic.length" class="col-9 d-flex picture-container me-2">
                        <div v-for="(a, i) in pic" class="col-4 p-3 square-container">
                          <div v-if="pic[i]" :key="i" class="col-12 square-container">
                            <div @click="openPicture(pic[i])" class="d-flex justify-content-center align-items-center search-icon">
                              <i class="fa fa-search" style="font-size:15px;"></i>
                            </div>
                            <img class="square-image"  :src="pic[i] ? pic[i] : ''" />
                          </div>
                        </div>
                      </div>
                      <div v-if="!isRead">
                        <input type="file" ref="fileInput" @change="limitFiles" multiple hidden accept="image/gif, image/jpeg, image/png"/>
                        <button v-if="!pic.length" class="btn btn-primary" type="button" @click="openFileInput">찾아보기</button>
                        <button v-else class="btn btn-danger" type="button" @click="pic.splice(0)">모두 삭제</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="error.message" class="error">{{ error.message }}</div>
                <div v-if="!isRead" class="col-12 col-xxl-10 d-flex justify-content-center">
                  <button class="btn btn-outline-light" type="submit">수정</button>
                  <button class="btn btn-outline-light ms-2" type="button" @click="deleteOrd">삭제</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Swal from 'sweetalert2';
import ModifyHistModal from '@/components/list/ModifyHistModal.vue'
import useOrderRegistry from '@/composables/registerOrder';
import commonApi from '@/composables/commonApi'
import PostCode from '@/components/PostCode.vue'
import { ref, reactive, onMounted, watch, defineAsyncComponent } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import { useLoginAuth } from '@/stores/LoginAuthStore';
const asyncPicComp = defineAsyncComponent(() => import('../../components/PicComponent.vue'))
const router = useRouter();
const route = useRoute()
const id = route.params.id
const isRead = route.query.state === 'read'
const LoginAuthStore = useLoginAuth(router)
const { selectCompList, selectProdType, selectProductList, selectBranchList, selectEngineerList,
loadSelectCompList, loadSelectProdTypeList, loadSelectProductList, loadSelectBranchList, loadSelectEngineerList,
providerId, prodTypeId, productId, branchId, engineerId, statCode, vhcCatCode, vhcTypeName, error,
typeCode, customerName, postalCode, customerAddr1, customerAddr2, extraAddr, addrType, primaryPhoneNo, secondaryPhoneNo, memo, checkedDetailArray, claimInfo, loadMemo, assigneeName, saveClaim, loadClaim,
loadProductId, loadProdTypeId, loadProviderId, loadProduct, loadEngineerId, uploadFiles, deleteOrder
} = useOrderRegistry(LoginAuthStore, id)
const { loadCommonCode } = commonApi(LoginAuthStore);
const workTypeListRef = ref([])
const progressListRef = ref([])
const vhcCatCodeListRef = ref([])
const inChargePersonRef = ref({});
const claimTypeRef = ref([]);
const claimTypeCodeRef = ref([]);
onMounted(async () => {
  preventBack();
  loadClaim();
  console.log(claimInfo, "claimInfo")
  loadSelectCompList();
  loadSelectBranchList();
  assigneeName.value = LoginAuthStore.currentUser.name;
  // 주문유형 ([0] 00010: 주문, [1] 00011: 클레임, [2] 00012: AS)
  loadCommonCode('00001').then(res => res.json()).then(res => typeCode.value = res.payload[1].code);
    // 진행상황
  loadCommonCode('00004').then(res => res.json()).then(res => progressListRef.value = res.payload);
  // 차종
  loadCommonCode('00005').then(res => res.json()).then(res => vhcCatCodeListRef.value = res.payload);
  // 클레임 접수형태
  loadCommonCode('00006').then(res => res.json()).then(res => claimTypeRef.value = res.payload);
  // 클레임 분류코드
  loadCommonCode('00007').then(res => res.json()).then(res => claimTypeCodeRef.value = res.payload);

})

const handlePrimaryPhoneNo = (e) => {
  if (e.target.value.replace(/[^0-9]/g, '').length === 12) {
    primaryPhoneNo.value = e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3`);
  } else {
    primaryPhoneNo.value = e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }
}
const handleSecondaryPhoneNo = (e) => {
  if (e.target.value.replace(/[^0-9]/g, '').length === 12) {
    secondaryPhoneNo.value = e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3`);
  } else {
    secondaryPhoneNo.value = e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }
}

const formSubmitted = ref(false);
const providerIdError = ref(false);
const prodTypeIdError = ref(false);
const productIdError = ref(false);
const customerNameError = ref(false);
const primaryPhoneNoError = ref(false);
const customerAddr1Error = ref(false);
const branchIdError = ref(false);
const engineerIdError = ref(false);
const inboundCodeError = ref(false);
const typeCodeError = ref(false);
const fileInput = ref(null);

const pic = reactive([])
const isModifyPic = ref(false);

const addressSought = async (address) => {
  postalCode.value = address.postalCd;
  customerAddr1.value = address.addr;
  extraAddr.value = address.extraAddr;
  addrType.value = address.type === 'R' ? 'ROAD' : 'PARCEL'
}

const openFileInput = () => {
  fileInput.value.click();
}
const limitFiles = () => {
  console.dir(fileInput.value)
  const maxFiles = 6;
  if (fileInput.value.files.length > maxFiles) {
    alert(`최대 ${maxFiles}개의 사진만 업로드할 수 있습니다.`)
    return fileInput.value.value = '';
  } else {
    pic.splice(0);
    uploadFiles.value = [];
    for (let i = 0; i < fileInput.value.files.length; i++) {
      const file = fileInput.value.files[i]
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          pic.push(e.target.result);
        }
        reader.readAsDataURL(file);
      }      
    }
    uploadFiles.value = fileInput.value.files;
  }
}

const handleMemo = (e) => {
  memo.value = e.target.textContent;
}
const focusMemo = () => {
  const memoRef = document.getElementById("addClaimMemo")
  memoRef.focus();
}

watch(providerId, async (newVal, oldVal) => {
  if (newVal) {
    prodTypeId.value = '';
    productId.value = '';
    await loadSelectProdTypeList();
    if (loadProdTypeId.value) prodTypeId.value = await loadProdTypeId.value;
  }
  else if (oldVal && !newVal) {
    prodTypeId.value = '';
    productId.value = '';
    selectProdType.value = [];
    selectProductList.value = [];
  }
})

watch(prodTypeId, async (newVal, oldVal) => {
  if (newVal) {
    productId.value = '';
    await loadSelectProductList();
    if (loadProductId.value) productId.value = await loadProductId.value;
  }
  else if (oldVal && !newVal) {
    productId.value = '';
    selectProductList.value = [];
  }
})
watch(loadProductId, async (newVal, oldVal) => {
  if (newVal) {
    await loadProduct(productId.value);
  }
  else if (oldVal && !newVal) {
    productId.value = '';
    selectProductList.value = [];
  }
})
watch(loadProviderId, async (newVal, oldVal) => {
  if (newVal) {
    providerId.value = await loadProviderId.value
  }
})
watch(loadProdTypeId, async (newVal, oldVal) => {
  if (newVal) {
    prodTypeId.value = await loadProdTypeId.value;
  }
})

watch(branchId, async (newVal, oldVal) => {
  if (newVal) {
    engineerId.value = '';
    loadSelectEngineerList();
    if (loadEngineerId.value) engineerId.value = await loadEngineerId.value;
  }
  else if (oldVal && !newVal) {
    engineerId.value = '';
    selectEngineerList.value = [];
  }
})

watch(pic, (newVal, oldVal) => {
  if (newVal.length === 0) isModifyPic.value = false;
  else isModifyPic.value = true;
})

const updateClaim = async () => {
  await saveClaim();
  const memoRef = document.getElementById("addClaimMemo")
  memoRef.textContent = '';
}

const validationSubmit = async () => {
  formSubmitted.value = true;

  if (providerId.value.length < 1) providerIdError.value = true;
  else providerIdError.value = false;

  if (prodTypeId.value.length < 1) prodTypeIdError.value = true;
  else prodTypeIdError.value = false;

  if (productId.value.length < 1) productIdError.value = true;
  else productIdError.value = false;

  if (customerName.value.length < 1) customerNameError.value = true;
  else customerNameError.value = false;

  if (primaryPhoneNo.value.length < 1) primaryPhoneNoError.value = true;
  else primaryPhoneNoError.value = false;

  if (customerAddr1.value.length < 1) customerAddr1Error.value = true;
  else customerAddr1Error.value = false;

  if (branchId.value.length < 1) branchIdError.value = true;
  else branchIdError.value = false;

  if (engineerId.value === null) engineerIdError.value = true;
  else engineerIdError.value = false;

  if (claimInfo.value.inboundCode.length < 1) inboundCodeError.value = true;
  else inboundCodeError.value = false;
  
  if (claimInfo.value.typeCode.length < 1) typeCodeError.value = true;
  else typeCodeError.value = false;

  await updateClaim();
}
const deleteOrd = async () => {
  if (confirm("클레임을 삭제하시겠습니까?")) await deleteOrder(id);
  else return;
  await router.push({name: route.query.name})
}

const openPicture = (url) => {
  new Swal({
    imageUrl: url,
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    },
    showConfirmButton: false, // "확인" 버튼 표시 안 함
    showCancelButton: true,
  });
}

const preventBack = () => {
  window.history.pushState(null, '', location.href)
  window.onpopstate = () => {
    history.go(1);
    router.push({name: route.query.name, query: route.query})
  }
}
</script>