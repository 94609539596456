import { ref } from 'vue'
import { trim, replacePath } from '@/util/Util'
import apiURL from '@/bis/data/settleManagementApi'

const settleManagement = (LoginAuthStore) => {
  const headersMap = {
    'Content-Type': 'application/json',
    'X-AUTH-TOKEN': LoginAuthStore.accessToken
  }

  const settleListRef = ref([]);
  const pageInfoRef = ref({});
  const errorRef = ref({});
  const incompleteCountRef = ref(0);
  const searchedQuery = ref();

  const listSettle = async (queryObj) => {
    searchedQuery.value = queryObj;
    let query = `?rrp=${queryObj.rrp}&pno=${queryObj.pno}&useNav=true`
    const startDateTime = !queryObj.startDateTime ? '' : '&startDateTime=' +queryObj.startDateTime
    const endDateTime = !queryObj.endDateTime ? '' : '&endDateTime=' + queryObj.endDateTime
    const filter1 = !queryObj.filter1 ? '' : '&filter1=' + trim(queryObj.filter1)
    const filter2 = !queryObj.filter2 ? '' : '&filter2=' + trim(queryObj.filter2)
    const saFilter = !queryObj.saFilter ? '' : '&saFilter=' + trim(queryObj.saFilter)
    const settleYn = !queryObj.settleYn ? '' : '&settleYn=' + trim(queryObj.settleYn)
    const paymentType = !queryObj.paymentType ? '' : '&paymentType=' + trim(queryObj.paymentType)
    query = query + startDateTime + endDateTime + filter1 + filter2 + saFilter + settleYn + paymentType
    console.log(query)
    fetch(`${apiURL.baseUri}${apiURL.listSettle.api}${query}`,
    {
      method: apiURL.listSettle.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
      console.log(res)
      if(res.success) {
        settleListRef.value = !res.payload ? []: res.payload;
        pageInfoRef.value = res?.pageInfo;
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => errorRef.value.message = err.message)
  }
  const listSettleForCardTx = async (queryObj) => {
    searchedQuery.value = queryObj;
    let query = `?rrp=${queryObj.rrp}&pno=${queryObj.pno}&useNav=true`
    const startDateTime = !queryObj.startDateTime ? '' : '&startDateTime=' +queryObj.startDateTime
    const endDateTime = !queryObj.endDateTime ? '' : '&endDateTime=' + queryObj.endDateTime
    const filter1 = !queryObj.filter1 ? '' : '&filter1=' + trim(queryObj.filter1)
    const filter2 = !queryObj.filter2 ? '' : '&filter2=' + trim(queryObj.filter2)
    // const saFilter = !queryObj.saFilter ? '' : '&saFilter=' + trim(queryObj.saFilter)
    const settleYn = !queryObj.settleYn ? '' : '&settleYn=' + trim(queryObj.settleYn)
    const paymentType = !queryObj.paymentType ? '' : '&paymentType=' + trim(queryObj.paymentType)
    const statCode = !queryObj.statCode ? '' : '&statCode=' + trim(queryObj.statCode)
    query = query + startDateTime + endDateTime + filter1 + filter2 + settleYn + paymentType + statCode
    console.log(query)
    fetch(`${apiURL.baseUri}${apiURL.listSettleForCardTx.api}${query}`,
    {
      method: apiURL.listSettleForCardTx.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
      console.log(res)
      if(res.success) {
        settleListRef.value = !res.payload ? []: res.payload;
        pageInfoRef.value = res?.pageInfo;
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => errorRef.value.message = err.message)
  }

  const completeSettleList = async (settleIdList) => {
    fetch(`${apiURL.baseUri}${apiURL.completeSettle.api}`, {
      method: apiURL.completeSettle.method,
      headers: headersMap,
      body: JSON.stringify(settleIdList)
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
        errorRef.value.message = err.message
    })
  }
  const completeSettleAllList = async () => {
    const requestBody = {
      startDateTime: searchedQuery.value.startDateTime ? searchedQuery.value.startDateTime : null,
      endDateTime: searchedQuery.value.endDateTime ? searchedQuery.value.endDateTime : null,
      paymentType: searchedQuery.value.paymentType,
      settleYn: searchedQuery.value.settleYn,
      filter1: searchedQuery.value.filter1 ? trim(searchedQuery.value.filter1) : '',
      filter2: searchedQuery.value.filter2 ? trim(searchedQuery.value.filter2) : '',
      saFilter: searchedQuery.value.saFilter,
    }
    console.log(requestBody, "all requestbody")
    fetch(`${apiURL.baseUri}${apiURL.completeSettleAll.api}`, {
      method: apiURL.completeSettleAll.method,
      headers: headersMap,
      body: JSON.stringify(requestBody)
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
        errorRef.value.message = err.message
    })
  }
  
  const incompleteSettleCount = async (queryObj, type) => {
    let query = `?saFilter=${type}`
    const startDateTime = !queryObj.startDateTime ? '' : '&startDateTime=' + queryObj.startDateTime
    const endDateTime = !queryObj.endDateTime ? '' : '&endDateTime=' + queryObj.endDateTime
    const filter1 = !queryObj.filter1 ? '' : '&filter1=' + trim(queryObj.filter1)
    const filter2 = !queryObj.filter2 ? '' : '&filter2=' + trim(queryObj.filter2)
    // const saFilter = !queryObj.saFilter ? '' : '&saFilter=' + trim(queryObj.saFilter)
    // const settleYn = !queryObj.settleYn ? '' : '&settleYn=' + trim(queryObj.settleYn)
    query = query + startDateTime + endDateTime + filter1 + filter2
    console.log(query)
    fetch(`${apiURL.baseUri}${apiURL.incompleteSettleCount.api}${query}`,
    {
      method: apiURL.incompleteSettleCount.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
      console.log(res)
      if(res.success) {
        incompleteCountRef.value = !res.payload ? 0 : res.payload;
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => errorRef.value.message = err.message)
  }

  const settleExcelDownload = async (queryObj, isExcelDown) => {
    let query = `?rrp=${queryObj.rrp}&pno=${queryObj.pno}&useNav=true`
    const startDateTime = !queryObj.startDateTime ? '' : '&startDateTime=' +queryObj.startDateTime
    const endDateTime = !queryObj.endDateTime ? '' : '&endDateTime=' + queryObj.endDateTime
    const filter1 = !queryObj.filter1 ? '' : '&filter1=' + trim(queryObj.filter1)
    const filter2 = !queryObj.filter2 ? '' : '&filter2=' + trim(queryObj.filter2)
    const saFilter = !queryObj.saFilter ? '' : '&saFilter=' + trim(queryObj.saFilter)
    const settleYn = !queryObj.settleYn ? '' : '&settleYn=' + trim(queryObj.settleYn)
    const paymentType = !queryObj.paymentType ? '' : '&paymentType=' + trim(queryObj.paymentType)
    const statCode = !queryObj.statCode ? '' : '&statCode=' + trim(queryObj.statCode)
    query = query + startDateTime + endDateTime + filter1 + filter2 + saFilter + settleYn + paymentType + statCode
    console.log(query)   
    await fetch(`${apiURL.baseUri}${apiURL.settleExcelDownload.api}${query}`,
    {
      method: apiURL.settleExcelDownload.method,
      headers: headersMap,
    }).then(async res => {
      if (res.status === 200) {
        const blob = await res.blob();
  
        const newBlob = new Blob([blob]);
        const newUrl = window.URL.createObjectURL(newBlob);
  
        const link = document.createElement('a');
        link.href = newUrl;
        link.setAttribute('download', 'settleList.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
  
        window.URL.revokeObjectURL(newBlob);
        isExcelDown.value = false;
      } else {
        console.log(res, "excel false RES");
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    })
    .catch(err => {
      console.error(err);
      errorRef.value.message = err.message
    })
  }
  const ccardtxinvoiceExcelDownload = async (queryObj, isExcelDown) => {
    let query = `?rrp=${queryObj.rrp}&pno=${queryObj.pno}&useNav=true`
    const startDateTime = !queryObj.startDateTime ? '' : '&startDateTime=' +queryObj.startDateTime
    const endDateTime = !queryObj.endDateTime ? '' : '&endDateTime=' + queryObj.endDateTime
    const filter1 = !queryObj.filter1 ? '' : '&filter1=' + trim(queryObj.filter1)
    const filter2 = !queryObj.filter2 ? '' : '&filter2=' + trim(queryObj.filter2)
    const saFilter = !queryObj.saFilter ? '' : '&saFilter=' + trim(queryObj.saFilter)
    const settleYn = !queryObj.settleYn ? '' : '&settleYn=' + trim(queryObj.settleYn)
    const paymentType = !queryObj.paymentType ? '' : '&paymentType=' + trim(queryObj.paymentType)
    const statCode = !queryObj.statCode ? '' : '&statCode=' + trim(queryObj.statCode)
    query = query + startDateTime + endDateTime + filter1 + filter2 + saFilter + settleYn + paymentType + statCode
    console.log(query)   
    await fetch(`${apiURL.baseUri}${apiURL.ccardtxinvoiceExcelDownload.api}${query}`,
    {
      method: apiURL.ccardtxinvoiceExcelDownload.method,
      headers: headersMap,
    }).then(async res => {
      if (res.status === 200) {
        const blob = await res.blob();
  
        const newBlob = new Blob([blob]);
        const newUrl = window.URL.createObjectURL(newBlob);
  
        const link = document.createElement('a');
        link.href = newUrl;
        link.setAttribute('download', 'ccardtxinvoiceList.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
  
        window.URL.revokeObjectURL(newBlob);
        isExcelDown.value = false;
      } else {
        console.log(res, "excel false RES");
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    })
    .catch(err => {
      console.error(err);
      errorRef.value.message = err.message
    })
  }

  const settleExcelDownloadSelected = async (idList, isExcelDown) => {
    let query = '';
    idList.forEach((n, i) => {
      if (i === 0) query = `?orderIdList=${n}`
      else query += `&orderIdList=${n}`
    })
    fetch(`${apiURL.baseUri}${apiURL.settleExcelDownloadSelected.api}${query}`,
    {
      method: apiURL.settleExcelDownloadSelected.method,
      headers: headersMap
    }).then(async res => {
      if (res.status === 200) {
        const blob = await res.blob();
  
        const newBlob = new Blob([blob]);
        const newUrl = window.URL.createObjectURL(newBlob);
  
        const link = document.createElement('a');
        link.href = newUrl;
        link.setAttribute('download', 'settleList.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
  
        window.URL.revokeObjectURL(newBlob);
        isExcelDown.value = false;
      } else {
        console.log(res, "excel false RES");
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    })
    .catch(err => {
      console.error(err);
      errorRef.value.message = err.message
    })
  }
  const ccardtxinvoiceExcelDownloadSelected = async (idList, isExcelDown) => {
    let query = '';
    idList.forEach((n, i) => {
      if (i === 0) query = `?orderIdList=${n}`
      else query += `&orderIdList=${n}`
    })
    fetch(`${apiURL.baseUri}${apiURL.ccardtxinvoiceExcelDownloadSelected.api}${query}`,
    {
      method: apiURL.ccardtxinvoiceExcelDownloadSelected.method,
      headers: headersMap
    }).then(async res => {
      if (res.status === 200) {
        const blob = await res.blob();
  
        const newBlob = new Blob([blob]);
        const newUrl = window.URL.createObjectURL(newBlob);
  
        const link = document.createElement('a');
        link.href = newUrl;
        link.setAttribute('download', 'ccardtxinvoiceList.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
  
        window.URL.revokeObjectURL(newBlob);
        isExcelDown.value = false;
      } else {
        console.log(res, "excel false RES");
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    })
    .catch(err => {
      console.error(err);
      errorRef.value.message = err.message
    })
  }

  const updatePayment = async (orderId, paymentInfo) => {
    console.log(paymentInfo, "all requestbody")
    fetch(`${apiURL.baseUri}${replacePath(apiURL.updatePayment.api, orderId)}`, {
      method: apiURL.updatePayment.method,
      headers: headersMap,
      body: JSON.stringify(paymentInfo)
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
        errorRef.value.message = err.message
    })
  }

  return {
    listSettle, completeSettleList, incompleteSettleCount, settleExcelDownload, completeSettleAllList, listSettleForCardTx, updatePayment,
    settleList: settleListRef, pageInfo: pageInfoRef, incompleteCount: incompleteCountRef, settleExcelDownloadSelected, ccardtxinvoiceExcelDownload, ccardtxinvoiceExcelDownloadSelected
  }
}
export default settleManagement