import {ref} from 'vue'
import {trim, replacePath} from '@/util/Util'
import apiURL from '@/bis/data/registerEngineerApi'
const useEngineerRegistry = (LoginAuthStore, id) => {
    const branchCodeListRef = ref([])
    const branchRef = ref('') //지점 
    const userIdRef = ref('')
    const passwdRef = ref('')
    const nameRef = ref('')
    const phoneNoRef = ref('')
    const ssnRef = ref(null) //주민등록번호 
    const accBankRef = ref('') //은행명
    const accHolderRef = ref('')//예금주 
    const accNoRef = ref('')//계좌번호
    const emailRef = ref(null)
    const postalCdRef = ref('') //우편번호
    const addr1Ref = ref('')
    const addr2Ref = ref('')
    const remarkRef = ref('') //참고항목
    const memoRef = ref('')
    const activeYnRef = ref('Y') //재직 퇴사 여부 
    const errorRef = ref({})
    const engineerListRef = ref([])
    const pageInfoRef = ref([])
    const certPicUriRef = ref('')
    const uploadPictureRef = ref('')
    const headersMap = {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': LoginAuthStore.accessToken
    }
    const clearAllFields = () => {
        branchRef.value = ''
        userIdRef.value = ''
        passwdRef.value = ''
        nameRef.value = ''
        ssnRef.value = ''
        phoneNoRef.value = ''
        accBankRef.value = ''
        accHolderRef.value = ''
        accNoRef.value = ''
        emailRef.value = ''
        postalCdRef.value = ''
        addr1Ref.value = ''
        addr2Ref.value = ''
        remarkRef.value = ''
        memoRef.value = ''
        activeYnRef.value = 'Y',
        certPicUriRef.value = '',
        uploadPictureRef.value = ''
    }
    const loadBranchCodeList = async (isPaging, queryObj) => {
        const query = isPaging ? `?pno=${queryObj.value.pno}&filter=${queryObj.value.filter}&useNav=true` : '';
        fetch(`${apiURL.baseUri}${apiURL.branchCodeList.api}${query}`,
        {
            method: apiURL.branchCodeList.method,
            headers: headersMap
        }).then(res => res.json())
        .then(res => {
            if(res.success) {
                const payload = res.payload
                branchCodeListRef.value = !payload ? [] : payload;
                pageInfoRef.value = !res.pageInfo ? []: res.pageInfo;
            } else {
                errorRef.value.message = res.error.msg
                LoginAuthStore.redirectInvalidSession(res)
            }
        })
    }
    const loadEngineer = async () => {
        const url = replacePath(apiURL.loadEngineer.api, id)
        fetch(`${apiURL.baseUri}${url}`,
        {
            method: apiURL.loadEngineer.method,
            headers: headersMap
        }).then(res => res.json())
        .then(res => {
            if(res.success) {
                console.log(res)
                const payload = res.payload;
                branchRef.value = payload.branchId
                userIdRef.value = payload.loginId
                passwdRef.value = ''
                nameRef.value = payload.name
                ssnRef.value = payload.ssn
                phoneNoRef.value = payload.phoneNo
                accBankRef.value = payload.accountBank
                accHolderRef.value = payload.accountHolder
                accNoRef.value = payload.accountNo
                emailRef.value = payload.email
                postalCdRef.value = payload.postalCode
                addr1Ref.value = payload.addr1
                addr2Ref.value = payload.addr2
                activeYnRef.value = payload.activeYn
                remarkRef.value = payload.remark
                memoRef.value = payload.memo
                certPicUriRef.value = payload.certPicUri
            } else {
                errorRef.value.message = res.error.msg
                LoginAuthStore.redirectInvalidSession(res)
            }
        }).catch(err => {
            errorRef.value.message = err.message
        })
    }
    const listEngineer = async(queryObj) => {
        let query = `?rrp=${queryObj.rrp}&pno=${queryObj.pno}`
        const filter = !queryObj.filter ? '': '&filter=' + trim(queryObj.filter)
        query = query + filter
        console.log(query)
        fetch(`${apiURL.baseUri}${apiURL.listEngineer.api}${query}`,
        {
            method: apiURL.listEngineer.method,
            headers: headersMap
        }).then(res => res.json())
        .then(res => {
            console.log(res)
            if(res.success) {
                engineerListRef.value = !res.payload ? []: res.payload
                pageInfoRef.value = !res.pageInfo ? []: res.pageInfo;
            } else {
                errorRef.value.message = res.error.msg
                LoginAuthStore.redirectInvalidSession(res)
            }
        }).catch(err => errorRef.value.message = err.message)
    } 

    const saveEngineer = async () => {
        errorRef.value = {}
        const branch = branchRef.value
        const userId = trim(userIdRef.value)
        const password = trim(passwdRef.value)
        const name = trim(nameRef.value)
        const ssn = ssnRef.value ? trim(ssnRef.value) : null;
        const phoneNo = trim(phoneNoRef.value)
        const accBank = trim(accBankRef.value)
        const accHolder = trim(accHolderRef.value)
        const accNo = trim(accNoRef.value)
        const email = emailRef.value ? trim(emailRef.value) : null
        const postalCd = trim(postalCdRef.value)
        const addr1 = trim(addr1Ref.value)
        const addr2 = trim(addr2Ref.value)
        const remark = remarkRef.value
        const activeYn = activeYnRef.value
        const memo = trim(memoRef.value)
        const uploadPicture = uploadPictureRef.value || certPicUriRef.value
        const createMode = !id
        if(!branch) errorRef.value.branch = '지사를 선택하세요'
        if(!userId) errorRef.value.userId = '기사 아이디를 입력하세요'
        if(createMode && !password) errorRef.value.password = '기사 패스워드를 입력하세요'
        if(!name) errorRef.value.name = '기사 이름을 입력하세요'
        if(phoneNo.length < 9 || phoneNo.length > 13) errorRef.value.phoneNo = '기사 휴대폰번호를 입력하세요'
        // if(!ssn) errorRef.value.ssn = '주민등록번호를 입력하세요'
        // if(!accBank) errorRef.value.accBank = '은행명을 입력하세요'
        // if(!accHolder) errorRef.value.accHolder = '예금주를 입력하세요'
        // if(!accNo) errorRef.value.accNo = '계좌번호를 입력하세요'
        // if(!postalCd) errorRef.value.postalCd = '우편번호를 입력하세요'
        // if(!addr1) errorRef.value.addr1 = '주소를 입력하세요'
        // if(!addr2) errorRef.value.addr2 = '상세주소를 입력하세요'
        // if(!uploadPicture) errorRef.value.certPic = '자격증 사진을 등록해주세요'
        const error = errorRef.value;
        if(
            error.branch || error.userId || error.password || error.name || error.phoneNo 
            // || error.ssn || error.accBank || error.accHolder || error.accNo 
            // || error.postalCd || error.addr1 || error.addr2 || error.certPic
        ) {
            console.log('errorRef', error)
            return
        }
        let apiObj = createMode ? 
        apiURL.addEngineer : {api: replacePath(apiURL.updateEngineer.api, id), method: apiURL.updateEngineer.method}
        console.log('apiObject', apiObj)
        const requestBody = {
            branchId: branch,
            userId: userId,
            password,
            name,
            ssn,
            phoneNo,
            accountBank: accBank,
            accountNo: accNo,
            accountHolder: accHolder,
            email,
            postalCode: postalCd,
            addr1, addr2,
            remark,
            activeYn,
            memo
        }
        fetch(                
            `${apiURL.baseUri}${apiObj.api}`,
            {
                method: apiObj.method,
                headers: headersMap,
                body: JSON.stringify(requestBody)
            }
        ).then(res => res.json())
        .then(async res => {
            const uploadPicture = uploadPictureRef.value
            if(res.success) {
                console.log(res)
                if (uploadPicture) await uploadCertPic(res.payload.id);
                clearAllFields()
                alert(createMode? '기사 계정을 생성하였습니다': '기사정보를 수정하였습니다')
                LoginAuthStore.getRouter.push({name:'engineerList'})
            } else {
                errorRef.value.message = res.error.msg
                LoginAuthStore.redirectInvalidSession(res)
            }
        }).catch(err => {
            errorRef.value.message = err.message
        })
    }
    const deleteEngineer = async() => {
        const url = replacePath(apiURL.deleteEngineer.api, id)
        fetch(`${apiURL.baseUri}${url}`, {
            method: apiURL.deleteEngineer.method,
            headers: headersMap
        }).then(res => res.json())
        .then(res => {
            if(res.success) {
                clearAllFields()
                console.log(res)
                alert('계정을 삭제하였습니다')
            } else {
                errorRef.value.message = res.error.msg
                LoginAuthStore.redirectInvalidSession(res)
            }
        }).catch(err => {
            errorRef.value.message = err.message
        })
    }

    const deleteEngineerList = async(engineerIdList) => {
        fetch(`${apiURL.baseUri}${apiURL.deleteEngineerList.api}`, {
            method: apiURL.deleteEngineerList.method,
            headers: headersMap,
            body: JSON.stringify(engineerIdList)
        }).then(res => res.json())
        .then(res => {
            if(res.success) {
                clearAllFields()
            } else {
                errorRef.value.message = res.error.msg
                LoginAuthStore.redirectInvalidSession(res)
            }
        }).catch(err => {
            errorRef.value.message = err.message
        })
    }

    const applyAddress = (addrInfo) => {
        postalCdRef.value = addrInfo.postalCd
        addr1Ref.value = addrInfo.addr
        if(addrInfo.type === 'R') addr1Ref.value = addrInfo.addr + ' ' + addrInfo.extraAddr
        console.log(addrInfo)
    } 

    const uploadCertPic = async (id) => {
        const uploadPic = uploadPictureRef.value;
        console.log(id, uploadPic, "uploadPic")
        const formData = new FormData();
        formData.append("file", uploadPic);
        const headers = {
            'X-AUTH-TOKEN': LoginAuthStore.accessToken,
        }
        await fetch(`${apiURL.baseUri}${replacePath(apiURL.uploadCertPicture.api, id)}`,
        {
            method: apiURL.uploadCertPicture.method,
            headers,
            body: formData
        }).then(res => res.json())
        .then(res => {
        if(res.success) {
            console.log(res, "payload upload");
            const payload = res.payload;
            if(!payload) payload = [];
        } else {
            errorRef.value.message = res.error.msg
            LoginAuthStore.redirectInvalidSession(res)
        }
        }).catch(err => {
            console.error(err);
            errorRef.value.message = err.message
        })
    }

    return {
        pageInfo: pageInfoRef, 
        branchCodeList: branchCodeListRef, branch: branchRef, userId: userIdRef, password: passwdRef, name: nameRef, ssn: ssnRef, phoneNo:phoneNoRef,
        accBank: accBankRef, accHolder: accHolderRef, accNo:accNoRef, email: emailRef, postalCd: postalCdRef,
        addr1: addr1Ref, addr2: addr2Ref, remark: remarkRef, memo: memoRef, activeYn: activeYnRef,
        engineerList: engineerListRef, error: errorRef,
        saveEngineer, deleteEngineer, deleteEngineerList, loadEngineer, listEngineer,applyAddress,loadBranchCodeList, uploadCertPic, uploadPicture: uploadPictureRef,
        certPicUri: certPicUriRef,
        
    }
}

export default useEngineerRegistry