<template>
  <div>
    <Breadcrumbs title="A/S 엑셀 업로드" main="A/S" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h5>A/S 엑셀 업로드</h5>
            </div>
            <div class="card-body">
              <span>업로드할 엑셀파일을 가운데 영역에 드래그 하세요.</span>
              <div class="my-4">
                <div v-if="excelFile.lastModified" class="dropzone dropzone-info w-100">
                  <div class="dz-message needsclick"><i class="icon-cloud-up"></i>
                    <div>
                      <div>
                        <span v-if="excelFile.name">{{ excelFile.name }}</span>
                      </div>
                      <div>
                        <span v-if="excelFile.size">{{ fnGetFileSize(excelFile.size) }}</span>
                      </div>
                      <div v-if="excelFile.lastModified" class="mt-1">
                        <button @click="excelUpload('as')" class="btn btn-success me-2" type="button">업로드</button>
                        <button @click="calcelUploadFile" class="btn btn-danger" type="button">취소</button>
                      </div>
                    </div>
                  </div>
                </div>
                <label v-else @dragover.prevent @drop.prevent="onDrop" for="excelUpload" class="dropzone dropzone-info w-100" style="cursor:pointer;">
                  <div class="dz-message needsclick"><i class="icon-cloud-up"></i>
                    <div>
                      <div>
                        <span>Drop files here to upload</span>
                      </div>
                    </div>
                  </div>
                </label>
                <input @change="getUploadFile" type="file" id="excelUpload" hidden accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
              </div>
              <div class="d-flex">
                <div>
                  <button @click="formExcelDownLoad('as')" class="btn btn-light" type="button">양식 다운로드</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, watch } from 'vue';
import { RouterLink, useRouter } from 'vue-router'
import { useLoginAuth } from '@/stores/LoginAuthStore'
import useOrderRegistry from '@/composables/registerOrder'
const router = useRouter()
const LoginAuthStore = useLoginAuth(router)
const { formExcelDownLoad, excelFile, excelUpload, error } = useOrderRegistry(LoginAuthStore);
const excelFileName = ref('');
const getUploadFile = (e) => {
  excelFile.value = e.target.files[0]
  e.target.value = '';
}
const onDrop = (event) => {
  excelFile.value = [...event.dataTransfer.files][0]
  event.target.value = '';
}
const fnGetFileSize = (filesize) => {
  const text = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
  const e = Math.floor(Math.log(filesize) / Math.log(1024));
  return (filesize / Math.pow(1024, e)).toFixed(2) + " " + text[e];
}
const calcelUploadFile = () => {
  excelFile.value = {};
}
watch(error, (newVal, oldVal) => {
    console.log(newVal, "newVal")
    if (newVal.message) {
      new Swal({
        title: '업로드 중 오류가 발생하였습니다.',
        text: `먼저 엑셀 양식이 올바른지 확인해 주세요. ${newVal.message}`,
      });
    } else if (newVal.payload.length && newVal.success) {
      new Swal({
        title: '엑셀 업로드가 성공하였습니다.',
      });
    }
  },
  { deep: true }
)
</script>
<style lang="scss">
</style>