import {ref} from 'vue'
import {trim, replacePath} from '@/util/Util'
import apiURL from '@/bis/data/registerSupplierApi'
const useSupplierRegistry = (LoginAuthStore, id) => {
    const userIdRef = ref('')
    const passwdRef = ref('')
    const phoneNoRef = ref('') //연락처 
    const nameRef = ref('') // 업체명
    const pocNameRef = ref('')
    const activeYnRef = ref('Y')
    const errorRef = ref({})

    const supplierListRef = ref([])
    const pageInfoRef = ref([])
    const headersMap = {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': LoginAuthStore.accessToken
    }
    const clearAllFields = () => {
        userIdRef.value = ''
        passwdRef.value = ''
        phoneNoRef.value = ''
        nameRef.value = ''
        pocNameRef.value = ''
        activeYnRef.value = 'Y'
    }

    const loadSupplier = async () => {
        const url = replacePath(apiURL.loadSupplier.api, id)
        fetch(`${apiURL.baseUri}${url}`,
        {
            method: apiURL.loadSupplier.method,
            headers: headersMap
        }).then(res => res.json())
        .then(res => {
            if(res.success) {
                console.log(res)
                const payload = res.payload;
                userIdRef.value = payload.loginId
                passwdRef.value = ''
                phoneNoRef.value = payload.phoneNo
                nameRef.value = payload.name
                pocNameRef.value = payload.pocName
                activeYnRef.value = payload.activeYn
            } else {
                errorRef.value.message = res.error.msg
                LoginAuthStore.redirectInvalidSession(res)
            }
        }).catch(err => {
            errorRef.value.message = err.message
        })
    }
    const listSupplier = async(queryObj) => {
        let query = `?rrp=${queryObj.rrp}&pno=${queryObj.pno}`
        const filter = !queryObj.filter ? '': '&filter=' + trim(queryObj.filter)
        query = query + filter
        console.log(query)
        fetch(`${apiURL.baseUri}${apiURL.listSupplier.api}${query}`,
        {
            method: apiURL.listSupplier.method,
            headers: headersMap
        }).then(res => res.json())
        .then(res => {
            console.log(res)
            if(res.success) {
                supplierListRef.value = !res.payload ? []: res.payload
                pageInfoRef.value = !res.pageInfo ? []: res.pageInfo;
            } else {
                errorRef.value.message = res.error.msg
                LoginAuthStore.redirectInvalidSession(res)
            }
        }).catch(err => errorRef.value.message = err.message)
    } 

    const saveSupplier = async () => {
        const userId = trim(userIdRef.value)
        const password = trim(passwdRef.value)
        const phoneNo = trim(phoneNoRef.value)
        const name = trim(nameRef.value)
        const pocName = trim(pocNameRef.value)
        const activeYn = activeYnRef.value
        const createMode = !id
        errorRef.value = {}
        
        if(!userId) errorRef.value.userId = '업체 아이디를 입력하세요'
        
        if(createMode && !password) errorRef.value.password = '업체 패스워드를 입력하세요'
        
        if(phoneNo.length < 9 || phoneNo.length > 13) errorRef.value.phoneNo = '휴대폰번호를 입력하세요'
        
        if(!name) errorRef.value.name = '업체명을 입력하세요'
        
        if(!pocName) errorRef.value.pocName = '담당자 이름을 입력하세요'

        const error = errorRef.value;
        if(
            error.userId || error.password || error.phoneNo
            || error.name || error.pocName
        ) {
            console.log('errorRef', error)
            return
        }
        let apiObj = createMode ? 
        apiURL.addSupplier : {api: replacePath(apiURL.updateSupplier.api, id), method: apiURL.updateSupplier.method}
        console.log('apiObject', apiObj)
        const requestBody = {
            userId: userId,
            password,
            name,
            pocName,
            phoneNo,
            activeYn
        }
        fetch(                
            `${apiURL.baseUri}${apiObj.api}`,
            {
                method: apiObj.method,
                headers: headersMap,
                body: JSON.stringify(requestBody)
            }
        ).then(res => res.json())
        .then(res => {
            if(res.success) {
                console.log(res)
                clearAllFields()
                alert(createMode? '업체를 생성하였습니다': '업체정보를 수정하였습니다')
                LoginAuthStore.getRouter.push({name:'companyList'})
            } else {
                errorRef.value.message = res.error.msg
                LoginAuthStore.redirectInvalidSession(res)
            }
        }).catch(err => {
            errorRef.value.message = err.message
        })
    }
    const deleteSupplier = async() => {
        const url = replacePath(apiURL.deleteSupplier.api, id)
        fetch(`${apiURL.baseUri}${url}`, {
            method: apiURL.deleteSupplier.method,
            headers: headersMap
        }).then(res => res.json())
        .then(res => {
            if(res.success) {
                clearAllFields()
                console.log(res)
                alert('계정을 삭제하였습니다')
            } else {
                errorRef.value.message = res.error.msg
                LoginAuthStore.redirectInvalidSession(res)
            }
        }).catch(err => {
            errorRef.value.message = err.message
        })
    }

    const deleteSupplierList = async(supplierList) => {
        fetch(`${apiURL.baseUri}${apiURL.deleteSupplierList.api}`, {
            method: apiURL.deleteSupplierList.method,
            headers: headersMap,
            body: JSON.stringify(supplierList)
        }).then(res => res.json())
        .then(res => {
            if(res.success) {
                clearAllFields()
            } else {
                errorRef.value.message = res.error.msg
                LoginAuthStore.redirectInvalidSession(res)
            }
        }).catch(err => {
            errorRef.value.message = err.message
        })
    }

    return {
        pageInfo: pageInfoRef, userId: userIdRef, password: passwdRef, phoneNo:phoneNoRef,
        name: nameRef, pocName: pocNameRef, activeYn: activeYnRef,
        supplierList: supplierListRef, error: errorRef,
        saveSupplier, deleteSupplier, deleteSupplierList, loadSupplier, listSupplier
    }
}

export default useSupplierRegistry