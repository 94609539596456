<template>
  <footer class="footer" :class="{footer: footer == 'footer-dark' ? 'footer-dark' : '', stretched}">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 footer-copyright text-center">
          <p class="mb-0">Copyright 2023&copy; Bis Co., Ltd. </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'footerpage',
  props: ['stretched'],
  data() {
    return {
      customizer: false
    };
  },
  computed: {
    ...mapGetters({
      footer: 'layout/footer'
    })
  }
};
</script>
<style scoped>
footer.footer.stretched {
  margin: 0;
}
</style>
