import { ref } from 'vue'
import { trim, replacePath } from '@/util/Util'
import apiURL from '@/bis/data/commonApi'
const commonApi = (LoginAuthStore) => {
  const headersMap = {
    'Content-Type': 'application/json',
    'X-AUTH-TOKEN': LoginAuthStore.accessToken
  }
  const passwdRef = ref('')
  const errorRef = ref({})
  const checkRes = ref();
  const pictureRef = ref({})
  const checkPwd = async () => {
    const pwd = trim(passwdRef.value)
    const query = `?pwd=${pwd}`
    const url = replacePath(apiURL.checkPwd.api);
    fetch(`${apiURL.baseUri}${url}${query}`,
    {
      method: apiURL.checkPwd.method,
      headers: headersMap,
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        console.log(res)
        passwdRef.value = ''
        checkRes.value = res;
        return res;
      } else {
        checkRes.value = res;
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
      checkRes.value = err;
      errorRef.value.message = err.message
      console.error(err)
    })
  }


  const loadCommonCode = async (code) => {
    const result = fetch(`${apiURL.baseUri}${replacePath(apiURL.getCommonCode.api, code)}`,
    {
      method: apiURL.getCommonCode.method,
      headers: headersMap,
    })
    return result;
  }

  const loadResource = async (uri) => {
    let query = `?uri=${uri}`
    fetch(`${apiURL.baseUri}${apiURL.loadResource.api}${query}`,
    {
      method: apiURL.loadResource.method,
      headers: headersMap,
    }).then(res => {
      pictureRef.value = res;
    })
    .catch(err => {
      errorRef.value.message = res.error.msg
      console.log(err)
    })
  }

  return {
    pwd: passwdRef, error: errorRef, checkResponse:checkRes,
    checkPwd, loadCommonCode, loadResource, picture: pictureRef,
  }
}
export default commonApi