import {defineStore} from 'pinia'
import {trim} from '@/util/Util'
import apiURL from '@/bis/data/loginAuthApi.json'
import {ResponseErrorCode, Roles, StorageKeys} from '@/bis/data/constants'
export const useLoginAuth = (router) => {
    const initStore = (store) => {
        store.saveIdYn = localStorage.getItem(StorageKeys.SAVE_USER_ID_YN) || false
    }
    const createLoginAuthStore = defineStore('LoginAuthStore', {
        state: () => ({
            saveIdYn: false,
            error: {message: '',userId: '', password: ''},
            accessToken: '',
            _loginUser: null
        }),
        getters: {
            currentUser() {
                // if(this._loginUser == null) {
                //     const stored = localStorage.getItem(StorageKeys.CURRENT_USER)
                //     this._loginUser = stored? JSON.parse(stored) : null
                // }
                return this._loginUser || {}
            },
            userLoggedIn() {
                return this.accessToken !== ''
            },
            savedUserId() {
                return this.saveIdYn ? localStorage.getItem(StorageKeys.STORED_USER_ID): ''
            },
            getRouter() {
                return router;
            }
        },
        actions: {
            async authenticate(userId, password) {
                userId = trim(userId)
                password = trim(password)
                if(this.saveIdYn) {
                    localStorage.setItem(StorageKeys.STORED_USER_ID, userId)
                }
                this.error.userId = userId? '' : '사용자 아이디를 입력하세요'
                this.error.password = password? '' : '암호를 입력하세요'

                if(!this.error.userId && !this.error.password) {
                    fetch(
                        `${apiURL.baseUri}${apiURL.login.api}?userId=${userId}&passwd=${password}`,
                        {
                            method: apiURL.login.method
                        }
                    ).then(res =>res.json())
                    .then(res => {
                        if(res.success) {
                            let currentUser = res.payload
                            this.accessToken = currentUser.accessToken
                            this._loginUser = currentUser
                            currentUser.roleDesc = Roles[res.payload.roleName]
                            
                        if (currentUser.roleName === 'ROLE_ROOT' || currentUser.roleName === 'ROLE_ADMIN') {
                            router.push({name:'orderList'})
                        } else if (currentUser.roleName === 'ROLE_SUPPLIER') {
                            router.push({name:'orderListForSeller'})
                        } else if (currentUser.roleName === 'ROLE_BRANCH') {
                            router.push({name:'orderListForBranch'})
                        }
                            // router.push({name:'orderList'})
                        } else {
                            this.error.message = res.error.msg
                        }
                    })
                    .catch(err => {
                        this.error.message = err.message
                    })
                }
            },
            async logout () {
                fetch(
                    `${apiURL.baseUri}${apiURL.logout.api}`,
                    {
                        method:apiURL.logout.method,
                        headers: {'X-AUTH-TOKEN': this.accessToken}
                    }
                ).then(res => res.json())
                .then(res => {
                    localStorage.removeItem(StorageKeys.CURRENT_USER)
                    this._loginUser = null
                    this.accessToken = ''
                    router.push({name: 'LoginView'})
                    if(res.success) {
                        this.error.message = '';
                    } else {
                        this.error.message = res.payload.msg
                        console.error(res.payload.msg)
                    }
                })
            },
            toggleSaveId () {
                this.saveIdYn = !this.saveIdYn
                localStorage.setItem(StorageKeys.SAVE_USER_ID_YN, this.saveIdYn)
                localStorage.setItem(StorageKeys.STORED_USER_ID, '')
            },
            redirectInvalidSession(res) {
                if((res.error && res.error.code === ResponseErrorCode.INVALID_ACCESS_TOKEN) || res.status === ResponseErrorCode.INVALID_ACCESS_TOKEN) {
                    this.accessToken = ''
                    console.log('redirecting login view')
                    router.push({name: 'LoginView'})
                }
            }
            
        }
    })
    const LoginAuthStore = createLoginAuthStore()
    initStore(LoginAuthStore)
    return LoginAuthStore
}
