<template>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <form class="needs-validation" novalidate="" @submit.prevent="validationSubmit">
        <div class="modal-header">
          <h5 class="modal-title">{{ `${(searchType === 'order') ? '주문' : (isClaim || searchType === 'claim') ? '클레임' : 'A/S'} 기본 정보` }}</h5>
          <button :id="`${whatIam}InfoCloseModal${orderId}`" class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span class="validation-name">업체명</span>
            </div>
            <div class="col-9">
              <select class="form-select btn-square digits" v-model="providerId" v-bind:class="formSubmitted ? providerIdError ? 'is-invalid' : 'is-valid' : ''" :disabled="isRead">
                <option value=''>선택하세요</option>
                <option v-for="item in selectCompList" :value="item?.id" :key="item?.id">{{ item?.name }}</option>
              </select>
              <div class="invalid-feedback text-start" v-if="error.providerId">{{error.providerId}}</div>
            </div>
          </div>
          <div class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span class="validation-name">상품분류</span>
            </div>
            <div class="col-9">
              <select class="form-select btn-square digits" v-model="prodTypeId" v-bind:class="formSubmitted ? prodTypeIdError ? 'is-invalid' : 'is-valid' : ''" :disabled="isRead">
                <option value=''>선택하세요</option>
                <option v-for="item in selectProdType" :value="item?.id" :key="item?.id">{{ item?.name }}</option>
              </select>
              <div class="invalid-feedback text-start" v-if="error.prodTypeId">{{error.prodTypeId}}</div>
            </div>
          </div>
          <div class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span class="validation-name">상품명</span>
            </div>
            <div class="col-9">
              <select class="form-select btn-square digits" v-model="productId" v-bind:class="formSubmitted ? productIdError ? 'is-invalid' : 'is-valid' : ''" :disabled="isRead">
                <option value=''>선택하세요</option>
                <option v-for="item in selectProductList" :value="item?.id" :key="item?.id">{{ item?.name }}</option>
              </select>
              <div class="invalid-feedback text-start" v-if="error.productId">{{error.productId}}</div>
            </div>
          </div>
          <div class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span>담당자명</span>
            </div>
            <div class="col-9">
              <input class="form-control btn-square w-100" type="text" v-model="assigneeName" placeholder="" :readOnly="isRead">
            </div>
          </div>
          <div class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span class="validation-name me-2">고객명</span>
              <i @click="copyBasicInfo" class="fa fa-copy" style="cursor:pointer;"></i>
            </div>
            <div class="col-9">
              <input class="form-control btn-square w-100" type="text" v-model="customerName"  v-bind:class="formSubmitted ? customerNameError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
              <div class="invalid-feedback text-start" v-if="error.customerName">{{error.customerName}}</div>
            </div>
          </div>
          <div class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span class="validation-name">전화번호1</span>
            </div>
            <div class="col-9">
              <input class="form-control btn-square w-100" type="text" maxlength="14" v-model="primaryPhoneNo" @input="handlePrimaryPhoneNo" v-bind:class="formSubmitted ? primaryPhoneNoError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
              <div class="invalid-feedback text-start" v-if="error.primaryPhoneNo">{{error.primaryPhoneNo}}</div>
            </div>
          </div>
          <div class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span>전화번호2</span>
            </div>
            <div class="col-9">
              <input class="form-control btn-square w-100" type="text" maxlength="14" v-model="secondaryPhoneNo" @input="handleSecondaryPhoneNo" placeholder="" :readOnly="isRead">
            </div>
          </div>
          <div class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span>지사명</span>
            </div>
            <div class="col-9">
              <select class="form-select btn-square digits" v-model="branchId" :disabled="isRead">
                <option value=''>선택하세요</option>
                <option v-for="item in selectBranchList" :value="item?.id" :key="item?.id">{{ item?.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span>설치기사</span>
            </div>
            <div class="col-9">
              <select class="form-select btn-square digits" v-model="engineerId" :disabled="isRead">
                <option value=''>선택하세요</option>
                <option v-for="item in selectEngineerList" :value="item?.id" :key="item?.id">{{ item?.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span class="validation-name">주소</span>
            </div>
            <div class="col-9">
              <div class="d-flex">
                <input class="form-control btn-square me-2" type="text" placeholder="주소 검색 버튼을 눌러주세요" v-model="customerAddr1" v-bind:class="formSubmitted ? customerAddr1Error ? 'is-invalid' : 'is-valid' : ''" readOnly>
                <PostCode v-if="!isRead" @address-sought="addressSought" />
              </div>
            </div>
          </div>
          <div class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span>상세주소</span>
            </div>
            <div class="col-9">
              <input class="form-control btn-square w-100" type="text" v-model="customerAddr2" placeholder="" :readOnly="isRead">
            </div>
          </div>
          <div v-if="!(isClaim || searchType === 'order' || searchType === 'claim')" class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span class="validation-name">작업유형</span>
            </div>
            <div class="col-9">
              <select class="form-select btn-square digits" v-model="workTypeCode" v-bind:class="formSubmitted ? workTypeCodeError ? 'is-invalid' : 'is-valid' : ''">
                <option value=''>선택하세요</option>
                <option v-for="item in workTypeListRef" :value="item?.code" :key="item?.code">{{ item?.name }}</option>
              </select>
            </div>
          </div>        
          <div v-if="(!isClaim || searchType !== 'claim')" class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span>진행상황</span>
            </div>
            <div class="col-9">
              <select class="form-select btn-square digits" v-model="statCode">
                <option value=''>선택하세요</option>
                <option v-for="item in progressListRef" :value="item?.code" :key="item?.code">{{ item?.name }}</option>
              </select>
            </div>
          </div>        
          <div v-if="searchType === 'order'" class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span class="validation-name">차종</span>
            </div>
            <div class="col-9">
              <div class="col-12 d-flex">
                <div class="col-4 pe-2">
                  <select class="form-select btn-square digits" v-model="vhcCatCode" v-bind:class="formSubmitted ? vhcCatCodeError ? 'is-invalid' : 'is-valid' : ''">
                    <option value=''>선택하세요</option>
                    <option v-for="item in vhcCatCodeListRef" :value="item?.code" :key="item?.code">{{ item?.name }}</option>
                  </select>
                  <div class="invalid-feedback text-start" v-if="error.vhcCatCode">{{error.vhcCatCode}}</div>
                </div>
                <div class="col-8">
                  <input class="form-control btn-square col-7" type="text" placeholder="차량명" v-model="vhcTypeName" v-bind:class="formSubmitted ? vhcTypeNameError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                  <div class="invalid-feedback text-start" v-if="error.vhcTypeName">{{error.vhcTypeName}}</div>
                </div>
              </div>
            </div>
          </div> 
          <div v-if="!(searchType === 'order')" class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span>차종</span>
            </div>
            <div class="col-9">
              <div class="col-12 d-flex">
                <div class="col-4 pe-2">
                  <select class="form-select btn-square digits" v-model="vhcCatCode">
                    <option value=''>선택하세요</option>
                    <option v-for="item in vhcCatCodeListRef" :value="item?.code" :key="item?.code">{{ item?.name }}</option>
                  </select>
                </div>
                <div class="col-8">
                  <input class="form-control btn-square col-7" type="text" placeholder="차량명" v-model="vhcTypeName" :readOnly="isRead">
                </div>
              </div>
            </div>
          </div> 
          <div v-if="(isClaim || searchType === 'claim')" class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span class="validation-name">분류</span>
            </div>
            <div class="col-9">
              <select class="form-select btn-square digits" v-model="claimInfo.inboundCode" v-bind:class="formSubmitted ? inboundCodeError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                <option value=''>선택하세요</option>
                <option v-for="item in claimTypeRef" :value="item?.code" :key="item?.code">{{ item?.name }}</option>
              </select>
              <div class="invalid-feedback text-start" v-if="error.inboundCode">{{error.inboundCode}}</div>
            </div>
          </div>
          <div v-if="(isClaim || searchType === 'claim')" class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span class="validation-name">접수형태</span>
            </div>
            <div class="col-9">
              <select class="form-select btn-square digits" v-model="claimInfo.typeCode" v-bind:class="formSubmitted ? typeCodeError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                <option value=''>선택하세요</option>
                <option v-for="item in claimTypeCodeRef" :value="item?.code" :key="item?.code">{{ item?.name }}</option>
              </select>
              <div class="invalid-feedback text-start" v-if="error.typeCode">{{error.typeCode}}</div>
            </div>
          </div>
          <!-- <div v-if="(!isClaim || searchType !== 'claim')" class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span>{{ `${(isOrder || searchType === 'order') ? '기본 장착비' : '결제 구분'}` }}</span>
            </div>
            <div class="col-9">
              <div class="col-12 d-flex">
                <div class="col-4 pe-2">
                  <select v-model="basicPayment.typeCode" class="form-select btn-square digits col-5">
                    <option v-for="(item, idx) in paymentCodeRef" :value='item?.code'>{{ item?.name }}</option>
                  </select>
                </div>
                <div class="col-8">
                  <input class="form-control btn-square col-7" type="text" placeholder="금액" v-model="basicPayment.amount" :readOnly="isRead">
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div v-if="basicPayment.typeCode === '01003' || basicPayment.typeCode === '01004'">
            <div class="col-12 d-flex align-items-center justify-content-center mt-2">
              <div class="col-2">
                <span class="validation-name">내용</span>
              </div>
              <div class="col-9">
                <input v-model="basicPayment.desc" class="form-control btn-square col-7" type="text" v-bind:class="formSubmitted ? basicDescError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                <div class="invalid-feedback text-start" v-if="error.basicDesc">{{error.basicDesc}}</div>
              </div>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center mt-2">
              <div class="col-2">
                <span class="validation-name">금액</span>
              </div>
              <div class="col-9">
                <input v-model="basicPayment.amount" class="form-control btn-square col-7" type="text" v-bind:class="formSubmitted ? basicAmountError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                <div class="invalid-feedback text-start" v-if="error.basicAmount">{{error.basicAmount}}</div>
              </div>
            </div>
            <div v-if="basicPayment.typeCode === '01003'">
              <div class="col-12 d-flex align-items-center justify-content-center mt-2">
                <div class="col-2">
                  <span class="validation-name">카드사명</span>
                </div>
                <div class="col-9">
                  <input v-model="basicPayment.cardCompany" class="form-control btn-square col-7" type="text" v-bind:class="formSubmitted ? basicCardCompanyError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                  <div class="invalid-feedback text-start" v-if="error.basicCardCompany">{{error.basicCardCompany}}</div>
                </div>
              </div>
              <div class="col-12 d-flex align-items-center justify-content-center mt-2">
                <div class="col-2">
                  <span class="validation-name">카드번호</span>
                </div>
                <div class="col-9">
                  <input v-model="basicPayment.cardNo" class="form-control btn-square col-7" type="text" v-bind:class="formSubmitted ? basicCardNoError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                  <div class="invalid-feedback text-start" v-if="error.basicCardNo">{{error.basicCardNo}}</div>
                </div>
              </div>
              <div class="col-12 d-flex align-items-center justify-content-center mt-2">
                <div class="col-2">
                  <span class="validation-name">유효기간</span>
                </div>
                <div class="col-9">
                  <input v-model="basicPayment.cardValidPeriod" class="form-control btn-square col-7" type="text" v-bind:class="formSubmitted ? basicCardValidPeriodError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                  <div class="invalid-feedback text-start" v-if="error.basicCardValidPeriod">{{error.basicCardValidPeriod}}</div>
                </div>
              </div>
              <div class="col-12 d-flex align-items-center justify-content-center mt-2">
                <div class="col-2">
                  <span class="validation-name">승인번호</span>
                </div>
                <div class="col-9">
                  <input v-model="basicPayment.txApprovalNo" class="form-control btn-square col-7" type="text" v-bind:class="formSubmitted ? basicTxApprovalNoError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                  <div class="invalid-feedback text-start" v-if="error.basicTxApprovalNo">{{error.basicTxApprovalNo}}</div>
                </div>
              </div>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center mt-2">
              <div class="col-2">
                <span class="validation-name">{{ basicPayment.typeCode === '01003' ? '승인일자' : '계산서발행일' }}</span>
              </div>
              <div class="col-9">
                <input v-model="basicPayment.approvalDateTime" class="form-control btn-square col-7" type="text" v-bind:class="formSubmitted ? basicApprovalDateTimeError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                <div class="invalid-feedback text-start" v-if="error.basicApprovalDateTime">{{error.basicApprovalDateTime}}</div>
              </div>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center mt-2">
              <div class="col-2">
                <span>비고</span>
              </div>
              <div class="col-9">
                <input v-model="basicPayment.remark" class="form-control btn-square col-7" type="text" placeholder="">
              </div>
            </div>
          </div> -->
          <!-- <div v-if="isOrder || searchType === 'order'" class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span>추가 장착비</span>
            </div>
            <div class="col-9">
              <div class="col-12 d-flex">
                <div class="col-4 pe-2">
                  <select v-model="optionPayment.typeCode" class="form-select btn-square digits col-5">
                    <option v-for="(item, idx) in paymentCodeRef" :value='item?.code'>{{ item?.name }}</option>
                  </select>
                </div>
                <div class="col-8">
                  <input class="form-control btn-square col-7" type="text" placeholder="금액" v-model="optionPayment.amount" :readOnly="isRead">
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div v-if="optionPayment.typeCode === '01003' || optionPayment.typeCode === '01004'">
            <div class="col-12 d-flex align-items-center justify-content-center mt-2">
              <div class="col-2">
                <span class="validation-name">내용</span>
              </div>
              <div class="col-9">
                <input v-model="optionPayment.desc" class="form-control btn-square col-7" type="text" v-bind:class="formSubmitted ? optionDescError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                <div class="invalid-feedback text-start" v-if="error.optionDesc">{{error.optionDesc}}</div>
              </div>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center mt-2">
              <div class="col-2">
                <span class="validation-name">금액</span>
              </div>
              <div class="col-9">
                <input v-model="optionPayment.amount" class="form-control btn-square col-7" type="text" v-bind:class="formSubmitted ? optionAmountError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                <div class="invalid-feedback text-start" v-if="error.optionAmount">{{error.optionAmount}}</div>
              </div>
            </div>
            <div v-if="optionPayment.typeCode === '01003'">
              <div class="col-12 d-flex align-items-center justify-content-center mt-2">
                <div class="col-2">
                  <span class="validation-name">카드사명</span>
                </div>
                <div class="col-9">
                  <input v-model="optionPayment.cardCompany" class="form-control btn-square col-7" type="text" v-bind:class="formSubmitted ? optionCardCompanyError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                  <div class="invalid-feedback text-start" v-if="error.optionCardCompany">{{error.optionCardCompany}}</div>
                </div>
              </div>
              <div class="col-12 d-flex align-items-center justify-content-center mt-2">
                <div class="col-2">
                  <span class="validation-name">카드번호</span>
                </div>
                <div class="col-9">
                  <input v-model="optionPayment.cardNo" class="form-control btn-square col-7" type="text" v-bind:class="formSubmitted ? optionCardNoError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                  <div class="invalid-feedback text-start" v-if="error.optionCardNo">{{error.optionCardNo}}</div>
                </div>
              </div>
              <div class="col-12 d-flex align-items-center justify-content-center mt-2">
                <div class="col-2">
                  <span class="validation-name">유효기간</span>
                </div>
                <div class="col-9">
                  <input v-model="optionPayment.cardValidPeriod" class="form-control btn-square col-7" type="text" v-bind:class="formSubmitted ? optionCardValidPeriodError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                  <div class="invalid-feedback text-start" v-if="error.optionCardValidPeriod">{{error.optionCardValidPeriod}}</div>
                </div>
              </div>
              <div class="col-12 d-flex align-items-center justify-content-center mt-2">
                <div class="col-2">
                  <span class="validation-name">승인번호</span>
                </div>
                <div class="col-9">
                  <input v-model="optionPayment.txApprovalNo" class="form-control btn-square col-7" type="text" v-bind:class="formSubmitted ? optionTxApprovalNoError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                  <div class="invalid-feedback text-start" v-if="error.optionTxApprovalNo">{{error.optionTxApprovalNo}}</div>
                </div>
              </div>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center mt-2">
              <div class="col-2">
                <span class="validation-name">{{ optionPayment.typeCode === '01003' ? '승인일자' : '계산서발행일' }}</span>
              </div>
              <div class="col-9">
                <input v-model="optionPayment.approvalDateTime" class="form-control btn-square col-7" type="text" v-bind:class="formSubmitted ? optionApprovalDateTimeError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                <div class="invalid-feedback text-start" v-if="error.optionApprovalDateTime">{{error.optionApprovalDateTime}}</div>
              </div>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center mt-2">
              <div class="col-2">
                <span>비고</span>
              </div>
              <div class="col-9">
                <input v-model="optionPayment.remark" class="form-control btn-square col-7" type="text" placeholder="" :readOnly="isRead">
              </div>
            </div>
          </div> -->
          <!-- <div v-if="isOrder || searchType === 'order'" class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span>탈거비</span>
            </div>
            <div class="col-9">
              <div class="col-12 d-flex">
                <div class="col-4 pe-2">
                  <select v-model="removalPayment.typeCode" class="form-select btn-square digits col-5">
                    <option v-for="(item, idx) in paymentCodeRef" :value='item?.code'>{{ item?.name }}</option>
                  </select>
                </div>
                <div class="col-8">
                  <input class="form-control btn-square col-7" type="text" placeholder="금액" v-model="removalPayment.amount" :readOnly="isRead">
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div v-if="removalPayment.typeCode === '01003' || removalPayment.typeCode === '01004'">
            <div class="col-12 d-flex align-items-center justify-content-center mt-2">
              <div class="col-2">
                <span class="validation-name">내용</span>
              </div>
              <div class="col-9">
                <input v-model="removalPayment.desc" class="form-control btn-square col-7" type="text" v-bind:class="formSubmitted ? removalDescError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                <div class="invalid-feedback text-start" v-if="error.removalDesc">{{error.removalDesc}}</div>
              </div>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center mt-2">
              <div class="col-2">
                <span class="validation-name">금액</span>
              </div>
              <div class="col-9">
                <input v-model="removalPayment.amount" class="form-control btn-square col-7" type="text" v-bind:class="formSubmitted ? removalAmountError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                <div class="invalid-feedback text-start" v-if="error.removalAmount">{{error.removalAmount}}</div>
              </div>
            </div>
            <div v-if="removalPayment.typeCode === '01003'">
              <div class="col-12 d-flex align-items-center justify-content-center mt-2">
                <div class="col-2">
                  <span class="validation-name">카드사명</span>
                </div>
                <div class="col-9">
                  <input v-model="removalPayment.cardCompany" class="form-control btn-square col-7" type="text" v-bind:class="formSubmitted ? removalCardCompanyError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                  <div class="invalid-feedback text-start" v-if="error.removalCardCompany">{{error.removalCardCompany}}</div>
                </div>
              </div>
              <div class="col-12 d-flex align-items-center justify-content-center mt-2">
                <div class="col-2">
                  <span class="validation-name">카드번호</span>
                </div>
                <div class="col-9">
                  <input v-model="removalPayment.cardNo" class="form-control btn-square col-7" type="text" v-bind:class="formSubmitted ? removalCardNoError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                  <div class="invalid-feedback text-start" v-if="error.removalCardNo">{{error.removalCardNo}}</div>
                </div>
              </div>
              <div class="col-12 d-flex align-items-center justify-content-center mt-2">
                <div class="col-2">
                  <span class="validation-name">유효기간</span>
                </div>
                <div class="col-9">
                  <input v-model="removalPayment.cardValidPeriod" class="form-control btn-square col-7" type="text" v-bind:class="formSubmitted ? removalCardValidPeriodError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                  <div class="invalid-feedback text-start" v-if="error.removalCardValidPeriod">{{error.removalCardValidPeriod}}</div>
                </div>
              </div>
              <div class="col-12 d-flex align-items-center justify-content-center mt-2">
                <div class="col-2">
                  <span class="validation-name">승인번호</span>
                </div>
                <div class="col-9">
                  <input v-model="removalPayment.txApprovalNo" class="form-control btn-square col-7" type="text" v-bind:class="formSubmitted ? removalTxApprovalNoError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                  <div class="invalid-feedback text-start" v-if="error.removalTxApprovalNo">{{error.removalTxApprovalNo}}</div>
                </div>
              </div>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center mt-2">
              <div class="col-2">
                <span class="validation-name">{{ removalPayment.typeCode === '01003' ? '승인일자' : '계산서발행일' }}</span>
              </div>
              <div class="col-9">
                <input v-model="removalPayment.approvalDateTime" class="form-control btn-square col-7" type="text" v-bind:class="formSubmitted ? removalApprovalDateTimeError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                <div class="invalid-feedback text-start" v-if="error.removalApprovalDateTime">{{error.removalApprovalDateTime}}</div>
              </div>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center mt-2">
              <div class="col-2">
                <span>비고</span>
              </div>
              <div class="col-9">
                <input v-model="removalPayment.remark" class="form-control btn-square col-7" type="text" placeholder="" :readOnly="isRead">
              </div>
            </div>
          </div> -->
          <div v-if="!(isClaim || searchType === 'claim')" class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span >{{`${(searchType === 'order') ? '제품 / 설치 특이사항' : '불량증상'}`}}</span>
            </div>
            <div class="col-9">
              <input class="form-control btn-square col-7" type="text" placeholder="" v-model="remark" :readOnly="isRead">
            </div>
          </div>
          <div v-if="isClaim || searchType === 'claim'" class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span>처리결과</span>
            </div>
            <div class="col-9">
              <input class="form-control btn-square col-7" type="text" placeholder="" v-model="claimInfo.result" :readOnly="isRead">
            </div>
          </div>
          <div class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span>본사메모</span>
            </div>
            <div class="col-9">
              <div class="form-control btn-square" @dblclick="focusMemo" @mousedown.left="downListener" @mousemove="moveListener" @mouseup.left="upListener('hq')" style="height: 100px;overflow-y:auto;" :contenteditable="!isRead">
                <div contenteditable="false"><div v-html="loadMemo" class="text-start"></div>
                  <div class="text-start" :id="`addModalMemo${orderId}`" :contenteditable="!isRead" @input="handleMemo"></div>
                </div>
              </div>
              <!-- <input class="form-control btn-square col-7" type="text" placeholder=""> -->
            </div>
          </div>
          <div class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span>기사메모</span>
            </div>
            <div class="col-9">
              <div class="form-control btn-square" @dblclick="focusEngMemo" @mousedown.left="downListener" @mousemove="moveListener" @mouseup.left="upListener('eg')" style="height: 100px;overflow-y:auto;" :contenteditable="!isRead">
                <div contenteditable="false"><div v-html="loadEngineerMemo" class="text-start"></div>
                  <div class="text-start" :id="`addModalEngineerMemo${orderId}`" :contenteditable="!isRead" @input="handleEngineerMemo"></div>
                </div>
              </div>
              <!-- <input class="form-control btn-square col-7" type="text" placeholder=""> -->
            </div>
          </div>
        </div>
        <div v-if="!isRead" class="modal-footer d-flex justify-content-center">
          <button class="btn btn-secondary" type="submit">수정</button>
          <button class="btn btn-primary" type="button" :disabled="happyCallRequested || isRead" @click="reqHappycall">해피콜 요청</button>
          <button v-if="searchType === 'order'" class="btn btn-info" type="button" :disabled="isRead" @click="getLoadOrderData">AS 등록</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed, defineProps, toRefs, onUnmounted, watch, defineEmits } from 'vue';
import useOrderRegistry from '@/composables/registerOrder';
import commonApi from '@/composables/commonApi'
import PostCode from '@/components/PostCode.vue'
import { useRouter } from 'vue-router'
import { useLoginAuth } from '@/stores/LoginAuthStore';
import Swal from 'sweetalert2';
const { ClipboardItem } = window;
const router = useRouter();
const LoginAuthStore = useLoginAuth(router)
const props = defineProps({
  orderId: {
    type: Number,
    required: true,
  },
  isRead: {
    type: Boolean,
    required: false,
  },
  searchType: {
    type: String,
    required: false,
  }
});
const { orderId, isRead, searchType } = toRefs(props);
const { selectCompList, selectProdType, selectProductList, selectBranchList, selectEngineerList,
loadSelectCompList, loadSelectProdTypeList, loadSelectProductList, loadSelectBranchList, loadSelectEngineerList, saveOrder,
providerId, prodTypeId, loadProviderId, loadProdTypeId, loadProductId, productId, branchId, loadEngineerId, engineerId, workTypeCode, statCode, vhcCatCode, vhcTypeName, error,
typeCode, customerName, postalCode, customerAddr1, customerAddr2, extraAddr, addrType, primaryPhoneNo, secondaryPhoneNo, remark, memo, loadMemo, loadEngineerMemo, engineerMemo, happyCallRequested,
basicPayment, optionPayment, removalPayment, loadOrder, loadClaim, assigneeName, loadProduct, loadOrderData, updateItems, sendHappycall, loadAs, claimInfo, saveAs, saveClaim} = useOrderRegistry(LoginAuthStore)
const { loadCommonCode } = commonApi(LoginAuthStore);
const workTypeListRef = ref([])
const progressListRef = ref([])
const vhcCatCodeListRef = ref([])
const paymentCodeRef = ref([]);
const inChargePersonRef = ref({});
const path = ref(router.currentRoute.value.path);
const isClaim = path.value.includes('claim');
const isCal = path.value.includes('calculate');
const claimTypeRef = ref([]);
const claimTypeCodeRef = ref([]);
const isOpenModal = ref(false);
const emit = defineEmits(['updateOrder']);
const whatIam = isCal ? 'cal' : (searchType.value === 'order') ? 'order' : (isClaim || searchType.value === 'claim') ? 'claim' : 'as'
onMounted(() => {
  const infoDetailModalEl = document.getElementById(`${whatIam}InfoDetail${orderId.value}`);
  infoDetailModalEl.addEventListener('shown.bs.modal', async () => {
    
    if (!isOpenModal.value) {
      isOpenModal.value = true;
      if (searchType.value === 'order') {
        await loadOrder(orderId.value);
        loadCommonCode('00003').then(res => res.json()).then(res => workTypeListRef.value = res.payload)
        // 주문유형 ([0] 00010: 주문, [1] 00011: 클레임, [2] 00012: AS)
        loadCommonCode('00001').then(res => res.json()).then(res => typeCode.value = res.payload[0].code);
      } else if (isClaim || searchType.value === 'claim') {
        await loadClaim(orderId.value);
        loadCommonCode('00006').then(res => res.json()).then(res => claimTypeRef.value = res.payload)
        loadCommonCode('00007').then(res => res.json()).then(res => claimTypeCodeRef.value = res.payload);
        // 주문유형 ([0] 00010: 주문, [1] 00011: 클레임, [2] 00012: AS)
        loadCommonCode('00001').then(res => res.json()).then(res => typeCode.value = res.payload[1].code);
      } else {
        console.log(orderId.value, isOpenModal.value, searchType.value, "orderId.value");
        let testOrderId = orderId.value;
        await loadAs(testOrderId);
        loadCommonCode('00002').then(res => res.json()).then(res => workTypeListRef.value = res.payload);
        // 주문유형 ([0] 00010: 주문, [1] 00011: 클레임, [2] 00012: AS)
        loadCommonCode('00001').then(res => res.json()).then(res => typeCode.value = res.payload[2].code);
      } 
      await loadSelectCompList();
      await loadSelectBranchList();
      // 진행상황
      loadCommonCode('00004').then(res => res.json()).then(res => progressListRef.value = res.payload);
      // 차종
      loadCommonCode('00005').then(res => res.json()).then(res => vhcCatCodeListRef.value = res.payload);
      // 결재유형코드
      loadCommonCode('00100').then(res => res.json()).then(res => paymentCodeRef.value = res.payload);
    }
  })

  infoDetailModalEl.addEventListener('hidden.bs.modal', async () => {
    isOpenModal.value = false;
    const isValid = document.querySelectorAll('.is-valid');
    isValid.forEach(el => el.classList.remove('is-valid'));
    infoDetailModalEl.removeEventListener('shown.bs.modal', async () => {
      if (!isOpenModal.value) {
        isOpenModal.value = true;
        if (searchType.value === 'order') {
          await loadOrder(orderId.value);
          loadCommonCode('00003').then(res => res.json()).then(res => workTypeListRef.value = res.payload)
          // 주문유형 ([0] 00010: 주문, [1] 00011: 클레임, [2] 00012: AS)
          loadCommonCode('00001').then(res => res.json()).then(res => typeCode.value = res.payload[0].code);
        } else if (isClaim || searchType.value === 'claim') {
          await loadClaim(orderId.value);
          loadCommonCode('00006').then(res => res.json()).then(res => claimTypeRef.value = res.payload)
          loadCommonCode('00007').then(res => res.json()).then(res => claimTypeCodeRef.value = res.payload);
          // 주문유형 ([0] 00010: 주문, [1] 00011: 클레임, [2] 00012: AS)
          loadCommonCode('00001').then(res => res.json()).then(res => typeCode.value = res.payload[1].code);
        } else {
          await loadAs(orderId.value);
          loadCommonCode('00002').then(res => res.json()).then(res => workTypeListRef.value = res.payload);
          // 주문유형 ([0] 00010: 주문, [1] 00011: 클레임, [2] 00012: AS)
          loadCommonCode('00001').then(res => res.json()).then(res => typeCode.value = res.payload[2].code);
        } 
        await loadSelectCompList();
        await loadSelectBranchList();
        // 진행상황
        loadCommonCode('00004').then(res => res.json()).then(res => progressListRef.value = res.payload);
        // 차종
        loadCommonCode('00005').then(res => res.json()).then(res => vhcCatCodeListRef.value = res.payload);
        // 결재유형코드
        loadCommonCode('00100').then(res => res.json()).then(res => paymentCodeRef.value = res.payload);
      }
    })
  })
})

const formSubmitted = ref(false);
const providerIdError = ref(false);
const prodTypeIdError = ref(false);
const productIdError = ref(false);
const customerNameError = ref(false);
const primaryPhoneNoError = ref(false);
const customerAddr1Error = ref(false);
const vhcCatCodeError = ref(false);
const vhcTypeNameError = ref(false);

const inboundCodeError = ref(false);
const typeCodeError = ref(false);
const workTypeCodeError = ref(false);
const isDragRef = ref(false);
const downListener = () => {
  isDragRef.value = false;
}
const moveListener = () => {
  setTimeout(() => {
    isDragRef.value = true;
  }, 1000)
}
const upListener = (type) => {
  if (!isDragRef.value) {
    if (type === "hq") return focusMemo();
    if (type === "eg") return focusEngMemo();
    if (type === "rj") return focusRejectMemo();
  }
}
// const basicDescError = ref(false);
// const basicAmountError = ref(false);
// const basicCardCompanyError = ref(false);
// const basicCardNoError = ref(false);
// const basicCardValidPeriodError = ref(false);
// const basicTxApprovalNoError = ref(false);
// const basicApprovalDateTimeError = ref(false);

// const optionDescError = ref(false);
// const optionAmountError = ref(false);
// const optionCardCompanyError = ref(false);
// const optionCardNoError = ref(false);
// const optionCardValidPeriodError = ref(false);
// const optionTxApprovalNoError = ref(false);
// const optionApprovalDateTimeError = ref(false);

// const removalDescError = ref(false);
// const removalAmountError = ref(false);
// const removalCardCompanyError = ref(false);
// const removalCardNoError = ref(false);
// const removalCardValidPeriodError = ref(false);
// const removalTxApprovalNoError = ref(false);
// const removalApprovalDateTimeError = ref(false);

const addressSought = async (address) => {
  postalCode.value = address.postalCd;
  customerAddr1.value = address.addr;
  extraAddr.value = address.extraAddr;
  addrType.value = address.type === 'R' ? 'ROAD' : 'PARCEL'
}

const handlePrimaryPhoneNo = (e) => {
  if (e.target.value.replace(/[^0-9]/g, '').length === 12) {
    primaryPhoneNo.value = e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3`);
  } else {
    primaryPhoneNo.value = e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }
}
const handleSecondaryPhoneNo = (e) => {
  if (e.target.value.replace(/[^0-9]/g, '').length === 12) {
    secondaryPhoneNo.value = e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3`);
  } else {
    secondaryPhoneNo.value = e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }
}

const updateOrder = async () => {
  if (searchType.value === 'order') await saveOrder(orderId.value);
  else if (isClaim) await saveClaim(orderId.value);
  else await saveAs(orderId.value);
  const memoRef = document.getElementById(`addModalMemo${orderId.value}`)
  memoRef.textContent = '';
  const engineerMemoRef = document.getElementById(`addModalEngineerMemo${orderId.value}`)
  engineerMemoRef.textContent = '';
}
const modalClose = async () => {
  const modalCloseEl = document.getElementById(`${whatIam}InfoCloseModal${orderId.value}`);
  await modalCloseEl.click();
}
const validationSubmit = async () => {
  formSubmitted.value = true;

  if (providerId.value.length < 1 || providerId.value === null) providerIdError.value = true;
  else providerIdError.value = false;

  if (prodTypeId.value.length < 1 || prodTypeId.value === null) prodTypeIdError.value = true;
  else prodTypeIdError.value = false;

  if (productId.value.length < 1 || productId.value === null) productIdError.value = true;
  else productIdError.value = false;

  if (customerName.value.length < 1 || customerName.value === null) customerNameError.value = true;
  else customerNameError.value = false;

  if (primaryPhoneNo.value.length > 14 || primaryPhoneNo.value.length < 1 || primaryPhoneNo.value === null) primaryPhoneNoError.value = true;
  else primaryPhoneNoError.value = false;

  if (customerAddr1.value.length < 1 || customerAddr1.value === null) customerAddr1Error.value = true;
  else customerAddr1Error.value = false;

  if (vhcCatCode.value.length < 1 || vhcCatCode.value === null) vhcCatCodeError.value = true;
  else vhcCatCodeError.value = false;
  if (vhcTypeName.value.length < 1 || vhcTypeName.value === null) vhcTypeNameError.value = true;
  else vhcTypeNameError.value = false;

  if (isClaim) {
    if (claimInfo.value.inboundCode.length < 1 || claimInfo.value.inboundCode === null) inboundCodeError.value = true;
    if (claimInfo.value.typeCode.length < 1 || claimInfo.value.typeCode === null) typeCodeError.value = true;
  }

  if (!(searchType.value === 'order' || isClaim)) {
    if (workTypeCode.value.length < 1 || workTypeCode.value === null) workTypeCodeError.value = true;
  }

  if (basicPayment.value.typeCode === '01003') {
    // if (basicPayment.value.desc.length < 1 || basicPayment.value.desc === null) basicDescError.value = true;
    // else basicDescError.value = false;
    // if (basicPayment.value.amount.length < 1 || basicPayment.value.amount === null) basicAmountError.value = true;
    // else basicAmountError.value = false;
    // if (basicPayment.value.cardCompany.length < 1 || basicPayment.value.cardCompany === null) basicCardCompanyError.value = true;
    // else basicCardCompanyError.value = false;
    // if (basicPayment.value.cardNo.length < 1 || basicPayment.value.cardNo === null) basicCardNoError.value = true;
    // else basicCardNoError.value = false;
    // if (basicPayment.value.cardValidPeriod.length < 1 || basicPayment.value.cardValidPeriod === null) basicCardValidPeriodError.value = true;
    // else basicCardValidPeriodError.value = false;
    // if (basicPayment.value.txApprovalNo.length < 1 || basicPayment.value.txApprovalNo === null) basicTxApprovalNoError.value = true;
    // else basicTxApprovalNoError.value = false;
    // if (basicPayment.value.approvalDateTime.length < 1 || basicPayment.value.approvalDateTime === null) basicApprovalDateTimeError.value = true;
    // else basicApprovalDateTimeError.value = false;
  }
  if (basicPayment.value.typeCode === '01004') {
    // if (basicPayment.value.desc.length < 1 || basicPayment.value.desc === null) basicDescError.value = true;
    // else basicDescError.value = false;
    // if (basicPayment.value.amount.length < 1 || basicPayment.value.amount === null) basicAmountError.value = true;
    // else basicAmountError.value = false;
    // if (basicPayment.value.approvalDateTime.length < 1 || basicPayment.value.approvalDateTime === null) basicApprovalDateTimeError.value = true;
    // else basicApprovalDateTimeError.value = false;
  }
  if (optionPayment.value.typeCode === '01003') {
    // if (optionPayment.value.desc.length < 1 || optionPayment.value.desc === null) optionDescError.value = true;
    // else optionDescError.value = false;
    // if (optionPayment.value.amount.length < 1 || optionPayment.value.amount === null) optionAmountError.value = true;
    // else optionAmountError.value = false;
    // if (optionPayment.value.cardCompany.length < 1 || optionPayment.value.cardCompany === null) optionCardCompanyError.value = true;
    // else optionCardCompanyError.value = false;
    // if (optionPayment.value.cardNo.length < 1 || optionPayment.value.cardNo === null) optionCardNoError.value = true;
    // else optionCardNoError.value = false;
    // if (optionPayment.value.cardValidPeriod.length < 1 || optionPayment.value.cardValidPeriod === null) optionCardValidPeriodError.value = true;
    // else optionCardValidPeriodError.value = false;
    // if (optionPayment.value.txApprovalNo.length < 1 || optionPayment.value.txApprovalNo === null) optionTxApprovalNoError.value = true;
    // else optionTxApprovalNoError.value = false;
    // if (optionPayment.value.approvalDateTime.length < 1 || optionPayment.value.approvalDateTime === null) optionApprovalDateTimeError.value = true;
    // else optionApprovalDateTimeError.value = false;
  }
  if (optionPayment.value.typeCode === '01004') {
    // if (optionPayment.value.desc.length < 1 || optionPayment.value.desc === null) optionDescError.value = true;
    // else optionDescError.value = false;
    // if (optionPayment.value.amount.length < 1 || optionPayment.value.amount === null) optionAmountError.value = true;
    // else optionAmountError.value = false;
    // if (optionPayment.value.approvalDateTime.length < 1 || optionPayment.value.approvalDateTime === null) optionApprovalDateTimeError.value = true;
    // else optionApprovalDateTimeError.value = false;
  }
  if (removalPayment.value.typeCode === '01003') {
    // if (removalPayment.value.desc.length < 1 || removalPayment.value.desc === null) removalDescError.value = true;
    // else removalDescError.value = false;
    // if (removalPayment.value.amount.length < 1 || removalPayment.value.amount === null) removalAmountError.value = true;
    // else removalAmountError.value = false;
    // if (removalPayment.value.cardCompany.length < 1 || removalPayment.value.cardCompany === null) removalCardCompanyError.value = true;
    // else removalCardCompanyError.value = false;
    // if (removalPayment.value.cardNo.length < 1 || removalPayment.value.cardNo === null) removalCardNoError.value = true;
    // else removalCardNoError.value = false;
    // if (removalPayment.value.cardValidPeriod.length < 1 || removalPayment.value.cardValidPeriod === null) removalCardValidPeriodError.value = true;
    // else removalCardValidPeriodError.value = false;
    // if (removalPayment.value.txApprovalNo.length < 1 || removalPayment.value.txApprovalNo === null) removalTxApprovalNoError.value = true;
    // else removalTxApprovalNoError.value = false;
    // if (removalPayment.value.approvalDateTime.length < 1 || removalPayment.value.approvalDateTime === null) removalApprovalDateTimeError.value = true;
    // else removalApprovalDateTimeError.value = false;
  }
  if (removalPayment.value.typeCode === '01004') {
    // if (removalPayment.value.desc.length < 1 || removalPayment.value.desc === null) removalDescError.value = true;
    // else removalDescError.value = false;
    // if (removalPayment.value.amount.length < 1 || removalPayment.value.amount === null) removalAmountError.value = true;
    // else removalAmountError.value = false;
    // if (removalPayment.value.approvalDateTime.length < 1 || removalPayment.value.approvalDateTime === null) removalApprovalDateTimeError.value = true;
    // else removalApprovalDateTimeError.value = false;
  }

  await updateOrder();
  if (providerIdError.value ||
      prodTypeIdError.value ||
      productIdError.value ||
      customerNameError.value ||
      primaryPhoneNoError.value ||
      customerAddr1Error.value ||
      vhcCatCodeError.value ||
      vhcTypeNameError.value ||
      // basicDescError.value ||
      // basicAmountError.value ||
      // basicCardCompanyError.value ||
      // basicCardNoError.value ||
      // basicCardValidPeriodError.value ||
      // basicTxApprovalNoError.value ||
      // basicApprovalDateTimeError.value ||
      // optionDescError.value ||
      // optionAmountError.value ||
      // optionCardCompanyError.value ||
      // optionCardNoError.value ||
      // optionCardValidPeriodError.value ||
      // optionTxApprovalNoError.value ||
      // optionApprovalDateTimeError.value ||
      // removalDescError.value ||
      // removalAmountError.value ||
      // removalCardCompanyError.value ||
      // removalCardNoError.value ||
      // removalCardValidPeriodError.value ||
      // removalTxApprovalNoError.value ||
      // removalApprovalDateTimeError.value ||
      inboundCodeError.value ||
      typeCodeError.value) console.log();
  else {
    await emit('updateOrder');
    await modalClose();
  }
}

const getLoadOrderData = async () => {
  await modalClose();
  await router.push({name:'ASRegister', query: { id: orderId.value }});
  // await loadOrder(orderId.value);
  // setTimeout(async () => {
  //   branchId.value = await '';
  // }, 200);
}

watch(providerId, async (newVal, oldVal) => {
  if (newVal) {
    prodTypeId.value = '';
    productId.value = '';
    await loadSelectProdTypeList();
    if (loadProdTypeId.value) prodTypeId.value = await loadProdTypeId.value;
  }
  else if (oldVal && !newVal) {
    prodTypeId.value = '';
    productId.value = '';
    selectProdType.value = [];
    selectProductList.value = [];
  }
})

watch(prodTypeId, async (newVal, oldVal) => {
  if (newVal) {
    productId.value = '';
    await loadSelectProductList();
    if (loadProductId.value) productId.value = await loadProductId.value;
  }
  else if (oldVal && !newVal) {
    productId.value = '';
    selectProductList.value = [];
  }
})
watch(loadProductId, async (newVal, oldVal) => {
  if (newVal) {
    await loadProduct(productId.value);
  }
  else if (oldVal && !newVal) {
    productId.value = '';
    selectProductList.value = [];
  }
})
watch(loadProviderId, async (newVal, oldVal) => {
  if (newVal) {
    providerId.value = await loadProviderId.value
  }
})
watch(loadProdTypeId, async (newVal, oldVal) => {
  if (newVal) {
    prodTypeId.value = await loadProdTypeId.value;
  }
})

watch(branchId, async (newVal, oldVal) => {
  if (newVal) {
    engineerId.value = '';
    loadSelectEngineerList();
    if (loadEngineerId.value) engineerId.value = await loadEngineerId.value;
  }
  else if (oldVal && !newVal) {
    engineerId.value = '';
    selectEngineerList.value = [];
  }
})

const handleMemo = (e) => {
  memo.value = e.target.textContent;
}
const handleEngineerMemo = (e) => {
  engineerMemo.value = e.target.textContent;
}
const focusMemo = () => {
  const memoRef = document.getElementById(`addModalMemo${orderId.value}`)
  memoRef.focus();
}
const focusEngMemo = () => {
  const memoRef = document.getElementById(`addModalEngineerMemo${orderId.value}`)
  memoRef.focus();
}
const happyCall = () => {
  new Swal({
    title: '해피콜 요청이 완료되었습니다.'
  });
}
const changeOrder = () => {
  new Swal({
    title: '수정이 완료되었습니다.'
  });
}

// const reqHappycall = async () => {
//   if (confirm("해피콜을 요청하시겠습니까?")) await sendHappycall();
//   else return;
//   await loadOrder(orderId.value)
// }
const reqHappycall = async () => {
  const confirmReq = await new Swal({
    input: "textarea",
    inputLabel: "해피콜 요청",
    inputPlaceholder: "요청사항 입력",
    inputAttributes: {
      "aria-label": "해피콜 요청"
    },
    showCancelButton: true,
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    },
  });
  if (confirmReq.isConfirmed) {
    await sendHappycall(confirmReq.value, orderId.value);
    if (searchType.value === 'order') await loadOrder(orderId.value);
    else if (isClaim || searchType.value === 'claim') await loadClaim(orderId.value);
    else if (!(searchType.value === 'order' || isClaim)) await loadAs(orderId.value);
  }
}
const copyBasicInfo = async () => {
  const html = `
    <div style="text-align:left;">
      <div>고객 : ${customerName.value}</div>
      <div>전화 : ${primaryPhoneNo.value}</div>
      <div>주소 : ${customerAddr1.value ? customerAddr1.value : ''} ${customerAddr2.value ? customerAddr2.value : ''}${extraAddr.value ? extraAddr.value : ''} </div>
    </div>
    `;
  const confirmReq = await new Swal({
    html,
    input: "textarea",
    inputPlaceholder: "메모 입력",
    inputAttributes: {
      "aria-label": "기본정보 복사"
    },
    showCancelButton: true,
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    },
    confirmButtonText: '복사',
    cancelButtonText: '취소',
  });
  if (confirmReq.isConfirmed) {
    const copyHtml = `
      <div style="text-align:left;">
        <div>고객 : ${customerName.value}</div>
        <div>전화 : ${primaryPhoneNo.value}</div>
        <div>주소 : ${customerAddr1.value ? customerAddr1.value : ''} ${customerAddr2.value ? customerAddr2.value : ''}${extraAddr.value ? extraAddr.value : ''} </div>
        ${confirmReq.value ? '<div>메모 : ' + confirmReq.value + '</div>' : ''}
      </div>
    `;
    const blob = new Blob([copyHtml], { type: 'text/html' });
    const textInput = new ClipboardItem({ 'text/html': blob });
    navigator.clipboard.write([textInput]);
    await new Swal({
      title: '복사 완료',
      showClass: {
        backdrop: 'swal2-noanimation', // disable backdrop animation
        popup: '',                     // disable popup animation
        icon: ''                       // disable icon animation
      },
      hideClass: {
        popup: '',                     // disable popup fade-out animation
      },
    });
  }
}
</script>
<style lang="sass">
</style>