<template>
  <div>
    <Breadcrumbs title="세금계산서 목록" main="세금계산서"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h5>세금계산서 목록</h5>
              <div class="p-1 m-t-5 d-flex align-items-center btnBox" style="border: solid rgba(175, 173, 173, 0.29) 1px">
                <div class="pe-1">
                  <Datepicker week-start="0" :day-class="isWeekendClass" class="datepicker-here datepicker-multi digits b-r-0 mr-1" :multi="true" format="MM-dd-yyyy" v-model="date" range locale="ko">
                  </Datepicker>
                </div>
                <div class="pe-1 d-flex justify-content-center">
                  <select v-model="filter1Type" class="form-select btn-square digits">
                    <option value="All">전체</option>
                    <option value="Product">상품명</option>
                    <option value="Customer">고객명</option>
                    <option value="PhoneNo">연락처</option>
                    <!-- <option value="TotalAmount">금액</option> -->
                    <option value="ApprovalDateTime">발행일자</option>
                  </select>
                </div>
                <div class="pe-1 d-flex justify-content-center">
                  <input v-model="filter1Val" @input="handleFilter1Val" @keypress.enter="searchSettle(true)" class="form-control btn-square w-100" type="text" placeholder="">
                </div>
                <div class="pe-1 d-flex justify-content-center">
                  <select v-model="filter2Type" class="form-select btn-square digits">
                    <option value="All">전체</option>
                    <option value="Product">상품명</option>
                    <option value="Customer">고객명</option>
                    <option value="PhoneNo">연락처</option>
                    <!-- <option value="TotalAmount">금액</option> -->
                    <option value="ApprovalDateTime">발행일자</option>
                  </select>     
                </div>
                <div class="pe-1 d-flex justify-content-center">
                  <input v-model="filter2Val" @input="handleFilter2Val" @keypress.enter="searchSettle(true)" class="form-control btn-square w-100" type="text" placeholder="">
                </div>
                <div class="pe-1 d-flex justify-content-center">
                  <select v-model="statCode" class="form-select btn-square digits">
                    <option value=''>진행상황 검색</option>
                    <option v-for="item in progressListRef" :value="item?.code" :key="item?.code">{{ item?.name }}</option>
                  </select>
                </div>                
                <div class="pe-1 d-flex justify-content-center">
                  <button @click="searchSettle(true)" class="btn btn-square btn-primary w-100" type="button" title="btn btn-outline-light btn-sm">검색</button>
                </div>
                <div class="pe-1 d-flex justify-content-center">
                  <button @click="excelDownload" class="btn btn-square btn-success w-100" type="button" title="btn btn-outline-light btn-sm" :disabled="isExcelDown">{{ isExcelDown ? '다운로드 중..' : '다운로드' }}</button>
                </div>
                <div class="pe-1 d-flex justify-content-center">
                  <button @click="initSearchSettle" class="btn btn-square btn-outline-light txt-dark" type="button" title="btn btn-outline-light btn-sm"><i class="fa fa-refresh me-1" aria-hidden="true"></i>검색초기화</button>
                </div>                
              </div>
            </div>
            <div class="card-body">
              <div>
                <div class="d-flex justify-content-between">
                </div>
              </div>
              <div class="table-responsive mt-3">
                <EasyDataTable ref="dataTable" v-model:server-options="serverOptions" v-model:items-selected="itemsSelected" :headers="Headers" :rowsPerPage="10" :items="settleList" hide-footer header-text-direction="center"
                  border-cell body-text-direction="center">
                  <template #item-productName="{ productName }">
                     <div style="width:100%;text-align:left !important;"><span>{{ productName }}</span></div>
                  </template>                  
                  <template #item-customer="{ customerName, id, orderTypeCode }">
                    <a href="/" style="color:#1071BC" data-bs-toggle="modal" :data-bs-target="`#${orderTypeCode === '00010' ? 'order' : 'as'}InfoDetail${id}`">{{ customerName }}</a>
                    <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" data-bs-focus="false" :id="`${orderTypeCode === '00010' ? 'order' : 'as'}InfoDetail${id}`" tabindex="-1" role="dialog" :aria-labelledby="`${orderTypeCode === '00010' ? 'order' : 'as'}InfoDetail${id}`"
                    aria-hidden="true">
                      <InfoDetailModal :orderId="id" :isRead="true" :searchType="orderTypeCode === '00010' ? 'order' : orderTypeCode === '00011' ? 'claim' : 'as'" />
                    </div>
                  </template>
                  <template #item-customerPhone="{ customerPhoneNo1 }">
                    <span>{{ conversionPhoneNo(customerPhoneNo1) }}</span>
                  </template>
                  <template #item-customerAddr="{address}">
                    <div style="width:100%;text-align:left !important;">
                      <span>{{ address }}</span>
                    </div>
                  </template>
                  <template #item-engineerName="{ engineerId, engineerName }">
                    <span style="color:#1071BC;cursor:pointer;" @click="openEngineerCertPic(engineerId)">{{ engineerName }}</span>
                  </template>                  
                  <template #item-orderType="{orderTypeCode}">
                    <div class="d-flex justify-content-center">
                      <span>{{ orderTypeCode === '00010' ? '주문' : orderTypeCode === '00011' ? '클레임' : 'A/S' }}</span>
                    </div>
                  </template>
                  <template #item-progress="{ statName, id, orderTypeCode }">
                    <a href="/" :style="`color:${statName === '완료' ? 'red' : '#1071BC'}`" data-bs-toggle="modal" :data-bs-target="`#${orderTypeCode === '00010' ? 'order' : 'as'}Progress${id}`">{{ statName }}</a>
                    <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" :id="`${orderTypeCode === '00010' ? 'order' : 'as'}Progress${id}`" tabindex="-1" role="dialog" :aria-labelledby="`${orderTypeCode === '00010' ? 'order' : 'as'}Progress${id}`"
                      aria-hidden="true">
                      <ProgressModal :orderId="id" :searchType="orderTypeCode === '00010' ? 'order' : orderTypeCode === '00011' ? 'claim' : 'as'"/>
                    </div>
                  </template>
                  <template #item-desc="{ taxInvoiceInfo }">
                    <span>{{ taxInvoiceInfo?.desc }}</span>
                  </template>
                  <template #item-amount="{ taxInvoiceTotalAmount }">
                    <span>{{ taxInvoiceTotalAmount ? comma(uncomma(taxInvoiceTotalAmount)) : 0 }}</span>
                  </template>
                  <template #item-approvalDateTime="{ taxInvoiceInfo }">
                    <span>{{ taxInvoiceInfo?.approvalDateTime }}</span>
                  </template>
                  <template #item-remark="{ taxInvoiceInfo }">
                    <span>{{ changeRemark(taxInvoiceInfo?.remark) }}</span>
                  </template>
                  <template #item-detailed="{ id }">
                    <router-link :to="{name: 'orderModify', params:{id}, query:{size, pno, startDateTime: queryObj.startDateTime, endDateTime: queryObj.endDateTime, filter1: queryObj.filter1, filter2: queryObj.filter2, name: 'taxList'}}" style="color:#1071BC">조회</router-link>
                  </template>
                  <template #item-input="{id, orderTypeCode, taxInvoiceInfo }">
                    <a href="/" style="color:#1071BC" data-bs-toggle="modal" :data-bs-target="`#tax${id}`">입력</a>
                    <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" :id="`tax${id}`" tabindex="-1" role="dialog" :aria-labelledby="`tax${id}`"
                      aria-hidden="true">
                      <TaxModal @list-tax="setTimeOutSearchSettle" :orderId="id" :taxInvoiceInfo="taxInvoiceInfo" :searchType="orderTypeCode === '00010' ? 'order' : orderTypeCode === '00011' ? 'claim' : 'as'"/>
                    </div>
                  </template>
                </EasyDataTable>
                <div class="customize-footer">
                  <div class="customize-rows-per-page mt-1">
                    <select
                      class="select-items"
                      @change="updateRowsPerPageSelect"
                    >
                      <option
                        v-for="item in rowsPerPageOpt"
                        :key="item"
                        :selected="item === rowsPerPageActiveOption"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div class="customize-index">
                    <nav class="m-b-30" aria-label="">
                      <ul class="pagination justify-content-center pagination-primary">

                        <li class="page-item prev-page" @click="firstPage" :disabled="isFirstPage"><a class="page-link" href="javascript:void(0)">처음</a></li>
                        <li class="page-item" @click="prevPage" :disabled="isFirstPage"><a class="page-link" href="javascript:void(0)">이전</a></li>
                        <li v-for="paginationNumber in pageList"
                          class="page-item customize-button"
                          :class="{ 'active': paginationNumber === pageNo + 1 }"
                          @click="updatePage(paginationNumber)"
                        >
                          <a class="page-link" href="javascript:void(0)">{{ paginationNumber }}</a>
                        </li>
                        <li class="page-item" @click="nextPage" :disabled="isLastPage"><a class="page-link" href="javascript:void(0)">다음</a></li>
                        <li class="page-item next-page" @click="lastPage" :disabled="isLastPage"><a class="page-link" href="javascript:void(0)">끝</a></li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Swal from 'sweetalert2';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { ref, onMounted, computed, watch } from 'vue';
import InfoDetailModal from '@/components/list/InfoDetailModal.vue'
import TaxModal from '@/components/list/TaxModal.vue'
import ProgressModal from '@/components/list/ProgressModal.vue'
import InstallConfirmModal from '@/components/list/InstallConfirmModal.vue'
import { RouterLink, useRouter, useRoute } from 'vue-router'
import { useLoginAuth } from '@/stores/LoginAuthStore'
import useSettleManagement from '@/composables/settleManagement'
import useOrderRegistry from '@/composables/registerOrder'
import commonApi from '@/composables/commonApi'
import { taxListHeader } from '@/constants/ListHeader'
const router = useRouter()
const route = useRoute()
const LoginAuthStore = useLoginAuth(router)
const { loadCommonCode, loadResource, picture } = commonApi(LoginAuthStore);
const { settleList, pageInfo, listSettleForCardTx, completeSettleList, completeSettleAllList, incompleteSettleCount, incompleteCount, ccardtxinvoiceExcelDownload, ccardtxinvoiceExcelDownloadSelected } = useSettleManagement(LoginAuthStore);
const { loadEngineer, certPicUri } = useOrderRegistry(LoginAuthStore);
const pageNo = computed(() => pageInfo.value?.pageNo);
const totalCount = computed(() => pageInfo.value?.totalCount);
const totalPages = computed(() => pageInfo.value?.totalPages);
const pageList = ref([])
const filter1Type = ref('All')
const filter2Type = ref('All')
const filter1Val = ref('')
const filter2Val = ref('')
const pno = ref(0)
const size = ref(25);
const pageNum = 10;
const paymentType = ref('TaxInvoice')
const statCode = ref('');
const queryObj = ref({startDateTime: '', endDateTime: '', filter1: filter1Type.value, filter2: filter2Type.value, statCode: statCode.value, rrp: size.value, pno: pno.value, paymentType: paymentType.value});
const date = ref([]);
const progressListRef = ref([])
const isSearchClick = ref(false);
const isExcelDown = ref(false);
const currentPageListStart = () => {
  return Math.floor(pno.value / pageNum) * pageNum + 1
}
const paging = async () => {
  //보여지는 페이지 리셋
  pageList.value = [];

  //현재페이지 기준으로 페이징 숫자 넣기
  let pageListStart = await currentPageListStart()
  for (let i = 1; i < pageNum + 1; i++) {  
    if (totalPages.value >= pageListStart){
      pageList.value.push(pageListStart)
      pageListStart ++;
    }
  }
}

const searchSettle = async (click) => {
  if (click) isSearchClick.value = true;
  if (isSearchClick.value) {
    pno.value = 0
    queryObj.value.filter1 = filter1Val.value ? `${filter1Type.value}:${filter1Val.value}` : 'All';
    queryObj.value.filter2 = filter2Val.value ? `${filter2Type.value}:${filter2Val.value}` : 'All';
    queryObj.value.startDateTime = date.value ? dateFormatString(date?.value[0]) : null
    queryObj.value.endDateTime = date.value ? dateFormatString(date?.value[1]) : null
    queryObj.value.statCode = statCode.value
  }
  queryObj.value.rrp = size.value
  queryObj.value.pno = pno.value
  await listSettleForCardTx(queryObj.value)
  isSearchClick.value = false;
  setTimeout(async () => {
    await paging()
  }, 1000);
}
const comma = (val) => {
  val = String(val);
  return val.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
}
const uncomma = (val) => {
  val = String(val);
  return val.replace(/[^\d]+/g, '');
}
const itemsSelected = ref([]);
const excelDownload = async () => {
  if (itemsSelected.value.length === 0 
    && !(filter1Val.value || filter2Val.value || statCode.value
    || (date?.value !== null && (date?.value[0] || date?.value[1])))) {
    return alert('엑셀 다운로드 시 조건(검색어를 포함한 검색, 체크박스 또는 기간)을 선택해 주세요.');
  }    new Swal({
    title: "엑셀 다운로드 하시겠습니까?",
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    },
    showConfirmButton: true, // "확인" 버튼 표시 안 함
    showCancelButton: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      isExcelDown.value = true;
      if (itemsSelected.value.length) {
        const selectedIdList = itemsSelected.value.map(item => item.id);
        await ccardtxinvoiceExcelDownloadSelected(selectedIdList, isExcelDown);
      } else {
        queryObj.value.filter1 = filter1Val.value ? `${filter1Type.value}:${filter1Val.value}` : 'All';
        queryObj.value.filter2 = filter2Val.value ? `${filter2Type.value}:${filter2Val.value}` : 'All';
        queryObj.value.startDateTime = date.value ? dateFormatString(date?.value[0]) : null
        queryObj.value.endDateTime = date.value ? dateFormatString(date?.value[1]) : null
        queryObj.value.statCode = statCode.value
        await ccardtxinvoiceExcelDownload(queryObj.value, isExcelDown)
      }
    }
  })
}

onMounted(async () => {
  // const endDate = new Date();
  // const startDate = new Date(new Date().setDate(endDate.getDate() - 7));
  // date.value = [startDate, endDate];
  // 진행상황
  loadCommonCode('00004').then(res => res.json()).then(res => progressListRef.value = res.payload);
  // loadSelectBranchList();
  await searchSettle();
  await incompleteSettleCount(queryObj.value, 'TaxInvoice');
  // getTodayReceiptCount();
})

const setTimeOutSearchSettle = () => {
  setTimeout(() => {
    searchSettle();
  }, 500);
}

const changeSearchSettle = async (type) => {
  searchSettle();
}
const initSearchSettle = async () => {
  filter1Type.value = 'All';
  filter2Type.value = 'All';
  filter1Val.value = '';
  filter2Val.value = '';
  pno.value = 0;
  date.value = null;
  queryObj.value = {startDateTime: '', endDateTime: '', filter1: filter1Type.value, filter2: filter2Type.value, rrp: size.value, pno: pno.value, paymentType: paymentType.value};
  searchSettle();
}
// $ref dataTable
const dataTable = ref();

// index related
const currentPageFirstIndex = computed(() => dataTable.value?.currentPageFirstIndex);
const currentPageLastIndex = computed(() => dataTable.value?.currentPageLastIndex);
const clientItemsLength = computed(() => dataTable.value?.clientItemsLength);

const isFirstPage = computed(() => dataTable.value?.isFirstPage);
const isLastPage = computed(() => dataTable.value?.isLastPage);

const updatePage = async (paginationNumber) => {
  pno.value = paginationNumber - 1;
  await searchSettle();
};
const lastPage = () => {
  updatePage(totalPages.value);
};
const firstPage = () => {
  updatePage(1);
};
const prevPage = () => {
  if (pno.value >= 1) updatePage(pno.value);
};
const nextPage = () => {
  if (pno.value + 2 <= totalPages.value) updatePage(pno.value + 2);
};

// rows per page related
const rowsPerPageOpt = [10, 25, 50, 100];
// const rowsPerPageOptions = computed(() => dataTable.value?.rowsPerPageOptions);
const rowsPerPageActiveOption = computed(() => dataTable.value?.rowsPerPageActiveOption);
const updateRowsPerPageSelect = async (e) => {
  size.value = Number(e.target.value);
  dataTable.value.updateRowsPerPageActiveOption(Number(e.target.value));
  await updatePage(1);
  await searchSettle()
};

const changeRemark = (rm) => {
  if (rm && rm.length > 20) return rm.slice(0, 20) + "...";
  return rm
}

const pic = ref('')
const openEngineerCertPic = async (engId) => {
  await loadEngineer(engId)
}
const openPicture = (url) => {
  new Swal({
    imageUrl: url,
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    },
    showConfirmButton: false, // "확인" 버튼 표시 안 함
    showCancelButton: true,
  }).then(() => {
    certPicUri.value = '';
  });
}

const leftPad = (val) => {
  if (val >= 10) return val;
  return `0${val}`;
}
const dateFormatString = (date) => {
  if (date) {
    const year = date.getFullYear()
    const month = leftPad(date.getMonth() + 1)
    const day = leftPad(date.getDate())
  
    return `${[month, day, year].join('/')}`
  }
}

const conversionPhoneNo = (phoneNo) => {
  const phone = phoneNo.replace(/-/g, "");
  if (phone.length === 12) {
    return phone.replace(/^(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3`);
  } else {
    return phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }
}

const handleFilter1Val = (e) => {
  if (filter1Type.value === 'BasicType' || filter1Type.value === 'OptionType' || filter1Type.value === 'UinstallType') {
    filter1Val.value = e.target.value.replace(/[^0-9]/g, '');
  } else filter1Val.value = e.target.value
}
const handleFilter2Val = (e) => {
  if (filter2Type.value === 'BasicType' || filter2Type.value === 'OptionType' || filter2Type.value === 'UinstallType') {
    filter2Val.value = e.target.value.replace(/[^0-9]/g, '');
  } else filter2Val.value = e.target.value
}

watch(filter1Type, (newVal, oldVal) => {
  if (newVal) filter1Val.value = '';
})
watch(filter2Type, (newVal, oldVal) => {
  if (newVal) filter2Val.value = '';
})

watch(certPicUri, async (newVal, oldVal) => {
  if (certPicUri.value) await loadResource(certPicUri.value)
})
watch(picture, (newVal, oldVal) => {
  picture.value.blob().then(blob => {
    pic.value = URL.createObjectURL(blob)
    return openPicture(pic.value)
  })
})

const serverOptions = ref({
  page: Number(pno.value),
  rowsPerPage: Number(size.value),
})

const Headers = ref(taxListHeader);
const isWeekendClass = (date) => {
  if (date.getDay() === 0) return 'sunColor'
  if (date.getDay() === 6) return 'satColor'
  return
}
</script>