import { ref } from 'vue'
import { trim, replacePath } from '@/util/Util'
import apiURL from '@/bis/data/registerProductApi'
const useProductRegistry = (LoginAuthStore, id) => {

  const selectCompListRef = ref([]);
  const selectCompProductListRef = ref([]);
  const errorRef = ref({});
  const productListRef = ref([]);
  const pageInfoRef = ref({});
  const datetimeRef = ref('');
  const prodIdRef = ref('');
  const nameRef = ref('');
  const categoryIdRef = ref('');
  const installProviderPriceRef = ref('');
  const installBranchPriceRef = ref('');
  const asProviderPriceRef = ref('');
  const asBranchPriceRef = ref('');
  const installDescriptionRef = ref('');
  const asDescriptionRef = ref('');
  const memoRef = ref('');
  const loadMemoRef = ref('');
  const manualUriRef = ref('');
  const providerIdRef = ref('');
  const productTypeRef = ref('Install');
  const exceptionalPriceListRef = ref({});
  const uploadFileRef = ref();

  const headersMap = {
    'Content-Type': 'application/json',
    'X-AUTH-TOKEN': LoginAuthStore.accessToken
  }

  const clearAllFields = () => {
    providerIdRef.value = '';
    categoryIdRef.value = '';
    nameRef.value = '';
    productTypeRef.value = 'Install';
    installProviderPriceRef.value = '';
    installBranchPriceRef.value = '';
    installDescriptionRef.value = '';
    asProviderPriceRef.value = '';
    asBranchPriceRef.value = '';
    asDescriptionRef.value = '';
    memoRef.value = '';
    loadMemoRef.value = '';
    exceptionalPriceListRef.value = {};
    uploadFileRef.value = '';
  }
  const comma = (val) => {
    val = String(val);
    return val.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }
  const uncomma = (val) => {
    val = String(val);
    return val.replace(/[^\d]+/g, '');
  }
  const stringNumberToInt = (stringNumber) => {
    return parseInt(String(stringNumber).replace(/,/g , ''));
  }

  const loadSelectCompList = async () => {
    fetch(`${apiURL.baseUri}${apiURL.getSelectCompList.api}`,
    {
      method: apiURL.getSelectCompList.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        const payload = res.payload;
        if(!payload) payload = [];
        selectCompListRef.value = payload;
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    })
  }
  const loadSelectCompProductList = async (isUseNav, queryObj) => {
    let query = '';
    if (isUseNav) query = `?useNav=${isUseNav}&pno=${queryObj.value.pno}`;
    fetch(`${apiURL.baseUri}${apiURL.getSelectCompProductList.api}${query}`,
    {
      method: apiURL.getSelectCompProductList.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        const payload = res.payload;
        if(!payload) payload = [];
        selectCompProductListRef.value = payload;
        if (isUseNav) pageInfoRef.value = res.pageInfo;
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    })
  }

  const listProduct = async(queryObj) => {
    let query = `?rrp=${queryObj.rrp}&pno=${queryObj.pno}`
    const filter = !queryObj.filter ? '': '&filter=' + trim(queryObj.filter)
    query = query + filter
    console.log(query)
    fetch(`${apiURL.baseUri}${apiURL.listProduct.api}${query}`,
    {
      method: apiURL.listProduct.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
        console.log(res)
        if(res.success) {
          productListRef.value = !res.payload ? []: res.payload;
          pageInfoRef.value = res?.pageInfo;
        } else {
          errorRef.value.message = res.error.msg
          LoginAuthStore.redirectInvalidSession(res)
        }
    }).catch(err => errorRef.value.message = err.message)
  }

  const loadProduct = () => {
    const url = replacePath(apiURL.loadProduct.api, id)
    fetch(`${apiURL.baseUri}${url}`,
    {
      method: apiURL.loadProduct.method,
      headers: headersMap
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        console.log(res)
        const payload = res.payload;
        datetimeRef.value = payload.createdDateTime
        prodIdRef.value = payload.id
        nameRef.value = payload.name
        categoryIdRef.value = payload.categoryId
        installProviderPriceRef.value = comma(uncomma(payload.installProviderPrice))
        installBranchPriceRef.value =  comma(uncomma(payload.installBranchPrice))
        asProviderPriceRef.value =  comma(uncomma(payload.asProviderPrice))
        asBranchPriceRef.value =  comma(uncomma(payload.asBranchPrice))
        installDescriptionRef.value = payload.installDescription     
        asDescriptionRef.value = payload.asDescription
        loadMemoRef.value = payload.memo
        manualUriRef.value = payload.manualUri
        providerIdRef.value = payload.providerId
        productTypeRef.value = payload.productType
        payload.exceptionalBranchPriceList.installPriceList.forEach(element => {
          element.price = comma(uncomma(element.price));
        });
        payload.exceptionalBranchPriceList.asPriceList.forEach(element => {
          element.price = comma(uncomma(element.price));
        });
        exceptionalPriceListRef.value = payload.exceptionalBranchPriceList
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
        errorRef.value.message = err.message
    })
  }

  const saveSelectCompProduct = async (saveProduct) => {
    const createMode = !saveProduct.id
    let apiObj = createMode
    ? apiURL.saveSelectCompProduct
    : { api: replacePath(apiURL.updateSelectCompProduct.api, saveProduct.id), method: apiURL.updateSelectCompProduct.method } 
    fetch(`${apiURL.baseUri}${apiObj.api}`, {
      method: apiObj.method,
      headers: headersMap,
      body: JSON.stringify(saveProduct)
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        console.log(res, "res");
        alert('저장되었습니다.')
        clearAllFields();
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
      errorRef.value.message = err.message
    })
  }

  const deleteSelectCompProduct = async (id) => {
    const url = replacePath(apiURL.deleteSelectCompProduct.api, id)
      fetch(`${apiURL.baseUri}${url}`, {
      method: apiURL.deleteSelectCompProduct.method,
      headers: headersMap,
      body: JSON.stringify(id)
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        console.log(res, "res");
        alert('삭제하였습니다.')
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
        errorRef.value.message = err.message
    })   
  }
  const saveProduct = async (exceptionalBranchPriceList, id) => {
    // 업체명
    const providerId = providerIdRef.value

    // 상품분류
    const categoryId = categoryIdRef.value

    // 상품명
    const name = trim(nameRef.value)

    // 구분
    const productType = productTypeRef.value

    // 장착업체단가
    const installProviderPrice = stringNumberToInt(installProviderPriceRef.value)

    // 장착 지점기준단가
    const installBranchPrice = stringNumberToInt(installBranchPriceRef.value)

    // 장착 표시 문구
    const installDescription = installDescriptionRef.value

    // AS 업체단가
    const asProviderPrice = stringNumberToInt(asProviderPriceRef.value)

    // AS 지점기준단가
    const asBranchPrice = stringNumberToInt(asBranchPriceRef.value)

    // AS 표시 문구
    const asDescription = asDescriptionRef.value

    // 상품 메모
    const memo = memoRef.value
    // 상품 매뉴얼 업로드

    exceptionalBranchPriceList.installPriceList.forEach(element => {
      element.price = stringNumberToInt(element.price);
    });
    exceptionalBranchPriceList.asPriceList.forEach(element => {
      element.price = stringNumberToInt(element.price);
    });
    
    const createMode = !id

    errorRef.value = {}
    if(!providerId) errorRef.value.providerId = '업체를 선택하세요'
    if(!categoryId) errorRef.value.categoryId = '상품 분류를 선택하세요'
    if(!name) errorRef.value.name = '상품명을 입력하세요'
    const error = errorRef.value;
    if(error.providerId || error.categoryId || error.name) {
        console.log('errorRef', error)
        return
    }
    let apiObj = createMode ? 
      apiURL.saveProduct : {api: replacePath(apiURL.updateProduct.api, id), method: apiURL.updateProduct.method}
    const requestBody = {
      providerId,
      categoryId,
      productType,
      name,
      installProviderPrice,
      installBranchPrice,
      installDescription,
      asProviderPrice,
      asBranchPrice,
      asDescription,
      memo,
      exceptionalBranchPriceList
    }
    const uploadFile = uploadFileRef.value
    fetch(`${apiURL.baseUri}${apiObj.api}`,
    {
      method: apiObj.method,
      headers: headersMap,
      body: JSON.stringify(requestBody)
    }).then(res => res.json())
    .then(async res => {
      if(res.success) {
        if (uploadFile) await uploadManual(res.payload);
        alert(createMode? '상품을 생성하였습니다' : '상품을 수정하였습니다')
        LoginAuthStore.getRouter.push({name:'BISProductList'})
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
      errorRef.value.message = err.message
    }).finally(() => {
      clearAllFields();
    })
  }

  const deleteProduct = async (id) => {
    const url = replacePath(apiURL.deleteProduct.api, id)
      fetch(`${apiURL.baseUri}${url}`, {
      method: apiURL.deleteProduct.method,
      headers: headersMap,
      body: JSON.stringify(id)
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        console.log(res, "res");
        clearAllFields();
        alert('상품을 삭제하였습니다.')

      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
        errorRef.value.message = err.message
    })  
  }
  const deleteProductList = async (ids) => {
    const url = apiURL.deleteProductList.api
      fetch(`${apiURL.baseUri}${url}`, {
      method: apiURL.deleteProductList.method,
      headers: headersMap,
      body: JSON.stringify(ids)
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        console.log(res, "res");
        clearAllFields();
        // alert('상품을 삭제하였습니다.')

      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
        errorRef.value.message = err.message
    })  
  }

  const uploadManual = async (id) => {
    const providerId = providerIdRef.value
    const uploadFile = await uploadFileRef.value
    const formData = new FormData();
    formData.append("file", uploadFile);
    const headers = {
      'X-AUTH-TOKEN': LoginAuthStore.accessToken,
    }
    await fetch(`${apiURL.baseUri}${replacePath(apiURL.uploadManualFile.api, [providerId, id])}`,
    {
      method: apiURL.uploadManualFile.method,
      headers,
      body: formData
    }).then(res => res.json())
    .then(res => {
      if(res.success) {
        console.log(res, "payload upload");
        const payload = res.payload;
        if(!payload) payload = [];
      } else {
        errorRef.value.message = res.error.msg
        LoginAuthStore.redirectInvalidSession(res)
      }
    }).catch(err => {
      console.error(err);
      errorRef.value.message = err.message
    })
  }
  const downloadManual = async () => {
    const manualUri = manualUriRef.value
    let query = `?uri=${manualUri}`
    await fetch(`${apiURL.baseUri}${apiURL.downloadManualFile.api}${query}`,
    {
      method: apiURL.downloadManualFile.method,
      headers: headersMap,
    }).then(async res => {
      console.log(res.body, "download res")
      const blob = await res.blob();

      const newBlob = new Blob([blob]);
      const newUrl = window.URL.createObjectURL(newBlob);

      const link = document.createElement('a');
      link.href = newUrl;
      link.setAttribute('download', manualUri);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      window.URL.revokeObjectURL(newBlob);
    })
    //     .then(res => {
    //   if(res.success) {
    //     console.log(res, "payload download");
    //     const payload = res.payload;
    //     if(!payload) payload = [];
    //   } else {
    //     errorRef.value.message = res.error.msg
    //     LoginAuthStore.redirectInvalidSession(res)
    //   }
    // })
    .catch(err => {
      console.error(err);
      errorRef.value.message = err.message
    })
  }

  return {
    selectCompList: selectCompListRef, selectCompProductList: selectCompProductListRef,
    productList: productListRef, pageInfo: pageInfoRef,
    datetime: datetimeRef, prodId: prodIdRef, name: nameRef, categoryId: categoryIdRef, installProviderPrice: installProviderPriceRef,
    installBranchPrice: installBranchPriceRef, asProviderPrice: asProviderPriceRef, asBranchPrice: asBranchPriceRef,
    installDescription: installDescriptionRef, asDescription: asDescriptionRef, loadMemo: loadMemoRef, memo: memoRef, manualUri: manualUriRef, providerId: providerIdRef, productType: productTypeRef,
    exceptionalPriceList: exceptionalPriceListRef,
    loadSelectCompList, loadSelectCompProductList, listProduct, loadProduct, saveSelectCompProduct, deleteSelectCompProduct, saveProduct, error: errorRef,
    deleteProduct, uploadManual, uploadFile: uploadFileRef, deleteProductList, downloadManual
  }
}

export default useProductRegistry