const Roles = {
    ROLE_ROOT: '루트사용자',
    ROLE_ADMIN: '본사 관리자',
    ROLE_BRANCH: '지사',
    ROLE_SUPPLIER: '업체',
    ROLE_ENGINEER: '기사'
}
const StorageKeys = {
    STORED_USER_ID: "bis.bo.saved.userId",
    SAVE_USER_ID_YN: "bis.bo.saved.userIdYn",
    CURRENT_USER: "bis.bo.current-user"
}
const AppConfig = {
    apiServerHostURL: 'bo.bis-svc.com'
}
const ResponseErrorCode = {
    INVALID_ACCESS_TOKEN: '4010',
    SERVER_ERROR: '5001'
}
export {Roles, StorageKeys, ResponseErrorCode, AppConfig}