<template>
  <div>
    <Breadcrumbs title="주문 엑셀 업로드" main="주문" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h5>주문 엑셀 업로드</h5>
            </div>
            <div class="card-body">
              <span>업로드할 엑셀파일을 가운데 영역에 드래그 하세요.</span>
              <div class="my-4">
                <div v-if="excelFile.lastModified" class="dropzone dropzone-info w-100">
                  <div class="dz-message needsclick"><i class="icon-cloud-up"></i>
                    <div>
                      <div>
                        <span v-if="excelFile.name">{{ excelFile.name }}</span>
                      </div>
                      <div>
                        <span v-if="excelFile.size">{{ fnGetFileSize(excelFile.size) }}</span>
                      </div>
                      <div v-if="excelFile.lastModified" class="mt-1">
                        <button @click="excelUploading" class="btn btn-success me-2" type="button" :disabled="isUploading || isError">{{ isUploading ? '업로드 중..' : isError ? '에러 발생' : '업로드' }}</button>
                        <button v-if="!isUploading && !isError" @click="calcelUploadFile" class="btn btn-danger" type="button">취소</button>
                      </div>
                    </div>
                  </div>
                </div>
                <label v-else @dragover.prevent @drop.prevent="onDrop" for="excelUpload" class="dropzone dropzone-info w-100" style="cursor:pointer;">
                  <div class="dz-message needsclick"><i class="icon-cloud-up"></i>
                    <div>
                      <div>
                        <span>Drop files here to upload</span>
                      </div>
                    </div>
                  </div>
                </label>
                <input @change="getUploadFile" type="file" id="excelUpload" hidden accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
              </div>
              <div class="d-flex">
                <div>
                  <button @click="formExcelDownLoad('order')" class="btn btn-light" type="button">양식 다운로드</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="excelUploadedData.total">
        <h5>업로드 결과</h5>
        <div>{{ `총 [ ${excelUploadedData.total} ] 건 중` }}</div>
        <div>{{ `성공 [ ${excelUploadedData.successful} ] 건, 실패 [ ${excelUploadedData.total - excelUploadedData.successful} ] 건` }}</div>
        <div class="mt-2" v-if="Object.keys(excelUploadedData.addressFailed).length !== 0 && excelUploadedData.addressFailed.constructor === Object">
          <div>좌표추출실패</div>
          <div v-for="key in Object.keys(excelUploadedData.addressFailed)">
            <div>
              {{ `${key} : ${excelUploadedData.addressFailed[key]}` }}
            </div>
          </div>
        </div>
        <div class="mt-2" v-if="Object.keys(excelUploadedData.productMatchFailed).length !== 0 && excelUploadedData.productMatchFailed.constructor === Object" >
          <div>상품매칭실패</div>
          <div v-for="key in Object.keys(excelUploadedData.productMatchFailed)">
            <div>
              {{ `${key} : ${excelUploadedData.productMatchFailed[key]}` }}
            </div>
          </div>
        </div>
        <div class="mt-2" v-if="Object.keys(excelUploadedData.engineerMatchFailed).length !== 0 && excelUploadedData.engineerMatchFailed.constructor === Object" >
          <div>기사배정실패</div>
          <div v-for="key in Object.keys(excelUploadedData.engineerMatchFailed)">
            <div>
              {{ `${key} : ${excelUploadedData.engineerMatchFailed[key]}` }}
            </div>
          </div>
        </div>
        <div class="mt-2" v-if="Object.keys(excelUploadedData.databaseFailed).length !== 0 && excelUploadedData.databaseFailed.constructor === Object">
          <div>데이터베이스 등록오류</div>
          <div v-for="key in Object.keys(excelUploadedData.databaseFailed)">
            <div>
              {{ `${key} : ${excelUploadedData.databaseFailed[key]}` }}
            </div>
          </div>
        </div>
        <div class="mt-2" v-if="excelUploadedData.failed">
          <div>총 실패 항목 번호 리스트</div>
          <div>{{ `[${excelUploadedData.failedExcelNoList.sort((a, b) => a - b).join(", ")}]` }}</div>
        </div>
      </div>
      <div v-if="error.message">
        <h5>업로드 중 오류가 발생하였습니다.</h5>
        <div>먼저 엑셀 양식이 올바른지 확인해 주세요.</div>
        <div>{{ error.message }}</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Swal from 'sweetalert2';
import { ref, onMounted, watch } from 'vue';
import { RouterLink, useRouter } from 'vue-router'
import { useLoginAuth } from '@/stores/LoginAuthStore'
import useOrderRegistry from '@/composables/registerOrder'
const router = useRouter()
const LoginAuthStore = useLoginAuth(router)
const { formExcelDownLoad, excelFile, excelUpload, error, excelUploadedData } = useOrderRegistry(LoginAuthStore);
const excelFileName = ref('');
const isUploading = ref(false);
const isError = ref(false);
const getUploadFile = (e) => {
  excelFile.value = e.target.files[0]
  e.target.value = '';
}
const onDrop = (event) => {
  excelFile.value = [...event.dataTransfer.files][0]
  event.target.value = '';
}
const fnGetFileSize = (filesize) => {
  const text = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
  const e = Math.floor(Math.log(filesize) / Math.log(1024));
  return (filesize / Math.pow(1024, e)).toFixed(2) + " " + text[e];
}
const calcelUploadFile = () => {
  excelFile.value = {};
}

const excelUploading = async () => {
  isUploading.value = await true;
  await excelUpload('order');
}

watch(error, (newVal, oldVal) => {
    console.log(newVal, "newVal")
    if (newVal.message) {
      new Swal({
        title: '업로드 중 오류가 발생하였습니다.',
        text: `먼저 엑셀 양식이 올바른지 확인해 주세요. ${newVal.message}`,
      });
      isUploading.value = false;
      isError.value = true;
    }
  },
  { deep: true }
)
watch(excelUploadedData, async (newVal, oldVal) => {
  console.log(newVal, "newVal")
  new Swal({
    title: `엑셀 업로드 결과 총 [${newVal.total}]건 중 성공 [${newVal.successful}]건, 실패 [${newVal.total - newVal.successful}]건이 있습니다.`,
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    },
    showConfirmButton: true,
    showCancelButton: false,
  });
  isUploading.value = false;
}, { deep: true })
</script>
<style lang="scss">
</style>