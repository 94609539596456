<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 p-0">
            <div class="login-card">
                <div>
                <!-- <div>
                    <a class="logo">
                    <img class="img-fluid for-light" src="@/assets/images/logo/logo.png" alt="looginpage" />
                    <img class="img-fluid for-dark" src="@/assets/images/logo/logo_dark.png" alt="looginpage" />
                    </a>
                </div> -->
                <div class="login-main">
                    <form class="theme-form">
                    <h4>Admin Web Back Office</h4>
                    <!-- <p>Enter your userId & password to login</p> -->
                    <div class="form-group">
                        <label class="col-form-label">사용자 아이디</label>
                        <input v-model="userId"
                        class="form-control" 
                        type="text"  
                        placeholder="아이디 입력"
                        autocomplete="off"
                        required>
                        <span class="validate-error" v-if="error.userId">{{LoginAuthStoreerror.userId}}</span>

                    </div>
                    <div class="form-group">
                        <label class="col-form-label">암호</label>
                        <div class="form-input position-relative">
                        <input v-model="password"
                        class="form-control" 
                        type="password" 
                        placeholder="암호 입력"
                        autocomplete="off"
                        required >
                        <span class="validate-error" v-if="error.password">{{error.password}}</span>
                        </div>
                    </div>
                    <div class="form-group mb-0">
                        <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" v-model="saveIdYn" @click="LoginAuthStore.toggleSaveId">
                        <label class="text-muted" for="checkbox1">아이디 저장</label>
                        </div>
                        <!-- <router-link class="link" to="/authentication/forgetpassword"> 암호를 분실하셨나요?</router-link> -->
                        <div class="w-100"><span class="error" v-if="error.message">{{ error.message }}</span></div>
                        <div class="text-end mt-3">
                        <button class="btn btn-primary btn-block w-100" type="submit" @click.prevent="tryLogin">로그인</button>
                        </div>
                    </div>
                    </form>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <Footer stretched="true" />    
  </template>
  
<!-- <script setup>
import loginAuth from '@/composables/loginAuth'
import Footer from '@/components/footer.vue'
const {authenticate, toggleSaveId, saveIdYn, error, userId, password} = loginAuth()
</script> -->
<script setup>

import {ref} from 'vue'
import Footer from '@/components/footer.vue'
import {useLoginAuth} from '@/stores/LoginAuthStore'
import { storeToRefs } from 'pinia'
import {useRouter} from 'vue-router'
const router = useRouter()
const LoginAuthStore = useLoginAuth(router)
const userId = ref(LoginAuthStore.savedUserId)
const password = ref('')
const {error, saveIdYn} = storeToRefs(LoginAuthStore)

const tryLogin = () => {
    LoginAuthStore.authenticate(userId.value, password.value)
}
</script>

<style>
span.error {
    color: rgb(208, 37, 37);
}
.login-main h4 {
    color:#555; 
    text-align: center; 
    padding-bottom: 10px;
}
</style>