<template>
  <div>
    <Breadcrumbs title="A/S 수정" main="A/S" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <div class="col-12 col-xxl-10 d-flex justify-content-between">
                <div class="d-flex align-items-end">
                  <h5>A/S 수정</h5>
                  <span class="ms-2 modifyDetailed"  data-bs-toggle="modal" data-bs-target="#asModifyHist">자세히 보기</span>
                  <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" id="asModifyHist" tabindex="-1" role="dialog" aria-labelledby="asModifyHist"
                    aria-hidden="true">
                    <ModifyHistModal :orderId="id" ref="modifyHistRef" />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <form class="needs-validation" novalidate="" @submit.prevent="validationSubmit">
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2 validation-name">업체명</div>
                    <div class="col-9">
                      <select class="form-select btn-square digits col-xxl-5" v-model="providerId" v-bind:class="formSubmitted ? providerIdError ? 'is-invalid' : 'is-valid' : ''" :disabled="isRead">
                        <option value=''>선택하세요</option>
                        <option v-for="item in selectCompList" :value="item?.id" :key="item?.id">{{ item?.name }}</option>
                      </select>
                      <div class="invalid-feedback" v-if="error.providerId">{{error.providerId}}</div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-2 validation-name">상품분류</div>
                    <div class="col-9">
                      <select class="form-select btn-square digits col-5" v-model="prodTypeId" v-bind:class="formSubmitted ? prodTypeIdError ? 'is-invalid' : 'is-valid' : ''" :disabled="isRead">
                        <option value=''>선택하세요</option>
                        <option v-for="item in selectProdType" :value="item?.id" :key="item?.id">{{ item?.name }}</option>
                      </select>
                      <div class="invalid-feedback" v-if="error.prodTypeId">{{error.prodTypeId}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2 validation-name">상품명</div>
                    <div class="col-9">
                      <select class="form-select btn-square digits col-5" v-model="productId" v-bind:class="formSubmitted ? productIdError ? 'is-invalid' : 'is-valid' : ''" :disabled="isRead">
                        <option value=''>선택하세요</option>
                        <option v-for="item in selectProductList" :value="item?.id" :key="item?.id">{{ item?.name }}</option>
                      </select>
                      <div class="invalid-feedback" v-if="error.productId">{{error.productId}}</div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-2">담당자명</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" v-model="assigneeName" :readOnly="isRead"/>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2 validation-name">고객명</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" v-model="customerName" v-bind:class="formSubmitted ? customerNameError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead"/>
                      <div class="invalid-feedback" v-if="error.customerName">{{error.customerName}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2 validation-name">전화번호1</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" maxlength="14" v-model="primaryPhoneNo" @input="handlePrimaryPhoneNo" v-bind:class="formSubmitted ? primaryPhoneNoError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead"/>
                      <div class="invalid-feedback" v-if="error.primaryPhoneNo">{{error.primaryPhoneNo}}</div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-2">전화번호2</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" maxlength="14" v-model="secondaryPhoneNo" @input="handleSecondaryPhoneNo" :readOnly="isRead"/>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2">지사명</div>
                    <div class="col-9">
                      <select class="form-select btn-square digits col-5" v-model="branchId" :disabled="isRead">
                        <option value=''>선택하세요</option>
                        <option v-for="item in selectBranchList" :value="item?.id" :key="item?.id">{{ item?.name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-2">설치기사</div>
                    <div class="col-9">
                      <select class="form-select btn-square digits col-5" v-model="engineerId" :disabled="isRead">
                        <option value=''>선택하세요</option>
                        <option v-for="item in selectEngineerList" :value="item?.id" :key="item?.id">{{ item?.name }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2">우편번호</div>
                    <div class="col-9 d-flex">
                      <input class="form-control btn-square me-2" style="width:40%;" type="text" v-model="postalCode" readOnly/>
                      <PostCode v-if="!isRead" @address-sought="addressSought" />
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2 validation-name">주소</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" placeholder="주소 검색 버튼을 눌러주세요" v-model="customerAddr1" v-bind:class="formSubmitted ? customerAddr1Error ? 'is-invalid' : 'is-valid' : ''" readOnly/>
                      <div class="invalid-feedback" v-if="error.customerAddr1">{{error.customerAddr1}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2">상세주소</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" v-model="customerAddr2" :readOnly="isRead"/>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-2">참고항목</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" v-model="extraAddr" readOnly/>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2 validation-name">작업유형</div>
                    <div class="col-9">
                      <select class="form-select btn-square digits col-5" v-model="workTypeCode" v-bind:class="formSubmitted ? workTypeCodeError ? 'is-invalid' : 'is-valid' : ''">
                        <option value=''>선택하세요</option>
                        <option v-for="item in workTypeListRef" :value="item?.code" :key="item?.code">{{ item?.name }}</option>
                      </select>
                      <div class="invalid-feedback" v-if="error.workTypeCode">{{error.workTypeCode}}</div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-2">진행상황</div>
                    <div class="col-9">
                      <select class="form-select btn-square digits col-5" v-model="statCode">
                        <option :value='null'>선택하세요</option>
                        <option v-for="item in progressListRef" :value="item?.code" :key="item?.code">{{ item?.name }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2">차종</div>
                    <div class="col-9">
                      <div class="col-12 d-flex">
                        <div class="col-5 pe-2">
                          <select class="form-select btn-square digits col-5" v-model="vhcCatCode">
                            <option value=''>선택하세요</option>
                            <option v-for="item in vhcCatCodeListRef" :value="item?.code" :key="item?.code">{{ item?.name }}</option>
                          </select>
                        </div>
                        <div class="col-7">
                          <input class="form-control btn-square" type="text" placeholder="차량명" v-model="vhcTypeName" :readOnly="isRead">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3 mt-4">
                  <div class="d-flex align-items-center col-12 col-xxl-9 me-4">
                    <div class="col-2 col-xxl-1 col-xl-1 col-lg-1 col-md-1">결제 구분</div>
                    <div class="col-12 form-group m-checkbox-inline mb-0 custom-radio-ml btnBox">
                      <div class="radio radio-primary" v-for="(item, idx) in paymentCodeRef">
                        <input :id="'basic' + idx" type="radio" :name="'basic' + idx" :value="item.code" v-model="basicPayment.typeCode" :readOnly="isRead">
                        <label class="mb-0" :for="'basic' + idx">{{ item.name }}</label>
                      </div>
                      <div class="radio radio-primary">
                        <input class="form-control btn-square" type="text" placeholder="금액" v-model="basicPayment.amount" @input="handleBasicAmount" :readOnly="isRead"/>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div v-if="basicPayment.typeCode === '01003'">
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2 validation-name">내용</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.desc" :readOnly="isRead"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2 validation-name">금액</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.amount" @input="handleBasicAmount" :readOnly="isRead"/>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2 validation-name">카드사명</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.cardCompany" :readOnly="isRead"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2 validation-name">카드번호</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.cardNo" :readOnly="isRead"/>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2 validation-name">유효기간</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.cardValidPeriod" :readOnly="isRead"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2 validation-name">승인번호</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.txApprovalNo" :readOnly="isRead"/>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2 validation-name">승인일자</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.approvalDateTime" :readOnly="isRead"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2">비고</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.remark" :readOnly="isRead"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="basicPayment.typeCode === '01004'">
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2 validation-name">내용</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.desc" :readOnly="isRead"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2 validation-name">금액</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.amount" @input="handleBasicAmount" :readOnly="isRead"/>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex col-12 mb-3">
                    <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                      <div class="col-2 validation-name">계산서발행</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.approvalDateTime" :readOnly="isRead"/>
                      </div>
                    </div>
                    <div class="d-flex align-items-center col-6 col-xxl-5">
                      <div class="col-2">비고</div>
                      <div class="col-9">
                        <input class="form-control btn-square" type="text" v-model="basicPayment.remark"/>
                      </div>
                    </div>
                  </div>
                </div> -->

                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2 validation-name">불량증상</div>
                    <div class="col-9">
                      <textarea class="form-control btn-square" style="min-height: 100px;" v-model="remark" type="text" v-bind:class="formSubmitted ? remarkError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead"/>
                      <div class="invalid-feedback" v-if="error.remark">{{error.remark}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3 mt-4">
                  <div class="d-flex align-items-center col-12 col-xxl-9 me-4">
                    <div class="col-2 col-xxl-1 col-xl-1 col-lg-1 col-md-1">처리내용</div>
                    <div class="col-12 d-flex form-group m-checkbox-inline mb-0 flex-wrap">
                      <div v-for="(item, idx) in processingDetailRef">
                        <label class="d-block" :for="'proc'+idx">
                          <input class="checkbox_animated" :id="'proc'+idx" :value="item.code" v-model="asInfo.itemCodeList" @input="checkProcessingDetail" type="checkbox" :readOnly="isRead">{{ item.name }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3 mt-4">
                  <div class="d-flex align-items-center col-12 col-xxl-9 me-4">
                    <div class="col-2 col-xxl-1 col-xl-1 col-lg-1 col-md-1">작동여부</div>
                    <div class="col-5 form-group m-checkbox-inline mb-0 custom-radio-ml">
                      <div class="radio radio-primary">
                        <input id="operation" type="radio" name="operation" value="Y" v-model="asInfo.operateYn" :readOnly="isRead">
                        <label class="mb-0" for="operation">정상작동</label>
                      </div>
                      <div class="radio radio-primary">
                        <input id="badOperation" type="radio" name="badOperation" value="N" v-model="asInfo.operateYn" :readOnly="isRead">
                        <label class="mb-0" for="badOperation">작동불량</label>
                      </div>                
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2">본사 메모<br/></div>
                    <div class="col-9">
                      <div class="form-control btn-square" @dblclick="focusMemo" @mousedown.left="downListener" @mousemove="moveListener" @mouseup.left="upListener('hq')" style="min-height: 100px;height: 100%;" :contenteditable="!isRead">
                        <div contenteditable="false"><div v-html="loadMemo"></div>
                          <div id="addASMemo" :contenteditable="!isRead" @input="handleMemo"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2">기사 메모<br/></div>
                    <div class="col-9">
                      <div class="form-control btn-square" @dblclick="focusEngMemo" @mousedown.left="downListener" @mousemove="moveListener" @mouseup.left="upListener('eg')" style="min-height: 100px;height: 100%;" :contenteditable="!isRead">
                        <div contenteditable="false"><div v-html="loadEngineerMemo"></div>
                          <div id="addASEngMemo" :contenteditable="!isRead" @input="handleEngineerMemo"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2">반려 메모<br/></div>
                    <div class="col-9">
                      <div class="form-control btn-square" @dblclick="focusRejectMemo" @mousedown.left="downListener" @mousemove="moveListener" @mouseup.left="upListener('rj')" style="min-height: 100px;height: 100%;" :contenteditable="!isRead">
                        <div contenteditable="false"><div v-html="loadRejectMemo"></div>
                          <div id="addASRejectMemo" :contenteditable="!isRead" @input="handleRejectMemo"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-5 me-4">
                    <div class="col-2"></div>
                    <div class="col-12 my-4">
                      <button class="btn btn-primary" type="button" :disabled="happyCallRequested || isRead" @click="reqHappycall">해피콜 요청</button>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2">접수자/<br/>AS 배정 일자</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" :value="progressOrder.assigneeName && progressOrder.daterPlaced ? `${progressOrder.assigneeName} / ${progressOrder.daterPlaced}` : ''" readOnly/>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-2">접수자/<br/>해피콜 접수 일자</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" :value="progressOrder.hcUserName && progressOrder.hcRequestDateTime ? `${progressOrder.hcUserName} / ${progressOrder.hcRequestDateTime}` : ''" readOnly/>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2">해피콜문자</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" :value="smsHistListRef ? smsHistListRef : ''" readOnly/>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-2">부재문자</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" :value="absentSmsHistListRef ? absentSmsHistListRef : ''" readOnly/>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2">통화</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" :value="hcCompleteHistListRef ? hcCompleteHistListRef : ''" readOnly/>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-2">반려일자</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" v-model="progressOrder.rejectedDateTime" readOnly/>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-5">
                  <div class="d-flex align-items-center col-6 col-xxl-5 me-4">
                    <div class="col-2">예약일자</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" v-model="progressOrder.reservedDateTime" readOnly/>
                    </div>
                  </div>
                  <div class="d-flex align-items-center col-6 col-xxl-5">
                    <div class="col-2">완료일자</div>
                    <div class="col-9">
                      <input class="form-control btn-square" type="text" v-model="progressOrder.completeDateTime" readOnly/>
                    </div>
                  </div>
                </div>
                <div v-if="error.message" class="error">{{ error.message }}</div>
                <div v-if="!isRead" class="col-12 col-xxl-10 d-flex justify-content-center">
                  <button class="btn btn-outline-light" type="submit">수정</button>
                  <button class="btn btn-outline-light ms-2" type="button" @click="deleteOrd">삭제</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Swal from 'sweetalert2';
import ModifyHistModal from '@/components/list/ModifyHistModal.vue'
import useOrderRegistry from '@/composables/registerOrder';
import commonApi from '@/composables/commonApi'
import PostCode from '@/components/PostCode.vue'
import { ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import { useLoginAuth } from '@/stores/LoginAuthStore';
const router = useRouter();
const route = useRoute()
const id = route.params.id
const isRead = route.query.state === 'read'
const LoginAuthStore = useLoginAuth(router)
const { selectCompList, selectProdType, selectProductList, selectBranchList, selectEngineerList,
loadSelectCompList, loadSelectProdTypeList, loadSelectProductList, loadSelectBranchList, loadSelectEngineerList, saveAs,
providerId, prodTypeId, productId, branchId, engineerId, workTypeCode, statCode, vhcCatCode, vhcTypeName, error,
typeCode, customerName, postalCode, customerAddr1, customerAddr2, extraAddr, addrType, primaryPhoneNo, secondaryPhoneNo, remark, memo, happyCallRequested,
basicPayment, optionPayment, removalPayment, checkedDetailArray, asInfo, loadAs, loadMemo, loadRejectMemo, loadEngineerMemo, engineerMemo, rejectMemo, loadProductId, loadProviderId, loadProdTypeId, loadEngineerId, loadProduct,
deleteOrder, sendHappycall, assigneeName, getProgressOrder, progressOrder, getHistAll, histAll
} = useOrderRegistry(LoginAuthStore, id)
const { loadCommonCode } = commonApi(LoginAuthStore);
const workTypeListRef = ref([])
const progressListRef = ref([])
const vhcCatCodeListRef = ref([])
const paymentCodeRef = ref([]);
const inChargePersonRef = ref({});
const processingDetailRef = ref([]);
const modifyHistRef = ref(null);
const isDragRef = ref(false);
const downListener = () => {
  isDragRef.value = false;
}
const moveListener = () => {
  setTimeout(() => {
    isDragRef.value = true;
  }, 1000)
}
const upListener = (type) => {
  if (!isDragRef.value) {
    if (type === "hq") return focusMemo();
    if (type === "eg") return focusEngMemo();
    if (type === "rj") return focusRejectMemo();
  }
}

onMounted(() => {
  preventBack();
  loadAs();
  loadSelectCompList();
  loadSelectBranchList();
  getProgressOrder(id);
  getHistAll(id);
  assigneeName.value = LoginAuthStore.currentUser.name;
  // 주문유형 ([0] 00010: 주문, [1] 00011: 클레임, [2] 00012: AS)
  loadCommonCode('00001').then(res => res.json()).then(res => typeCode.value = res.payload[2].code);
  // 작업유형(AS)
  loadCommonCode('00002').then(res => res.json()).then(res => workTypeListRef.value = res.payload);
  // 진행상황
  loadCommonCode('00004').then(res => res.json()).then(res => progressListRef.value = res.payload);
  // 차종
  loadCommonCode('00005').then(res => res.json()).then(res => vhcCatCodeListRef.value = res.payload);
  // AS 처리내용
  loadCommonCode('00008').then(res => res.json()).then(res => processingDetailRef.value = res.payload);
  // 결제유형코드
  loadCommonCode('00100').then(res => res.json()).then(res => paymentCodeRef.value = res.payload);

})

const comma = (val) => {
  val = String(val);
  return val.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
}
const uncomma = (val) => {
  val = String(val);
  return val.replace(/[^\d]+/g, '');
}

const handlePrimaryPhoneNo = (e) => {
  if (e.target.value.replace(/[^0-9]/g, '').length === 12) {
    primaryPhoneNo.value = e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3`);
  } else {
    primaryPhoneNo.value = e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }
}
const handleSecondaryPhoneNo = (e) => {
  if (e.target.value.replace(/[^0-9]/g, '').length === 12) {
    secondaryPhoneNo.value = e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3`);
  } else {
    secondaryPhoneNo.value = e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }
}
const handleBasicAmount = (e) => {
  basicPayment.value.amount = comma(uncomma(e.target.value))
}

const formSubmitted = ref(false);
const providerIdError = ref(false);
const prodTypeIdError = ref(false);
const productIdError = ref(false);
const customerNameError = ref(false);
const primaryPhoneNoError = ref(false);
const customerAddr1Error = ref(false);
const workTypeCodeError = ref(false);
const remarkError = ref(false);

const absentSmsHistListRef = ref('');
const hcCompleteHistListRef = ref('');
const hcRequestHistListRef = ref('');
const smsHistListRef = ref('');

// const basicDescError = ref(false);
// const basicAmountError = ref(false);
// const basicCardCompanyError = ref(false);
// const basicCardNoError = ref(false);
// const basicCardValidPeriodError = ref(false);
// const basicTxApprovalNoError = ref(false);
// const basicApprovalDateTimeError = ref(false);

const addressSought = async (address) => {
  postalCode.value = address.postalCd;
  customerAddr1.value = address.addr;
  extraAddr.value = address.extraAddr;
  addrType.value = address.type === 'R' ? 'ROAD' : 'PARCEL'
}

const checkProcessingDetail = (e) => {
  let arr = [];
  arr = asInfo?.value?.itemCodeList ? [...asInfo.value.itemCodeList] : [];
  if (arr.includes(e.target.value)) arr = arr.filter(code => code !== e.target.value)
  else arr.push(e.target.value);
  asInfo.value.itemCodeList = arr;
}


const loadData = ref();

const getLoadOrderData = (data) => {
  loadData.value = data;
}
const getLoadASData = (data) => {
  loadData.value = data;
}
const getLoadClaimData = (data) => {
  loadData.value = data;
}

watch(providerId, async (newVal, oldVal) => {
  if (newVal) {
    prodTypeId.value = '';
    productId.value = '';
    await loadSelectProdTypeList();
    if (loadProdTypeId.value) prodTypeId.value = await loadProdTypeId.value;
  }
  else if (oldVal && !newVal) {
    prodTypeId.value = '';
    productId.value = '';
    selectProdType.value = [];
    selectProductList.value = [];
  }
})

watch(prodTypeId, async (newVal, oldVal) => {
  if (newVal) {
    productId.value = '';
    await loadSelectProductList();
    if (loadProductId.value) productId.value = await loadProductId.value;
  }
  else if (oldVal && !newVal) {
    productId.value = '';
    selectProductList.value = [];
  }
})
watch(loadProductId, async (newVal, oldVal) => {
  if (newVal) {
    await loadProduct(productId.value);
  }
  else if (oldVal && !newVal) {
    productId.value = '';
    selectProductList.value = [];
  }
})
watch(loadProviderId, async (newVal, oldVal) => {
  if (newVal) {
    providerId.value = await loadProviderId.value
  }
})
watch(loadProdTypeId, async (newVal, oldVal) => {
  if (newVal) {
    prodTypeId.value = await loadProdTypeId.value;
  }
})

watch(branchId, async (newVal, oldVal) => {
  if (newVal) {
    engineerId.value = '';
    loadSelectEngineerList();
    if (loadEngineerId.value) engineerId.value = await loadEngineerId.value;
  }
  else if (oldVal && !newVal) {
    engineerId.value = '';
    selectEngineerList.value = [];
  }
})

const handleMemo = (e) => {
  memo.value = e.target.textContent;
}
const handleEngineerMemo = (e) => {
  engineerMemo.value = e.target.textContent;
}
const handleRejectMemo = (e) => {
  rejectMemo.value = e.target.textContent;
}
const focusMemo = () => {
  const memoRef = document.getElementById("addASMemo")
  memoRef.focus();
}
const focusEngMemo = () => {
  const memoRef = document.getElementById("addASEngMemo")
  memoRef.focus();
}
const focusRejectMemo = () => {
  const memoRef = document.getElementById("addASRejectMemo")
  memoRef.focus();
}

const updateAs = async () => {
  await saveAs();
  const memoRef = document.getElementById("addASMemo")
  memoRef.textContent = '';
  const engineerMemoRef = document.getElementById("addASEngMemo")
  engineerMemoRef.textContent = '';
  const rejectMemoRef = document.getElementById("addASRejectMemo")
  rejectMemoRef.textContent = '';
}

const validationSubmit = async () => {
  formSubmitted.value = true;

  if (providerId.value.length < 1) providerIdError.value = true;
  else providerIdError.value = false;

  if (prodTypeId.value.length < 1) prodTypeIdError.value = true;
  else prodTypeIdError.value = false;

  if (productId.value.length < 1) productIdError.value = true;
  else productIdError.value = false;

  if (customerName.value.length < 1) customerNameError.value = true;
  else customerNameError.value = false;

  if (primaryPhoneNo.value.length < 1) primaryPhoneNoError.value = true;
  else primaryPhoneNoError.value = false;

  if (customerAddr1.value.length < 1) customerAddr1Error.value = true;
  else customerAddr1Error.value = false;

  if (workTypeCode.value.length < 1) workTypeCodeError.value = true;
  else workTypeCodeError.value = false;
  if (remark.value.length < 1) remarkError.value = true;
  else remarkError.value = false;

  // if (basicPayment.value.typeCode === '01003') {
  //   if (basicPayment.value.desc.length < 1) basicDescError.value = true;
  //   else basicDescError.value = false;
  //   if (basicPayment.value.amount.length < 1) basicAmountError.value = true;
  //   else basicAmountError.value = false;
  //   if (basicPayment.value.cardCompany.length < 1) basicCardCompanyError.value = true;
  //   else basicCardCompanyError.value = false;
  //   if (basicPayment.value.cardNo.length < 1) basicCardNoError.value = true;
  //   else basicCardNoError.value = false;
  //   if (basicPayment.value.cardValidPeriod.length < 1) basicCardValidPeriodError.value = true;
  //   else basicCardValidPeriodError.value = false;
  //   if (basicPayment.value.txApprovalNo.length < 1) basicTxApprovalNoError.value = true;
  //   else basicTxApprovalNoError.value = false;
  //   if (basicPayment.value.approvalDateTime.length < 1) basicApprovalDateTimeError.value = true;
  //   else basicApprovalDateTimeError.value = false;
  // }
  // if (basicPayment.value.typeCode === '01004') {
  //   if (basicPayment.value.desc.length < 1) basicDescError.value = true;
  //   else basicDescError.value = false;
  //   if (basicPayment.value.amount.length < 1) basicAmountError.value = true;
  //   else basicAmountError.value = false;
  //   if (basicPayment.value.approvalDateTime.length < 1) basicApprovalDateTimeError.value = true;
  //   else basicApprovalDateTimeError.value = false;
  // }

  await updateAs();
}
const deleteOrd = async () => {
  if (confirm("A/S 주문을 삭제하시겠습니까?")) await deleteOrder(id);
  else return;
  await router.push({name: route.query.name})
}

const reqHappycall = async () => {
  const confirmReq = await new Swal({
    input: "textarea",
    inputLabel: "해피콜 요청",
    inputPlaceholder: "요청사항 입력",
    inputAttributes: {
      "aria-label": "해피콜 요청"
    },
    showCancelButton: true,
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    },
  });
  if (confirmReq.isConfirmed) {
    await sendHappycall(confirmReq.value);
    await loadAs()
    setTimeout(() => {
      if (modifyHistRef.value) modifyHistRef.value.watchHappyCall();
    }, 500)
  }
}

const getHistAllList = async () => {
  const hist = {...histAll.value};
  if (hist?.absentSmsHistList?.length) {
    hist.absentSmsHistList.forEach((el, i) => {
      if (i !== 0) absentSmsHistListRef.value = `${absentSmsHistListRef.value}, ${el.nth}차: ${el.callDateTime}${el.remark ? '('+el.remark+')' : ''}`
      else absentSmsHistListRef.value = `${el.nth}차: ${el.callDateTime}${el.remark ? '('+el.remark+')' : ''}`
    })
  }
  if (hist?.hcCompleteHistList?.length) {
    hist.hcCompleteHistList.forEach((el, i) => {
      if (i !== 0) hcCompleteHistListRef.value = `${hcCompleteHistListRef.value}, ${el.nth}차: ${el.callDateTime}${el.remark ? '('+el.remark+')' : ''}`
      else hcCompleteHistListRef.value = `${el.nth}차: ${el.callDateTime}${el.remark ? '('+el.remark+')' : ''}`
    })
  }
  if (hist?.hcRequestHistList?.length) {
    hist.hcRequestHistList.forEach((el, i) => {
      if (i !== 0) hcRequestHistListRef.value = `${hcRequestHistListRef.value}, ${el.nth}차: ${el.callDateTime}${el.remark ? '('+el.remark+')' : ''}`
      else hcRequestHistListRef.value = `${el.nth}차: ${el.callDateTime}${el.remark ? '('+el.remark+')' : ''}`
    })
  }
  if (hist?.smsHistList?.length) {
    hist.smsHistList.forEach((el, i) => {
      if (i !== 0) smsHistListRef.value = `${smsHistListRef.value}, ${el.nth}차: ${el.callDateTime}${el.remark ? '('+el.remark+')' : ''}`
      else smsHistListRef.value = `${el.nth}차: ${el.callDateTime}${el.remark ? '('+el.remark+')' : ''}`
    })
  }
}
watch(histAll, (newVal, oldVal) => {
  if (newVal) getHistAllList();
})

const preventBack = () => {
  window.history.pushState(null, '', location.href)
  window.onpopstate = () => {
    history.go(1);
    router.push({name: route.query.name, query: route.query})
  }
}
</script>