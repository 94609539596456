<template>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <form class="needs-validation" novalidate="" @submit.prevent="validationSubmit">
        <div class="modal-header">
          <h5 class="modal-title">{{`${searchType === 'order' ? '주문' : 'A/S'} 결제방식`}}</h5>
          <button :id="`${whatIam}PaymentCloseModal${orderId}${type}`" class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span class="validation-name">업체명</span>
            </div>
            <div class="col-9">
              <select class="form-select btn-square digits" v-model="providerId" v-bind:class="formSubmitted ? providerIdError ? 'is-invalid' : 'is-valid' : ''" :disabled="isRead">
                <option value=''>선택하세요</option>
                <option v-for="item in selectCompList" :value="item?.id" :key="item?.id">{{ item?.name }}</option>
              </select>
              <div class="invalid-feedback text-start" v-if="error.providerId">{{error.providerId}}</div>
            </div>
          </div>
          <div class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span class="validation-name">상품분류</span>
            </div>
            <div class="col-9">
              <select class="form-select btn-square digits" v-model="prodTypeId" v-bind:class="formSubmitted ? prodTypeIdError ? 'is-invalid' : 'is-valid' : ''" :disabled="isRead">
                <option value=''>선택하세요</option>
                <option v-for="item in selectProdType" :value="item?.id" :key="item?.id">{{ item?.name }}</option>
              </select>
              <div class="invalid-feedback text-start" v-if="error.prodTypeId">{{error.prodTypeId}}</div>
            </div>
          </div>
          <div class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span class="validation-name">상품명</span>
            </div>
            <div class="col-9">
              <select class="form-select btn-square digits" v-model="productId" v-bind:class="formSubmitted ? productIdError ? 'is-invalid' : 'is-valid' : ''" :disabled="isRead">
                <option value=''>선택하세요</option>
                <option v-for="item in selectProductList" :value="item?.id" :key="item?.id">{{ item?.name }}</option>
              </select>
              <div class="invalid-feedback text-start" v-if="error.productId">{{error.productId}}</div>
            </div>
          </div>
          <!-- <div class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span>담당자명</span>
            </div>
            <div class="col-9">
              <input class="form-control btn-square w-100" type="text" v-model="assigneeName" placeholder="">
            </div>
          </div> -->
          <div class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span class="validation-name">고객명</span>
            </div>
            <div class="col-9">
              <input class="form-control btn-square w-100" type="text" v-model="customerName"  v-bind:class="formSubmitted ? customerNameError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
              <div class="invalid-feedback text-start" v-if="error.customerName">{{error.customerName}}</div>
            </div>
          </div>
          <div class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span class="validation-name">전화번호1</span>
            </div>
            <div class="col-9">
              <input class="form-control btn-square w-100" type="text" v-model="primaryPhoneNo" v-bind:class="formSubmitted ? primaryPhoneNoError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
              <div class="invalid-feedback text-start" v-if="error.primaryPhoneNo">{{error.primaryPhoneNo}}</div>
            </div>
          </div>
          <div class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span class="validation-name">주소</span>
            </div>
            <div class="col-9">
              <input class="form-control btn-square w-100" type="text" placeholder="" v-model="customerAddr1" v-bind:class="formSubmitted ? customerAddr1Error ? 'is-invalid' : 'is-valid' : ''" readOnly>
            </div>
          </div>
          <div class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span class="validation-name">차종</span>
            </div>
            <div class="col-9">
              <div class="col-12 d-flex">
                <div class="col-4 pe-2">
                  <select class="form-select btn-square digits" v-model="vhcCatCode" v-bind:class="formSubmitted ? vhcCatCodeError ? 'is-invalid' : 'is-valid' : ''">
                    <option value=''>선택하세요</option>
                    <option v-for="item in vhcCatCodeListRef" :value="item?.code" :key="item?.code">{{ item?.name }}</option>
                  </select>
                  <div class="invalid-feedback text-start" v-if="error.vhcCatCode">{{error.vhcCatCode}}</div>
                </div>
                <div class="col-8">
                  <input class="form-control btn-square col-7" type="text" placeholder="차량명" v-model="vhcTypeName" v-bind:class="formSubmitted ? vhcTypeNameError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead">
                  <div class="invalid-feedback text-start" v-if="error.vhcTypeName">{{error.vhcTypeName}}</div>
                </div>
              </div>
            </div>
          </div>        
          <div class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span>{{`${searchType === 'order' ? '기본 장착비' : '결제구분'}`}}</span>
            </div>
            <div class="col-9">
              <div class="col-12 d-flex">
                <div class="col-4 pe-2">
                  <select v-model="basicPayment.typeCode" class="form-select btn-square digits col-5">
                    <option v-for="(item, idx) in paymentCodeRef" :value='item?.code'>{{ item?.name }}</option>
                  </select>
                </div>
                <div class="col-8">
                  <input class="form-control btn-square col-7" type="text" placeholder="금액" v-model="basicPayment.amount" @input="handleBasicAmount" :readOnly="isRead">
                </div>
              </div>
            </div>
          </div>
          <div v-if="searchType === 'order'" class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span>추가 장착비</span>
            </div>
            <div class="col-9">
              <div class="col-12 d-flex">
                <div class="col-4 pe-2">
                  <select v-model="optionPayment.typeCode" class="form-select btn-square digits col-5">
                    <option v-for="(item, idx) in paymentCodeRef" :value='item?.code'>{{ item?.name }}</option>
                  </select>
                </div>
                <div class="col-8">
                  <input class="form-control btn-square col-7" type="text" placeholder="금액" v-model="optionPayment.amount" @input="handleOptionAmount" :readOnly="isRead">
                </div>
              </div>
            </div>
          </div>
          <div v-if="searchType === 'order'" class="col-12 d-flex align-items-center justify-content-center mt-2">
            <div class="col-2">
              <span>탈거비</span>
            </div>
            <div class="col-9">
              <div class="col-12 d-flex">
                <div class="col-4 pe-2">
                  <select v-model="removalPayment.typeCode" class="form-select btn-square digits col-5">
                    <option v-for="(item, idx) in paymentCodeRef" :value='item?.code'>{{ item?.name }}</option>
                  </select>
                </div>
                <div class="col-8">
                  <input class="form-control btn-square col-7" type="text" placeholder="금액" v-model="removalPayment.amount" @input="handleRemovalAmount" :readOnly="isRead">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isRead" class="modal-footer d-flex justify-content-center">
          <button class="btn btn-secondary" type="submit">수정</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed, defineProps, toRefs, onUnmounted, watch, defineEmits } from 'vue';
import useOrderRegistry from '@/composables/registerOrder';
import commonApi from '@/composables/commonApi'
import PostCode from '@/components/PostCode.vue'
import { useRouter } from 'vue-router'
import { useLoginAuth } from '@/stores/LoginAuthStore';
import Swal from 'sweetalert2';
const router = useRouter();
const LoginAuthStore = useLoginAuth(router)
const props = defineProps({
  orderId: {
    type: Number,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
  isRead: {
    type: Boolean,
    required: false,
  },
  searchType: {
    type: String,
    required: false,
  }
});
const { orderId, type, isRead, searchType } = toRefs(props);
const { selectCompList, selectProdType, selectProductList, selectBranchList, selectEngineerList,
loadSelectCompList, loadSelectProdTypeList, loadSelectProductList, loadSelectBranchList, loadSelectEngineerList, saveOrder, saveAs,
providerId, prodTypeId, loadProviderId, loadProdTypeId, loadProductId, productId, branchId, loadEngineerId, engineerId, workTypeCode, statCode, vhcCatCode, vhcTypeName, error,
typeCode, customerName, postalCode, customerAddr1, customerAddr2, extraAddr, addrType, primaryPhoneNo, secondaryPhoneNo, remark, memo, loadMemo, happyCallRequested,
basicPayment, optionPayment, removalPayment, loadOrder, assigneeName, loadProduct, loadOrderData, updateItems, sendHappycall, loadAs} = useOrderRegistry(LoginAuthStore)
const { loadCommonCode } = commonApi(LoginAuthStore);

const workTypeListRef = ref([])
const progressListRef = ref([])
const vhcCatCodeListRef = ref([])
const paymentCodeRef = ref([]);
const inChargePersonRef = ref({});
const path = ref(router.currentRoute.value.path);
const isClaim = path.value.includes('claim');
const isCal = path.value.includes('calculate');
const isComp = path.value.includes('calculate/comp');
const isOpenModal = ref(false);
const whatIam = isCal ? 'cal' : (searchType.value === 'order') ? 'order' : (isClaim || searchType.value === 'claim') ? 'claim' : 'as'
const emit = defineEmits(['listOrder']);
onMounted(async () => {
  const paymentModalEl = document.getElementById(`${whatIam}Payment${orderId.value}${type.value}`);
  paymentModalEl.addEventListener('shown.bs.modal', async () => {
    if (!isOpenModal.value) {
      isOpenModal.value = true;
      if (searchType.value === 'order') await loadOrder(orderId.value);
      else if (searchType.value !== 'order') await loadAs(orderId.value);
      await loadSelectCompList();
      await loadSelectBranchList();
        // 주문유형 ([0] 00010: 주문, [1] 00011: 클레임, [2] 00012: AS)
      loadCommonCode('00001').then(res => res.json()).then(res => typeCode.value = res.payload[0].code);
      // 작업유형
      searchType.value === 'order'
        ? loadCommonCode('00003').then(res => res.json()).then(res => workTypeListRef.value = res.payload)
        : loadCommonCode('00002').then(res => res.json()).then(res => workTypeListRef.value = res.payload);
      // 진행상황
      loadCommonCode('00004').then(res => res.json()).then(res => progressListRef.value = res.payload);
      // 차종
      loadCommonCode('00005').then(res => res.json()).then(res => vhcCatCodeListRef.value = res.payload);
      // 결재유형코드
      loadCommonCode('00100').then(res => res.json()).then(res => paymentCodeRef.value = res.payload);
    }
  })

  paymentModalEl.addEventListener('hidden.bs.modal', async () => {
    isOpenModal.value = false;
    const isValid = document.querySelectorAll('.is-valid');
    isValid.forEach(el => el.classList.remove('is-valid'));
    paymentModalEl.removeEventListener('shown.bs.modal', async () => {
      if (!isOpenModal.value) {
        isOpenModal.value = true;
        if (searchType.value === 'order') await loadOrder(orderId.value);
        else if (searchType.value !== 'order') await loadAs(orderId.value);
        await loadSelectCompList();
        await loadSelectBranchList();
          // 주문유형 ([0] 00010: 주문, [1] 00011: 클레임, [2] 00012: AS)
        loadCommonCode('00001').then(res => res.json()).then(res => typeCode.value = res.payload[0].code);
        // 작업유형
        searchType.value === 'order'
          ? loadCommonCode('00003').then(res => res.json()).then(res => workTypeListRef.value = res.payload)
          : loadCommonCode('00002').then(res => res.json()).then(res => workTypeListRef.value = res.payload);
        // 진행상황
        loadCommonCode('00004').then(res => res.json()).then(res => progressListRef.value = res.payload);
        // 차종
        loadCommonCode('00005').then(res => res.json()).then(res => vhcCatCodeListRef.value = res.payload);
        // 결재유형코드
        loadCommonCode('00100').then(res => res.json()).then(res => paymentCodeRef.value = res.payload);
      }
    })
  })
})
const comma = (val) => {
  val = String(val);
  return val.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
}
const uncomma = (val) => {
  val = String(val);
  return val.replace(/[^\d]+/g, '');
}
const handleBasicAmount = (e) => {
  basicPayment.value.amount = comma(uncomma(e.target.value))
}
const handleOptionAmount = (e) => {
  optionPayment.value.amount = comma(uncomma(e.target.value))
}
const handleRemovalAmount = (e) => {
  removalPayment.value.amount = comma(uncomma(e.target.value))
}
const formSubmitted = ref(false);
const providerIdError = ref(false);
const prodTypeIdError = ref(false);
const productIdError = ref(false);
const customerNameError = ref(false);
const primaryPhoneNoError = ref(false);
const customerAddr1Error = ref(false);
const vhcCatCodeError = ref(false);
const vhcTypeNameError = ref(false);

const basicDescError = ref(false);
const basicAmountError = ref(false);
// const basicCardCompanyError = ref(false);
// const basicCardNoError = ref(false);
// const basicCardValidPeriodError = ref(false);
// const basicTxApprovalNoError = ref(false);
// const basicApprovalDateTimeError = ref(false);

const optionDescError = ref(false);
const optionAmountError = ref(false);
// const optionCardCompanyError = ref(false);
// const optionCardNoError = ref(false);
// const optionCardValidPeriodError = ref(false);
// const optionTxApprovalNoError = ref(false);
// const optionApprovalDateTimeError = ref(false);

const removalDescError = ref(false);
const removalAmountError = ref(false);
// const removalCardCompanyError = ref(false);
// const removalCardNoError = ref(false);
// const removalCardValidPeriodError = ref(false);
// const removalTxApprovalNoError = ref(false);
// const removalApprovalDateTimeError = ref(false);

const addressSought = async (address) => {
  postalCode.value = address.postalCd;
  customerAddr1.value = address.addr;
  extraAddr.value = address.extraAddr;
  addrType.value = address.type === 'R' ? 'ROAD' : 'PARCEL'
}

const updateOrder = async () => {
  if (searchType.value === 'order') await saveOrder(orderId.value, isCal, isComp);
  else if (searchType.value !== 'order') await saveAs(orderId.value, isCal, isComp);
  const memoRef = document.getElementById(`addModalMemo${orderId.value}`)
  memoRef.textContent = '';
}
const validationSubmit = async () => {
  formSubmitted.value = true;

  if (providerId.value.length < 1 || providerId.value === null) providerIdError.value = true;
  else providerIdError.value = false;

  if (prodTypeId.value.length < 1 || prodTypeId.value === null) prodTypeIdError.value = true;
  else prodTypeIdError.value = false;

  if (productId.value.length < 1 || productId.value === null) productIdError.value = true;
  else productIdError.value = false;

  if (customerName.value.length < 1 || customerName.value === null) customerNameError.value = true;
  else customerNameError.value = false;

  if (primaryPhoneNo.value.length < 1 || primaryPhoneNo.value === null) primaryPhoneNoError.value = true;
  else primaryPhoneNoError.value = false;

  if (customerAddr1.value.length < 1 || customerAddr1.value === null) customerAddr1Error.value = true;
  else customerAddr1Error.value = false;

  if (vhcCatCode.value.length < 1 || vhcCatCode.value === null) vhcCatCodeError.value = true;
  else vhcCatCodeError.value = false;
  if (vhcTypeName.value.length < 1 || vhcTypeName.value === null) vhcTypeNameError.value = true;
  else vhcTypeNameError.value = false;

  if (basicPayment.value.typeCode === '01003') {
    if (basicPayment.value.desc === null) basicDescError.value = true;
    else basicDescError.value = false;
    if (basicPayment.value.amount === null) basicAmountError.value = true;
    else basicAmountError.value = false;
    // if (basicPayment.value.cardCompany === null) basicCardCompanyError.value = true;
    // else basicCardCompanyError.value = false;
    // if (basicPayment.value.cardNo === null) basicCardNoError.value = true;
    // else basicCardNoError.value = false;
    // if (basicPayment.value.cardValidPeriod === null) basicCardValidPeriodError.value = true;
    // else basicCardValidPeriodError.value = false;
    // if (basicPayment.value.txApprovalNo === null) basicTxApprovalNoError.value = true;
    // else basicTxApprovalNoError.value = false;
    // if (basicPayment.value.approvalDateTime === null) basicApprovalDateTimeError.value = true;
    // else basicApprovalDateTimeError.value = false;
  }
  if (basicPayment.value.typeCode === '01004') {
    if (basicPayment.value.desc === null) basicDescError.value = true;
    else basicDescError.value = false;
    if (basicPayment.value.amount === null) basicAmountError.value = true;
    else basicAmountError.value = false;
    // if (basicPayment.value.approvalDateTime === null) basicApprovalDateTimeError.value = true;
    // else basicApprovalDateTimeError.value = false;
  }
  if (optionPayment.value.typeCode === '01003') {
    if (optionPayment.value.desc === null) optionDescError.value = true;
    else optionDescError.value = false;
    if (optionPayment.value.amount === null) optionAmountError.value = true;
    else optionAmountError.value = false;
    // if (optionPayment.value.cardCompany === null) optionCardCompanyError.value = true;
    // else optionCardCompanyError.value = false;
    // if (optionPayment.value.cardNo === null) optionCardNoError.value = true;
    // else optionCardNoError.value = false;
    // if (optionPayment.value.cardValidPeriod === null) optionCardValidPeriodError.value = true;
    // else optionCardValidPeriodError.value = false;
    // if (optionPayment.value.txApprovalNo === null) optionTxApprovalNoError.value = true;
    // else optionTxApprovalNoError.value = false;
    // if (optionPayment.value.approvalDateTime === null) optionApprovalDateTimeError.value = true;
    // else optionApprovalDateTimeError.value = false;
  }
  if (optionPayment.value.typeCode === '01004') {
    if (optionPayment.value.desc === null) optionDescError.value = true;
    else optionDescError.value = false;
    if (optionPayment.value.amount === null) optionAmountError.value = true;
    else optionAmountError.value = false;
    // if (optionPayment.value.approvalDateTime === null) optionApprovalDateTimeError.value = true;
    // else optionApprovalDateTimeError.value = false;
  }
  if (removalPayment.value.typeCode === '01003') {
    if (removalPayment.value.desc === null) removalDescError.value = true;
    else removalDescError.value = false;
    if (removalPayment.value.amount === null) removalAmountError.value = true;
    else removalAmountError.value = false;
    // if (removalPayment.value.cardCompany === null) removalCardCompanyError.value = true;
    // else removalCardCompanyError.value = false;
    // if (removalPayment.value.cardNo === null) removalCardNoError.value = true;
    // else removalCardNoError.value = false;
    // if (removalPayment.value.cardValidPeriod === null) removalCardValidPeriodError.value = true;
    // else removalCardValidPeriodError.value = false;
    // if (removalPayment.value.txApprovalNo === null) removalTxApprovalNoError.value = true;
    // else removalTxApprovalNoError.value = false;
    // if (removalPayment.value.approvalDateTime === null) removalApprovalDateTimeError.value = true;
    // else removalApprovalDateTimeError.value = false;
  }
  if (removalPayment.value.typeCode === '01004') {
    if (removalPayment.value.desc === null) removalDescError.value = true;
    else removalDescError.value = false;
    if (removalPayment.value.amount === null) removalAmountError.value = true;
    else removalAmountError.value = false;
    // if (removalPayment.value.approvalDateTime === null) removalApprovalDateTimeError.value = true;
    // else removalApprovalDateTimeError.value = false;
  }

  await updateOrder();
  const modalClose = document.getElementById(`${whatIam}PaymentCloseModal${orderId.value}${type.value}`);
  if (providerIdError.value ||
      prodTypeIdError.value ||
      productIdError.value ||
      customerNameError.value ||
      primaryPhoneNoError.value ||
      customerAddr1Error.value ||
      vhcCatCodeError.value ||
      vhcTypeNameError.value ||
      basicDescError.value ||
      basicAmountError.value ||
      optionDescError.value ||
      optionAmountError.value ||
      removalDescError.value ||
      removalAmountError.value) console.log();
  else {
    await modalClose.click();
    await emit('listOrder');
  }
  // if (providerIdError.value ||
  //     prodTypeIdError.value ||
  //     productIdError.value ||
  //     customerNameError.value ||
  //     primaryPhoneNoError.value ||
  //     customerAddr1Error.value ||
  //     vhcCatCodeError.value ||
  //     vhcTypeNameError.value ||
  //     basicDescError.value ||
  //     basicAmountError.value ||
  //     basicCardCompanyError.value ||
  //     basicCardNoError.value ||
  //     basicCardValidPeriodError.value ||
  //     basicTxApprovalNoError.value ||
  //     basicApprovalDateTimeError.value ||
  //     optionDescError.value ||
  //     optionAmountError.value ||
  //     optionCardCompanyError.value ||
  //     optionCardNoError.value ||
  //     optionCardValidPeriodError.value ||
  //     optionTxApprovalNoError.value ||
  //     optionApprovalDateTimeError.value ||
  //     removalDescError.value ||
  //     removalAmountError.value ||
  //     removalCardCompanyError.value ||
  //     removalCardNoError.value ||
  //     removalCardValidPeriodError.value ||
  //     removalTxApprovalNoError.value ||
  //     removalApprovalDateTimeError.value) console.log();
  // else await modalClose.click();
}

watch(providerId, async (newVal, oldVal) => {
  if (newVal) {
    prodTypeId.value = '';
    productId.value = '';
    await loadSelectProdTypeList();
    if (loadProdTypeId.value) prodTypeId.value = await loadProdTypeId.value;
  }
  else if (oldVal && !newVal) {
    prodTypeId.value = '';
    productId.value = '';
    selectProdType.value = [];
    selectProductList.value = [];
  }
})

watch(prodTypeId, async (newVal, oldVal) => {
  if (newVal) {
    productId.value = '';
    await loadSelectProductList();
    if (loadProductId.value) productId.value = await loadProductId.value;
  }
  else if (oldVal && !newVal) {
    productId.value = '';
    selectProductList.value = [];
  }
})
watch(loadProductId, async (newVal, oldVal) => {
  if (newVal) {
    await loadProduct(productId.value);
  }
  else if (oldVal && !newVal) {
    productId.value = '';
    selectProductList.value = [];
  }
})
watch(loadProviderId, async (newVal, oldVal) => {
  if (newVal) {
    providerId.value = await loadProviderId.value
  }
})
watch(loadProdTypeId, async (newVal, oldVal) => {
  if (newVal) {
    prodTypeId.value = await loadProdTypeId.value;
  }
})

watch(branchId, async (newVal, oldVal) => {
  if (newVal) {
    engineerId.value = '';
    loadSelectEngineerList();
    if (loadEngineerId.value) engineerId.value = await loadEngineerId.value;
  }
  else if (oldVal && !newVal) {
    engineerId.value = '';
    selectEngineerList.value = [];
  }
})

const handleMemo = (e) => {
  memo.value = e.target.textContent;
}
const focusMemo = () => {
  const memoRef = document.getElementById(`addModalMemo${orderId.value}`)
  console.log('focus click', memoRef);
  memoRef.focus();
}

const happyCall = () => {
  new Swal({
    title: '해피콜 요청이 완료되었습니다.'
  });
}
const changeOrder = () => {
  new Swal({
    title: '수정이 완료되었습니다.'
  });
}

const reqHappycall = async () => {
  if (confirm("해피콜을 요청하시겠습니까?")) await sendHappycall();
  else return;
  await loadOrder();
}
</script>
<style lang="sass">
</style>