import {ref} from 'vue'
import {trim, replacePath} from '@/util/Util'
import apiURL from '@/bis/data/registerAdminApi'
const useAdminRegistry = (LoginAuthStore, id) => {
    // const idRef = ref(id)
    const userIdRef = ref('')
    const passwdRef = ref('')
    const phoneNoRef = ref('')
    const nameRef = ref('')
    const errorRef = ref({})
    const adminListRef = ref([])
    const pageInfoRef = ref([])
    const headersMap = {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': LoginAuthStore.accessToken
    }
    const loadAdmin = async () => {
        const url = replacePath(apiURL.loadAdmin.api, id)
        fetch(`${apiURL.baseUri}${url}`,
        {
            method: apiURL.loadAdmin.method,
            headers: headersMap
        }).then(res => res.json())
        .then(res => {
            if(res.success) {
                console.log(res)
                userIdRef.value = res.payload.userId
                passwdRef.value = ''
                nameRef.value = res.payload.name
                phoneNoRef.value = res.payload.phoneNo
            } else {
                errorRef.value.message = res.error.msg
                LoginAuthStore.redirectInvalidSession(res)
            }
        }).catch(err => {
            errorRef.value.message = err.message
        })
    }
    const listAdmin = async(queryObj) => {
        let query = `?rrp=${queryObj.rrp}&pno=${queryObj.pno}`
        const filter = !queryObj.filter ? '': '&filter=' + trim(queryObj.filter)
        query = query + filter
        console.log(query)
        fetch(`${apiURL.baseUri}${apiURL.listAdmin.api}${query}`,
        {
            method: apiURL.listAdmin.method,
            headers: headersMap
        }).then(res => res.json())
        .then(res => {
            console.log(res)
            if(res.success) {
                adminListRef.value = !res.payload ? []: res.payload;
                pageInfoRef.value = !res.pageInfo ? []: res.pageInfo;
            } else {
                errorRef.value.message = res.error.msg
                LoginAuthStore.redirectInvalidSession(res)
            }
        }).catch(err => errorRef.value.message = err.message)
    } 
    const saveAdmin = async () => {
        const userId = trim(userIdRef.value)
        const password = trim(passwdRef.value)
        const phoneNo = trim(phoneNoRef.value)
        const name = trim(nameRef.value)
        const createMode = !id
        let apiObj = createMode ? 
        apiURL.addAdmin 
        : {api: replacePath(apiURL.updateAdmin.api, id), method: apiURL.updateAdmin.method} 
        console.log('apiObject', apiObj)

        errorRef.value = {}
        
        if(!userId) {
            errorRef.value.userId = '관리자 아이디를 입력하세요'
        }
        if(createMode && !password) {
            errorRef.value.password = '관리자 패스워드를 입력하세요'
        }
        if(phoneNo.length < 9 || phoneNo.length > 13) {
            errorRef.value.phoneNo = '관리자 전화번호를 입력하세요'
        }
        if(!name) {
            errorRef.value.name = '관리자 이름을 입력하세요'
        }
        const error = errorRef.value;
        if(error.userId || error.password || error.phoneNo || error.name) {
            console.log('errorRef', error)
            return
        }
        const requestBody = {
            userId: userId,
            password,
            name,
            phoneNo
        }
        fetch(                
            `${apiURL.baseUri}${apiObj.api}`,
            {
                method: apiObj.method,
                headers: headersMap,
                body: JSON.stringify(requestBody)
            }
        ).then(res => res.json())
        .then(res => {
            if(res.success) {
                console.log(res)
                // userIdRef.value = ''
                // passwdRef.value = ''
                // nameRef.value = ''
                // phoneNoRef.value = ''
                alert(createMode? '관리자를 생성하였습니다': '관리자정보를 수정하였습니다')
                LoginAuthStore.getRouter.push({name:'adminList'})
            } else {
                errorRef.value.message = res.error.msg
                LoginAuthStore.redirectInvalidSession(res)
            }
        }).catch(err => {
            errorRef.value.message = err.message
        })
    }
    const deleteAdmin = async() => {
        const url = replacePath(apiURL.deleteAdmin.api, id)
        fetch(`${apiURL.baseUri}${url}`, {
            method: apiURL.deleteAdmin.method,
            headers: headersMap
        }).then(res => res.json())
        .then(res => {
            if(res.success) {
                console.log(res)
                userIdRef.value = ''
                passwdRef.value = ''
                nameRef.value = ''
                phoneNoRef.value = ''
                alert('관리자 계정을 삭제하였습니다')
            } else {
                errorRef.value.message = res.error.msg
                LoginAuthStore.redirectInvalidSession(res)
            }
        }).catch(err => {
            errorRef.value.message = err.message
        })
    }

    const deleteAdminList = async(idList) => {
        fetch(`${apiURL.baseUri}${apiURL.deleteAdminList.api}`, {
            method: apiURL.deleteAdminList.method,
            headers: headersMap,
            body: JSON.stringify(idList)
        }).then(res => res.json())
        .then(res => {
            if(res.success) {
                userIdRef.value = ''
                passwdRef.value = ''
                nameRef.value = ''
                phoneNoRef.value = ''
            } else {
                errorRef.value.message = res.error.msg
                LoginAuthStore.redirectInvalidSession(res)
            }
        }).catch(err => {
            errorRef.value.message = err.message
        })

    }
    return {
        pageInfo: pageInfoRef, adminList: adminListRef, adminId: userIdRef, adminPassword: passwdRef, adminName: nameRef, adminPhoneNo: phoneNoRef,
        error: errorRef,
        saveAdmin, loadAdmin,listAdmin,deleteAdmin, deleteAdminList
    }
}

export default useAdminRegistry