<template>
  <div>
    <Breadcrumbs title="지사 수정" main="지사" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <div class="col-8 d-flex justify-content-between">
                  <h5>지사 수정</h5>
              </div>
            </div>
            <div class="card-body">
              <form class="needs-validation" novalidate="" @submit.prevent="validationSubmit">
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1 validation-name">지정그룹</div>
                    <div class="col-12 col-xxl-6">
                      <select class="form-select btn-square digits col-5" v-model="group.code" v-bind:class="formSubmitted ? groupError ? 'is-invalid' : 'is-valid' : ''">
                        <option value=''>선택하세요.</option>
                        <option value='HEADQUARTER'>BIS 본사</option>
                        <option value='BRANCH'>지사</option>
                      </select>
                      <div class="invalid-feedback" v-if="error.group">{{error.group}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1 validation-name">아이디</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="text" v-model="userId" v-bind:class="formSubmitted ? idError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead"/>
                      <div class="invalid-feedback" v-if="error.userId">{{error.userId}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1">비밀번호</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="password" v-model="password" :readOnly="isRead"/>
                      <!-- <div class="invalid-feedback" v-if="error.password">{{error.password}}</div> -->
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1 validation-name">지점명</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="text" v-model="branchName" v-bind:class="formSubmitted ? branchError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead"/>
                      <div class="invalid-feedback" v-if="error.branchName">{{error.branchName}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1 validation-name">대표자 이름</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="text" v-model="repName" v-bind:class="formSubmitted ? repError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead"/>
                      <div class="invalid-feedback" v-if="error.repName">{{error.repName}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1">주민등록번호</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="text" v-model="ssn" :readOnly="isRead"/>
                      <!-- <div class="invalid-feedback" v-if="error.ssn">{{error.ssn}}</div> -->
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1">사업자명</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="text" v-model="bizName" :readOnly="isRead"/>
                      <!-- <div class="invalid-feedback" v-if="error.bizName">{{error.bizName}}</div> -->
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1">사업자번호</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="text" v-model="bizNo" :readOnly="isRead"/>
                      <!-- <div class="invalid-feedback" v-if="error.bizNo">{{error.bizNo}}</div> -->
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1 validation-name">연락처</div>
                    <div class="col-12 col-xxl-6">
                      <input class="form-control btn-square" type="text" maxlength="14" v-model="phoneNo" @input="handlePhoneNo" v-bind:class="formSubmitted ? phoneError ? 'is-invalid' : 'is-valid' : ''" :readOnly="isRead"/>
                      <div class="invalid-feedback" v-if="error.phoneNo">{{error.phoneNo}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="d-flex align-items-center col-8 me-4">
                    <div class="col-2 col-xxl-1">메모</div>
                    <div class="col-12 col-xxl-6">
                      <textarea class="form-control btn-square" type="text" v-model="memo" :readOnly="isRead"/>
                    </div>
                  </div>
                </div>
                <div class="error" v-if="error.message">{{ error.message }}</div>
                <div v-if="!isRead" class="col-12 col-xxl-5 d-flex justify-content-center">
                  <button class="btn btn-outline-light" type="submit">수정</button>
                  <button class="btn btn-outline-light ms-2" type="button" @click="deleteBranch">삭제</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { useRouter,useRoute } from 'vue-router'
import {useLoginAuth} from '@/stores/LoginAuthStore'
import useBranchRegistry from '@/composables/registerBranch'
const router = useRouter()
const route = useRoute()
const LoginAuthStore = useLoginAuth(router)
const id = route.params.id
const isRead = route.query.state === 'read'
const {
  group, userId, password, ssn, phoneNo, branchName, bizName, repName, bizNo, memo, error,
  loadBranch, saveBranch, deleteBranch
} = useBranchRegistry(LoginAuthStore, id)
onMounted(() => {
  preventBack();
  loadBranch()
})
const formSubmitted = ref(false);
const groupError = ref(false);
const idError = ref(false);
const pwError = ref(false);
const ssnError = ref(false);
const phoneError = ref(false);
const branchError = ref(false);
const bizNmError = ref(false);
const bizNoError = ref(false);
const repError = ref(false);
const handlePhoneNo = (e) => {
  if (e.target.value.replace(/[^0-9]/g, '').length === 12) {
    phoneNo.value = e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3`);
  } else {
    phoneNo.value = e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }
}
const validationSubmit = () => {
  formSubmitted.value = true;
  console.log(group.value, "group")
  if (group.value.code.length < 1) groupError.value = true;
  else groupError.value = false;
  
  if (userId.value.length < 1) idError.value = true;
  else idError.value = false;
  
  if (password.value.length < 1) pwError.value = true;
  else pwError.value = false;
  
  // if (ssn.value === null) ssnError.value = true;
  // else ssnError.value = false;

  if (phoneNo.value.length < 9 || phoneNo.value.length > 13) phoneError.value = true;
  else phoneError.value = false;

  if (branchName.value.length < 1) branchError.value = true;
  else branchError.value = false;

  // if (bizName.value.length < 1) bizNmError.value = true;
  // else bizNmError.value = false;

  // if (bizNo.value === null) bizNoError.value = true;
  // else bizNoError.value = false;

  if (repName.value.length < 1) repError.value = true;
  else repError.value = false;

  saveBranch();
}

const preventBack = () => {
  window.history.pushState(null, '', location.href)
  window.onpopstate = () => {
    history.go(1);
    router.push({name: route.query.name, query: route.query})
  }
}
</script>