<template>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{`${searchType === 'order' ? '주문' : 'A/S'} 진행상황 기본 정보`}}</h5>
        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>업체명</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square w-100" type="text" v-model="progressOrder.providerName"  placeholder="" readOnly>
          </div>
        </div>
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>상품분류</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square w-100" type="text" v-model="progressOrder.productCategoryName"  placeholder="" readOnly>
          </div>
        </div>
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>상품명</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square w-100" type="text" v-model="progressOrder.productName"  placeholder="" readOnly>
          </div>
        </div>
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>고객명</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square w-100" type="text" v-model="progressOrder.customerName"  placeholder="" readOnly>
          </div>
        </div>
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>전화번호1</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square w-100" type="text" v-model="progressOrder.customerPhoneNo1" placeholder="" readOnly>
          </div>
        </div>
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>주소</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square w-100" type="text" v-model="progressOrder.address" placeholder="" readOnly>
          </div>
        </div>
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>진행상황</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square w-100" type="text" v-model="progressOrder.statName" placeholder="" readOnly>
          </div>
        </div>
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>차종</span>
          </div>
          <div class="col-9">
            <div class="col-12 d-flex">
              <div class="col-4 pe-2">
                <input class="form-control btn-square col-7" type="text" :value="progressOrder.vhcCat === '00050' ? '국산' : progressOrder.vhcCat === '00051' ? '수입' : ''" placeholder="" readOnly>
              </div>
              <div class="col-8">
                <input class="form-control btn-square col-7" type="text" v-model="progressOrder.vhcName" placeholder="" readOnly>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 d-flex align-items-center justify-content-center mt-5">
          <div class="col-2">
            <span>접수자/{{ searchType === 'order' ? '주문 접수' : 'AS 배정' }} 일자</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square col-7" type="text" :value="progressOrder.assigneeName && progressOrder.daterPlaced ? `${progressOrder.assigneeName} / ${progressOrder.daterPlaced}` : ''" placeholder="" readOnly>
          </div>
        </div>           
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>접수자/해피콜 접수 일자</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square col-7" type="text" :value="progressOrder.hcUserName && progressOrder.hcRequestDateTime ? `${progressOrder.hcUserName} / ${progressOrder.hcRequestDateTime}` : ''" placeholder="" readOnly>
          </div>
        </div>           
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>해피콜문자</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square col-7" type="text" :value="smsHistListRef ? smsHistListRef : ''" placeholder="" readOnly>
          </div>
        </div>           
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>부재문자</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square col-7" type="text" :value="absentSmsHistListRef ? absentSmsHistListRef : ''" placeholder="" readOnly>
          </div>
        </div>           
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>통화</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square col-7" type="text" :value="hcCompleteHistListRef ? hcCompleteHistListRef : ''" placeholder="" readOnly>
          </div>
        </div>           
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>반려일자</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square col-7" type="text" v-model="progressOrder.rejectedDateTime" placeholder="" readOnly>
          </div>
        </div>           
        <div class="col-12 d-flex align-items-center justify-content-center mt-2">
          <div class="col-2">
            <span>예약일자</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square col-7" type="text" v-model="progressOrder.reservedDateTime" placeholder="" readOnly>
          </div>
        </div>           
        <div class="col-12 d-flex align-items-center justify-content-center mt-2 mb-5">
          <div class="col-2">
            <span>완료일자</span>
          </div>
          <div class="col-9">
            <input class="form-control btn-square col-7" type="text" v-model="progressOrder.completeDateTime" placeholder="" readOnly>
          </div>
        </div>           
      </div>
    </div>
  </div>
</template>
<script setup>
import Swal from 'sweetalert2';
import { ref, onMounted, computed, defineProps, toRefs, onUnmounted, watch } from 'vue';
import useOrderRegistry from '@/composables/registerOrder';
import commonApi from '@/composables/commonApi'
import { useRouter } from 'vue-router'
import { useLoginAuth } from '@/stores/LoginAuthStore';

const router = useRouter();
const LoginAuthStore = useLoginAuth(router)
const props = defineProps({
  orderId: {
    type: Number,
    required: true,
  },
  isRead: {
    type: Boolean,
    required: false,
  },
  searchType: {
    type: String,
    required: false,
  }
});
const { orderId, isRead, searchType } = toRefs(props);
const { getProgressOrder, progressOrder, getHistAll, histAll } = useOrderRegistry(LoginAuthStore)
const { loadCommonCode } = commonApi(LoginAuthStore);
const workTypeListRef = ref([])
const progressListRef = ref([])
const vhcCatCodeListRef = ref([])
const paymentCodeRef = ref([]);
const inChargePersonRef = ref({});
const path = ref(router.currentRoute.value.path);
const isClaim = path.value.includes('claim');
const isCal = path.value.includes('calculate');
const isOpenModal = ref(false);
const whatIam = isCal ? 'cal' : (searchType.value === 'order') ? 'order' : (isClaim || searchType.value === 'claim') ? 'claim' : 'as'
onMounted(async () => {
  const progressModalEl = document.getElementById(`${whatIam}Progress${orderId.value}`);
  progressModalEl.addEventListener('shown.bs.modal', async () => {
    if (!isOpenModal.value) {
      isOpenModal.value = true;
      await getProgressOrder(orderId.value)
      await getHistAll(orderId.value);
    }
  })

  progressModalEl.addEventListener('hidden.bs.modal', async () => {
    isOpenModal.value = false;
    progressModalEl.removeEventListener('shown.bs.modal', async () => {
      if (!isOpenModal.value) {
        isOpenModal.value = true;
        await getProgressOrder(orderId.value)
        await getHistAll(orderId.value);
      }
    })
  })
})
const absentSmsHistListRef = ref('');
const hcCompleteHistListRef = ref('');
const smsHistListRef = ref('');
const getHistAllList = async () => {
  const hist = {...histAll.value};
  if (hist?.absentSmsHistList?.length) {
    hist.absentSmsHistList.forEach((el, i) => {
      if (i !== 0) absentSmsHistListRef.value = `${absentSmsHistListRef.value}, ${el.nth}차: ${el.callDateTime}${el.remark ? '('+el.remark+')' : ''}`
      else absentSmsHistListRef.value = `${el.nth}차: ${el.callDateTime}${el.remark ? '('+el.remark+')' : ''}`
    })
  }
  if (hist?.hcCompleteHistList?.length) {
    hist.hcCompleteHistList.forEach((el, i) => {
      if (i !== 0) hcCompleteHistListRef.value = `${hcCompleteHistListRef.value}, ${el.nth}차: ${el.callDateTime}${el.remark ? '('+el.remark+')' : ''}`
      else hcCompleteHistListRef.value = `${el.nth}차: ${el.callDateTime}${el.remark ? '('+el.remark+')' : ''}`
    })
  }
  if (hist?.smsHistList?.length) {
    hist.smsHistList.forEach((el, i) => {
      if (i !== 0) smsHistListRef.value = `${smsHistListRef.value}, ${el.nth}차: ${el.callDateTime}${el.remark ? '('+el.remark+')' : ''}`
      else smsHistListRef.value = `${el.nth}차: ${el.callDateTime}${el.remark ? '('+el.remark+')' : ''}`
    })
  }
}
watch(histAll, (newVal, oldVal) => {
  if (newVal) getHistAllList();
})
const happyCall = () => {
  new Swal({
    title: '해피콜 요청이 완료되었습니다.'
  });
}
const changeOrder = () => {
  new Swal({
    title: '수정이 완료되었습니다.'
  });
}
</script>
<style lang="sass">
</style>