<template>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="modal-title">수정 내역</h5>
          <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <table v-if="histData.length" class="productManageTable">
          <thead>
            <tr>
              <th style="min-width:70px;">수정자</th>
              <th style="min-width:160px;">시간</th>
              <th style="min-width:80px;">항목</th>
              <th>내용</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="data in histData">
              <td>{{ data.updateUserName }}</td>
              <td>{{ data.createdDateTime }}</td>
              <td>{{ data.itemName }}</td>
              <td>{{ data.updateContent }}</td>
            </tr>
          </tbody>
        </table>
        <div v-else class="d-flex justify-content-center align-items-center mt-5 mb-5">
          <span>수정 내역이 없습니다.</span>
        </div>
        <div v-if="histData.length" class="customize-index">
          <nav class="m-b-30" aria-label="">
            <ul class="pagination justify-content-center pagination-primary">

              <li class="page-item prev-page" @click="firstPage" :disabled="pageNo + 1 === 1"><a class="page-link"
                  href="javascript:void(0)">처음</a></li>
              <li class="page-item" @click="prevPage" :disabled="pageNo + 1 === 1"><a class="page-link"
                  href="javascript:void(0)">이전</a></li>
              <li v-for="paginationNumber in totalPages" class="page-item customize-button"
                :class="{ 'active': paginationNumber === pageNo + 1 }"
                @click="updatePage(paginationNumber)">
                <a class="page-link" href="javascript:void(0)">{{ paginationNumber }}</a>
              </li>
              <li class="page-item" @click="nextPage" :disabled="pageNo + 1 === totalPages"><a class="page-link"
                  href="javascript:void(0)">다음</a></li>
              <li class="page-item next-page" @click="lastPage" :disabled="pageNo + 1 === totalPages"><a class="page-link"
                  href="javascript:void(0)">끝</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, defineProps, watch, toRefs, defineExpose } from 'vue';
import { useRouter } from 'vue-router'
import { useLoginAuth } from '@/stores/LoginAuthStore';
import useOrderRegistry from '@/composables/registerOrder';
const router = useRouter();
const LoginAuthStore = useLoginAuth(router)
const { modifyHistory, histPageInfo, histData, } = useOrderRegistry(LoginAuthStore)
const pno = ref(0)
const queryObj = ref({pno: pno.value});
const props = defineProps({
  orderId: {
    type: String,
    required: true,
  }
});
const searchHistory = async () => {
  queryObj.value.pno = pno.value
  await updatePage(1);
  isSearchClick.value = false;
}
const { orderId } = toRefs(props);
onMounted(async () => {
  await modifyHistory(orderId.value, queryObj.value);
  console.log(histData, "histData");
})
const pageNo = computed(() => histPageInfo.value?.pageNo);
const totalCount = computed(() => histPageInfo.value?.totalCount);
const totalPages = computed(() => histPageInfo.value?.totalPages);
const updatePage = async (paginationNumber) => {
  pno.value = paginationNumber - 1;
  queryObj.value.pno = pno.value
  await modifyHistory(orderId.value, queryObj.value);
};
const firstPage = () => {
  if (pno.value >= 1) updatePage(1);
};
const prevPage = () => {
  if (pno.value >= 1) updatePage(pno.value);
};
const nextPage = () => {
  if (pno.value + 2 <= totalPages.value) updatePage(pno.value + 2);
};
const lastPage = () => {
  if (pno.value + 2 <= totalPages.value) updatePage(totalPages.value);
};

const watchHappyCall = async () => {
  await modifyHistory(orderId.value, queryObj.value);
}

defineExpose({
  watchHappyCall
})

</script>
<style lang="scss" scoped>
.productManageTable {
  border-collapse: collapse;
  margin: 20px auto;
}
.productColumnWidth {
  width: 50%;
}
.dispOrderColumnWidth {
  width: 10%;
}
.updateColumnWidth {
  width: 15%;
}
th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

// th {
//     background-color: #f2f2f2;
// }

// tr:nth-child(even) {
//     background-color: #f2f2f2;
// }

.edit-button {
  padding: 5px 10px;
  cursor: pointer;
}
.confirm-button {
  position: absolute;
  right: 8px;
  top: 2px;
  font-size: 30px;
  cursor: pointer;
}
.edit-wrap {
  position: relative;
}
</style>